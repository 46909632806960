export default{
  "patient_shoulder_pain":"Schmerz",
  "patient_shoulder_pain_q1":"Haben Sie Schmerzen in der Schulter?",
  "patient_shoulder_pain_q1_a1":"nein",
  "patient_shoulder_pain_q1_a2":"ja",
  "patient_shoulder_pain_q2":"Auf einer Skala von 0-15, wie hoch waren Ihre Schmerzen bei alltäglichen Aktivitäten in den letzten 24 Stunden?",
  "patient_shoulder_pain_q2_a1":"Unerträglicher Schmerz",
  "patient_shoulder_pain_q2_a2":"Kein Schmerz",
  "patient_shoulder_pain_q3":"Wird Ihr Schlaf durch die Schmerzen beeinträchtigt?",
  "patient_shoulder_pain_q3_a1":"Unbeeinträchtigter Schlaf",
  "patient_shoulder_pain_q3_a2":"Teilweise beeinträchtigter Schlaf",
  "patient_shoulder_pain_q3_a3":"Schlafstörungen jede Nacht",
  "patient_shoulder_pain_q4":"Wie stark sind Ihre Schmerzen heute?",
  "patient_shoulder_pain_q5":"Rauchen Sie?",
  "patient_shoulder_pain_q5_a1":"nein",
  "patient_shoulder_pain_q5_a2":"ja",
  "patient_shoulder_pain_q5_a3":"Ex-Raucher",
  "patient_shoulder_pain_q5_a2_q1":"Wie viele Zigaretten am Tag?",
  "patient_shoulder_pain_q5_a3_q1":"Wie lange haben Sie aufgehört (Jahre)?",
  "patient_shoulder_pain_q5_a3_q2":"Wie viele Jahre haben Sie geraucht?",
  "patient_shoulder_pain_q6":"Nehmen Sie Schmerzmittel (z. B. Paracetamol etc.)?",
  "patient_shoulder_pain_q6_a1":"nein",
  "patient_shoulder_pain_q6_a2":"ja",
  "patient_shoulder_pain_q6_a2_q1":"Nehmen Sie  starke Schmerzmittel (z. B. Codien, Tramadol, Morphine)?",
  "patient_shoulder_pain_q6_a2_q1_a1":"nein",
  "patient_shoulder_pain_q6_a2_q1_a2":"ja",
  "patient_shoulder_pain_q6_a2_q2":"Wie viele Tabletten nehmen Sie durchschnittlich täglich?",
  "patient_shoulder_pain_q7":"Welche der folgenden Aussagen beschreibt Ihren Schmerz am besten?",
  "patient_shoulder_pain_q7_a1":"kein",
  "patient_shoulder_pain_q7_a2":"gelegentlich und leicht",
  "patient_shoulder_pain_q7_a3":"bei schwerer oder besonderer Aktivität vorhanden, gelegentlich Aspirin",
  "patient_shoulder_pain_q7_a4":"bei leichter Aktivität vorhanden, wenig oder gar nicht in Ruhe, nehme häufig Aspirin",
  "patient_shoulder_pain_q7_a5":"stets vorhanden, erträglich, gelegentlich starke Medikamente erforderlich",
  "patient_shoulder_pain_q7_a6":"ständig vorhanden, unerträglich, häufig starke Medikamente erforderlich",
  "patient_shoulder_pain_q8":"Haben Sie Kribbeln (Ameisenlaufen) im Arm?",
  "patient_shoulder_pain_q8_a1":"nein",
  "patient_shoulder_pain_q8_a2":"ja",
  "patient_shoulder_pain_q9":"Markieren Sie, wo der Schmerz ist",
  "patient_shoulder_pain_q9_a1":"Vorderseite",
  "patient_shoulder_pain_q9_a2":"Rücken",
  "patient_shoulder_function":"Funktion",
  "patient_shoulder_function_q1":"Welche der folgenden Aussagen beschreibt am besten die Funktion der betroffenen Schulter?",
  "patient_shoulder_function_q1_a1":"normal",
  "patient_shoulder_function_q1_a2":"nur leichte Einschränkungen, Arbeiten über Schulterhöhe möglich",
  "patient_shoulder_function_q1_a3":"die meiste Hausarbeit, Einkaufen, Autofahren, Haare kämmen, An- und Ausziehen",
  "patient_shoulder_function_q1_a4":"kann leichte Hausarbeiten und die meisten Aktivitäten des täglichen Lebens erledigen",
  "patient_shoulder_function_q1_a5":"nur leichte Aktivitäten möglich",
  "patient_shoulder_function_q1_a6":"kann Gliedmaß nicht benutzen",
  "patient_shoulder_function_instruction":"Wählen Sie die Zahl aus, die Ihre Fähigkeit angibt, die folgenden Aktivitäten auszuführen",
  "patient_shoulder_function_instruction_0":"unfähig",
  "patient_shoulder_function_instruction_1":"sehr schwierig",
  "patient_shoulder_function_instruction_2":"etwas schwierig",
  "patient_shoulder_function_instruction_3":"nicht schwierig",
  "patient_shoulder_function_note":"Hinweis: Bitte beantworten Sie die folgenden Fragen zu Ihrer Schulterfunktion.",
  "patient_shoulder_function_q2":"Mantel/Hemd anziehen",
  "patient_shoulder_function_q2_a0":"unfähig",
  "patient_shoulder_function_q2_a1":"sehr schwierig",
  "patient_shoulder_function_q2_a2":"etwas schwierig",
  "patient_shoulder_function_q2_a3":"nicht schwierig",
  "patient_shoulder_function_q3":"Schlafen Sie auf Ihrer schmerzenden oder betroffenen Seite",
  "patient_shoulder_function_q3_a0":"unfähig",
  "patient_shoulder_function_q3_a1":"sehr schwierig",
  "patient_shoulder_function_q3_a2":"etwas schwierig",
  "patient_shoulder_function_q3_a3":"nicht schwierig",
  "patient_shoulder_function_q4":"Rücken waschen/BH zumachen",
  "patient_shoulder_function_q4_a0":"unfähig",
  "patient_shoulder_function_q4_a1":"sehr schwierig",
  "patient_shoulder_function_q4_a2":"etwas schwierig",
  "patient_shoulder_function_q4_a3":"nicht schwierig",
  "patient_shoulder_function_q5":"Toilettengang",
  "patient_shoulder_function_q5_a0":"unfähig",
  "patient_shoulder_function_q5_a1":"sehr schwierig",
  "patient_shoulder_function_q5_a2":"etwas schwierig",
  "patient_shoulder_function_q5_a3":"nicht schwierig",
  "patient_shoulder_function_q6":"Haare kämmen",
  "patient_shoulder_function_q6_a0":"unfähig",
  "patient_shoulder_function_q6_a1":"sehr schwierig",
  "patient_shoulder_function_q6_a2":"etwas schwierig",
  "patient_shoulder_function_q6_a3":"nicht schwierig",
  "patient_shoulder_function_q7":"Rasieren/Make-up auftragen",
  "patient_shoulder_function_q7_a0":"unfähig",
  "patient_shoulder_function_q7_a1":"sehr schwierig",
  "patient_shoulder_function_q7_a2":"etwas schwierig",
  "patient_shoulder_function_q7_a3":"nicht schwierig",
  "patient_shoulder_function_q8":"Zähne putzen",
  "patient_shoulder_function_q8_a0":"unfähig",
  "patient_shoulder_function_q8_a1":"sehr schwierig",
  "patient_shoulder_function_q8_a2":"etwas schwierig",
  "patient_shoulder_function_q8_a3":"nicht schwierig",
  "patient_shoulder_function_q9":"Ein Glas aus einer vollen Flasche flüllen",
  "patient_shoulder_function_q9_a0":"unfähig",
  "patient_shoulder_function_q9_a1":"sehr schwierig",
  "patient_shoulder_function_q9_a2":"etwas schwierig",
  "patient_shoulder_function_q9_a3":"nicht schwierig",
  "patient_shoulder_function_q10":"Trinken (volles Glas zum Mund führen)",
  "patient_shoulder_function_q10_a0":"unfähig",
  "patient_shoulder_function_q10_a1":"sehr schwierig",
  "patient_shoulder_function_q10_a2":"etwas schwierig",
  "patient_shoulder_function_q10_a3":"nicht schwierig",
  "patient_shoulder_function_q11":"Suppe essen (vollen Löffel zum Mund führen)",
  "patient_shoulder_function_q11_a0":"unfähig",
  "patient_shoulder_function_q11_a1":"sehr schwierig",
  "patient_shoulder_function_q11_a2":"etwas schwierig",
  "patient_shoulder_function_q11_a3":"nicht schwierig",
  "patient_shoulder_function_q12":"Jemandem die Hand schütteln / eine Tür öffnen",
  "patient_shoulder_function_q12_a0":"unfähig",
  "patient_shoulder_function_q12_a1":"sehr schwierig",
  "patient_shoulder_function_q12_a2":"etwas schwierig",
  "patient_shoulder_function_q12_a3":"nicht schwierig",
  "patient_shoulder_function_q13":"Telefonieren (auf Ohrhöhe)",
  "patient_shoulder_function_q13_a0":"unfähig",
  "patient_shoulder_function_q13_a1":"sehr schwierig",
  "patient_shoulder_function_q13_a2":"etwas schwierig",
  "patient_shoulder_function_q13_a3":"nicht schwierig",
  "patient_shoulder_function_q14":"Einen Brief schreiben / auf Papier unterschreiben / eine Tastatur benutzen",
  "patient_shoulder_function_q14_a0":"unfähig",
  "patient_shoulder_function_q14_a1":"sehr schwierig",
  "patient_shoulder_function_q14_a2":"etwas schwierig",
  "patient_shoulder_function_q14_a3":"nicht schwierig",
  "patient_shoulder_function_q15":"Erreiche ein hohes Regal (über der Schulter)",
  "patient_shoulder_function_q15_a0":"unfähig",
  "patient_shoulder_function_q15_a1":"sehr schwierig",
  "patient_shoulder_function_q15_a2":"etwas schwierig",
  "patient_shoulder_function_q15_a3":"nicht schwierig",
  "patient_shoulder_function_q16":"Heben Sie 0,5 kg höher als die Schulter",
  "patient_shoulder_function_q16_a0":"unfähig",
  "patient_shoulder_function_q16_a1":"sehr schwierig",
  "patient_shoulder_function_q16_a2":"etwas schwierig",
  "patient_shoulder_function_q16_a3":"nicht schwierig",
  "patient_shoulder_function_q17":"Heben Sie 4,5 kg höher als die Schulter",
  "patient_shoulder_function_q17_a0":"unfähig",
  "patient_shoulder_function_q17_a1":"sehr schwierig",
  "patient_shoulder_function_q17_a2":"etwas schwierig",
  "patient_shoulder_function_q17_a3":"nicht schwierig",
  "patient_shoulder_function_q18":"Können Sie 10 kg an Ihrer Seite auf der betroffenen Schulter tragen?",
  "patient_shoulder_function_q18_a0":"unfähig",
  "patient_shoulder_function_q18_a1":"sehr schwierig",
  "patient_shoulder_function_q18_a2":"etwas schwierig",
  "patient_shoulder_function_q18_a3":"nicht schwierig",
  "patient_shoulder_function_q19":"Einen Ball unterhand werfen (mindestens 3 Meter)",
  "patient_shoulder_function_q19_a0":"unfähig",
  "patient_shoulder_function_q19_a1":"sehr schwierig",
  "patient_shoulder_function_q19_a2":"etwas schwierig",
  "patient_shoulder_function_q19_a3":"nicht schwierig",
  "patient_shoulder_function_q20":"Einen Ball überhand werfen (mindestens 6 Meter)",
  "patient_shoulder_function_q20_a0":"unfähig",
  "patient_shoulder_function_q20_a1":"sehr schwierig",
  "patient_shoulder_function_q20_a2":"etwas schwierig",
  "patient_shoulder_function_q20_a3":"nicht schwierig",
  "patient_shoulder_function_q21":"Erledige die volle übliche Arbeit",
  "patient_shoulder_function_q21_a0":"unfähig",
  "patient_shoulder_function_q21_a1":"sehr schwierig",
  "patient_shoulder_function_q21_a2":"etwas schwierig",
  "patient_shoulder_function_q21_a3":"nicht schwierig",
  "patient_shoulder_function_q22":"Wieviel Arbeit können Sie mit Ihrer beeinträchtigten Schulter täglich leisten?",
  "patient_shoulder_function_q22_a0":"Keine",
  "patient_shoulder_function_q22_a1":"Ich kann alles machen.",
  "patient_shoulder_function_q23":"Volle übliche Hobbies/Sport ausüben",
  "patient_shoulder_function_q23_a0":"unfähig",
  "patient_shoulder_function_q23_a1":"sehr schwierig",
  "patient_shoulder_function_q23_a2":"etwas schwierig",
  "patient_shoulder_function_q23_a3":"nicht schwierig",
  "patient_shoulder_function_q24":"Wieviele Freizeitaktivitäten können Sie mit Ihrer beeinträchtigten Schulter machen?",
  "patient_shoulder_function_q24_a0":"Keine",
  "patient_shoulder_function_q24_a1":"Alle",
  "patient_shoulder_function_q25":"Bitte führen Sie die Arbeitstätigkeiten auf",
  "patient_shoulder_function_q26":"Bitte geben Sie Hobbys / sportliche Aktivitäten an",
  "patient_shoulder_feeling_instability":"Gefühl der Instabilität",
  "patient_shoulder_feeling_instability_q1":"Fühlt sich Ihre Schulter instabil an (als würde sie sich ausrenken)?",
  "patient_shoulder_feeling_instability_q1_a1":"nein",
  "patient_shoulder_feeling_instability_q1_a2":"ja",
  "patient_shoulder_feeling_instability_q1_a2_q1":"Bitte geben Sie an, wie instabil Ihre Schulter ist",
  "patient_shoulder_wosi":"Western Ontario Schulterinstabilitätsindex",
  "patient_shoulder_wosi_instruction":"Anleitung: Sie werden gebeten, in diesem Teil des Fragebogens anzugeben, wie viele Symptome Sie in der letzten Woche im Zusammenhang mit Ihrer problematischen Schulter hatten. Bewegen Sie einfach den Schieberegler entlang der Linie, so daß er genau Ihren Symptomen entspricht.",
  "patient_shoulder_wosi_note":"Hinweis: Je weiter Sie den Schieberegler nach rechts bewegen, desto häufiger tritt dieses Symptom auf. Je weiter Sie den Schieberegler nach links bewegen, desto seltener tritt dieses Symptom auf. Wenn Sie Fragen zur Absicht einer bestimmten Frage haben, wenden Sie sich bitte an uns.",
  "patient_shoulder_wosi_sectionA":"Abschnitt A: Körperliche Symptome",
  "patient_shoulder_wosi_q1":"Wie stark schmerzt Ihre Schulter bei Aktivitäten über Kopf?",
  "patient_shoulder_wosi_q1_a1":"keine Schmerzen",
  "patient_shoulder_wosi_q1_a2":"extreme Schmerzen",
  "patient_shoulder_wosi_q2":"Wie viel Schmerz oder Pochen verspüren Sie in Ihrer Schulter?",
  "patient_shoulder_wosi_q2_a1":"Kein(e) Schmerzen/Pochen",
  "patient_shoulder_wosi_q2_a2":"extreme(s) Schmerzen / Pochen",
  "patient_shoulder_wosi_q3":"Wie viel Schwäche oder Kraftlosigkeit spüren Sie in Ihrer Schulter?",
  "patient_shoulder_wosi_q3_a1":"Keine Schwäche",
  "patient_shoulder_wosi_q3_a2":"extreme Schwäche",
  "patient_shoulder_wosi_q4":"Wie viel Ermüdung oder Mangel an Ausdauer spüren Sie in Ihrer Schulter?",
  "patient_shoulder_wosi_q4_a1":"keine Ermüdung",
  "patient_shoulder_wosi_q4_a2":"extreme Ermüdung",
  "patient_shoulder_wosi_q5":"Wie viel Klicken, Knacken oder Schnappen spüren Sie in Ihrer Schulter?",
  "patient_shoulder_wosi_q5_a1":"kein Klicken",
  "patient_shoulder_wosi_q5_a2":"extremes Klicken",
  "patient_shoulder_wosi_q6":"Wie viel Steifheit spüren Sie in Ihrer Schulter?",
  "patient_shoulder_wosi_q6_a1":"keine Steifheit",
  "patient_shoulder_wosi_q6_a2":"extreme Steifheit",
  "patient_shoulder_wosi_q7":"Wie viel Unbehagen verspüren Sie in Ihren Nackenmuskeln als Folge Ihrer Schulter?",
  "patient_shoulder_wosi_q7_a1":"kein Unbehagen",
  "patient_shoulder_wosi_q7_a2":"extremes Unbehagen",
  "patient_shoulder_wosi_q8":"Wie stark fühlt sich Ihre Schulter instabil order locker an?",
  "patient_shoulder_wosi_q8_a1":"keine Instabilität",
  "patient_shoulder_wosi_q8_a2":"extreme Instabilität",
  "patient_shoulder_wosi_q9":"Wie viel kompensieren Sie Ihre Schulter mit anderen Muskeln?",
  "patient_shoulder_wosi_q9_a1":"gar nicht",
  "patient_shoulder_wosi_q9_a2":"extrem",
  "patient_shoulder_wosi_q10":"Wie viel Bewegungseinschränkung haben Sie in Ihrer Schulter?",
  "patient_shoulder_wosi_q10_a1":"keine Bewegungseinschränkung",
  "patient_shoulder_wosi_q10_a2":"extreme Bewegungseinschränkung",
  "patient_shoulder_wosi_sectionB":"Abschnitt B: Sport / Freizeit / Arbeit",
  "patient_shoulder_wosi_q11":"Wie sehr schränkt Ihre Schulter Ihre Fähigkeit ein, an Sport- oder Freizeitaktivitäten teilzunehmen?",
  "patient_shoulder_wosi_q11_a1":"nicht eingeschränkt",
  "patient_shoulder_wosi_q11_a2":"extrem eingeschränkt",
  "patient_shoulder_wosi_q12":"Wie stark hat Ihre Schulter Ihre Fähigkeit beeinträchtigt, die spezifischen Fähigkeiten auszuführen, die für Ihren Sport oder Ihre Arbeit erforderlich sind?",
  "patient_shoulder_wosi_q12_note":"Wenn Ihre Schulter sowohl Sport als auch Arbeit betrifft, überlegen Sie, welcher Bereich am stärksten betroffen ist.",
  "patient_shoulder_wosi_q12_a1":"nicht betroffen",
  "patient_shoulder_wosi_q12_a2":"extrem betroffen",
  "patient_shoulder_wosi_q13":"Wie sehr haben Sie das Bedürfnis, Ihren Arm bei Aktivitäten zu schützen?",
  "patient_shoulder_wosi_q13_a1":"gar nicht",
  "patient_shoulder_wosi_q13_a2":"extrem",
  "patient_shoulder_wosi_q14":"Wie schwierig ist es für Sie, schwere Gegenstände unter Schulterhöhe zu heben?",
  "patient_shoulder_wosi_q14_a1":"keine Schwierigkeit",
  "patient_shoulder_wosi_q14_a2":"extreme Schwierigkeit",
  "patient_shoulder_wosi_sectionC":"Abschnitt C: Lebensstil",
  "patient_shoulder_wosi_q15":"Wie viel Angst haben Sie, auf die Schulter zu fallen?",
  "patient_shoulder_wosi_q15_a1":"keine Angst",
  "patient_shoulder_wosi_q15_a2":"extreme Angst",
  "patient_shoulder_wosi_q16":"Wie schwierig ist es für Sie, Ihr gewünschtes Fitnessniveau aufrechtzuerhalten?",
  "patient_shoulder_wosi_q16_a1":"keine Schwierigkeit",
  "patient_shoulder_wosi_q16_a2":"extreme Schwierigkeit",
  "patient_shoulder_wosi_q17":"Wie schwer fällt es Ihnen, mit Familie oder Freunden „herumzualbern“?",
  "patient_shoulder_wosi_q17_a1":"keine Schwierigkeit",
  "patient_shoulder_wosi_q17_a2":"extreme Schwierigkeit",
  "patient_shoulder_wosi_q18":"Wie viel Schlafschwierigkeiten haben Sie wegen Ihrer Schulter?",
  "patient_shoulder_wosi_q18_a1":"keine Schwierigkeiten",
  "patient_shoulder_wosi_q18_a2":"extreme Schwierigkeiten",
  "patient_shoulder_wosi_sectionD":"Abschnitt D: Emotionen",
  "patient_shoulder_wosi_q19":"Wie bewusst sind Sie Ihrer Schulter?",
  "patient_shoulder_wosi_q19_a1":"nicht bewusst",
  "patient_shoulder_wosi_q19_a2":"extrem bewusst",
  "patient_shoulder_wosi_q20":"Wie besorgt sind Sie über eine Verschlechterung Ihrer Schulter?",
  "patient_shoulder_wosi_q20_a1":"keine Sorge",
  "patient_shoulder_wosi_q20_a2":"äußerst besorgt",
  "patient_shoulder_wosi_q21":"Wie viel Frustration empfinden Sie wegen Ihrer Schulter?",
  "patient_shoulder_wosi_q21_a1":"nicht frustriert",
  "patient_shoulder_wosi_q21_a2":"extrem frustriert",
  "patient_shoulder_ac_joint":"Schultereckgelenk",
  "patient_shoulder_ac_joint_q1":"Leiden Sie unter einer Instabilität Ihres Schultereckgelenk?",
  "patient_shoulder_ac_joint_q1_note":"Hinweis: Das Schultereckgelenk ist das Gelenk an der Oberseite der Schulter, das das Schlüsselbein und die vordere Spitze des Schulterblatts verbindet.",
  "patient_shoulder_ac_joint_q1_a1":"Nein",
  "patient_shoulder_ac_joint_q1_a2":"Ja",
  "patient_shoulder_sacs":"Der spezifische AC-Score (SACS)",
  "patient_shoulder_sacs_explanation":"Erläuterung: Bei jeder Frage werden Sie gebeten, entweder Ihr Schmerzniveau oder Ihre Schwierigkeiten bei der Ausführung verschiedener Aufgaben zu bewerten. Die Bewertungsskala ist so konzipiert, dass 0 keine Schmerzen oder Schwierigkeiten bedeutet, während 10 die stärksten Schmerzen oder Schwierigkeiten widerspiegelt.",
  "patient_shoulder_sacs_note":"Hinweis: Eine Bewertung von 2 würde minimale Schmerzen bedeuten. Wenn Sie Fragen zur Absicht einer bestimmten Frage haben, wenden Sie sich bitte an uns.",
  "patient_shoulder_sacs_sectionA":"Abschnitt A: Schmerz",
  "patient_shoulder_sacs_a1":"Bewerten Sie Ihre Schmerzen in Ihrer Schulter, wenn sie am schlimmsten – am stärksten – sind.",
  "patient_shoulder_sacs_a1_a1":"kein Schmerz",
  "patient_shoulder_sacs_a1_a2":"schwerste Schmerzen",
  "patient_shoulder_sacs_a2":"Bewerten Sie Ihr Schmerzniveau in Ihrer Schulter, wenn sie am besten ist.",
  "patient_shoulder_sacs_a2_a1":"kein Schmerz",
  "patient_shoulder_sacs_a2_a2":"schwerste Schmerzen",
  "patient_shoulder_sacs_a3":"Bewerten Sie den Schmerz ein, wenn Sie Ihre Schulter drücken/berühren oder wenn Druck auf Ihre Schulter ausgeübt wird.",
  "patient_shoulder_sacs_a3_note":"z.B. Trageriemen für Laptop oder Handtasche",
  "patient_shoulder_sacs_a3_a1":"kein Schmerz",
  "patient_shoulder_sacs_a3_a2":"schwerste Schmerzen",
  "patient_shoulder_sacs_a4":"Bewerten Sie das Ausmaß der Schmerzspannung oder des Ziehens, das Sie in Ihrem Nacken und/oder der betroffenen Schulterblattregion spüren.",
  "patient_shoulder_sacs_a4_a1":"kein Schmerz",
  "patient_shoulder_sacs_a4_a2":"schwerste Schmerzen",
  "patient_shoulder_sacs_sectionB":"Abschnitt B: Funktion",
  "patient_shoulder_sacs_b1":"Bewerten Sie das Klicken und/oder Knirschen und/oder Klopfen, das Sie in Ihrer Schulter spüren.",
  "patient_shoulder_sacs_b1_a1":"kein Klicken",
  "patient_shoulder_sacs_b1_a2":"extremes Klicken",
  "patient_shoulder_sacs_b2":"Geben Sie an, ob Ihr Arm das Bedürfnis verspürt, beim Gehen gestützt zu werden",
  "patient_shoulder_sacs_b2_note":"z.B. Ihren Arm in einer Tasche ruhen lassen oder eine Schlinge verwenden",
  "patient_shoulder_sacs_b2_a1":"keine Notwendigkeit zu unterstützen",
  "patient_shoulder_sacs_b2_a2":"extreme Unterstützungsbedürftigkeit",
  "patient_shoulder_sacs_b3":"Bewerten Sie Ihren Schwierigkeitsgrad, Ihren Arm zu belasten oder sich auf Ihren Arm zu lehnen",
  "patient_shoulder_sacs_b3_note":"z.B. Pushup Cycling Yoga",
  "patient_shoulder_sacs_b3_a1":"keine Schwierigkeit",
  "patient_shoulder_sacs_b3_a2":"extreme Schwierigkeiten oder nicht fähig zu tun",
  "patient_shoulder_sacs_b4":"Bewerten Sie Ihren Schwierigkeitsgrad, wenn Sie schwere Gegenstände länger als 5 Minuten an Ihrer Seite tragen",
  "patient_shoulder_sacs_b4_note":"z.B. volle Einkaufstasche",
  "patient_shoulder_sacs_b4_a1":"keine Schwierigkeit",
  "patient_shoulder_sacs_b4_a2":"extreme Schwierigkeiten oder nicht fähig zu tun",
  "patient_shoulder_sacs_b5":"Bewerten Sie den Schwierigkeitsgrad, mit dem betroffenen Arm über Ihren Körper zu reichen",
  "patient_shoulder_sacs_b5_note":"z.B. um zur nicht betroffenen Schulter zu reichen und sie zu berühren",
  "patient_shoulder_sacs_b5_a1":"keine Schwierigkeit",
  "patient_shoulder_sacs_b5_a2":"extreme Schwierigkeiten oder nicht fähig zu tun",
  "patient_shoulder_sacs_b6":"Bewerten Sie Ihren Schwierigkeitsgrad schwerer Gegenstände oder Gewichte über den Kopf zu heben",
  "patient_shoulder_sacs_b6_note":"z.B. mehr als fünf Kilogramm",
  "patient_shoulder_sacs_b6_a1":"keine Schwierigkeit",
  "patient_shoulder_sacs_b6_a2":"extreme Schwierigkeiten oder nicht fähig zu tun",
  "patient_shoulder_sacs_b7":"Bewerten  Sie den Schwierigkeitsgrad, den betroffenen Arm längere Zeit auszustrecken",
  "patient_shoulder_sacs_b7_note":"z.B. das Lenkrad während der Fahrt festhalten",
  "patient_shoulder_sacs_b7_a1":"keine Schwierigkeit",
  "patient_shoulder_sacs_b7_a2":"extreme Schwierigkeiten oder nicht fähig zu tun",
  "patient_shoulder_sacs_b8":"Bewerten Sie den Schwierigkeitsgrad, den Sie bei der Ausübung Ihrer gewöhnlichen Tätigkeit haben.",
  "patient_shoulder_sacs_b8_a1":"keine Schwierigkeit",
  "patient_shoulder_sacs_b8_a2":"extreme Schwierigkeiten oder nicht fähig zu tun",
  "patient_shoulder_sacs_b8_a3":"unzutreffend",
  "patient_shoulder_sacs_b9":"Bewerten Sie, wie sehr Sie Ihren gewohnten Beruf ändern/modifizieren mussten, um Ihr Schulterproblem zu kompensieren.",
  "patient_shoulder_sacs_b9_a1":"keine Schwierigkeit",
  "patient_shoulder_sacs_b9_a2":"extreme Schwierigkeiten oder nicht fähig zu tun",
  "patient_shoulder_sacs_b9_a3":"unzutreffend",
  "patient_shoulder_sacs_b10":"Bewerten Sie den Schwierigkeitsgrad, den Sie bei der Ausübung Ihrer primären sportlichen Aktivität erleben.",
  "patient_shoulder_sacs_b10_a1":"keine Schwierigkeit",
  "patient_shoulder_sacs_b10_a2":"extreme Schwierigkeiten oder nicht fähig zu tun",
  "patient_shoulder_sacs_b10_a3":"unzutreffend",
  "patient_shoulder_sacs_b11":"Bewerten Sie, wie sehr Sie Ihre primäre sportliche Aktivität ändern mussten, um Ihr Schulterproblem zu kompensieren.",
  "patient_shoulder_sacs_b11_a1":"keine Schwierigkeit",
  "patient_shoulder_sacs_b11_a2":"extreme Schwierigkeiten oder nicht fähig zu tun",
  "patient_shoulder_sacs_b11_a3":"unzutreffend",
  "patient_shoulder_sacs_sectionC":"Abschnitt C: Lebensqualität und Zufriedenheit",
  "patient_shoulder_sacs_c1":"Bewerten Sie den Unterschied im Aussehen der Knochen in Ihrer Schulter im Vergleich zu einer normalen Schulter",
  "patient_shoulder_sacs_c1_note":"einschließlich des Vorhandenseins eines Klumpens",
  "patient_shoulder_sacs_c1_a1":"kein Unterschied",
  "patient_shoulder_sacs_c1_a2":"extrem unterschiedlich",
  "patient_shoulder_sacs_c2":"Bewerten Sie das allgemeine Ausmaß an Angstzustände oder Stress, das Ihr Schulterproblem bei Ihnen verursacht hat.",
  "patient_shoulder_sacs_c2_a1":"keine Angstzustände/Stress",
  "patient_shoulder_sacs_c2_a2":"schweres Maß an Angstzuständen/Stress",
  "patient_shoulder_sacs_c3":"Wie oft sind Sie sich Ihrer Schulterprobleme bewusst?",
  "patient_shoulder_sacs_c3_a1":"niemals",
  "patient_shoulder_sacs_c3_a2":"ständig",
  "patient_shoulder_sacs_c4":"Haben Sie Ihren Lebensstil geändert, um eine potenzielle Schädigung oder Verschlimmerung Ihres Schulterproblems zu vermeiden?",
  "patient_shoulder_sacs_c4_a1":"gar nicht",
  "patient_shoulder_sacs_c4_a2":"total",
  "patient_shoulder_sacs_c5":"Wie unglücklich/unzufrieden sind Sie mit Ihrer Schulter?",
  "patient_shoulder_sacs_c5_a1":"extrem glücklich",
  "patient_shoulder_sacs_c5_a2":"extrem unglücklich",
  "patient_shoulder_sacs_c6":"Wie würden Sie Ihre Schulter insgesamt als Prozentsatz im Vergleich zu Ihren Erwartungen an eine „normale“ Schulter bewerten, bei der 0 % schlecht und 100 % sehr gut ist?",
  "patient_shoulder_satisfaction":"Zufriedenheit",
  "patient_shoulder_satisfaction_q1":"Betreffs Ihrer betroffenen Schulter, sind Sie derzeit",
  "patient_shoulder_satisfaction_q1_a1":"zufrieden und/oder besser",
  "patient_shoulder_satisfaction_q1_a2":"nicht zufrieden und/oder schlimmer",
  "patient_shoulder_complete":"Ende das Fragebogens",
  "patient_shoulder_complete_text1":"Vielen Dank, dass Sie sich die Zeit genommen haben, dieses Formular auszufüllen.",
  "patient_shoulder_complete_text2":"Bitte klicken Sie auf „einreichen“, um den Vorgang abzuschließen und Ihre Ergebnisse anzuzeigen.",
  "patient_shoulder_complete_text3":"Ihre Antworten werden vertraulich behandelt und in allen Berichten werden nur anonymisierte Informationen verwendet.",
  "patient_shoulder_results":"Ergebnisse",
  "patient_shoulder_results_text1":"Nachfolgend finden Sie Ihre Schmerz- und Schulterfunktionswerte, die anhand Ihres Fragebogens berechnet wurden",
  "patient_shoulder_results_pain":"Schmerz",
  "patient_shoulder_results_function":"Funktion",
  "patient_shoulder_results_instability":"Instabilität",
  "patient_shoulder_results_selfevaluation":"Schulterselbstbewertung",
  "patient_shoulder_results_text2":"Ihr Arzt wird in Kürze bei Ihnen sein.",
  "patient_hip_pain":"Schmerz",
  "patient_hip_pain_q1":"INSGESAMT wie würden Sie die Schmerzen beschreiben, die Sie IM LETZTEN MONAT normalerweise in der Hüfte haben?",
  "patient_hip_pain_q1_a1":"keine",
  "patient_hip_pain_q1_a2":"sehr milde",
  "patient_hip_pain_q1_a3":"leichte",
  "patient_hip_pain_q1_a4":"mäßige",
  "patient_hip_pain_q1_a5":"schwere",
  "patient_hip_pain_q2":"Wie stark sind die Schmerzen in Ihrer Hüfte ZU DIESEM ZEITPUNKT? (0 bedeutet keine Schmerzen und 10 bedeutet die stärksten Schmerzen, die Sie sich vorstellen können)",
  "patient_hip_pain_q2_a1":"keine Schmerzen",
  "patient_hip_pain_q2_a2":"schwerste Schmerzen",
  "patient_hip_pain_q3":"Welche der folgenden Aussagen beschreibt Ihre Schmerzen am besten?",
  "patient_hip_pain_q3_a1":"keine/ignoriere",
  "patient_hip_pain_q3_a2":"leichte, gelegentlich, keine Kompromisse in der Aktivität",
  "patient_hip_pain_q3_a3":"leichte Schmerzen, keine Auswirkung auf durchschnittliche Aktivitäten, Schmerzen nach normaler Aktivität, nehme Aspirin ein",
  "patient_hip_pain_q3_a4":"mäßige, erträglich, mache Zugeständnisse, nehme gelegentlich Codein",
  "patient_hip_pain_q3_a5":"starke Schmerzen, starke Einschränkung der Aktivitäten",
  "patient_hip_pain_q3_a6":"völlig behindert",
  "patient_hip_pain_q4":"Wie OFT schmerzt Ihre Hüfte?",
  "patient_hip_pain_q4_a1":"niemals",
  "patient_hip_pain_q4_a2":"monatlich",
  "patient_hip_pain_q4_a3":"wöchentlich",
  "patient_hip_pain_q4_a4":"täglich",
  "patient_hip_pain_q4_a5":"immer",
  "patient_hip_pain_q5":"Wie sehr haben Ihre Hüftschmerzen IHRE ÜBLICHE ARBEIT (einschließlich Hausarbeit) BEEINFLUSST?",
  "patient_hip_pain_q5_a1":"gar nicht",
  "patient_hip_pain_q5_a2":"ein bisschen",
  "patient_hip_pain_q5_a3":"in Maßen",
  "patient_hip_pain_q5_a4":"außerordentlich",
  "patient_hip_pain_q5_a5":"total",
  "patient_hip_pain_instruction":"Wie viel HÜFTSCHMERZEN haben Sie bei den folgenden Aktivitäten erlebt?",
  "patient_hip_pain_instruction_0":"keine",
  "patient_hip_pain_instruction_1":"leichte",
  "patient_hip_pain_instruction_2":"mäßige",
  "patient_hip_pain_instruction_3":"schwere",
  "patient_hip_pain_instruction_4":"extreme",
  "patient_hip_pain_note":"Hinweis: Bitte beantworten Sie die folgenden Fragen zu Ihren Hüftaktivitäten.",
  "patient_hip_pain_q6":"Hüfte vollständig strecken",
  "patient_hip_pain_q6_a1":"keine",
  "patient_hip_pain_q6_a2":"leichte",
  "patient_hip_pain_q6_a3":"mäßige",
  "patient_hip_pain_q6_a4":"schwere",
  "patient_hip_pain_q6_a5":"extreme",
  "patient_hip_pain_q7":"Hüfte vollständig beugen",
  "patient_hip_pain_q7_a1":"keine",
  "patient_hip_pain_q7_a2":"leichte",
  "patient_hip_pain_q7_a3":"mäßige",
  "patient_hip_pain_q7_a4":"schwere",
  "patient_hip_pain_q7_a5":"extreme",
  "patient_hip_pain_q8":"Gehen auf ebener Fläche",
  "patient_hip_pain_q8_a1":"keine",
  "patient_hip_pain_q8_a2":"leichte",
  "patient_hip_pain_q8_a3":"mäßige",
  "patient_hip_pain_q8_a4":"schwere",
  "patient_hip_pain_q8_a5":"extreme",
  "patient_hip_pain_q9":"Wie lange können Sie gehen, bevor die Hüftschmerzen stark werden? (mit oder ohne Stock)",
  "patient_hip_pain_q9_a1":"keine Schmerzen/mehr als 30 Minuten/unbegrenzt",
  "patient_hip_pain_q9_a2":"16–30 Minuten/6 Blöcke",
  "patient_hip_pain_q9_a3":"5–15 Minuten/2–3 Blöcke",
  "patient_hip_pain_q9_a4":"nur im Haus/im Innenbereich",
  "patient_hip_pain_q9_a5":"kann überhaupt nicht gehen/starke Schmerzen beim Gehen/nur von Bett zu Stuhl",
  "patient_hip_pain_q10":"Gehen auf einer harten Oberfläche (Asphaltbeton usw.)",
  "patient_hip_pain_q10_a1":"keine",
  "patient_hip_pain_q10_a2":"leichte",
  "patient_hip_pain_q10_a3":"mäßige",
  "patient_hip_pain_q10_a4":"schwere",
  "patient_hip_pain_q10_a5":"extreme",
  "patient_hip_pain_q11":"Gehen auf unebenem Untergrund",
  "patient_hip_pain_q11_a1":"keine",
  "patient_hip_pain_q11_a2":"leichte",
  "patient_hip_pain_q11_a3":"mäßige",
  "patient_hip_pain_q11_a4":"schwere",
  "patient_hip_pain_q11_a5":"extreme",
  "patient_hip_pain_q12":"Treppen herauf oder herunter gehen",
  "patient_hip_pain_q12_a1":"keine",
  "patient_hip_pain_q12_a2":"leichte",
  "patient_hip_pain_q12_a3":"mäßige",
  "patient_hip_pain_q12_a4":"schwere",
  "patient_hip_pain_q12_a5":"extreme",
  "patient_hip_pain_q13":"Nachts im Bett",
  "patient_hip_pain_q13_a1":"keine",
  "patient_hip_pain_q13_a2":"leichte",
  "patient_hip_pain_q13_a3":"mäßige",
  "patient_hip_pain_q13_a4":"schwere",
  "patient_hip_pain_q13_a5":"extreme",
  "patient_hip_pain_q14":"Wie oft wurden Sie nachts im Bett von Hüftschmerzen geplagt?",
  "patient_hip_pain_q14_a1":"keine Nacht",
  "patient_hip_pain_q14_a2":"nur 1 oder 2 Nächte",
  "patient_hip_pain_q14_a3":"manche Nächte",
  "patient_hip_pain_q14_a4":"die meisten Nächte",
  "patient_hip_pain_q14_a5":"jede Nacht",
  "patient_hip_pain_q15":"Sitzend oder liegend",
  "patient_hip_pain_q15_a1":"keine",
  "patient_hip_pain_q15_a2":"leichte",
  "patient_hip_pain_q15_a3":"mäßige",
  "patient_hip_pain_q15_a4":"schwere",
  "patient_hip_pain_q15_a5":"extreme",
  "patient_hip_pain_q16":"Aufrecht stehend",
  "patient_hip_pain_q16_a1":"keine",
  "patient_hip_pain_q16_a2":"leichte",
  "patient_hip_pain_q16_a3":"mäßige",
  "patient_hip_pain_q16_a4":"schwere",
  "patient_hip_pain_q16_a5":"extreme",
  "patient_hip_pain_q17":"Von einem Stuhl aufstehen",
  "patient_hip_pain_q17_a1":"keine",
  "patient_hip_pain_q17_a2":"leichte",
  "patient_hip_pain_q17_a3":"mäßige",
  "patient_hip_pain_q17_a4":"schwere",
  "patient_hip_pain_q17_a5":"extreme",
  "patient_hip_pain_q18":"Nach einer Aktivität",
  "patient_hip_pain_q18_a1":"keine",
  "patient_hip_pain_q18_a2":"leichte",
  "patient_hip_pain_q18_a3":"mäßige",
  "patient_hip_pain_q18_a4":"schwere",
  "patient_hip_pain_q18_a5":"extreme",
  "patient_hip_other_symptoms":"Weitere Symptome",
  "patient_hip_other_symptoms_instruction1":"Bitte wählen Sie die Antwort aus, die Ihre Hüftsymptomen am besten widerspiegelt.",
  "patient_hip_other_symptoms_instruction2":"Wenn Sie unsicher sind, wie Sie eine Frage beantworten sollen, geben Sie bitte die bestmögliche Antwort.",
  "patient_hip_other_symptoms_instruction3":"Hatten Sie folgendes",
  "patient_hip_other_symptoms_instruction_0":"keine",
  "patient_hip_other_symptoms_instruction_1":"mild",
  "patient_hip_other_symptoms_instruction_2":"moderate",
  "patient_hip_other_symptoms_instruction_3":"severe",
  "patient_hip_other_symptoms_instruction_4":"extrem",
  "patient_hip_other_symptoms_q1":"Knirschendes Klicken oder andere Geräusche aus Ihrer Hüfte",
  "patient_hip_other_symptoms_q1_a1":"niemals",
  "patient_hip_other_symptoms_q1_a2":"selten",
  "patient_hip_other_symptoms_q1_a3":"manchmal",
  "patient_hip_other_symptoms_q1_a4":"oft",
  "patient_hip_other_symptoms_q1_a5":"immer",
  "patient_hip_other_symptoms_q2":"Widerstand oder Blockade",
  "patient_hip_other_symptoms_q2_a1":"kein/e",
  "patient_hip_other_symptoms_q2_a2":"leichter/e",
  "patient_hip_other_symptoms_q2_a3":"mäßiger/e",
  "patient_hip_other_symptoms_q2_a4":"schwerer/e",
  "patient_hip_other_symptoms_q2_a5":"extremer/e",
  "patient_hip_other_symptoms_q3":"Steifheit nach dem ersten Erwachen am Morgen",
  "patient_hip_other_symptoms_q3_a1":"keine",
  "patient_hip_other_symptoms_q3_a2":"leichte",
  "patient_hip_other_symptoms_q3_a3":"mäßige",
  "patient_hip_other_symptoms_q3_a4":"schwere",
  "patient_hip_other_symptoms_q3_a5":"extreme",
  "patient_hip_other_symptoms_q4":"Steifheit nach dem Sitzen, Liegen oder Ruhen später am Tag",
  "patient_hip_other_symptoms_q4_a1":"keine",
  "patient_hip_other_symptoms_q4_a2":"leichte",
  "patient_hip_other_symptoms_q4_a3":"mäßige",
  "patient_hip_other_symptoms_q4_a4":"schwere",
  "patient_hip_other_symptoms_q4_a5":"extreme",
  "patient_hip_other_symptoms_q5":"Hinken",
  "patient_hip_other_symptoms_q5_a1":"kein, selten/nie",
  "patient_hip_other_symptoms_q5_a2":"leichtes, manchmal oder nur am Anfang",
  "patient_hip_other_symptoms_q5_a3":"mäßiges, oft, nicht nur am Anfang",
  "patient_hip_other_symptoms_q5_a4":"schweres, meistens",
  "patient_hip_other_symptoms_q5_a5":"die ganze Zeit oder nicht in der Lage zu gehen",
  "patient_hip_other_symptoms_q6":"Plötzliche, starke Schmerzen – „Schießen“, „Stechen“ oder „Krämpfe“ aus der Hüfte",
  "patient_hip_other_symptoms_q6_a1":"keine Tage",
  "patient_hip_other_symptoms_q6_a2":"nur 1 oder 2 Tage",
  "patient_hip_other_symptoms_q6_a3":"manche Tage",
  "patient_hip_other_symptoms_q6_a4":"an den meisten Tagen",
  "patient_hip_other_symptoms_q6_a5":"jeden Tag",
  "patient_hip_other_symptoms_q7":"Hüfte gibt nach",
  "patient_hip_other_symptoms_q7_a1":"nicht",
  "patient_hip_other_symptoms_q7_a2":"leicht",
  "patient_hip_other_symptoms_q7_a3":"mäßig",
  "patient_hip_other_symptoms_q7_a4":"schwer",
  "patient_hip_other_symptoms_q7_a5":"extrem",
  "patient_hip_other_symptoms_q8":"Verringerte Bewegung",
  "patient_hip_other_symptoms_q8_a1":"keine",
  "patient_hip_other_symptoms_q8_a2":"leichte",
  "patient_hip_other_symptoms_q8_a3":"mäßige",
  "patient_hip_other_symptoms_q8_a4":"schwere",
  "patient_hip_other_symptoms_q8_a5":"extreme",
  "patient_hip_other_symptoms_q9":"Benutzen Sie Hilfsmittel zum Gehen?",
  "patient_hip_other_symptoms_q9_a1":"keine",
  "patient_hip_other_symptoms_q9_a2":"Stock für lange Spaziergänge",
  "patient_hip_other_symptoms_q9_a3":"meistens Gehstock/Spazierstock",
  "patient_hip_other_symptoms_q9_a4":"eine Krücke",
  "patient_hip_other_symptoms_q9_a5":"zwei Stöcke/Spazierstöcke",
  "patient_hip_other_symptoms_q9_a6":"zwei Krücken oder nicht gehfähig",
  "patient_hip_function":"Funktion",
  "patient_hip_function_instruction":"Geben Sie den SCHWIERIGKEITSGRAD an, den Sie aufgrund Ihrer Hüfte bei den folgenden Aktivitäten in der letzten Woche hatten",
  "patient_hip_function_instruction_0":"keine",
  "patient_hip_function_instruction_1":"leichte",
  "patient_hip_function_instruction_2":"mäßige",
  "patient_hip_function_instruction_3":"schwere",
  "patient_hip_function_instruction_4":"extreme/nicht möglich",
  "patient_hip_function_note":"Hinweis: Wenn Sie eine bestimmte Aktivität nicht ausführen, stellen Sie sich vor, wie sich Ihre Hüfte anfühlen würde, wenn Sie sie ausprobieren müssten",
  "patient_hip_function_q1":"Treppe herunter gehen",
  "patient_hip_function_q1_a1":"keine",
  "patient_hip_function_q1_a2":"leichte",
  "patient_hip_function_q1_a3":"mäßige",
  "patient_hip_function_q1_a4":"schwere",
  "patient_hip_function_q1_a5":"extreme/nicht möglich",
  "patient_hip_function_q2":"Treppe herauf gehen",
  "patient_hip_function_q2_a1":"keine",
  "patient_hip_function_q2_a2":"leichte",
  "patient_hip_function_q2_a3":"mäßige",
  "patient_hip_function_q2_a4":"schwere",
  "patient_hip_function_q2_a5":"extreme/nicht möglich",
  "patient_hip_function_q3":"Sitzen",
  "patient_hip_function_q3_a1":"keine",
  "patient_hip_function_q3_a2":"leichte",
  "patient_hip_function_q3_a3":"mäßige",
  "patient_hip_function_q3_a4":"schwere",
  "patient_hip_function_q3_a5":"extreme/nicht möglich",
  "patient_hip_function_q4":"Aufstehen aus dem Sitzen",
  "patient_hip_function_q4_a1":"keine",
  "patient_hip_function_q4_a2":"leichte",
  "patient_hip_function_q4_a3":"mäßige",
  "patient_hip_function_q4_a4":"schwere",
  "patient_hip_function_q4_a5":"extreme/nicht möglich",
  "patient_hip_function_q5":"Aufstehen von und hinsetzen auf Boden",
  "patient_hip_function_q5_a1":"keine",
  "patient_hip_function_q5_a2":"leichte",
  "patient_hip_function_q5_a3":"mäßige",
  "patient_hip_function_q5_a4":"schwere",
  "patient_hip_function_q5_a5":"extreme/nicht möglich",
  "patient_hip_function_q6":"Stehen",
  "patient_hip_function_q6_a1":"keine",
  "patient_hip_function_q6_a2":"leichte",
  "patient_hip_function_q6_a3":"mäßige",
  "patient_hip_function_q6_a4":"schwere",
  "patient_hip_function_q6_a5":"extreme/nicht möglich",
  "patient_hip_function_q7":"15 Minuten stehen",
  "patient_hip_function_q7_a1":"keine",
  "patient_hip_function_q7_a2":"leichte",
  "patient_hip_function_q7_a3":"mäßige",
  "patient_hip_function_q7_a4":"schwere",
  "patient_hip_function_q7_a5":"extreme/nicht möglich",
  "patient_hip_function_q8":"Beine weit auseinander spreizen",
  "patient_hip_function_q8_a1":"keine",
  "patient_hip_function_q8_a2":"leichte",
  "patient_hip_function_q8_a3":"mäßige",
  "patient_hip_function_q8_a4":"schwere",
  "patient_hip_function_q8_a5":"extreme/nicht möglich",
  "patient_hip_function_q9":"Beim Gehen ausschreiten",
  "patient_hip_function_q9_a1":"keine",
  "patient_hip_function_q9_a2":"leichte",
  "patient_hip_function_q9_a3":"mäßige",
  "patient_hip_function_q9_a4":"schwere",
  "patient_hip_function_q9_a5":"extreme/nicht möglich",
  "patient_hip_function_q10":"Zum Boden beugen/einen Gegenstand aufheben",
  "patient_hip_function_q10_a1":"keine",
  "patient_hip_function_q10_a2":"leichte",
  "patient_hip_function_q10_a3":"mäßige",
  "patient_hip_function_q10_a4":"schwere",
  "patient_hip_function_q10_a5":"extreme/nicht möglich",
  "patient_hip_function_q11":"Gehen auf ebener Oberfläche",
  "patient_hip_function_q11_a1":"keine",
  "patient_hip_function_q11_a2":"leichte",
  "patient_hip_function_q11_a3":"mäßige",
  "patient_hip_function_q11_a4":"schwere",
  "patient_hip_function_q11_a5":"extreme/nicht möglich",
  "patient_hip_function_q12":"Gehen auf unebenem Untergrund",
  "patient_hip_function_q12_a1":"keine",
  "patient_hip_function_q12_a2":"leichte",
  "patient_hip_function_q12_a3":"mäßige",
  "patient_hip_function_q12_a4":"schwere",
  "patient_hip_function_q12_a5":"extreme/nicht möglich",
  "patient_hip_function_q13":"Lange Strecken gehen",
  "patient_hip_function_q13_a1":"keine",
  "patient_hip_function_q13_a2":"leichte",
  "patient_hip_function_q13_a3":"mäßige",
  "patient_hip_function_q13_a4":"schwere",
  "patient_hip_function_q13_a5":"extreme/nicht möglich",
  "patient_hip_function_q14":"Gehen für Training",
  "patient_hip_function_q14_a1":"keine",
  "patient_hip_function_q14_a2":"leichte",
  "patient_hip_function_q14_a3":"mäßige",
  "patient_hip_function_q14_a4":"schwere",
  "patient_hip_function_q14_a5":"extreme/nicht möglich",
  "patient_hip_function_q15":"Steile Hügel hinaufgehen",
  "patient_hip_function_q15_a1":"keine",
  "patient_hip_function_q15_a2":"leichte",
  "patient_hip_function_q15_a3":"mäßige",
  "patient_hip_function_q15_a4":"schwere",
  "patient_hip_function_q15_a5":"extreme/nicht möglich",
  "patient_hip_function_q16":"Steile Hügel heruntergehen",
  "patient_hip_function_q16_a1":"keine",
  "patient_hip_function_q16_a2":"leichte",
  "patient_hip_function_q16_a3":"mäßige",
  "patient_hip_function_q16_a4":"schwere",
  "patient_hip_function_q16_a5":"extreme/nicht möglich",
  "patient_hip_function_q17":"anfangs gehen",
  "patient_hip_function_q17_a1":"keine",
  "patient_hip_function_q17_a2":"leichte",
  "patient_hip_function_q17_a3":"mäßige",
  "patient_hip_function_q17_a4":"schwere",
  "patient_hip_function_q17_a5":"extreme/nicht möglich",
  "patient_hip_function_q18":"Zu Fuß etwa 10 Minuten gehen",
  "patient_hip_function_q18_a1":"keine",
  "patient_hip_function_q18_a2":"leichte",
  "patient_hip_function_q18_a3":"mäßige",
  "patient_hip_function_q18_a4":"schwere",
  "patient_hip_function_q18_a5":"extreme/nicht möglich",
  "patient_hip_function_q19":"15 Minuten oder länger gehen",
  "patient_hip_function_q19_a1":"keine",
  "patient_hip_function_q19_a2":"leichte",
  "patient_hip_function_q19_a3":"mäßige",
  "patient_hip_function_q19_a4":"schwere",
  "patient_hip_function_q19_a5":"extreme/nicht möglich",
  "patient_hip_function_q20":"Bordstein herauf- und heruntergehen",
  "patient_hip_function_q20_a1":"keine",
  "patient_hip_function_q20_a2":"leichte",
  "patient_hip_function_q20_a3":"mäßige",
  "patient_hip_function_q20_a4":"schwere",
  "patient_hip_function_q20_a5":"extreme/nicht möglich",
  "patient_hip_function_q21":"Ein- und Aussteigen aus dem Auto oder öffentlichen Verkehrsmitteln (Bus), je nachdem, was Sie verwenden",
  "patient_hip_function_q21_a1":"keine",
  "patient_hip_function_q21_a2":"leichte",
  "patient_hip_function_q21_a3":"mäßige",
  "patient_hip_function_q21_a4":"schwere",
  "patient_hip_function_q21_a5":"extreme/nicht möglich",
  "patient_hip_function_q22":"Einkaufen gehen",
  "patient_hip_function_q22_a1":"keine",
  "patient_hip_function_q22_a2":"leichte",
  "patient_hip_function_q22_a3":"mäßige",
  "patient_hip_function_q22_a4":"schwere",
  "patient_hip_function_q22_a5":"extreme/nicht möglich",
  "patient_hip_function_q23":"Socken/Strümpfe anziehen",
  "patient_hip_function_q23_a1":"keine",
  "patient_hip_function_q23_a2":"leichte",
  "patient_hip_function_q23_a3":"mäßige",
  "patient_hip_function_q23_a4":"schwere",
  "patient_hip_function_q23_a5":"extreme/nicht möglich",
  "patient_hip_function_q24":"Socken/Strümpfe ausziehen",
  "patient_hip_function_q24_a1":"keine",
  "patient_hip_function_q24_a2":"leichte",
  "patient_hip_function_q24_a3":"mäßige",
  "patient_hip_function_q24_a4":"schwere",
  "patient_hip_function_q24_a5":"extreme/nicht möglich",
  "patient_hip_function_q25":"Im Bett liegen (umdrehen, Hüftposition beibehalten)",
  "patient_hip_function_q25_a1":"keine",
  "patient_hip_function_q25_a2":"leichte",
  "patient_hip_function_q25_a3":"mäßige",
  "patient_hip_function_q25_a4":"schwere",
  "patient_hip_function_q25_a5":"extreme/nicht möglich",
  "patient_hip_function_q26":"Sich im Bett umdrehen",
  "patient_hip_function_q26_a1":"keine",
  "patient_hip_function_q26_a2":"leichte",
  "patient_hip_function_q26_a3":"mäßige",
  "patient_hip_function_q26_a4":"schwere",
  "patient_hip_function_q26_a5":"extreme/nicht möglich",
  "patient_hip_function_q27":"Aufstehen aus dem Bett",
  "patient_hip_function_q27_a1":"keine",
  "patient_hip_function_q27_a2":"leichte",
  "patient_hip_function_q27_a3":"mäßige",
  "patient_hip_function_q27_a4":"schwere",
  "patient_hip_function_q27_a5":"extreme/nicht möglich",
  "patient_hip_function_q28":"Ein- und Aussteigen aus dem Bad",
  "patient_hip_function_q28_a1":"keine",
  "patient_hip_function_q28_a2":"leichte",
  "patient_hip_function_q28_a3":"mäßige",
  "patient_hip_function_q28_a4":"schwere",
  "patient_hip_function_q28_a5":"extreme/nicht möglich",
  "patient_hip_function_q29":"Auf die Toilette setzen und aufstehen",
  "patient_hip_function_q29_a1":"keine",
  "patient_hip_function_q29_a2":"leichte",
  "patient_hip_function_q29_a3":"mäßige",
  "patient_hip_function_q29_a4":"schwere",
  "patient_hip_function_q29_a5":"extreme/nicht möglich",
  "patient_hip_function_q30":"Sich selbst waschen und trocknen (überall)",
  "patient_hip_function_q30_a1":"keine",
  "patient_hip_function_q30_a2":"leichte",
  "patient_hip_function_q30_a3":"mäßige",
  "patient_hip_function_q30_a4":"schwere",
  "patient_hip_function_q30_a5":"extreme/nicht möglich",
  "patient_hip_function_q31":"Leichte bis mittelschwere Arbeit (Stehen, Gehen)",
  "patient_hip_function_q31_a1":"keine",
  "patient_hip_function_q31_a2":"leichte",
  "patient_hip_function_q31_a3":"mäßige",
  "patient_hip_function_q31_a4":"schwere",
  "patient_hip_function_q31_a5":"extreme/nicht möglich",
  "patient_hip_function_q32":"schwere Hausarbeit (schwere Kisten bewegen, Böden schrubben usw.)",
  "patient_hip_function_q32_a1":"keine",
  "patient_hip_function_q32_a2":"leichte",
  "patient_hip_function_q32_a3":"mäßige",
  "patient_hip_function_q32_a4":"schwere",
  "patient_hip_function_q32_a5":"extreme/nicht möglich",
  "patient_hip_function_q33":"Leichte Hausarbeit (Kochen, Staubwischen usw.)",
  "patient_hip_function_q33_a1":"keine",
  "patient_hip_function_q33_a2":"leichte",
  "patient_hip_function_q33_a3":"mäßige",
  "patient_hip_function_q33_a4":"schwere",
  "patient_hip_function_q33_a5":"extreme/nicht möglich",
  "patient_hip_function_q34":"Kinder hochheben oder tragen",
  "patient_hip_function_q34_a1":"keine",
  "patient_hip_function_q34_a2":"leichte",
  "patient_hip_function_q34_a3":"mäßige",
  "patient_hip_function_q34_a4":"schwere",
  "patient_hip_function_q34_a5":"extreme/nicht möglich",
  "patient_hip_function_q35":"Hocken",
  "patient_hip_function_q35_a1":"keine",
  "patient_hip_function_q35_a2":"leichte",
  "patient_hip_function_q35_a3":"mäßige",
  "patient_hip_function_q35_a4":"schwere",
  "patient_hip_function_q35_a5":"extreme/nicht möglich",
  "patient_hip_function_q36":"Joggen zum Trainieren",
  "patient_hip_function_q36_a1":"keine",
  "patient_hip_function_q36_a2":"leichte",
  "patient_hip_function_q36_a3":"mäßige",
  "patient_hip_function_q36_a4":"schwere",
  "patient_hip_function_q36_a5":"extreme/nicht möglich",
  "patient_hip_function_q37":"Laufen",
  "patient_hip_function_q37_a1":"keine",
  "patient_hip_function_q37_a2":"leichte",
  "patient_hip_function_q37_a3":"mäßige",
  "patient_hip_function_q37_a4":"schwere",
  "patient_hip_function_q37_a5":"extreme/nicht möglich",
  "patient_hip_function_q38":"Drehen auf dem belasteten Bein",
  "patient_hip_function_q38_a1":"keine",
  "patient_hip_function_q38_a2":"leichte",
  "patient_hip_function_q38_a3":"mäßige",
  "patient_hip_function_q38_a4":"schwere",
  "patient_hip_function_q38_a5":"extreme/nicht möglich",
  "patient_hip_function_q39":"Sexuelle Aktivität",
  "patient_hip_function_q39_a1":"keine",
  "patient_hip_function_q39_a2":"leichte",
  "patient_hip_function_q39_a3":"mäßige",
  "patient_hip_function_q39_a4":"schwere",
  "patient_hip_function_q39_a5":"extreme/nicht möglich",
  "patient_hip_function_q39_not_relevant":"Dies ist nicht relevant für mich",
  "patient_hip_function_q40":"Sportarten mit hoher Belastung, die Sprinten oder Schneiden beinhalten (z. B. Fußball, Basketball, Tennis, Aerobic)",
  "patient_hip_function_q40_a1":"keine",
  "patient_hip_function_q40_a2":"leichte",
  "patient_hip_function_q40_a3":"mäßige",
  "patient_hip_function_q40_a4":"schwere",
  "patient_hip_function_q40_a5":"extreme/nicht möglich",
  "patient_hip_function_q41":"Sportarten mit geringer Belastung (z. B. Golf, Bowling)",
  "patient_hip_function_q41_a1":"keine",
  "patient_hip_function_q41_a2":"leichte",
  "patient_hip_function_q41_a3":"mäßige",
  "patient_hip_function_q41_a4":"schwere",
  "patient_hip_function_q41_a5":"extreme/nicht möglich",
  "patient_hip_function_q42":"Freizeitaktivitäten",
  "patient_hip_function_q42_a1":"keine",
  "patient_hip_function_q42_a2":"leichte",
  "patient_hip_function_q42_a3":"mäßige",
  "patient_hip_function_q42_a4":"schwere",
  "patient_hip_function_q42_a5":"extreme/nicht möglich",
  "patient_hip_function_q43":"Wie würden Sie Ihre Hüften heute in Prozent des Normalwertes einschätzen? (0 % bis 100 %, wobei 100 % normal ist)",
  "patient_hip_function_q44":"Bitte geben Sie die höchste Aktivitätsstufe an, an der Sie teilnehmen können",
  "patient_hip_function_q44_q1":"Vor Ihrer Hüftverletzung/Hüftproblem",
  "patient_hip_function_q44_q1_a1":"wettbewerbsorientiert",
  "patient_hip_function_q44_q1_a2":"freizeitliche, körperliche Fitness",
  "patient_hip_function_q44_q1_a3":"kein Sport, nur Arbeit",
  "patient_hip_function_q44_q1_a4":"nur Aktivitäten des täglichen Lebens",
  "patient_hip_function_q44_q1_a5":"Krankheitsurlaub oder Invalidenrente aufgrund gesundheitlicher Probleme",
  "patient_hip_function_q44_q1_a1_o1":"Wettkampfsportarten, die auf Welt- oder olympischem Niveau oder professionell ausgeübt werden",
  "patient_hip_function_q44_q1_a1_o2":"Leistungssportarten wie Leichtathletik, Schläger-/Ballsportarten, Turnen, Rudern, Skifahren oder Kampfsportarten auf nationaler oder internationaler Ebene",
  "patient_hip_function_q44_q1_a1_o3":"Leistungssportarten wie Wassersport, Radfahren, Hockey, Curling oder Reiten/Rodeo auf nationaler oder internationaler Ebene",
  "patient_hip_function_q44_q1_a2_o1":"Freizeitsportarten wie Laufen, Ball-/Schlägersport, Krafttraining, Curling, Rudern, Golf, Yoga oder Gymnastik mindestens 5-mal pro Woche",
  "patient_hip_function_q44_q1_a2_o2":"Freizeitsportarten wie Schwimmen, Wassersport, Skifahren, Hockey, Inlineskaten, Radfahren, Reiten oder Bergsteigen mindestens 5-mal pro Woche",
  "patient_hip_function_q44_q1_a2_o3":"Freizeitsport mindestens zweimal wöchentlich",
  "patient_hip_function_q44_q1_a2_o4":"Freizeitsport einmal pro Woche oder weniger",
  "patient_hip_function_q44_q1_a2_o5":"Freizeitsport weniger als einmal im Monat",
  "patient_hip_function_q44_q1_a3_o1":"schwere Arbeiten wie Bauarbeiten",
  "patient_hip_function_q44_q1_a3_o2":"mäßig schwere Arbeiten wie Lkw-Fahren",
  "patient_hip_function_q44_q1_a3_o3":"leichte Arbeit wie Krankenpflege",
  "patient_hip_function_q44_q1_a3_o4":"sitzende Tätigkeiten wie Sekretariats- oder Computerarbeit",
  "patient_hip_function_q44_q1_a4_o1":"tägliche Aktivitäten wie Gartenarbeit, Treppensteigen, Tragen von Lasten, Schieben/Ziehen einer Last, Fähigkeit zu rennen, wenn man zu spät kommt",
  "patient_hip_function_q44_q1_a4_o2":"tägliche Aktivitäten wie Hausputzen, ein oder zwei Treppen steigen oder Gehen mit leichter Last",
  "patient_hip_function_q44_q1_a4_o3":"tägliche Aktivitäten eingeschränkt (z. B. keine Treppen steigen, keine Lasten tragen können)",
  "patient_hip_function_q44_q2":"Aktueller Stand",
  "patient_hip_function_q44_q2_a1":"wettbewerbsorientiert",
  "patient_hip_function_q44_q2_a2":"freizeitliche, körperliche Fitness",
  "patient_hip_function_q44_q2_a3":"kein Sport, nur Arbeit",
  "patient_hip_function_q44_q2_a4":"nur Aktivitäten des täglichen Lebens",
  "patient_hip_function_q44_q2_a5":"Krankheitsurlaub oder Invalidenrente aufgrund gesundheitlicher Probleme",
  "patient_hip_function_q44_q2_a1_o1":"Wettkampfsportarten, die auf Welt- oder olympischem Niveau oder professionell ausgeübt werden",
  "patient_hip_function_q44_q2_a1_o2":"Leistungssportarten wie Leichtathletik, Schläger-/Ballsportarten, Turnen, Rudern, Skifahren oder Kampfsportarten auf nationaler oder internationaler Ebene",
  "patient_hip_function_q44_q2_a1_o3":"Leistungssportarten wie Wassersport, Radfahren, Hockey, Curling oder Reiten/Rodeo auf nationaler oder internationaler Ebene",
  "patient_hip_function_q44_q2_a2_o1":"Freizeitsportarten wie Laufen, Ball-/Schlägersport, Krafttraining, Curling, Rudern, Golf, Yoga oder Gymnastik mindestens 5-mal pro Woche",
  "patient_hip_function_q44_q2_a2_o2":"Freizeitsportarten wie Schwimmen, Wassersport, Skifahren, Hockey, Inlineskaten, Radfahren, Reiten oder Bergsteigen mindestens 5-mal pro Woche",
  "patient_hip_function_q44_q2_a2_o3":"Freizeitsport mindestens zweimal wöchentlich",
  "patient_hip_function_q44_q2_a2_o4":"Freizeitsport einmal pro Woche oder weniger",
  "patient_hip_function_q44_q2_a2_o5":"Freizeitsport weniger als einmal im Monat",
  "patient_hip_function_q44_q2_a3_o1":"schwere Arbeiten wie Bauarbeiten",
  "patient_hip_function_q44_q2_a3_o2":"mäßig schwere Arbeiten wie Lkw-Fahren",
  "patient_hip_function_q44_q2_a3_o3":"leichte Arbeit wie Krankenpflege",
  "patient_hip_function_q44_q2_a3_o4":"sitzende Tätigkeiten wie Sekretariats- oder Computerarbeit",
  "patient_hip_function_q44_q2_a4_o1":"tägliche Aktivitäten wie Gartenarbeit, Treppensteigen, Tragen von Lasten, Schieben/Ziehen einer Last, Fähigkeit zu rennen, wenn man zu spät kommt",
  "patient_hip_function_q44_q2_a4_o2":"tägliche Aktivitäten wie Hausputzen, ein oder zwei Treppen steigen oder Gehen mit leichter Last",
  "patient_hip_function_q44_q2_a4_o3":"tägliche Aktivitäten eingeschränkt (z. B. keine Treppen steigen, keine Lasten tragen können)",
  "patient_hip_quality_of_life":"Lebensqualität",
  "patient_hip_quality_of_life_instruction":"In den folgenden Fragen geht es um soziale, emotionale und Lebensstilprobleme, die Sie im Zusammenhang mit Ihrem Hüftproblem haben könnten. Bitte denken Sie darüber nach, wie Sie sich die meiste Zeit in Bezug auf Ihre Hüfte gefühlt haben.",
  "patient_hip_quality_of_life_q1":"Wie oft sind Sie sich Ihres Hüftproblems bewusst?",
  "patient_hip_quality_of_life_q1_a1":"niemals",
  "patient_hip_quality_of_life_q1_a2":"monatlich",
  "patient_hip_quality_of_life_q1_a3":"wöchentlich",
  "patient_hip_quality_of_life_q1_a4":"täglich",
  "patient_hip_quality_of_life_q1_a5":"ständig",
  "patient_hip_quality_of_life_q2":"Haben Sie Ihren Lebensstil geändert, um potenziell schädliche Aktivitäten für Ihre Hüfte zu vermeiden?",
  "patient_hip_quality_of_life_q2_a1":"gar nicht",
  "patient_hip_quality_of_life_q2_a2":"leicht",
  "patient_hip_quality_of_life_q2_a3":"in Maßen",
  "patient_hip_quality_of_life_q2_a4":"stark",
  "patient_hip_quality_of_life_q2_a5":"total",
  "patient_hip_quality_of_life_q3":"Wie besorgt sind Sie über Ihre Fähigkeit, Ihr gewünschtes Fitnessniveau aufrechtzuerhalten?",
  "patient_hip_quality_of_life_q3_a1":"überhaupt nicht besorgt",
  "patient_hip_quality_of_life_q3_a2":"äußerst besorgt",
  "patient_hip_quality_of_life_q4":"Wie sehr sind Sie mit dem Mangel an Vertrauen in Ihre Hüfte beunruhigt?",
  "patient_hip_quality_of_life_q4_a1":"gar nicht",
  "patient_hip_quality_of_life_q4_a2":"leicht",
  "patient_hip_quality_of_life_q4_a3":"in Maßen",
  "patient_hip_quality_of_life_q4_a4":"stark",
  "patient_hip_quality_of_life_q4_a5":"äußerst",
  "patient_hip_quality_of_life_q5":"Wie stark lenkt Sie Ihr Hüftproblem ab?",
  "patient_hip_quality_of_life_q5_a1":"überhaupt keine Ablenkung",
  "patient_hip_quality_of_life_q5_a2":"extreme Ablenkung",
  "patient_hip_quality_of_life_q6":"Wie viel Schwierigkeiten haben Sie im Allgemeinen mit Ihrer Hüfte?",
  "patient_hip_quality_of_life_q6_a1":"keine",
  "patient_hip_quality_of_life_q6_a2":"leichte",
  "patient_hip_quality_of_life_q6_a3":"mäßige",
  "patient_hip_quality_of_life_q6_a4":"schwere",
  "patient_hip_quality_of_life_q6_a5":"extreme",
  "patient_hip_quality_of_life_q7":"Wie ängstlich/deprimiert sind Sie heute?",
  "patient_hip_quality_of_life_q7_a1":"gar nicht",
  "patient_hip_quality_of_life_q7_a2":"leicht",
  "patient_hip_quality_of_life_q7_a3":"in Maßen",
  "patient_hip_quality_of_life_q7_a4":"stark",
  "patient_hip_quality_of_life_q7_a5":"extrem",
  "patient_hip_quality_of_life_q8":"Wie gut/schlecht ist Ihre Gesundheit heute? (100 bedeutet beste Gesundheit 0 bedeutet schlechteste Gesundheit, die Sie sich vorstellen können)",
  "patient_hip_quality_of_life_q8_a1":"schlechteste Gesundheit",
  "patient_hip_quality_of_life_q8_a2":"beste Gesundheit",
  "patient_hip_satisfaction":"Zufriedenheit",
  "patient_hip_satisfaction_instructions":"Wie zufrieden sind Sie mit den Ergebnissen Ihrer Hüftoperation/-behandlung?",
  "patient_hip_satisfaction_instructions_1":"sehr unzufrieden",
  "patient_hip_satisfaction_instructions_2":"etwas unzufrieden",
  "patient_hip_satisfaction_instructions_3":"etwas zufrieden",
  "patient_hip_satisfaction_instructions_4":"sehr zufrieden",
  "patient_hip_satisfaction_q1":"insgesamt",
  "patient_hip_satisfaction_q1_not_applicable":"unzutreffend",
  "patient_hip_satisfaction_q1_a1":"sehr unzufrieden",
  "patient_hip_satisfaction_q1_a2":"etwas unzufrieden",
  "patient_hip_satisfaction_q1_a3":"etwas zufrieden",
  "patient_hip_satisfaction_q1_a4":"sehr zufrieden",
  "patient_hip_satisfaction_q2":"Zur Linderung Ihrer Schmerzen?",
  "patient_hip_satisfaction_q2_not_applicable":"unzutreffend",
  "patient_hip_satisfaction_q2_a1":"sehr unzufrieden",
  "patient_hip_satisfaction_q2_a2":"etwas unzufrieden",
  "patient_hip_satisfaction_q2_a3":"etwas zufrieden",
  "patient_hip_satisfaction_q2_a4":"sehr zufrieden",
  "patient_hip_satisfaction_q3":"Um Ihre Fähigkeit zu verbessern, Haus- oder Gartenarbeit zu erledigen?",
  "patient_hip_satisfaction_q3_not_applicable":"unzutreffend",
  "patient_hip_satisfaction_q3_a1":"sehr unzufrieden",
  "patient_hip_satisfaction_q3_a2":"etwas unzufrieden",
  "patient_hip_satisfaction_q3_a3":"etwas zufrieden",
  "patient_hip_satisfaction_q3_a4":"sehr zufrieden",
  "patient_hip_satisfaction_q4":"Um Ihre Fähigkeit zu verbessern,  Freizeitaktivitäten zu unternehmen?",
  "patient_hip_satisfaction_q4_not_applicable":"unzutreffend",
  "patient_hip_satisfaction_q4_a1":"sehr unzufrieden",
  "patient_hip_satisfaction_q4_a2":"etwas unzufrieden",
  "patient_hip_satisfaction_q4_a3":"etwas zufrieden",
  "patient_hip_satisfaction_q4_a4":"sehr zufrieden",
  "patient_hip_complete":"Ende des Fragebogens",
  "patient_hip_complete_text1":"Vielen Dank, dass Sie sich die Zeit genommen haben, dieses Formular auszufüllen.",
  "patient_hip_complete_text2":"Bitte klicken Sie auf „Senden“, um den Vorgang abzuschließen und Ihre Ergebnisse anzuzeigen.",
  "patient_hip_complete_text3":"Ihre Antworten werden vertraulich behandelt und in allen Berichten werden nur anonymisierte Informationen verwendet.",
  "patient_hip_results":"Ergebnisse",
  "patient_hip_results_text1":"Nachfolgend finden Sie Ihre Schmerz- und Hüftfunktionswerte, die anhand Ihres Fragebogens berechnet wurden.",
  "patient_hip_results_pain":"Schmerz",
  "patient_hip_results_function":"Funktion",
  "patient_hip_results_health_perception":"Allgemeine Gesundheitswahrnehmung",
  "patient_hip_results_self_evaluation":"Hüfteselbstbewertung",
  "patient_hip_results_text2":"Ihr Arzt wird in Kürze bei Ihnen sein.",
  "":"",
  "patient_adult_knee_pain":"Schmerz",
  "patient_adult_knee_pain_q1":"Was ist das höchste Aktivitätsniveau, das Sie ohne nennenswerte Knieschmerzen ausführen können?",
  "patient_adult_knee_pain_q1_a1":"sehr anstrengende Aktivitäten wie Springen oder Drehen wie beim Basketball oder Fußball",
  "patient_adult_knee_pain_q1_a2":"anstrengende Aktivitäten wie schwere körperliche Arbeit, Skifahren oder Tennis",
  "patient_adult_knee_pain_q1_a3":"moderate Aktivitäten wie moderate körperliche Arbeit, Laufen oder Joggen",
  "patient_adult_knee_pain_q1_a4":"leichte Aktivitäten wie Spazierengehen, Hausarbeit oder Gartenarbeit",
  "patient_adult_knee_pain_q1_a5":"aufgrund von Knieschmerzen ist es mir nicht möglich, eine der oben genannten Aktivitäten auszuführen",
  "patient_adult_knee_pain_q2":"Wie oft hatten Sie Schmerzen?",
  "patient_adult_knee_pain_q2_a1":"niemals",
  "patient_adult_knee_pain_q2_a2":"monatlich",
  "patient_adult_knee_pain_q2_a3":"wöchentlich",
  "patient_adult_knee_pain_q2_a4":"täglich",
  "patient_adult_knee_pain_q2_a5":"ständig",
  "patient_adult_knee_pain_q3":"Welche der folgenden Aussagen beschreibt Ihren Schmerz am besten?",
  "patient_adult_knee_pain_q3_a1":"keine Schmerzen, normales Knie, 100 % Leistung",
  "patient_adult_knee_pain_q3_a2":"gelegentliche Schmerzen bei anstrengendem Sport und schwerer Arbeit, Knie nicht ganz normal, einige Einschränkungen, aber geringfügig und erträglich",
  "patient_adult_knee_pain_q3_a3":"Schmerzen, die normalerweise durch Sport, leichte Freizeitaktivitäten oder mäßige Arbeit hervorgerufen werden. Tritt gelegentlich beim Gehen von mehr als 2 km, im Stehen oder bei leichter Arbeit auf",
  "patient_adult_knee_pain_q3_a4":"gelegentliche Schmerzen bei leichten Freizeitsportarten oder mäßiger Arbeitstätigkeit, Lauf- oder Schwerwehen, anstrengenden Sportarten",
  "patient_adult_knee_pain_q3_a5":"Schmerzen sind ein erhebliches Problem bei einfachen Aktivitäten wie Gehen <2 km, werden durch Ruhe gelindert und sind nicht in der Lage, Sport zu treiben",
  "patient_adult_knee_pain_q3_a6":"ständig",
  "patient_adult_knee_pain_q4":"Wenn Sie Schmerzen haben, wie stark sind Sie?",
  "patient_adult_knee_pain_q4_a1":"keine",
  "patient_adult_knee_pain_q4_a2":"sehr mild",
  "patient_adult_knee_pain_q4_a3":"leicht",
  "patient_adult_knee_pain_q4_a4":"mäßig",
  "patient_adult_knee_pain_q4_a5":"schwer",
  "patient_adult_knee_pain_q5":"Wie sehr haben Knieschmerzen Ihre normale Arbeit (einschließlich Hausarbeit) beeinträchtigt?",
  "patient_adult_knee_pain_q5_a1":"gar nicht",
  "patient_adult_knee_pain_q5_a2":"ein bisschen",
  "patient_adult_knee_pain_q5_a3":"in Maßen",
  "patient_adult_knee_pain_q5_a4":"außerordentlich",
  "patient_adult_knee_pain_q5_a5":"total",
  "patient_adult_knee_pain_instruction":"Wie stark sind Ihre KNIESCHMERZEN bei den following AKTIVITÄTEN?",
  "patient_adult_knee_pain_instruction_0":"keine",
  "patient_adult_knee_pain_instruction_1":"leicht",
  "patient_adult_knee_pain_instruction_2":"mäßig",
  "patient_adult_knee_pain_instruction_3":"schwer",
  "patient_adult_knee_pain_instruction_4":"extrem",
  "patient_adult_knee_pain_q6":"Knie drehen",
  "patient_adult_knee_pain_q6_a1":"keine",
  "patient_adult_knee_pain_q6_a2":"leicht",
  "patient_adult_knee_pain_q6_a3":"mäßig",
  "patient_adult_knee_pain_q6_a4":"schwer",
  "patient_adult_knee_pain_q6_a5":"extrem",
  "patient_adult_knee_pain_q7":"Knie vollständig strecken",
  "patient_adult_knee_pain_q7_a1":"keine",
  "patient_adult_knee_pain_q7_a2":"leicht",
  "patient_adult_knee_pain_q7_a3":"mäßig",
  "patient_adult_knee_pain_q7_a4":"schwer",
  "patient_adult_knee_pain_q7_a5":"extrem",
  "patient_adult_knee_pain_q8":"Knie vollständig beugen",
  "patient_adult_knee_pain_q8_a1":"keine",
  "patient_adult_knee_pain_q8_a2":"leicht",
  "patient_adult_knee_pain_q8_a3":"mäßig",
  "patient_adult_knee_pain_q8_a4":"schwer",
  "patient_adult_knee_pain_q8_a5":"extrem",
  "patient_adult_knee_pain_q9":"Gehen auf ebener Fläche",
  "patient_adult_knee_pain_q9_a1":"keine",
  "patient_adult_knee_pain_q9_a2":"leicht",
  "patient_adult_knee_pain_q9_a3":"mäßig",
  "patient_adult_knee_pain_q9_a4":"schwer",
  "patient_adult_knee_pain_q9_a5":"extrem",
  "patient_adult_knee_pain_q10":"Wie lange können Sie gehen, bevor die Knieschmerzen schwer werden? (mit oder ohne Stock)",
  "patient_adult_knee_pain_q10_a1":"mehr als 30 Minuten",
  "patient_adult_knee_pain_q10_a2":"16-30 Minuten",
  "patient_adult_knee_pain_q10_a3":"5-15 Minuten",
  "patient_adult_knee_pain_q10_a4":"nur rund ums Haus",
  "patient_adult_knee_pain_q10_a5":"kann überhaupt nicht gehen/starke Schmerzen beim Gehen",
  "patient_adult_knee_pain_q11":"Treppen herauf oder herunter gehen",
  "patient_adult_knee_pain_q11_a1":"keine",
  "patient_adult_knee_pain_q11_a2":"leicht",
  "patient_adult_knee_pain_q11_a3":"mäßig",
  "patient_adult_knee_pain_q11_a4":"schwer",
  "patient_adult_knee_pain_q11_a5":"extrem",
  "patient_adult_knee_pain_q12":"Nachts im Bett",
  "patient_adult_knee_pain_q12_a1":"keine",
  "patient_adult_knee_pain_q12_a2":"leicht",
  "patient_adult_knee_pain_q12_a3":"mäßig",
  "patient_adult_knee_pain_q12_a4":"schwer",
  "patient_adult_knee_pain_q12_a5":"extrem",
  "patient_adult_knee_pain_q13":"Wie oft wurden Sie nachts im Bett von Knieschmerzen geplagt?",
  "patient_adult_knee_pain_q13_a1":"keine Nächte",
  "patient_adult_knee_pain_q13_a2":"nur 1 oder 2 Nächte",
  "patient_adult_knee_pain_q13_a3":"manche Nächte",
  "patient_adult_knee_pain_q13_a4":"die meisten Nächte",
  "patient_adult_knee_pain_q13_a5":"jede Nacht",
  "patient_adult_knee_pain_q14":"Sitzend oder liegend",
  "patient_adult_knee_pain_q14_a1":"keine",
  "patient_adult_knee_pain_q14_a2":"leicht",
  "patient_adult_knee_pain_q14_a3":"mäßig",
  "patient_adult_knee_pain_q14_a4":"schwer",
  "patient_adult_knee_pain_q14_a5":"extrem",
  "patient_adult_knee_pain_q15":"Aufrecht stehend",
  "patient_adult_knee_pain_q15_a1":"keine",
  "patient_adult_knee_pain_q15_a2":"leicht",
  "patient_adult_knee_pain_q15_a3":"mäßig",
  "patient_adult_knee_pain_q15_a4":"schwer",
  "patient_adult_knee_pain_q15_a5":"extrem",
  "patient_adult_knee_pain_q16":"Von einem Stuhl aufstehen",
  "patient_adult_knee_pain_q16_a1":"keine",
  "patient_adult_knee_pain_q16_a2":"leicht",
  "patient_adult_knee_pain_q16_a3":"mäßig",
  "patient_adult_knee_pain_q16_a4":"schwer",
  "patient_adult_knee_pain_q16_a5":"extrem",
  "patient_adult_knee_other_symptoms":"Weitere Symptome",
  "patient_adult_knee_other_symptoms_instruction1":"Bitte wählen Sie die Antwort aus, die am besten zu Ihren Kniesymptomen passt.",
  "patient_adult_knee_other_symptoms_instruction2":"Wenn Sie sich nicht sicher sind, wie Sie eine Frage beantworten sollen, geben Sie bitte die bestmögliche Antwort.",
  "patient_adult_knee_other_symptoms_instruction3":"Hatten Sie folgendes:",
  "patient_adult_knee_other_symptoms_instruction_0":"None",
  "patient_adult_knee_other_symptoms_instruction_1":"leicht",
  "patient_adult_knee_other_symptoms_instruction_2":"Moderate",
  "patient_adult_knee_other_symptoms_instruction_3":"Severe",
  "patient_adult_knee_other_symptoms_instruction_4":"extrem",
  "patient_adult_knee_other_symptoms_q1":"Schwellung",
  "patient_adult_knee_other_symptoms_q1_a1":"niemals",
  "patient_adult_knee_other_symptoms_q1_a2":"selten",
  "patient_adult_knee_other_symptoms_q1_a3":"manchmal",
  "patient_adult_knee_other_symptoms_q1_a4":"oft",
  "patient_adult_knee_other_symptoms_q1_a5":"immer",
  "patient_adult_knee_other_symptoms_q2":"Schleifendes Klicken oder andere Geräusche, wenn Sie Ihr Knie bewegen",
  "patient_adult_knee_other_symptoms_q2_a1":"niemals",
  "patient_adult_knee_other_symptoms_q2_a2":"selten",
  "patient_adult_knee_other_symptoms_q2_a3":"manchmal",
  "patient_adult_knee_other_symptoms_q2_a4":"oft",
  "patient_adult_knee_other_symptoms_q2_a5":"immer",
  "patient_adult_knee_other_symptoms_q3":"Widerstand oder Blockade",
  "patient_adult_knee_other_symptoms_q3_a1":"niemals",
  "patient_adult_knee_other_symptoms_q3_a2":"selten",
  "patient_adult_knee_other_symptoms_q3_a3":"manchmal",
  "patient_adult_knee_other_symptoms_q3_a4":"oft",
  "patient_adult_knee_other_symptoms_q3_a5":"immer",
  "patient_adult_knee_other_symptoms_q4":"Hinken",
  "patient_adult_knee_other_symptoms_q4_a1":"selten/nie",
  "patient_adult_knee_other_symptoms_q4_a2":"manchmal oder nur am Anfang",
  "patient_adult_knee_other_symptoms_q4_a3":"oft, nicht nur am Anfang",
  "patient_adult_knee_other_symptoms_q4_a4":"meistens",
  "patient_adult_knee_other_symptoms_q4_a5":"immer",
  "patient_adult_knee_other_symptoms_q5":"Wie steif oder geschwollen war Ihr Knie in den letzten 4 Wochen oder seit Ihrer Knieverletzung?",
  "patient_adult_knee_other_symptoms_q5_a1":"gar nicht",
  "patient_adult_knee_other_symptoms_q5_a2":"leicht",
  "patient_adult_knee_other_symptoms_q5_a3":"in Maßen",
  "patient_adult_knee_other_symptoms_q5_a4":"sehr",
  "patient_adult_knee_other_symptoms_q5_a5":"äußerst",
  "patient_adult_knee_other_symptoms_q6":"Was ist das höchste Aktivitätsniveau, das Sie ausführen können, ohne dass Ihr Knie stark anschwillt?",
  "patient_adult_knee_other_symptoms_q6_a1":"sehr anstrengende Aktivitäten wie Springen oder Drehen wie beim Basketball oder Fußball",
  "patient_adult_knee_other_symptoms_q6_a2":"anstrengende Aktivitäten wie schwere körperliche Arbeit, Skifahren oder Tennis",
  "patient_adult_knee_other_symptoms_q6_a3":"moderate Aktivitäten wie moderate körperliche Arbeit, Laufen oder Joggen",
  "patient_adult_knee_other_symptoms_q6_a4":"leichte Aktivitäten wie Spazierengehen, Hausarbeit oder Gartenarbeit",
  "patient_adult_knee_other_symptoms_q6_a5":"aufgrund einer Knieschwellung ist es mir nicht möglich, eine der oben genannten Aktivitäten auszuführen",
  "patient_adult_knee_other_symptoms_q7":"Wie stark ist Ihre Gelenksteifheit:",
  "patient_adult_knee_other_symptoms_q7_0":"keine",
  "patient_adult_knee_other_symptoms_q7_1":"leicht",
  "patient_adult_knee_other_symptoms_q7_2":"mäßig",
  "patient_adult_knee_other_symptoms_q7_3":"schwer",
  "patient_adult_knee_other_symptoms_q7_4":"extrem",
  "patient_adult_knee_other_symptoms_q7_q1":"Am Morgen, nach dem ersten Erwachen?",
  "patient_adult_knee_other_symptoms_q7_q1_a1":"keine",
  "patient_adult_knee_other_symptoms_q7_q1_a2":"leicht",
  "patient_adult_knee_other_symptoms_q7_q1_a3":"mäßig",
  "patient_adult_knee_other_symptoms_q7_q1_a4":"schwer",
  "patient_adult_knee_other_symptoms_q7_q1_a5":"extrem",
  "patient_adult_knee_other_symptoms_q7_q2":"Später am Tag, nach Sitzen, Liegen oder Ausruhen?",
  "patient_adult_knee_other_symptoms_q7_q2_a1":"keine",
  "patient_adult_knee_other_symptoms_q7_q2_a2":"leicht",
  "patient_adult_knee_other_symptoms_q7_q2_a3":"mäßig",
  "patient_adult_knee_other_symptoms_q7_q2_a4":"schwer",
  "patient_adult_knee_other_symptoms_q7_q2_a5":"extrem",
  "patient_adult_knee_other_symptoms_q8":"Was ist das höchste Aktivitätsniveau, das Sie ausführen können, ohne dass Ihr Knie signifikant nachgibt?",
  "patient_adult_knee_other_symptoms_q8_a1":"kein Nachgeben",
  "patient_adult_knee_other_symptoms_q8_a2":"gelegentliches Nachgeben bei anstrengenden Sportarten oder schwerer Arbeit, kann an allen Sportarten teilnehmen, es bestehen jedoch gewisse Vorsichtsmaßnahmen oder Einschränkungen. Selten beim Sport oder anderen schweren Anstrengungen",
  "patient_adult_knee_other_symptoms_q8_a3":"häufig beim Sport oder anderen schweren Anstrengungen. Gelegentliches Nachgeben bei leichtem Sport oder mäßiger Arbeit. Kann dies kompensieren, schränkt jedoch intensive Aktivitäten, Sport oder schwere Arbeit ein, nicht in der Lage, plötzlich zu schneiden oder sich zu verdrehen",
  "patient_adult_knee_other_symptoms_q8_a4":"Nachgeben schränkt Sport oder mäßige Arbeit ein, tritt selten beim Gehen oder bei leichter Arbeit auf (ca. 3 mal pro Jahr). Gelegentlich bei täglichen Aktivitäten",
  "patient_adult_knee_other_symptoms_q8_a5":"Nachgeben bei einfachen Gehaktivitäten und leichter Arbeit. Tritt einmal im Monat auf und erfordert Hilfe. Oft bei alltäglichen Aktivitäten",
  "patient_adult_knee_other_symptoms_q8_a6":"schwerwiegendes Problem bei einfachen Gehaktivitäten, das bei jedem Schritt nachgibt und sich beim Gehen nicht drehen oder drehen kann, ohne nachzugeben",
  "patient_adult_knee_other_symptoms_q9":"Verwenden Sie irgendeine HILFE zum Gehen?",
  "patient_adult_knee_other_symptoms_q9_a1":"keine",
  "patient_adult_knee_other_symptoms_q9_a2":"Stock oder Krücke",
  "patient_adult_knee_other_symptoms_q9_a3":"Belastung nicht möglich",
  "patient_adult_knee_function":"Funktion",
  "patient_adult_knee_function_instruction":"Geben Sie den SCHWIERIGKEITSGRAD an, den Sie bei den folgenden Aktivitäten in der letzten Woche aufgrund Ihres Knies hatten",
  "patient_adult_knee_function_instruction_0":"keine Schwierigkeit",
  "patient_adult_knee_function_instruction_1":"minimale Schwierigkeit",
  "patient_adult_knee_function_instruction_2":"mittlere Schwierigkeit",
  "patient_adult_knee_function_instruction_3":"extreme Schwierigkeit",
  "patient_adult_knee_function_instruction_4":"unfähig zu tun",
  "patient_adult_knee_function_note":"Hinweis: Wenn Sie eine bestimmte Aktivität nicht ausführen, stellen Sie sich vor, wie sich Ihr Knie anfühlen würde, wenn Sie es versuchen müssten",
  "patient_adult_knee_function_q1":"Treppen hinauf gehen",
  "patient_adult_knee_function_q1_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q1_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q1_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q1_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q1_a5":"unfähig zu tun",
  "patient_adult_knee_function_q2":"Treppen herunter gehen",
  "patient_adult_knee_function_q2_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q2_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q2_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q2_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q2_a5":"unfähig zu tun",
  "patient_adult_knee_function_q3":"Knien Sie auf der Vorderseite Ihres Knies",
  "patient_adult_knee_function_q3_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q3_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q3_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q3_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q3_a5":"unfähig zu tun",
  "patient_adult_knee_function_q4":"Ihr Knie strecken",
  "patient_adult_knee_function_q4_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q4_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q4_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q4_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q4_a5":"unfähig zu tun",
  "patient_adult_knee_function_q5":"Ihr Kniebeugen",
  "patient_adult_knee_function_q5_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q5_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q5_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q5_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q5_a5":"unfähig zu tun",
  "patient_adult_knee_function_q6":"Hocken",
  "patient_adult_knee_function_q6_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q6_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q6_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q6_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q6_a5":"unfähig zu tun",
  "patient_adult_knee_function_q7":"Mit gebeugtem Knie sitzen",
  "patient_adult_knee_function_q7_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q7_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q7_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q7_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q7_a5":"unfähig zu tun",
  "patient_adult_knee_function_q8":"Aufstehen von einem Stuhl",
  "patient_adult_knee_function_q8_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q8_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q8_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q8_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q8_a5":"unfähig zu tun",
  "patient_adult_knee_function_q9":"Aufstehen aus dem Bett",
  "patient_adult_knee_function_q9_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q9_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q9_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q9_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q9_a5":"unfähig zu tun",
  "patient_adult_knee_function_q10":"Im Bett liegen (Kniestellung beibehalten, wenn Sie sich umdrehen)",
  "patient_adult_knee_function_q10_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q10_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q10_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q10_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q10_a5":"unfähig zu tun",
  "patient_adult_knee_function_q11":"Sich zum Boden beugen/einen Gegenstand aufheben",
  "patient_adult_knee_function_q11_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q11_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q11_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q11_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q11_a5":"unfähig zu tun",
  "patient_adult_knee_function_q12":"Socken, Strumpfhosen anziehen",
  "patient_adult_knee_function_q12_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q12_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q12_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q12_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q12_a5":"unfähig zu tun",
  "patient_adult_knee_function_q13":"Socken, Strumpfhosen ausziehen",
  "patient_adult_knee_function_q13_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q13_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q13_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q13_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q13_a5":"unfähig zu tun",
  "patient_adult_knee_function_q14":"Stehen",
  "patient_adult_knee_function_q14_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q14_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q14_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q14_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q14_a5":"unfähig zu tun",
  "patient_adult_knee_function_q15":"Gehen",
  "patient_adult_knee_function_q15_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q15_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q15_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q15_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q15_a5":"unfähig zu tun",
  "patient_adult_knee_function_q16":"Geradeaus rennen",
  "patient_adult_knee_function_q16_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q16_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q16_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q16_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q16_a5":"unfähig zu tun",
  "patient_adult_knee_function_q17":"Springen mit und Landen auf dem betroffenen Bein",
  "patient_adult_knee_function_q17_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q17_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q17_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q17_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q17_a5":"unfähig zu tun",
  "patient_adult_knee_function_q18":"Schnelles Stoppen und Starten",
  "patient_adult_knee_function_q18_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q18_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q18_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q18_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q18_a5":"unfähig zu tun",
  "patient_adult_knee_function_q19":"Das verletzte Knie drehen",
  "patient_adult_knee_function_q19_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q19_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q19_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q19_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q19_a5":"unfähig zu tun",
  "patient_adult_knee_function_q20":"Ein- und Aussteigen in ein Auto oder öffentliche Verkehrsmittel",
  "patient_adult_knee_function_q20_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q20_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q20_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q20_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q20_a5":"unfähig zu tun",
  "patient_adult_knee_function_q21":"Ein- und Aussteigen aus dem Bad",
  "patient_adult_knee_function_q21_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q21_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q21_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q21_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q21_a5":"unfähig zu tun",
  "patient_adult_knee_function_q22":"Auf die Toilette setzen und aufstehen",
  "patient_adult_knee_function_q22_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q22_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q22_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q22_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q22_a5":"unfähig zu tun",
  "patient_adult_knee_function_q23":"Sich selbst waschen und abtrocknen",
  "patient_adult_knee_function_q23_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q23_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q23_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q23_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q23_a5":"unfähig zu tun",
  "patient_adult_knee_function_q24":"Haushaltseinkäufe",
  "patient_adult_knee_function_q24_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q24_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q24_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q24_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q24_a5":"unfähig zu tun",
  "patient_adult_knee_function_q25":"schwere Hausarbeit (schwere Kisten bewegen, Böden schrubben usw.)",
  "patient_adult_knee_function_q25_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q25_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q25_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q25_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q25_a5":"unfähig zu tun",
  "patient_adult_knee_function_q26":"Leichte Hausarbeit (Kochen, Abstauben usw)",
  "patient_adult_knee_function_q26_a1":"keine Schwierigkeit",
  "patient_adult_knee_function_q26_a2":"minimale Schwierigkeit",
  "patient_adult_knee_function_q26_a3":"mittlere Schwierigkeit",
  "patient_adult_knee_function_q26_a4":"extreme Schwierigkeit",
  "patient_adult_knee_function_q26_a5":"unfähig zu tun",
  "patient_adult_knee_function_q27":"Wie würden Sie die AKTUELLE Funktion Ihres Knies auf einer Skala von 0-10 bewerten?",
  "patient_adult_knee_function_q28":"10 ist normal und 0 ist die Unfähigkeit, eine Ihrer üblichen täglichen Aktivitäten auszuführen, die Sport beinhalten kann",
  "patient_adult_knee_function_q28_a1":"am schlimmsten",
  "patient_adult_knee_function_q28_a2":"am besten",
  "patient_adult_knee_function_q29":"Was ist das höchste Aktivitätsniveau, an dem Sie regelmäßig teilnehmen können?",
  "patient_adult_knee_function_q29_a1":"sehr anstrengende Aktivitäten wie Springen oder Drehen wie beim Basketball oder Fußball",
  "patient_adult_knee_function_q29_a2":"anstrengende Aktivitäten wie schwere körperliche Arbeit, Skifahren oder Tennis",
  "patient_adult_knee_function_q29_a3":"moderate Aktivitäten wie moderate körperliche Arbeit, Laufen oder Joggen",
  "patient_adult_knee_function_q29_a4":"leichte Aktivitäten wie Spazierengehen, Hausarbeit oder Gartenarbeit",
  "patient_adult_knee_function_q29_a5":"aufgrund von Knieschmerzen ist es mir nicht möglich, eine der oben genannten Aktivitäten auszuführen",
  "patient_adult_knee_function_q30":"Bitte geben Sie die höchste Aktivitätsstufe an, an der Sie teilnehmen können",
  "patient_adult_knee_function_q30_q1":"Vor Ihrer Knieverletzung/Ihrem Knieproblem",
  "patient_adult_knee_function_q30_q1_a1":"wettbewerbsorientiert",
  "patient_adult_knee_function_q30_q1_a2":"freizeitliche, körperliche Fitness",
  "patient_adult_knee_function_q30_q1_a3":"kein Sport, nur Arbeit",
  "patient_adult_knee_function_q30_q1_a4":"nur Aktivitäten des täglichen Lebens",
  "patient_adult_knee_function_q30_q1_a5":"Krankheitsurlaub oder Invalidenrente aufgrund gesundheitlicher Probleme",
  "patient_adult_knee_function_q30_q1_a1_o1":"Wettkampfsportarten, die auf Welt- oder olympischem Niveau oder professionell ausgeübt werden",
  "patient_adult_knee_function_q30_q1_a1_o2":"Leistungssportarten wie Leichtathletik, Schläger-/Ballsportarten, Turnen, Rudern, Skifahren oder Kampfsportarten auf nationaler oder internationaler Ebene",
  "patient_adult_knee_function_q30_q1_a1_o3":"Leistungssportarten wie Wassersport, Radfahren, Hockey, Curling oder Reiten/Rodeo auf nationaler oder internationaler Ebene",
  "patient_adult_knee_function_q30_q1_a2_o1":"Freizeitsportarten wie Laufen, Ball-/Schlägersport, Krafttraining, Curling, Rudern, Golf, Yoga oder Gymnastik mindestens 5-mal pro Woche",
  "patient_adult_knee_function_q30_q1_a2_o2":"Freizeitsportarten wie Schwimmen, Wassersport, Skifahren, Hockey, Inlineskaten, Radfahren, Reiten oder Bergsteigen mindestens 5-mal pro Woche",
  "patient_adult_knee_function_q30_q1_a2_o3":"Freizeitsport mindestens zweimal wöchentlich",
  "patient_adult_knee_function_q30_q1_a2_o4":"Freizeitsport einmal pro Woche oder weniger",
  "patient_adult_knee_function_q30_q1_a2_o5":"Freizeitsport weniger als einmal im Monat",
  "patient_adult_knee_function_q30_q1_a3_o1":"schwere Arbeiten wie Bauarbeiten",
  "patient_adult_knee_function_q30_q1_a3_o2":"mäßig schwere Arbeiten wie Lkw-Fahren",
  "patient_adult_knee_function_q30_q1_a3_o3":"leichte Arbeit wie Krankenpflege",
  "patient_adult_knee_function_q30_q1_a3_o4":"sitzende Tätigkeiten wie Sekretariats- oder Computerarbeit",
  "patient_adult_knee_function_q30_q1_a4_o1":"tägliche Aktivitäten wie Gartenarbeit, Treppensteigen, Tragen von Lasten, Schieben/Ziehen einer Last, Fähigkeit zu rennen, wenn man zu spät kommt",
  "patient_adult_knee_function_q30_q1_a4_o2":"tägliche Aktivitäten wie Hausputzen, ein oder zwei Treppen steigen oder Gehen mit leichter Last",
  "patient_adult_knee_function_q30_q1_a4_o3":"tägliche Aktivitäten eingeschränkt (z. B. keine Treppen steigen, keine Lasten tragen können)",
  "patient_adult_knee_function_q30_q2":"Aktueller Stand",
  "patient_adult_knee_function_q30_q2_a1":"wettbewerbsorientiert",
  "patient_adult_knee_function_q30_q2_a2":"freizeitliche, körperliche Fitness",
  "patient_adult_knee_function_q30_q2_a3":"kein Sport, nur Arbeit",
  "patient_adult_knee_function_q30_q2_a4":"nur Aktivitäten des täglichen Lebens",
  "patient_adult_knee_function_q30_q2_a5":"Krankheitsurlaub oder Invalidenrente aufgrund gesundheitlicher Probleme",
  "patient_adult_knee_function_q30_q2_a1_o1":"Wettkampfsportarten, die auf Welt- oder olympischem Niveau oder professionell ausgeübt werden",
  "patient_adult_knee_function_q30_q2_a1_o2":"Leistungssportarten wie Leichtathletik, Schläger-/Ballsportarten, Turnen, Rudern, Skifahren oder Kampfsportarten auf nationaler oder internationaler Ebene",
  "patient_adult_knee_function_q30_q2_a1_o3":"Leistungssportarten wie Wassersport, Radfahren, Hockey, Curling oder Reiten/Rodeo auf nationaler oder internationaler Ebene",
  "patient_adult_knee_function_q30_q2_a2_o1":"Freizeitsportarten wie Laufen, Ball-/Schlägersport, Krafttraining, Curling, Rudern, Golf, Yoga oder Gymnastik mindestens 5-mal pro Woche",
  "patient_adult_knee_function_q30_q2_a2_o2":"Freizeitsportarten wie Schwimmen, Wassersport, Skifahren, Hockey, Inlineskaten, Radfahren, Reiten oder Bergsteigen mindestens 5-mal pro Woche",
  "patient_adult_knee_function_q30_q2_a2_o3":"Freizeitsport mindestens zweimal wöchentlich",
  "patient_adult_knee_function_q30_q2_a2_o4":"Freizeitsport einmal pro Woche oder weniger",
  "patient_adult_knee_function_q30_q2_a2_o5":"Freizeitsport weniger als einmal im Monat",
  "patient_adult_knee_function_q30_q2_a3_o1":"schwere Arbeiten wie Bauarbeiten",
  "patient_adult_knee_function_q30_q2_a3_o2":"mäßig schwere Arbeiten wie Lkw-Fahren",
  "patient_adult_knee_function_q30_q2_a3_o3":"leichte Arbeit wie Krankenpflege",
  "patient_adult_knee_function_q30_q2_a3_o4":"sitzende Tätigkeiten wie Sekretariats- oder Computerarbeit",
  "patient_adult_knee_function_q30_q2_a4_o1":"tägliche Aktivitäten wie Gartenarbeit, Treppensteigen, Tragen von Lasten, Schieben/Ziehen einer Last, Fähigkeit zu rennen, wenn man zu spät kommt",
  "patient_adult_knee_function_q30_q2_a4_o2":"tägliche Aktivitäten wie Hausputzen, ein oder zwei Treppen steigen oder Gehen mit leichter Last",
  "patient_adult_knee_function_q30_q2_a4_o3":"tägliche Aktivitäten eingeschränkt (z. B. keine Treppen steigen, keine Lasten tragen können)",
  "patient_adult_knee_function_q31":"Verrutscht Ihre Kniescheibe oder fühlt es sich an, als würde sie verrutschen?",
  "patient_adult_knee_function_q31_a1":"nein",
  "patient_adult_knee_function_q31_a2":"ja",
  "patient_adult_knee_patellofemoral_instability":"Patellofemorale Instabilität",
  "patient_adult_knee_patellofemoral_instability_instruction":"Bitte wählen Sie aus, was am besten beschreibt, wie oft sich Ihre Kniescheibe anfühlt, als würde sie aus dem Gelenk „herausspringen“ oder sich instabil anfühlt, wenn Sie die folgenden Aktivitäten ausführen",
  "patient_adult_knee_patellofemoral_instability_instruction_0":"niemals",
  "patient_adult_knee_patellofemoral_instability_instruction_1":"selten",
  "patient_adult_knee_patellofemoral_instability_instruction_2":"manchmal",
  "patient_adult_knee_patellofemoral_instability_instruction_3":"oft",
  "patient_adult_knee_patellofemoral_instability_instruction_4":"immer",
  "patient_adult_knee_patellofemoral_instability_q1":"Drehen/Wechseln der Richtung beim Sport/Spiel",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q1_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q1_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q1_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q1_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q1_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q2":"Richtungswechsel beim Laufen",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q2_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q2_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q2_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q2_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q2_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q3":"Geradeauslaufen auf unebenem Untergrund",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q3_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q3_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q3_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q3_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q3_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q4":"Gehen auf rutschigen, nassen oder vereisten Oberflächen",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q4_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q4_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q4_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q4_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q4_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q5":"Seitlich laufen",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q5_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q5_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q5_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q5_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q5_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q6":"Auf einem Bein hüpfen",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q6_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q6_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q6_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q6_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q6_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q7":"Springen",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q7_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q7_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q7_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q7_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q7_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q8":"Geradeauslaufen auf ebenem Untergrund",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q8_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q8_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q8_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q8_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q8_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q9":"Treppen herunter gehen",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q9_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q9_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q9_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q9_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q9_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q10":"Hocken",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q10_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q10_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q10_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q10_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q10_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q11":"Knien",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q11_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q11_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q11_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q11_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q11_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q12":"Auf unebenen Flächen geradeaus gehen",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q12_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q12_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q12_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q12_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q12_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q13":"Treppen heraufgehen",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q13_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q13_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q13_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q13_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q13_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q14":"Auf oder über eine hohe Stufe treten",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q14_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q14_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q14_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q14_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q14_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q15":"Beim Sitzen die Beine kreuzen",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q15_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q15_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q15_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q15_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q15_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q16":"Auf unebenen Flächen geradeaus gehen",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q16_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q16_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q16_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q16_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q16_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q17":"In ein Auto Ein- und Aussteigen",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q17_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q17_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q17_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q17_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q17_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q18":"Einen schweren Einkaufswagens in einem Gang im Supermarkt drehen",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q18_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q18_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q18_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q18_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q18_a5":"immer",
  "patient_adult_knee_patellofemoral_instability_q19":"Sich umdrehen, um über die Schulter zu schauen",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do":"tue es nicht",
  "patient_adult_knee_patellofemoral_instability_q19_a1":"niemals",
  "patient_adult_knee_patellofemoral_instability_q19_a2":"selten",
  "patient_adult_knee_patellofemoral_instability_q19_a3":"manchmal",
  "patient_adult_knee_patellofemoral_instability_q19_a4":"oft",
  "patient_adult_knee_patellofemoral_instability_q19_a5":"immer",
  "patient_adult_knee_quality_of_life":"Lebensqualität",
  "patient_adult_knee_quality_of_life_instruction":"In den folgenden Fragen geht es um soziale, emotionale und Lebensstilprobleme, die Sie im Zusammenhang mit Ihrem Knieproblem haben könnten. Bitte denken Sie darüber nach, wie Sie sich die meiste Zeit in Bezug auf Ihr Knie gefühlt haben.",
  "patient_adult_knee_quality_of_life_q1":"Wie oft sind Sie sich Ihres Knieproblems bewusst?",
  "patient_adult_knee_quality_of_life_q1_a1":"niemals",
  "patient_adult_knee_quality_of_life_q1_a2":"monatlich",
  "patient_adult_knee_quality_of_life_q1_a3":"wöchentlich",
  "patient_adult_knee_quality_of_life_q1_a4":"täglich",
  "patient_adult_knee_quality_of_life_q1_a5":"ständig",
  "patient_adult_knee_quality_of_life_q2":"Haben Sie Ihren Lebensstil geändert, um potenziell schädliche Aktivitäten für Ihr Knie zu vermeiden?",
  "patient_adult_knee_quality_of_life_q2_a1":"gar nicht",
  "patient_adult_knee_quality_of_life_q2_a2":"leicht",
  "patient_adult_knee_quality_of_life_q2_a3":"in Maßen",
  "patient_adult_knee_quality_of_life_q2_a4":"stark",
  "patient_adult_knee_quality_of_life_q2_a5":"total",
  "patient_adult_knee_quality_of_life_q3":"Wie sehr sind Sie mit dem Vertrauen in Ihr Knie beunruhigt?",
  "patient_adult_knee_quality_of_life_q3_a1":"gar nicht",
  "patient_adult_knee_quality_of_life_q3_a2":"leicht",
  "patient_adult_knee_quality_of_life_q3_a3":"in Maßen",
  "patient_adult_knee_quality_of_life_q3_a4":"stark",
  "patient_adult_knee_quality_of_life_q3_a5":"äußerst",
  "patient_adult_knee_quality_of_life_q4":"Wie viel Schwierigkeiten haben Sie im Allgemeinen mit Ihrem Knie?",
  "patient_adult_knee_quality_of_life_q4_a1":"keine",
  "patient_adult_knee_quality_of_life_q4_a2":"leichte",
  "patient_adult_knee_quality_of_life_q4_a3":"mäßige",
  "patient_adult_knee_quality_of_life_q4_a4":"schwere",
  "patient_adult_knee_quality_of_life_q4_a5":"extreme",
  "patient_adult_knee_quality_of_life_q5":"Wie ängstlich/deprimiert sind Sie heute?",
  "patient_adult_knee_quality_of_life_q5_a1":"nicht ängstlich/deprimiert",
  "patient_adult_knee_quality_of_life_q5_a2":"leicht",
  "patient_adult_knee_quality_of_life_q5_a3":"in Maßen",
  "patient_adult_knee_quality_of_life_q5_a4":"stark",
  "patient_adult_knee_quality_of_life_q5_a5":"extrem",
  "patient_adult_knee_quality_of_life_q6":"Wie gut/schlecht ist Ihre Gesundheit heute?",
  "patient_adult_knee_quality_of_life_q6_instruction":"100 bedeutet beste Gesundheit, 0 bedeutet schlechteste Gesundheit, die Sie sich vorstellen können",
  "patient_adult_knee_quality_of_life_q6_instruction_a1":"am schlimmsten",
  "patient_adult_knee_quality_of_life_q6_instruction_a2":"am besten",
  "patient_adult_knee_satisfaction":"Zufriedenheit",
  "patient_adult_knee_satisfaction_instructions":"Wie zufrieden sind Sie mit den Ergebnissen Ihrer Operation/Behandlung?",
  "patient_adult_knee_satisfaction_instructions_1":"sehr unzufrieden",
  "patient_adult_knee_satisfaction_instructions_2":"etwas unzufrieden",
  "patient_adult_knee_satisfaction_instructions_3":"etwas zufrieden",
  "patient_adult_knee_satisfaction_instructions_4":"sehr zufrieden",
  "patient_adult_knee_satisfaction_q1":"insgesamt",
  "patient_adult_knee_satisfaction_q1_not_applicable":"unzutreffend",
  "patient_adult_knee_satisfaction_q1_a1":"sehr unzufrieden",
  "patient_adult_knee_satisfaction_q1_a2":"etwas unzufrieden",
  "patient_adult_knee_satisfaction_q1_a3":"etwas zufrieden",
  "patient_adult_knee_satisfaction_q1_a4":"sehr zufrieden",
  "patient_adult_knee_satisfaction_q2":"Zur Linderung Ihrer Schmerzen?",
  "patient_adult_knee_satisfaction_q2_not_applicable":"unzutreffend",
  "patient_adult_knee_satisfaction_q2_a1":"sehr unzufrieden",
  "patient_adult_knee_satisfaction_q2_a2":"etwas unzufrieden",
  "patient_adult_knee_satisfaction_q2_a3":"etwas zufrieden",
  "patient_adult_knee_satisfaction_q2_a4":"sehr zufrieden",
  "patient_adult_knee_satisfaction_q3":"Um Ihre Fähigkeit zu verbessern, Haus- oder Gartenarbeit zu erledigen?",
  "patient_adult_knee_satisfaction_q3_not_applicable":"unzutreffend",
  "patient_adult_knee_satisfaction_q3_a1":"sehr unzufrieden",
  "patient_adult_knee_satisfaction_q3_a2":"etwas unzufrieden",
  "patient_adult_knee_satisfaction_q3_a3":"etwas zufrieden",
  "patient_adult_knee_satisfaction_q3_a4":"sehr zufrieden",
  "patient_adult_knee_satisfaction_q4":"Um Ihre Fähigkeit zu verbessern,  Freizeitaktivitäten zu unternehmen?",
  "patient_adult_knee_satisfaction_q4_not_applicable":"unzutreffend",
  "patient_adult_knee_satisfaction_q4_a1":"sehr unzufrieden",
  "patient_adult_knee_satisfaction_q4_a2":"etwas unzufrieden",
  "patient_adult_knee_satisfaction_q4_a3":"etwas zufrieden",
  "patient_adult_knee_satisfaction_q4_a4":"sehr zufrieden",
  "patient_adult_knee_complete":"Ende des Fragebogens",
  "patient_adult_knee_complete_text1":"Vielen Dank, dass Sie sich die Zeit genommen haben, dieses Formular auszufüllen.",
  "patient_adult_knee_complete_text2":"Bitte klicken Sie auf „Senden“, um den Vorgang abzuschließen und Ihre Ergebnisse anzuzeigen.",
  "patient_adult_knee_complete_text3":"Ihre Antworten werden vertraulich behandelt und in allen Berichten werden nur anonymisierte Informationen verwendet.",
  "patient_adult_knee_results":"Ergebnisse",
  "patient_adult_knee_results_text1":"Nachfolgend finden Sie Ihre Schmerz- und Kniefunktionswerte, die anhand Ihres Fragebogens berechnet wurden.",
  "patient_adult_knee_results_pain":"Schmerz",
  "patient_adult_knee_results_function":"Funktion",
  "patient_adult_knee_results_health_perception":"Allgemeine Gesundheitswahrnehmung",
  "patient_adult_knee_results_self_evaluation":"Knieselbstbewertung",
  "patient_adult_knee_results_text2":"Ihr Arzt wird in Kürze bei Ihnen sein.",
  "patient_adolescent_knee_pain":"Schmerz",
  "patient_adolescent_knee_function_q23":"Einkaufen gehen",
  "patient_adolescent_knee_function_q23_a1":"keine Schwierigkeit",
  "patient_adolescent_knee_function_q23_a2":"minimale Schwierigkeit",
  "patient_adolescent_knee_function_q23_a3":"mittlere Schwierigkeit",
  "patient_adolescent_knee_function_q23_a4":"extreme Schwierigkeit",
  "patient_adolescent_knee_function_q23_a5":"unfähig zu tun",
  "patient_pedia_knee_pain":"Schmerz",
  "patient_pedia_knee_pain_q1":"Wie oft hat Dein verletztes Knie in den letzten 4 Wochen oder seit Deiner Verletzung geschmerzt?",
  "patient_pedia_knee_pain_q1_a1":"tut niemals weh",
  "patient_pedia_knee_pain_q1_a2":"selten",
  "patient_pedia_knee_pain_q1_a3":"manchmal",
  "patient_pedia_knee_pain_q1_a4":"oft",
  "patient_pedia_knee_pain_q1_a5":"ständig",
  "patient_pedia_knee_pain_q2":"Wie sehr schmerzt Dein verletztes Knie heute?",
  "patient_pedia_knee_pain_q2_a1":"tut überhaupt nicht weh",
  "patient_pedia_knee_pain_q2_a2":"es tut so weh, dass ich es nicht ertragen kann",
  "patient_pedia_knee_pain_instructions":"Wie viele Knieschmerzen hattest Du in den letzten 7 Tagen bei den folgenden Aktivitäten?",
  "patient_pedia_knee_pain_instructions_0":"kein Schmerz",
  "patient_pedia_knee_pain_instructions_1":"ein bisschen Schmerz",
  "patient_pedia_knee_pain_instructions_2":"etwas Schmerz",
  "patient_pedia_knee_pain_instructions_3":"viele Schmerzen",
  "patient_pedia_knee_pain_instructions_4":"extreme Schmerzen",
  "patient_pedia_knee_pain_note":"Hinweis: Bitte beantworte die folgenden Fragen zu Deinen Knieaktivitäten.",
  "patient_pedia_knee_pain_q3":"Drehen/Schwenken des Knies beim Gehen/Stehen/Laufen",
  "patient_pedia_knee_pain_q3_a1":"kein Schmerz",
  "patient_pedia_knee_pain_q3_a2":"ein bisschen Schmerz",
  "patient_pedia_knee_pain_q3_a3":"etwas Schmerz",
  "patient_pedia_knee_pain_q3_a4":"viele Schmerzen",
  "patient_pedia_knee_pain_q3_a5":"extreme Schmerzen",
  "patient_pedia_knee_pain_q4":"Knie vollständig strecken",
  "patient_pedia_knee_pain_q4_a1":"kein Schmerz",
  "patient_pedia_knee_pain_q4_a2":"ein bisschen Schmerz",
  "patient_pedia_knee_pain_q4_a3":"etwas Schmerz",
  "patient_pedia_knee_pain_q4_a4":"viele Schmerzen",
  "patient_pedia_knee_pain_q4_a5":"extreme Schmerzen",
  "patient_pedia_knee_pain_q5":"Knie vollständig beugen",
  "patient_pedia_knee_pain_q5_a1":"kein Schmerz",
  "patient_pedia_knee_pain_q5_a2":"ein bisschen Schmerz",
  "patient_pedia_knee_pain_q5_a3":"etwas Schmerz",
  "patient_pedia_knee_pain_q5_a4":"viele Schmerzen",
  "patient_pedia_knee_pain_q5_a5":"extreme Schmerzen",
  "patient_pedia_knee_pain_q6":"Treppen heraufgehen",
  "patient_pedia_knee_pain_q6_a1":"kein Schmerz",
  "patient_pedia_knee_pain_q6_a2":"ein bisschen Schmerz",
  "patient_pedia_knee_pain_q6_a3":"etwas Schmerz",
  "patient_pedia_knee_pain_q6_a4":"viele Schmerzen",
  "patient_pedia_knee_pain_q6_a5":"extreme Schmerzen",
  "patient_pedia_knee_pain_q7":"Treppe herunter gehen",
  "patient_pedia_knee_pain_q7_a1":"kein Schmerz",
  "patient_pedia_knee_pain_q7_a2":"ein bisschen Schmerz",
  "patient_pedia_knee_pain_q7_a3":"etwas Schmerz",
  "patient_pedia_knee_pain_q7_a4":"viele Schmerzen",
  "patient_pedia_knee_pain_q7_a5":"extreme Schmerzen",
  "patient_pedia_knee_pain_q8":"Mit gebeugtem Knie sitzen",
  "patient_pedia_knee_pain_q8_a1":"kein Schmerz",
  "patient_pedia_knee_pain_q8_a2":"ein bisschen Schmerz",
  "patient_pedia_knee_pain_q8_a3":"etwas Schmerz",
  "patient_pedia_knee_pain_q8_a4":"viele Schmerzen",
  "patient_pedia_knee_pain_q8_a5":"extreme Schmerzen",
  "patient_pedia_knee_pain_q9":"Für längere Zeit aufrecht auf beiden Beinen stehen",
  "patient_pedia_knee_pain_q9_a1":"kein Schmerz",
  "patient_pedia_knee_pain_q9_a2":"ein bisschen Schmerz",
  "patient_pedia_knee_pain_q9_a3":"etwas Schmerz",
  "patient_pedia_knee_pain_q9_a4":"viele Schmerzen",
  "patient_pedia_knee_pain_q9_a5":"extreme Schmerzen",
  "patient_pedia_knee_other_symptoms":"Weitere Symptome",
  "patient_pedia_knee_other_symptoms_instruction1":"Wie oft hattest Du",
  "patient_pedia_knee_other_symptoms_instruction1_0":"niemals",
  "patient_pedia_knee_other_symptoms_instruction1_1":"selten",
  "patient_pedia_knee_other_symptoms_instruction1_2":"manchmal",
  "patient_pedia_knee_other_symptoms_instruction1_3":"oft",
  "patient_pedia_knee_other_symptoms_instruction1_4":"immer",
  "patient_pedia_knee_other_symptoms_q1":"Knie macht Geräusche",
  "patient_pedia_knee_other_symptoms_q1_a1":"niemals",
  "patient_pedia_knee_other_symptoms_q1_a2":"selten",
  "patient_pedia_knee_other_symptoms_q1_a3":"manchmal",
  "patient_pedia_knee_other_symptoms_q1_a4":"oft",
  "patient_pedia_knee_other_symptoms_q1_a5":"immer",
  "patient_pedia_knee_other_symptoms_q2":"Knie hat Widerstand or ist blockiert",
  "patient_pedia_knee_other_symptoms_q2_a1":"niemals",
  "patient_pedia_knee_other_symptoms_q2_a2":"selten",
  "patient_pedia_knee_other_symptoms_q2_a3":"manchmal",
  "patient_pedia_knee_other_symptoms_q2_a4":"oft",
  "patient_pedia_knee_other_symptoms_q2_a5":"immer",
  "patient_pedia_knee_other_symptoms_q3":"Fült sich an, als ob das Knie stecken bleibt (eingeklemmt), aber Du kannst es immer noch bewegen",
  "patient_pedia_knee_other_symptoms_q3_a1":"niemals",
  "patient_pedia_knee_other_symptoms_q3_a2":"selten",
  "patient_pedia_knee_other_symptoms_q3_a3":"manchmal",
  "patient_pedia_knee_other_symptoms_q3_a4":"oft",
  "patient_pedia_knee_other_symptoms_q3_a5":"immer",
  "patient_pedia_knee_other_symptoms_q4":"Schwellung/geschwollenes Knie",
  "patient_pedia_knee_other_symptoms_q4_a1":"niemals",
  "patient_pedia_knee_other_symptoms_q4_a2":"selten",
  "patient_pedia_knee_other_symptoms_q4_a3":"manchmal",
  "patient_pedia_knee_other_symptoms_q4_a4":"oft",
  "patient_pedia_knee_other_symptoms_q4_a5":"immer",
  "patient_pedia_knee_other_symptoms_q5":"Wie GESCHWOLLEN (ODER GESCHWOLLEN) war Dein Knie?",
  "patient_pedia_knee_other_symptoms_q5_a1":"überhaupt nicht geschwollen",
  "patient_pedia_knee_other_symptoms_q5_a2":"ein bisschen geschwollen",
  "patient_pedia_knee_other_symptoms_q5_a3":"etwas geschwollen",
  "patient_pedia_knee_other_symptoms_q5_a4":"sehr geschwollen",
  "patient_pedia_knee_other_symptoms_q5_a5":"extrem geschwollen",
  "patient_pedia_knee_other_symptoms_instruction2":"Wenn Du gebeten würdest, die folgenden Aktivitäten durchzuführen, was könntest Du HEUTE am meisten tun ...",
  "patient_pedia_knee_other_symptoms_instruction2_0":"sehr schwere Aktivitäten wie Springen oder schnelles Drehen, um die Richtung zu ändern, wie beim Basketball oder Fußball",
  "patient_pedia_knee_other_symptoms_instruction2_1":"schwere Aktivitäten wie schweres Heben beim Skifahren oder Tennis",
  "patient_pedia_knee_other_symptoms_instruction2_2":"schwere Aktivitäten wie schnelles Gehen oder Joggen",
  "patient_pedia_knee_other_symptoms_instruction2_3":"leichte Aktivitäten wie Gehen mit normaler Geschwindigkeit",
  "patient_pedia_knee_other_symptoms_instruction2_4":"ich kann keine der oben aufgeführten Aktivitäten ausführen",
  "patient_pedia_knee_other_symptoms_q6":"Ohne dass Dein Knie STARK SCHMERZT",
  "patient_pedia_knee_other_symptoms_q6_a1":"sehr schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q6_a2":"schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q6_a3":"etwas schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q6_a4":"leichte Aktivitäten",
  "patient_pedia_knee_other_symptoms_q6_a5":"ich kann nichts von dem oben genannten tun",
  "patient_pedia_knee_other_symptoms_q7":"Ohne dass Dein Knie ANSCHWILLT",
  "patient_pedia_knee_other_symptoms_q7_a1":"sehr schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q7_a2":"schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q7_a3":"etwas schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q7_a4":"leichte Aktivitäten",
  "patient_pedia_knee_other_symptoms_q7_a5":"ich kann nichts von dem oben genannten tun",
  "patient_pedia_knee_other_symptoms_q8":"Ohne dass Dein Knies sich ANFUÜHLT, ALS OB ES DICH NICHT AUFRECHTHALTEN KANN",
  "patient_pedia_knee_other_symptoms_q8_a1":"sehr schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q8_a2":"schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q8_a3":"Etwas schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q8_a5":"ich kann nichts von dem oben genannten tun",
  "patient_pedia_knee_other_symptoms_q9":"meistens",
  "patient_pedia_knee_other_symptoms_q9_a1":"sehr schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q9_a2":"schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q9_a3":"Etwas schwere Aktivitäten",
  "patient_pedia_knee_other_symptoms_q9_a4":"leichte Aktivitäten",
  "patient_pedia_knee_other_symptoms_q9_a5":"ich kann nichts von dem oben genannten tun",
  "patient_pedia_knee_function":"Funktion",
  "patient_pedia_knee_function_instruction":"Wie viele Schwierigkeiten hattest Du aufgrund Deines Knies wenn Du folgendes tust:",
  "patient_pedia_knee_function_instruction_0":"keine Schwierigkeit",
  "patient_pedia_knee_function_instruction_1":"wenige",
  "patient_pedia_knee_function_instruction_2":"einige",
  "patient_pedia_knee_function_instruction_3":"eine Menge",
  "patient_pedia_knee_function_instruction_4":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_note":"Hinweis: Wenn Du eine bestimmte Aktivität nicht ausführen kannst, stell Dir vor, wie sich Dein Knie anfühlen würde, wenn Du es versuchen müsstest",
  "patient_pedia_knee_function_q1":"Treppensteigen nach oben",
  "patient_pedia_knee_function_q1_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q1_a2":"wenige",
  "patient_pedia_knee_function_q1_a3":"einige",
  "patient_pedia_knee_function_q1_a4":"eine Menge",
  "patient_pedia_knee_function_q1_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q2":"Treppensteigen nach unten",
  "patient_pedia_knee_function_q2_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q2_a2":"wenige",
  "patient_pedia_knee_function_q2_a3":"einige",
  "patient_pedia_knee_function_q2_a4":"eine Menge",
  "patient_pedia_knee_function_q2_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q3":"Knien auf der Vorderseite Deines Knies",
  "patient_pedia_knee_function_q3_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q3_a2":"wenige",
  "patient_pedia_knee_function_q3_a3":"einige",
  "patient_pedia_knee_function_q3_a4":"eine Menge",
  "patient_pedia_knee_function_q3_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q4":"Dein Knie vollständig strecken ",
  "patient_pedia_knee_function_q4_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q4_a2":"wenige",
  "patient_pedia_knee_function_q4_a3":"einige",
  "patient_pedia_knee_function_q4_a4":"eine Menge",
  "patient_pedia_knee_function_q4_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q5":"Dein Knie vollständig beugen",
  "patient_pedia_knee_function_q5_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q5_a2":"wenige",
  "patient_pedia_knee_function_q5_a3":"einige",
  "patient_pedia_knee_function_q5_a4":"eine Menge",
  "patient_pedia_knee_function_q5_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q6":"Dein Knie gleich nach dem Aufwachen am Morgen bewegen",
  "patient_pedia_knee_function_q6_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q6_a2":"wenige",
  "patient_pedia_knee_function_q6_a3":"einige",
  "patient_pedia_knee_function_q6_a4":"eine Menge",
  "patient_pedia_knee_function_q6_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q7":"Dein Knie später am Tag bewegen, nachdem Du eine Weile gesessen bist",
  "patient_pedia_knee_function_q7_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q7_a2":"wenige",
  "patient_pedia_knee_function_q7_a3":"einige",
  "patient_pedia_knee_function_q7_a4":"eine Menge",
  "patient_pedia_knee_function_q7_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q8":"Gehe in die Hocke wie ein Baseball-Catcher",
  "patient_pedia_knee_function_q8_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q8_a2":"wenige",
  "patient_pedia_knee_function_q8_a3":"einige",
  "patient_pedia_knee_function_q8_a4":"eine Menge",
  "patient_pedia_knee_function_q8_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q9":"Mit gebeugten Knien und flachen Füßen auf dem Boden auf einen Stuhl sitzen",
  "patient_pedia_knee_function_q9_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q9_a2":"wenige",
  "patient_pedia_knee_function_q9_a3":"einige",
  "patient_pedia_knee_function_q9_a4":"eine Menge",
  "patient_pedia_knee_function_q9_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q10":"Von einem Stuhl aufstehen",
  "patient_pedia_knee_function_q10_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q10_a2":"wenige",
  "patient_pedia_knee_function_q10_a3":"einige",
  "patient_pedia_knee_function_q10_a4":"eine Menge",
  "patient_pedia_knee_function_q10_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q11":"Dich herunter Beugen und einen Gegenstand vom Boden aufheben",
  "patient_pedia_knee_function_q11_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q11_a2":"wenige",
  "patient_pedia_knee_function_q11_a3":"einige",
  "patient_pedia_knee_function_q11_a4":"eine Menge",
  "patient_pedia_knee_function_q11_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q12":"Laufen beim Spielen oder bei sportlichen Aktivitäten",
  "patient_pedia_knee_function_q12_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q12_a2":"wenige",
  "patient_pedia_knee_function_q12_a3":"einige",
  "patient_pedia_knee_function_q12_a4":"eine Menge",
  "patient_pedia_knee_function_q12_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q13":"Springen beim Spiel oder bei sportlichen Aktivitäten",
  "patient_pedia_knee_function_q13_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q13_a2":"wenige",
  "patient_pedia_knee_function_q13_a3":"einige",
  "patient_pedia_knee_function_q13_a4":"eine Menge",
  "patient_pedia_knee_function_q13_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q14":"Schnelles Starten und Stoppen",
  "patient_pedia_knee_function_q14_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q14_a2":"wenige",
  "patient_pedia_knee_function_q14_a3":"einige",
  "patient_pedia_knee_function_q14_a4":"eine Menge",
  "patient_pedia_knee_function_q14_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q15":"Drehen beim Spielen oder bei sportlichen Aktivitäten",
  "patient_pedia_knee_function_q15_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q15_a2":"wenige",
  "patient_pedia_knee_function_q15_a3":"einige",
  "patient_pedia_knee_function_q15_a4":"eine Menge",
  "patient_pedia_knee_function_q15_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q16":"Dein Gleichgewicht halten beim Gehen/Rennen auf unebenem Untergrund",
  "patient_pedia_knee_function_q16_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q16_a2":"wenige",
  "patient_pedia_knee_function_q16_a3":"einige",
  "patient_pedia_knee_function_q16_a4":"eine Menge",
  "patient_pedia_knee_function_q16_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q17":"Sport treiben",
  "patient_pedia_knee_function_q17_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q17_a2":"wenige",
  "patient_pedia_knee_function_q17_a3":"einige",
  "patient_pedia_knee_function_q17_a4":"eine Menge",
  "patient_pedia_knee_function_q17_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q18":"In ein Auto einsteigen/aussteigen",
  "patient_pedia_knee_function_q18_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q18_a2":"wenige",
  "patient_pedia_knee_function_q18_a3":"einige",
  "patient_pedia_knee_function_q18_a4":"eine Menge",
  "patient_pedia_knee_function_q18_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q19":"Aus dem Bett steigen",
  "patient_pedia_knee_function_q19_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q19_a2":"wenige",
  "patient_pedia_knee_function_q19_a3":"einige",
  "patient_pedia_knee_function_q19_a4":"eine Menge",
  "patient_pedia_knee_function_q19_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q20":"Knieposition beim Liegen im Bett ändern",
  "patient_pedia_knee_function_q20_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q20_a2":"wenige",
  "patient_pedia_knee_function_q20_a3":"einige",
  "patient_pedia_knee_function_q20_a4":"eine Menge",
  "patient_pedia_knee_function_q20_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q21":"In die/aus der Badewanne/Dusche steigen",
  "patient_pedia_knee_function_q21_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q21_a2":"wenige",
  "patient_pedia_knee_function_q21_a3":"einige",
  "patient_pedia_knee_function_q21_a4":"eine Menge",
  "patient_pedia_knee_function_q21_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q22":"Eine schwere Tasche/Rucksack usw. Tragen",
  "patient_pedia_knee_function_q22_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q22_a2":"wenige",
  "patient_pedia_knee_function_q22_a3":"einige",
  "patient_pedia_knee_function_q22_a4":"eine Menge",
  "patient_pedia_knee_function_q22_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q23":"Leichte Aufgaben wie Deine Zimmer saubermachen, Füllen / Leeren der Spülmaschine, Dein Bett machen usw.",
  "patient_pedia_knee_function_q23_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q23_a2":"wenige",
  "patient_pedia_knee_function_q23_a3":"einige",
  "patient_pedia_knee_function_q23_a4":"eine Menge",
  "patient_pedia_knee_function_q23_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q24":"Zur Schule gehen oder in der Schule herumlaufen (Treppensteigen, Türen öffnen, Bücher tragen, Spielen in der Pause)",
  "patient_pedia_knee_function_q24_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q24_a2":"wenige",
  "patient_pedia_knee_function_q24_a3":"einige",
  "patient_pedia_knee_function_q24_a4":"eine Menge",
  "patient_pedia_knee_function_q24_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q25":"Dinge mit Freunden unternehmen",
  "patient_pedia_knee_function_q25_a1":"keine Schwierigkeit",
  "patient_pedia_knee_function_q25_a2":"wenige",
  "patient_pedia_knee_function_q25_a3":"einige",
  "patient_pedia_knee_function_q25_a4":"eine Menge",
  "patient_pedia_knee_function_q25_a5":"extreme Schwierigkeiten/unmöglich",
  "patient_pedia_knee_function_q26":"Wie gut hat Dein Knie funktioniert, bevor Du Dich verletzt hast?",
  "patient_pedia_knee_function_q26_instruction":"10 ist normal und 0 ist die Unfähigkeit, eine Deiner üblichen täglichen Aktivitäten auszuführen, die Sport beinhalten kann",
  "patient_pedia_knee_function_q26_instruction_a1":"Ich konnte überhaupt nichts tun",
  "patient_pedia_knee_function_q26_instruction_a2":"Ich konnte alles tun, was ich wollte",
  "patient_pedia_knee_function_q27":"Wie gut funktioniert Dein Knie JETZT?",
  "patient_pedia_knee_function_q27_instruction":"10 ist normal und 0 ist die Unfähigkeit, eine Ihrer üblichen täglichen Aktivitäten auszuführen, die Sport beinhalten kann",
  "patient_pedia_knee_function_q27_instruction_a1":"Ich kann überhaupt nichts tun",
  "patient_pedia_knee_function_q27_instruction_a2":"Ich kann alles tun, was ich tun möchte",
  "patient_pedia_knee_function_q28":"Verrutscht Deine Kniescheibe oder fühlt es sich an, als würde sie verrutschen?",
  "patient_pedia_knee_function_q28_a1":"nein",
  "patient_pedia_knee_function_q28_a2":"ja",
  "patient_pedia_knee_instability":"Patellofemorale Instabilität",
  "patient_pedia_knee_instability_instruction":"Was beschreibt am besten, wie oft sich Deine Kniescheibe anfühlt, als würde sie aus dem Gelenk „herausspringen“ oder sich instabil anfühlt, wenn Du die folgenden Aktivitäten ausführst?",
  "patient_pedia_knee_instability_instruction_0":"niemals",
  "patient_pedia_knee_instability_instruction_1":"selten",
  "patient_pedia_knee_instability_instruction_2":"manchmal",
  "patient_pedia_knee_instability_instruction_3":"oft",
  "patient_pedia_knee_instability_instruction_4":"immer",
  "patient_pedia_knee_instability_note":"Hinweis: Bitte beantworte die folgenden Fragen zu Deinen Knieaktivitäten",
  "patient_pedia_knee_instability_q1":"Drehen/Wechseln der Richtung beim Sport/Spiel",
  "patient_pedia_knee_instability_q1_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q1_a1":"niemals",
  "patient_pedia_knee_instability_q1_a2":"selten",
  "patient_pedia_knee_instability_q1_a3":"manchmal",
  "patient_pedia_knee_instability_q1_a4":"oft",
  "patient_pedia_knee_instability_q1_a5":"immer",
  "patient_pedia_knee_instability_q2":"Richtungswechsel beim Laufen",
  "patient_pedia_knee_instability_q2_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q2_a1":"niemals",
  "patient_pedia_knee_instability_q2_a2":"selten",
  "patient_pedia_knee_instability_q2_a3":"manchmal",
  "patient_pedia_knee_instability_q2_a4":"oft",
  "patient_pedia_knee_instability_q2_a5":"immer",
  "patient_pedia_knee_instability_q3":"Geradeauslaufen auf unebenem Untergrund",
  "patient_pedia_knee_instability_q3_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q3_a1":"niemals",
  "patient_pedia_knee_instability_q3_a2":"selten",
  "patient_pedia_knee_instability_q3_a3":"manchmal",
  "patient_pedia_knee_instability_q3_a4":"oft",
  "patient_pedia_knee_instability_q3_a5":"immer",
  "patient_pedia_knee_instability_q4":"Gehen auf rutschigen, nassen oder vereisten Oberflächen",
  "patient_pedia_knee_instability_q4_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q4_a1":"niemals",
  "patient_pedia_knee_instability_q4_a2":"selten",
  "patient_pedia_knee_instability_q4_a3":"manchmal",
  "patient_pedia_knee_instability_q4_a4":"oft",
  "patient_pedia_knee_instability_q4_a5":"immer",
  "patient_pedia_knee_instability_q5":"Seitlich laufen",
  "patient_pedia_knee_instability_q5_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q5_a1":"niemals",
  "patient_pedia_knee_instability_q5_a2":"selten",
  "patient_pedia_knee_instability_q5_a3":"manchmal",
  "patient_pedia_knee_instability_q5_a4":"oft",
  "patient_pedia_knee_instability_q5_a5":"immer",
  "patient_pedia_knee_instability_q6":"Auf einem Bein hüpfen",
  "patient_pedia_knee_instability_q6_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q6_a1":"niemals",
  "patient_pedia_knee_instability_q6_a2":"selten",
  "patient_pedia_knee_instability_q6_a3":"manchmal",
  "patient_pedia_knee_instability_q6_a4":"oft",
  "patient_pedia_knee_instability_q6_a5":"immer",
  "patient_pedia_knee_instability_q7":"Springen",
  "patient_pedia_knee_instability_q7_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q7_a1":"niemals",
  "patient_pedia_knee_instability_q7_a2":"selten",
  "patient_pedia_knee_instability_q7_a3":"manchmal",
  "patient_pedia_knee_instability_q7_a4":"oft",
  "patient_pedia_knee_instability_q7_a5":"immer",
  "patient_pedia_knee_instability_q8":"Geradeauslaufen auf ebenem Untergrund",
  "patient_pedia_knee_instability_q8_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q8_a1":"niemals",
  "patient_pedia_knee_instability_q8_a2":"selten",
  "patient_pedia_knee_instability_q8_a3":"manchmal",
  "patient_pedia_knee_instability_q8_a4":"oft",
  "patient_pedia_knee_instability_q8_a5":"immer",
  "patient_pedia_knee_instability_q9":"Treppen herunter gehen",
  "patient_pedia_knee_instability_q9_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q9_a1":"niemals",
  "patient_pedia_knee_instability_q9_a2":"selten",
  "patient_pedia_knee_instability_q9_a3":"manchmal",
  "patient_pedia_knee_instability_q9_a4":"oft",
  "patient_pedia_knee_instability_q9_a5":"immer",
  "patient_pedia_knee_instability_q10":"Hocken",
  "patient_pedia_knee_instability_q10_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q10_a1":"niemals",
  "patient_pedia_knee_instability_q10_a2":"selten",
  "patient_pedia_knee_instability_q10_a3":"manchmal",
  "patient_pedia_knee_instability_q10_a4":"oft",
  "patient_pedia_knee_instability_q10_a5":"immer",
  "patient_pedia_knee_instability_q11":"Knien",
  "patient_pedia_knee_instability_q11_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q11_a1":"niemals",
  "patient_pedia_knee_instability_q11_a2":"selten",
  "patient_pedia_knee_instability_q11_a3":"manchmal",
  "patient_pedia_knee_instability_q11_a4":"oft",
  "patient_pedia_knee_instability_q11_a5":"immer",
  "patient_pedia_knee_instability_q12":"Auf unebenen Flächen geradeaus gehen",
  "patient_pedia_knee_instability_q12_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q12_a1":"niemals",
  "patient_pedia_knee_instability_q12_a2":"selten",
  "patient_pedia_knee_instability_q12_a3":"manchmal",
  "patient_pedia_knee_instability_q12_a4":"oft",
  "patient_pedia_knee_instability_q12_a5":"immer",
  "patient_pedia_knee_instability_q13":"Treppen heraufgehen",
  "patient_pedia_knee_instability_q13_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q13_a1":"niemals",
  "patient_pedia_knee_instability_q13_a2":"selten",
  "patient_pedia_knee_instability_q13_a3":"manchmal",
  "patient_pedia_knee_instability_q13_a4":"oft",
  "patient_pedia_knee_instability_q13_a5":"immer",
  "patient_pedia_knee_instability_q14":"Auf oder über eine hohe Stufe treten",
  "patient_pedia_knee_instability_q14_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q14_a1":"niemals",
  "patient_pedia_knee_instability_q14_a2":"selten",
  "patient_pedia_knee_instability_q14_a3":"manchmal",
  "patient_pedia_knee_instability_q14_a4":"oft",
  "patient_pedia_knee_instability_q14_a5":"immer",
  "patient_pedia_knee_instability_q15":"Beim Sitzen die Beine kreuzen",
  "patient_pedia_knee_instability_q15_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q15_a1":"niemals",
  "patient_pedia_knee_instability_q15_a2":"selten",
  "patient_pedia_knee_instability_q15_a3":"manchmal",
  "patient_pedia_knee_instability_q15_a4":"oft",
  "patient_pedia_knee_instability_q15_a5":"immer",
  "patient_pedia_knee_instability_q16":"Auf unebenen Flächen geradeaus gehen",
  "patient_pedia_knee_instability_q16_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q16_a1":"niemals",
  "patient_pedia_knee_instability_q16_a2":"selten",
  "patient_pedia_knee_instability_q16_a3":"manchmal",
  "patient_pedia_knee_instability_q16_a4":"oft",
  "patient_pedia_knee_instability_q16_a5":"immer",
  "patient_pedia_knee_instability_q17":"In ein Auto Ein- und Aussteigen",
  "patient_pedia_knee_instability_q17_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q17_a1":"niemals",
  "patient_pedia_knee_instability_q17_a2":"selten",
  "patient_pedia_knee_instability_q17_a3":"manchmal",
  "patient_pedia_knee_instability_q17_a4":"oft",
  "patient_pedia_knee_instability_q17_a5":"immer",
  "patient_pedia_knee_instability_q18":"Einen schweren Einkaufswagens in einem Gang im Supermarkt drehen",
  "patient_pedia_knee_instability_q18_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q18_a1":"niemals",
  "patient_pedia_knee_instability_q18_a2":"selten",
  "patient_pedia_knee_instability_q18_a3":"manchmal",
  "patient_pedia_knee_instability_q18_a4":"oft",
  "patient_pedia_knee_instability_q18_a5":"immer",
  "patient_pedia_knee_instability_q19":"Sich umdrehen, um über die Schulter zu schauen",
  "patient_pedia_knee_instability_q19_do_not_do":"tue es nicht",
  "patient_pedia_knee_instability_q19_a1":"niemals",
  "patient_pedia_knee_instability_q19_a2":"selten",
  "patient_pedia_knee_instability_q19_a3":"manchmal",
  "patient_pedia_knee_instability_q19_a4":"oft",
  "patient_pedia_knee_instability_q19_a5":"immer",
  "patient_pedia_knee_quality_of_life":"Lebensqualität",
  "patient_pedia_knee_quality_of_life_instruction":"Wie hat sich Deine Verletzung auf Dein Leben ausgewirkt?",
  "patient_pedia_knee_quality_of_life_q1":"Wie oft denkst Du über Dein Knieproblem nach?",
  "patient_pedia_knee_quality_of_life_q1_a1":"niemals",
  "patient_pedia_knee_quality_of_life_q1_a2":"selten",
  "patient_pedia_knee_quality_of_life_q1_a3":"manchmal",
  "patient_pedia_knee_quality_of_life_q1_a4":"oft",
  "patient_pedia_knee_quality_of_life_q1_a5":"die ganze Zeit",
  "patient_pedia_knee_problem_q2":"Wieviel hast Du Dein Leben wegen Deines verletzten Knies geändert?",
  "patient_pedia_knee_problem_q2_a1":"gar nicht",
  "patient_pedia_knee_problem_q2_a2":"ein wenig",
  "patient_pedia_knee_problem_q2_a3":"etwas",
  "patient_pedia_knee_problem_q2_a4":"eine Menge",
  "patient_pedia_knee_problem_q2_a5":"sehr viel",
  "patient_pedia_knee_problem_q3":"Wie sehr vertraust Du Deinem verletzten Knie?",
  "patient_pedia_knee_problem_q3_a1":"vollständig",
  "patient_pedia_knee_problem_q3_a2":"eine Menge",
  "patient_pedia_knee_problem_q3_a3":"etwas",
  "patient_pedia_knee_problem_q3_a4":"wenig",
  "patient_pedia_knee_problem_q3_a5":"gar nicht",
  "patient_pedia_knee_problem_q4":"Wie viele Schwierigkeiten hast Du insgesamt mit Deinem verletzten Knie?",
  "patient_pedia_knee_problem_q4_a1":"keine Schwierigkeit",
  "patient_pedia_knee_problem_q4_a2":"wenige",
  "patient_pedia_knee_problem_q4_a3":"etwas",
  "patient_pedia_knee_problem_q4_a4":"eine Menge",
  "patient_pedia_knee_problem_q4_a5":"extreme Schwierigkeiten",
  "patient_pedia_knee_satisfaction":"Zufriedenheit",
  "patient_pedia_knee_satisfaction_instruction":"Wie zufrieden bist Du mit den Ergebnissen Deiner Knieoperation/-behandlung?",
  "patient_pedia_knee_satisfaction_instruction_a1":"sehr unzufrieden",
  "patient_pedia_knee_satisfaction_instruction_a2":"etwas unzufrieden",
  "patient_pedia_knee_satisfaction_instruction_a3":"etwas zufrieden",
  "patient_pedia_knee_satisfaction_instruction_a4":"sehr zufrieden",
  "patient_pedia_knee_satisfaction_q1":"insgesamt",
  "patient_pedia_knee_satisfaction_q1_NA":"unzutreffend",
  "patient_pedia_knee_satisfaction_q1_a1":"sehr unzufrieden",
  "patient_pedia_knee_satisfaction_q1_a2":"etwas unzufrieden",
  "patient_pedia_knee_satisfaction_q1_a3":"etwas zufrieden",
  "patient_pedia_knee_satisfaction_q1_a4":"sehr zufrieden",
  "patient_pedia_knee_satisfaction_q2":"Zur Linderung Deiner Schmerzen?",
  "patient_pedia_knee_satisfaction_q2_NA":"unzutreffend",
  "patient_pedia_knee_satisfaction_q2_a1":"sehr unzufrieden",
  "patient_pedia_knee_satisfaction_q2_a2":"etwas unzufrieden",
  "patient_pedia_knee_satisfaction_q2_a3":"etwas zufrieden",
  "patient_pedia_knee_satisfaction_q2_a4":"sehr zufrieden",
  "patient_pedia_knee_satisfaction_q3":"Um Deine Fähigkeit zu verbessern, Haus- oder Gartenarbeit zu erledigen?",
  "patient_pedia_knee_satisfaction_q3_NA":"unzutreffend",
  "patient_pedia_knee_satisfaction_q3_a1":"sehr unzufrieden",
  "patient_pedia_knee_satisfaction_q3_a2":"etwas unzufrieden",
  "patient_pedia_knee_satisfaction_q3_a3":"etwas zufrieden",
  "patient_pedia_knee_satisfaction_q3_a4":"sehr zufrieden",
  "patient_pedia_knee_satisfaction_q4":"Um Deine Fähigkeit zur Ausübung von Freizeitaktivitäten zu verbessern?",
  "patient_pedia_knee_satisfaction_q4_NA":"unzutreffend",
  "patient_pedia_knee_satisfaction_q4_a1":"sehr unzufrieden",
  "patient_pedia_knee_satisfaction_q4_a2":"etwas unzufrieden",
  "patient_pedia_knee_satisfaction_q4_a3":"etwas zufrieden",
  "patient_pedia_knee_satisfaction_q4_a4":"sehr zufrieden",
  "patient_pedia_knee_complete":"Ende das Fragebogens",
  "patient_pedia_knee_complete_text1":"Vielen Dank, dass Du Dir die Zeit genommen hast, dieses Formular auszufüllen.",
  "patient_pedia_knee_complete_text2":"Bitte klickeauf „Senden“, um den Vorgang abzuschließen und Deine Ergebnisse anzuzeigen.",
  "patient_pedia_knee_complete_text3":"Deine Antworten werden vertraulich behandelt und in allen Berichten werden nur anonymisierte Informationen verwendet.",
  "patient_pedia_knee_results":"Ergebnisse",
  "patient_pedia_knee_results_text1":"Nachfolgend findest Du Deine Schmerz- und Kniefunktionswerte, die anhand Dines Fragebogens berechnet wurden.",
  "patient_pedia_knee_results_pain":"Schmerz",
  "patient_pedia_knee_results_function":"Funktion",
  "patient_pedia_knee_results_self_evaluation":"Knieselbstbewertung",
  "patient_pedia_knee_results_text2":"Dein Arzt wird in Kürze bei Dir sein.",
  "patient_foot_ankle_pain":"Schmerz",
  "patient_foot_ankle_pain_q1":"Wie stark sind die AKTUELLEN SCHMERZEN in Ihrem Fuß/Knöchel?",
  "patient_foot_ankle_pain_q1_0":"keine",
  "patient_foot_ankle_pain_q1_1":"Der schlimmste Schmerz, den man sich vorstellen kann",
  "patient_foot_ankle_pain_q2":"Was ist die beste Beschreibung des SCHMERZ in Ihrem Fuß/Knöchel?",
  "patient_foot_ankle_pain_q2_a1":"kein",
  "patient_foot_ankle_pain_q2_a2":"mild, gelegentlich",
  "patient_foot_ankle_pain_q2_a3":"mäßig, täglich",
  "patient_foot_ankle_pain_q2_a4":"schwerwiegend, fast immer vorhanden",
  "patient_foot_ankle_pain_q3":"Wie HÄUFIG haben Sie Fuß-/Knöchelschmerzen?",
  "patient_foot_ankle_pain_q3_a1":"niemals",
  "patient_foot_ankle_pain_q3_a2":"monatlich",
  "patient_foot_ankle_pain_q3_a3":"wöchentlich",
  "patient_foot_ankle_pain_q3_a4":"täglich",
  "patient_foot_ankle_pain_q3_a5":"immer",
  "patient_foot_ankle_pain_instruction":"Wie viel FUSS-/KNÖCHELSCHMERZEN hatten Sie bei den folgenden Aktivitäten?",
  "patient_foot_ankle_pain_instruction_0":"keine",
  "patient_foot_ankle_pain_instruction_1":"leichte",
  "patient_foot_ankle_pain_instruction_2":"mäßige",
  "patient_foot_ankle_pain_instruction_3":"schwere",
  "patient_foot_ankle_pain_instruction_4":"extreme",
  "patient_foot_ankle_pain_q4":"Allgemeines Schmerzniveau",
  "patient_foot_ankle_pain_q4_a1":"keine",
  "patient_foot_ankle_pain_q4_a2":"leichte",
  "patient_foot_ankle_pain_q4_a3":"mäßige",
  "patient_foot_ankle_pain_q4_a4":"schwere",
  "patient_foot_ankle_pain_q4_a5":"extreme",
  "patient_foot_ankle_pain_q5":"Im Ruhezustand",
  "patient_foot_ankle_pain_q5_a1":"keine",
  "patient_foot_ankle_pain_q5_a2":"leichte",
  "patient_foot_ankle_pain_q5_a3":"mäßige",
  "patient_foot_ankle_pain_q5_a4":"schwere",
  "patient_foot_ankle_pain_q5_a5":"extreme",
  "patient_foot_ankle_pain_q6":"Während normaler Aktivität",
  "patient_foot_ankle_pain_q6_a1":"keine",
  "patient_foot_ankle_pain_q6_a2":"leichte",
  "patient_foot_ankle_pain_q6_a3":"mäßige",
  "patient_foot_ankle_pain_q6_a4":"schwere",
  "patient_foot_ankle_pain_q6_a5":"extreme",
  "patient_foot_ankle_pain_q7":"Nach dem Aufwachen am Morgen",
  "patient_foot_ankle_pain_q7_a1":"keine",
  "patient_foot_ankle_pain_q7_a2":"leichte",
  "patient_foot_ankle_pain_q7_a3":"mäßige",
  "patient_foot_ankle_pain_q7_a4":"schwere",
  "patient_foot_ankle_pain_q7_a5":"extreme",
  "patient_foot_ankle_pain_q8":"Ihrem Fuß/Knöchel Drehen",
  "patient_foot_ankle_pain_q8_a1":"keine",
  "patient_foot_ankle_pain_q8_a2":"leichte",
  "patient_foot_ankle_pain_q8_a3":"mäßige",
  "patient_foot_ankle_pain_q8_a4":"schwere",
  "patient_foot_ankle_pain_q8_a5":"extreme",
  "patient_foot_ankle_pain_q9":"Fuß/Knöchel vollständig strecken",
  "patient_foot_ankle_pain_q9_a1":"keine",
  "patient_foot_ankle_pain_q9_a2":"leichte",
  "patient_foot_ankle_pain_q9_a3":"mäßige",
  "patient_foot_ankle_pain_q9_a4":"schwere",
  "patient_foot_ankle_pain_q9_a5":"extreme",
  "patient_foot_ankle_pain_q10":"Fuß/Knöchel vollständig beugen",
  "patient_foot_ankle_pain_q10_a1":"keine",
  "patient_foot_ankle_pain_q10_a2":"leichte",
  "patient_foot_ankle_pain_q10_a3":"mäßige",
  "patient_foot_ankle_pain_q10_a4":"schwere",
  "patient_foot_ankle_pain_q10_a5":"extreme",
  "patient_foot_ankle_pain_q11":"Gehen auf ebener Fläche",
  "patient_foot_ankle_pain_q11_a1":"keine",
  "patient_foot_ankle_pain_q11_a2":"leichte",
  "patient_foot_ankle_pain_q11_a3":"mäßige",
  "patient_foot_ankle_pain_q11_a4":"schwere",
  "patient_foot_ankle_pain_q11_a5":"extreme",
  "patient_foot_ankle_pain_q12":"Treppen herauf oder herunter gehen",
  "patient_foot_ankle_pain_q12_a1":"keine",
  "patient_foot_ankle_pain_q12_a2":"leichte",
  "patient_foot_ankle_pain_q12_a3":"mäßige",
  "patient_foot_ankle_pain_q12_a4":"schwere",
  "patient_foot_ankle_pain_q12_a5":"extreme",
  "patient_foot_ankle_pain_q13":"Nachts im Bett",
  "patient_foot_ankle_pain_q13_a1":"keine",
  "patient_foot_ankle_pain_q13_a2":"leichte",
  "patient_foot_ankle_pain_q13_a3":"mäßige",
  "patient_foot_ankle_pain_q13_a4":"schwere",
  "patient_foot_ankle_pain_q13_a5":"extreme",
  "patient_foot_ankle_pain_q14":"Sitzend oder liegend",
  "patient_foot_ankle_pain_q14_a1":"keine",
  "patient_foot_ankle_pain_q14_a2":"leichte",
  "patient_foot_ankle_pain_q14_a3":"mäßige",
  "patient_foot_ankle_pain_q14_a4":"schwere",
  "patient_foot_ankle_pain_q14_a5":"extreme",
  "patient_foot_ankle_pain_q15":"Aufrecht stehend",
  "patient_foot_ankle_pain_q15_a1":"keine",
  "patient_foot_ankle_pain_q15_a2":"leichte",
  "patient_foot_ankle_pain_q15_a3":"mäßige",
  "patient_foot_ankle_pain_q15_a4":"schwere",
  "patient_foot_ankle_pain_q15_a5":"extreme",
  "patient_foot_ankle_instability":"Instabilität",
  "patient_foot_ankle_instability_q1":"Leiden Sie unter wiederholten Verstauchungen, einem Nachgeben Ihres Knöchels oder haben Sie das Gefühl, dass er gleich nachgeben wird?",
  "patient_foot_ankle_instability_q1_a1":"nein",
  "patient_foot_ankle_instability_q1_a2":"ja",
  "patient_foot_ankle_instability_q2":"Wurde bei Ihnen jemals eine Knöchelinstabilität/wiederkehrende Verstauchungen diagnostiziert/behandelt?",
  "patient_foot_ankle_instability_q2_a1":"nein",
  "patient_foot_ankle_instability_q2_a2":"ja",
  "patient_foot_ankle_instability_q3":"Fühlt sich Ihr gesunder Knöchel völlig stabil an?",
  "patient_foot_ankle_instability_q3_a1":"nein",
  "patient_foot_ankle_instability_q3_a2":"ja",
  "patient_foot_ankle_cumberland":"CUMBERLAND KNÖCHELINSTABILITÄTSTOOL",
  "patient_foot_ankle_cumberland_instruction":"Bitte wählen Sie in jeder Frage eine Aussage aus, die Ihren Knöchel am besten beschreibt.",
  "patient_foot_ankle_cumberland_q1":"Ich habe Schmerzen im Knöchel",
  "patient_foot_ankle_cumberland_q1_a1":"niemals",
  "patient_foot_ankle_cumberland_q1_a2":"beim Sport",
  "patient_foot_ankle_cumberland_q1_a3":"Laufen auf unebenem Untergrund",
  "patient_foot_ankle_cumberland_q1_a4":"Laufen auf ebenen Flächen",
  "patient_foot_ankle_cumberland_q1_a5":"Gehen auf unebenem Untergrund",
  "patient_foot_ankle_cumberland_q1_a6":"Gehen auf ebenen Flächen",
  "patient_foot_ankle_cumberland_q2":"Mein Knöchel fühlt sich INSTABIL an",
  "patient_foot_ankle_cumberland_q2_a1":"niemals",
  "patient_foot_ankle_cumberland_q2_a2":"manchmal beim Sport (nicht jedes Mal)",
  "patient_foot_ankle_cumberland_q2_a3":"häufig beim Sport (jedes Mal)",
  "patient_foot_ankle_cumberland_q2_a4":"manchmal während der täglichen Aktivität",
  "patient_foot_ankle_cumberland_q2_a5":"häufig während der täglichen Aktivität",
  "patient_foot_ankle_cumberland_q3":"Wenn ich SCHARFE Wendungen mache, fühlt sich mein Knöchel INSTABIL an",
  "patient_foot_ankle_cumberland_q3_a1":"niemals",
  "patient_foot_ankle_cumberland_q3_a2":"manchmal beim Laufen",
  "patient_foot_ankle_cumberland_q3_a3":"oft beim Laufen",
  "patient_foot_ankle_cumberland_q3_a4":"beim Gehen",
  "patient_foot_ankle_cumberland_q4":"Wenn ich die Treppe heruntergehe, fühlt sich mein Knöchel instabil an",
  "patient_foot_ankle_cumberland_q4_a1":"niemals",
  "patient_foot_ankle_cumberland_q4_a2":"wenn ich schnell gehe",
  "patient_foot_ankle_cumberland_q4_a3":"ab und zu",
  "patient_foot_ankle_cumberland_q4_a4":"immer",
  "patient_foot_ankle_cumberland_q5":"Mein Knöchel fühlt sich instabil an, wenn ich auf EINEM Bein stehe",
  "patient_foot_ankle_cumberland_q5_a1":"niemals",
  "patient_foot_ankle_cumberland_q5_a2":"auf dem Fußballen",
  "patient_foot_ankle_cumberland_q5_a3":"auf meinem flachen Fuß",
  "patient_foot_ankle_cumberland_q6":"Mein Knöchel fühlt sich instabil an, wenn",
  "patient_foot_ankle_cumberland_q6_a1":"niemals",
  "patient_foot_ankle_cumberland_q6_a2":"ich von einer Seite zur anderen hüpfe",
  "patient_foot_ankle_cumberland_q6_a3":"ich auf der Stelle hüpfe",
  "patient_foot_ankle_cumberland_q6_a4":"ich springe",
  "patient_foot_ankle_cumberland_q7":"Mein Knöchel fühlt sich instabil an, wenn",
  "patient_foot_ankle_cumberland_q7_a1":"niemals",
  "patient_foot_ankle_cumberland_q7_a2":"ich auf unebenem Untergrund laufe",
  "patient_foot_ankle_cumberland_q7_a3":"ich auf unebenem Untergrund jogge",
  "patient_foot_ankle_cumberland_q7_a4":"ich auf unebenen Flächen gehe",
  "patient_foot_ankle_cumberland_q7_a5":"ich auf ebenen Flächen gehe",
  "patient_foot_ankle_cumberland_q8":"Wenn ich anfange, mich umzudrehen (oder „zu verdrehen“), kann ich es normalerweise stoppen",
  "patient_foot_ankle_cumberland_q8_a1":"sofort",
  "patient_foot_ankle_cumberland_q8_a2":"oft",
  "patient_foot_ankle_cumberland_q8_a3":"manchmal",
  "patient_foot_ankle_cumberland_q8_a4":"niemals",
  "patient_foot_ankle_cumberland_q8_a5":"Ich habe noch nie meinen Knöchel verstaucht",
  "patient_foot_ankle_cumberland_q9":"Nach einem TYPISCHEN Vorfall, bei dem ich meinen Knöchel verknackse, wird mein Knöchel wieder „normal“",
  "patient_foot_ankle_cumberland_q9_a1":"fast sofort",
  "patient_foot_ankle_cumberland_q9_a2":"weniger als ein Tag",
  "patient_foot_ankle_cumberland_q9_a3":"1-2 Tage",
  "patient_foot_ankle_cumberland_q9_a4":"mehr als 2 Tage",
  "patient_foot_ankle_cumberland_q9_a5":"ich habe noch nie meinen Knöchel verstaucht",
  "patient_foot_ankle_other_symptoms":"Weitere Symptome",
  "patient_foot_ankle_other_symptoms_instruction1":"Bitte wählen Sie die Antwort aus, die am besten zu Ihren Fuß-/Knöchelsymptomen passt.",
  "patient_foot_ankle_other_symptoms_instruction2":"Wenn Sie sich nicht sicher sind, wie Sie eine Frage beantworten sollen, geben Sie bitte die bestmögliche Antwort.",
  "patient_foot_ankle_other_symptoms_note":"Hinweis: Beantworten Sie diese Fragen unter Berücksichtigung Ihrer Fuß-/Knöchelsymptome in der letzten Woche.",
  "patient_foot_ankle_other_symptoms_instruction3":"Hatten Sie folgendes:",
  "patient_foot_ankle_other_symptoms_instruction3_0":"niemals",
  "patient_foot_ankle_other_symptoms_instruction3_1":"selten",
  "patient_foot_ankle_other_symptoms_instruction3_2":"manchmal",
  "patient_foot_ankle_other_symptoms_instruction3_3":"oft",
  "patient_foot_ankle_other_symptoms_instruction3_4":"immer",
  "patient_foot_ankle_other_symptoms_q1":"Schwellung in Ihrem Fuß/Knöchel",
  "patient_foot_ankle_other_symptoms_q1_a1":"niemals",
  "patient_foot_ankle_other_symptoms_q1_a2":"selten",
  "patient_foot_ankle_other_symptoms_q1_a3":"manchmal",
  "patient_foot_ankle_other_symptoms_q1_a4":"oft",
  "patient_foot_ankle_other_symptoms_q1_a5":"immer",
  "patient_foot_ankle_other_symptoms_q2":"Knirschen, Klicken oder andere Geräusche hören, wenn sich Ihr Fuß/Knöchel bewegt",
  "patient_foot_ankle_other_symptoms_q2_a1":"niemals",
  "patient_foot_ankle_other_symptoms_q2_a2":"selten",
  "patient_foot_ankle_other_symptoms_q2_a3":"manchmal",
  "patient_foot_ankle_other_symptoms_q2_a4":"oft",
  "patient_foot_ankle_other_symptoms_q2_a5":"immer",
  "patient_foot_ankle_other_symptoms_q3":"Bleibt stecken beim Bewegen",
  "patient_foot_ankle_other_symptoms_q3_a1":"niemals",
  "patient_foot_ankle_other_symptoms_q3_a2":"selten",
  "patient_foot_ankle_other_symptoms_q3_a3":"manchmal",
  "patient_foot_ankle_other_symptoms_q3_a4":"oft",
  "patient_foot_ankle_other_symptoms_q3_a5":"immer",
  "patient_foot_ankle_other_symptoms_instruction4":"Wie stark ist die Steifheit Ihres Fußes/Knöchels:",
  "patient_foot_ankle_other_symptoms_instruction4_0":"keine",
  "patient_foot_ankle_other_symptoms_instruction4_1":"leicht",
  "patient_foot_ankle_other_symptoms_instruction4_2":"mäßig",
  "patient_foot_ankle_other_symptoms_instruction4_3":"schwer",
  "patient_foot_ankle_other_symptoms_instruction4_4":"extrem",
  "patient_foot_ankle_other_symptoms_q4":"Nach dem ersten Aufwachen am Morgen",
  "patient_foot_ankle_other_symptoms_q4_a1":"keine",
  "patient_foot_ankle_other_symptoms_q4_a2":"leicht",
  "patient_foot_ankle_other_symptoms_q4_a3":"mäßig",
  "patient_foot_ankle_other_symptoms_q4_a4":"schwer",
  "patient_foot_ankle_other_symptoms_q4_a5":"extrem",
  "patient_foot_ankle_other_symptoms_q5":"Später am Tag, nach Sitzen, Liegen oder Ausruhen",
  "patient_foot_ankle_other_symptoms_q5_a1":"keine",
  "patient_foot_ankle_other_symptoms_q5_a2":"leicht",
  "patient_foot_ankle_other_symptoms_q5_a3":"mäßig",
  "patient_foot_ankle_other_symptoms_q5_a4":"schwer",
  "patient_foot_ankle_other_symptoms_q5_a5":"extrem",
  "patient_foot_ankle_function":"Funktion",
  "patient_foot_ankle_function_q1":"Welche Einschränkungen haben Sie aufgrund Ihres Fußes/Knöchels in Ihrem Alltag?",
  "patient_foot_ankle_function_q1_a1":"keine Einschränkungen, kein Unterstützung",
  "patient_foot_ankle_function_q1_a2":"keine Einschränkung der täglichen Aktivitäten, Einschränkung der Freizeitaktivitäten, keine Unterstützung",
  "patient_foot_ankle_function_q1_a3":"begrenzte Tages- und Freizeitaktivitäten, Stock",
  "patient_foot_ankle_function_q1_a4":"starke Einschränkung der Alltags- und Freizeitaktivitäten, Gehhilfe, Krücken, Rollstuhl, Stütze",
  "patient_foot_ankle_function_q2":"Welches Schuhwerk tragen Sie normalerweise?",
  "patient_foot_ankle_function_q2_a1":"modische, konventionelle Schuhe, keine Einlage erforderlich",
  "patient_foot_ankle_function_q2_a2":"bequemes Schuhwerk, Schuheinlage",
  "patient_foot_ankle_function_q2_a3":"modifizierte Schuhe oder Stützstrumpf",
  "patient_foot_ankle_function_q3":"Welche Art von Untergrund bereitet Ihnen Schwierigkeiten beim Gehen?",
  "patient_foot_ankle_function_q3_a1":"keine Schwierigkeiten auf jedem Untergrund",
  "patient_foot_ankle_function_q3_a2":"einige Schwierigkeiten auf unebenem Gelände, Treppen, Steigungen, Leitern",
  "patient_foot_ankle_function_q3_a3":"große Schwierigkeiten auf unebenem Gelände, Treppen, Steigungen, Leitern",
  "patient_foot_ankle_function_instruction":"Geben Sie den SCHWIERIGKEITSGRAD an, den Sie AUFGRUND IHRES FUSSES/KNÖCHELS erlebt haben",
  "patient_foot_ankle_function_instruction_0":"keine Schwierigkeit",
  "patient_foot_ankle_function_instruction_1":"leichte Schwierigkeit",
  "patient_foot_ankle_function_instruction_2":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_instruction_3":"extreme Schwierigkeit",
  "patient_foot_ankle_function_instruction_4":"unfähig zu tun",
  "patient_foot_ankle_function_note":"Hinweis: Wenn die betreffende Aktivität durch etwas anderes als Ihren Fuß oder Knöchel eingeschränkt wird, kreuzen Sie bei den Punkten, bei denen dies eine Option ist, „unzutreffend“ an. Ansonsten beantworten Sie bitte jede Frage mit einer Antwort, die Ihren Zustand in der letzten Woche am besten beschreibt.",
  "patient_foot_ankle_function_q4":"Ihren Fuß/Knöchel vollständig strecken",
  "patient_foot_ankle_function_q4_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q4_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q4_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q4_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q4_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q5":"Ihren Fuß / Knöchel vollständig beugen",
  "patient_foot_ankle_function_q5_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q5_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q5_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q5_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q5_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q6":"Treppe herunter gehen",
  "patient_foot_ankle_function_q6_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q6_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q6_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q6_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q6_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q7":"Treppe herauf gehen",
  "patient_foot_ankle_function_q7_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q7_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q7_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q7_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q7_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q8":"Aufstehen aus dem Sitzen",
  "patient_foot_ankle_function_q8_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q8_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q8_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q8_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q8_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q9":"Stehen",
  "patient_foot_ankle_function_q9_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q9_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q9_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q9_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q9_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q10":"Sich zum Boden beugen/einen Gegenstand aufheben",
  "patient_foot_ankle_function_q10_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q10_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q10_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q10_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q10_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q11":"Gehen auf ebener Fläche",
  "patient_foot_ankle_function_q11_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q11_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q11_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q11_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q11_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q12":"Was ist die maximale Entfernung, die Sie gehen können?",
  "patient_foot_ankle_function_q12_a1":"mehr als 6 Blöcke",
  "patient_foot_ankle_function_q12_a2":"4-6 Blöcke",
  "patient_foot_ankle_function_q12_a3":"1-3 Blöcke",
  "patient_foot_ankle_function_q12_a4":"weniger als 1 Block",
  "patient_foot_ankle_function_q13":"Gehen auf ebenem Boden ohne Schuhe",
  "patient_foot_ankle_function_q13_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q13_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q13_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q13_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q13_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q14":"Hügel heraufgehen",
  "patient_foot_ankle_function_q14_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q14_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q14_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q14_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q14_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q15":"Hügel heruntergehen",
  "patient_foot_ankle_function_q15_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q15_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q15_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q15_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q15_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q16":"Gehen auf unebenem Boden",
  "patient_foot_ankle_function_q16_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q16_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q16_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q16_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q16_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q17":"Bordstein herauf- und heruntergehen",
  "patient_foot_ankle_function_q17_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q17_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q17_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q17_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q17_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q18":"Bis zu den Zehen reichen",
  "patient_foot_ankle_function_q18_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q18_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q18_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q18_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q18_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q19":"anfangs gehen",
  "patient_foot_ankle_function_q19_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q19_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q19_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q19_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q19_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q20":"5 Minuten oder weniger gehen",
  "patient_foot_ankle_function_q20_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q20_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q20_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q20_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q20_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q21":"Zu Fuß etwa 10 Minuten gehen",
  "patient_foot_ankle_function_q21_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q21_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q21_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q21_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q21_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q22":"15 Minuten oder länger gehen",
  "patient_foot_ankle_function_q22_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q22_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q22_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q22_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q22_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q23":"Ein-/Aussteigen aus dem Auto",
  "patient_foot_ankle_function_q23_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q23_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q23_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q23_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q23_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q24":"Einkaufen gehen",
  "patient_foot_ankle_function_q24_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q24_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q24_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q24_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q24_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q25":"Socken, Strumpfhosen anziehen",
  "patient_foot_ankle_function_q25_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q25_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q25_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q25_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q25_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q26":"Socken, Strumpfhosen ausziehen",
  "patient_foot_ankle_function_q26_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q26_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q26_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q26_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q26_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q27":"Aufstehen aus dem Bett",
  "patient_foot_ankle_function_q27_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q27_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q27_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q27_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q27_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q28":"Im Bett liegen (umdrehen und Fuß-/Knöchelstellung beibehalten)",
  "patient_foot_ankle_function_q28_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q28_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q28_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q28_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q28_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q29":"Schlafen",
  "patient_foot_ankle_function_q29_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q29_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q29_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q29_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q29_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q30":"Ein- und Aussteigen aus der Badewanne",
  "patient_foot_ankle_function_q30_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q30_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q30_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q30_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q30_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q31":"Sitzen",
  "patient_foot_ankle_function_q31_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q31_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q31_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q31_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q31_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q32":"Auf die Toilette setzen und aufstehen",
  "patient_foot_ankle_function_q32_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q32_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q32_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q32_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q32_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q33":"Aufgaben im Haushalt",
  "patient_foot_ankle_function_q33_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q33_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q33_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q33_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q33_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q34":"Aktivitäten des täglichen Lebens",
  "patient_foot_ankle_function_q34_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q34_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q34_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q34_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q34_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q35":"Körperpflege (einschließlich sich selbst Waschen oder Anziehen)",
  "patient_foot_ankle_function_q35_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q35_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q35_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q35_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q35_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q36":"Schwere Arbeiten (Schieben/Ziehen, Klettern, Tragen schwerer Kisten, Fußböden schrubben usw.)",
  "patient_foot_ankle_function_q36_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q36_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q36_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q36_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q36_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q37":"Leichte Hausarbeit (Kochen, Abstauben usw)",
  "patient_foot_ankle_function_q37_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q37_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q37_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q37_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q37_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q38":"Leichte bis mittelschwere Arbeit (Stehen, Gehen)",
  "patient_foot_ankle_function_q38_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q38_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q38_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q38_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q38_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q39":"Freizeitaktivitäten",
  "patient_foot_ankle_function_q39_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q39_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q39_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q39_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q39_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q40":"Hocken",
  "patient_foot_ankle_function_q40_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q40_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q40_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q40_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q40_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q41":"Drehen auf dem Fuß/Knöchel",
  "patient_foot_ankle_function_q41_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q41_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q41_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q41_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q41_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q42":"Knien",
  "patient_foot_ankle_function_q42_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q42_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q42_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q42_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q42_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q43":"Laufen",
  "patient_foot_ankle_function_q43_not_applicable":"unzutreffend",
  "patient_foot_ankle_function_q43_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q43_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q43_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q43_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q43_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q44":"Springen",
  "patient_foot_ankle_function_q44_not_applicable":"unzutreffend",
  "patient_foot_ankle_function_q44_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q44_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q44_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q44_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q44_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q45":"Landen",
  "patient_foot_ankle_function_q45_not_applicable":"unzutreffend",
  "patient_foot_ankle_function_q45_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q45_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q45_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q45_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q45_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q46":"Schnell starten und stoppen",
  "patient_foot_ankle_function_q46_not_applicable":"unzutreffend",
  "patient_foot_ankle_function_q46_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q46_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q46_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q46_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q46_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q47":"Schnell starten und stoppen",
  "patient_foot_ankle_function_q47_not_applicable":"unzutreffend",
  "patient_foot_ankle_function_q47_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q47_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q47_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q47_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q47_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q48":"Aktivitäten mit geringer Belastung",
  "patient_foot_ankle_function_q48_not_applicable":"unzutreffend",
  "patient_foot_ankle_function_q48_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q48_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q48_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q48_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q48_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q49":"Fähigkeit, Aktivitäten auf normale Art und Weise auszuführen",
  "patient_foot_ankle_function_q49_not_applicable":"unzutreffend",
  "patient_foot_ankle_function_q49_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q49_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q49_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q49_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q49_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q50":"Fähigkeit, Ihren Wunschsport so lange auszuüben, wie Sie möchten",
  "patient_foot_ankle_function_q50_not_applicable":"unzutreffend",
  "patient_foot_ankle_function_q50_a1":"keine Schwierigkeit",
  "patient_foot_ankle_function_q50_a2":"leichte Schwierigkeit",
  "patient_foot_ankle_function_q50_a3":"mittlere Schwierigkeit",
  "patient_foot_ankle_function_q50_a4":"extreme Schwierigkeit",
  "patient_foot_ankle_function_q50_a5":"unfähig zu tun",
  "patient_foot_ankle_function_q51":"Wie würden Sie Ihren Knöchel/Fuß heute in Prozent des Normalwerts bewerten? (0 bis 100 %, wobei 100 % normal sind)",
  "patient_foot_ankle_function_q51_a1":"Normal",
  "patient_foot_ankle_function_q52":"Was ist das höchste Aktivitätsniveau, an dem Sie regelmäßig teilnehmen können?",
  "patient_foot_ankle_function_q52_q1":"Vor Ihrer Fuß- oder Knöchelverletzung/ Ihrem Knöchelproblem",
  "patient_foot_ankle_function_q52_q1_a1":"höchststufe",
  "patient_foot_ankle_function_q52_q1_a2":"niedrigeres Wettbewerbsniveau",
  "patient_foot_ankle_function_q52_q1_a3":"Freizeit",
  "patient_foot_ankle_function_q52_q1_a4":"Keine sportliche Betätigung",
  "patient_foot_ankle_function_q52_q1_a5":"kein Sport, keine Arbeit",
  "patient_foot_ankle_function_q52_q1_a4_o7":"gehunfähig, behindert aufgrund von Knöchelproblemen",
  "patient_foot_ankle_function_q52_q1_a1_title":"Top-Level (internationale Elite, Profi, Nationalmannschaft oder erste Liga)",
  "patient_foot_ankle_function_q52_q1_a2_title":"Niedrigeres Wettbewerbsniveau",
  "patient_foot_ankle_function_q52_q1_a3_title":"Freizeit",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1":"Aerobic, Fitness",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2":"Alpines Skifahren und Snowboarden",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3":"American Football",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4":"Badminton",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5":"Baseball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6":"Basketball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7":"Bowling/Curling",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08":"Boxen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9":"Geländelaufen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10":"Radfahren",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11":"Tanzen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12":"Tauchen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13":"Pferdesport",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14":"Fechten",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15":"Floorball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16":"Freestyle-Snowboarden",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17":"Golf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18":"Gymnastik",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19":"Handball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20":"Hockey",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21":"Eishockey",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22":"Korfball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23":"Kampfkünste",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24":"Moderner Fünfkampf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25":"Motorsport, technischer Sport",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26":"Bergsteigen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27":"Mountainbiken/BMX",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28":"Nordisches Skifahren",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29":"Orientierungslauf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30":"Fallschirmspringen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31":"Gewichtheben",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32":"Rhythmische Sportgymnastik",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33":"Rudern, Kajakfahren",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34":"Rugby",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35":"Segeln",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36":"Gerätetauchen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37":"Schießen, Bogenschießen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38":"Skaten, Inline-Skaten",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39":"Fußball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40":"Softball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41":"Squash",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42":"Surfen, Windsurfen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43":"Tischtennis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44":"Tennis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45":"Leichtathletic: andere (z.B. Weitsprung, Hochsprung, Speerwerfen, Kugelstossen)",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46":"Leichtathletik: Laufwettbewerbe",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47":"Triathlon",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48":"Volleyball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49":"Wasserball und Schwimmen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50":"Wasserski",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51":"Gewichtheben Bodybuilding",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52":"Ringen",
  "patient_foot_ankle_function_q52_q1_a4_o1":"schwere körperliche Arbeit",
  "patient_foot_ankle_function_q52_q1_a4_o2":"Körperliche Arbeit",
  "patient_foot_ankle_function_q52_q1_a4_o3":"Besondere Berufe: Balletttänzer, Berufssoldat, Spezialrettungsarbeiter, Stuntman",
  "patient_foot_ankle_function_q52_q1_a4_o4":"kann auf jedem unebenen Untergrund laufen",
  "patient_foot_ankle_function_q52_q1_a4_o5":"Alltagsaktivitäten sind nicht eingeschränkt",
  "patient_foot_ankle_function_q52_q1_a4_o6":"kann auf ebenem Boden gehen, aber die alltäglichen Aktivitäten sind eingeschränkt",
  "patient_foot_ankle_function_q52_q2":"aktueller Stand",
  "patient_foot_ankle_function_q52_q2_a1":"Höchststufe",
  "patient_foot_ankle_function_q52_q2_a2":"niedrigeres Wettbewerbsniveau",
  "patient_foot_ankle_function_q52_q2_a3":"Freizeit",
  "patient_foot_ankle_function_q52_q2_a4":"Keine sportliche Betätigung",
  "patient_foot_ankle_function_q52_q2_a5":"kein Sport, keine Arbeit",
  "patient_foot_ankle_function_q52_q2_a4_o7":"gehunfähig, behindert aufgrund von Knöchelproblemen",
  "patient_foot_ankle_function_q52_q2_a1_title":"Top-Level (internationale Elite, Profi, Nationalmannschaft oder erste Liga)",
  "patient_foot_ankle_function_q52_q2_a2_title":"Niedrigeres Wettbewerbsniveau",
  "patient_foot_ankle_function_q52_q2_a3_title":"Freizeit",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1":"Aerobic, Fitness",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2":"Alpines Skifahren und Snowboarden",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3":"American Football",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4":"Badminton",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5":"Baseball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6":"Basketball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7":"Bowling/Curling",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8":"Boxen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9":"Geländelaufen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10":"Radfahren",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11":"Tanzen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12":"Tauchen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13":"Pferdesport",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14":"Fechten",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15":"Floorball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16":"Freestyle-Snowboarden",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17":"Golf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18":"Gymnastik",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19":"Handball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20":"Hockey",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21":"Eishockey",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22":"Korfball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23":"Kampfkünste",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24":"Moderner Fünfkampf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25":"Motorsport, technischer Sport",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26":"Bergsteigen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27":"Mountainbiken/BMX",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28":"Nordisches Skifahren",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29":"Orientierungslauf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30":"Fallschirmspringen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31":"Gewichtheben",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32":"Rhythmische Sportgymnastik",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33":"Rudern, Kajakfahren",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34":"Rugby",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35":"Segeln",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36":"Gerätetauchen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37":"Schießen, Bogenschießen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38":"Skaten, Inline-Skaten",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39":"Fußball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40":"Softball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41":"Squash",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42":"Surfen Windsurfen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43":"Tischtennis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44":"Tennis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45":"Leichtathletic: andere (z.B. Weitsprung, Hochsprung, Speerwerfen, Kugelstossen)",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46":"Leichtathletik: Laufwettbewerbe",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47":"Triathlon",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48":"Volleyball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49":"Wasserball und Schwimmen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50":"Wasserski",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51":"Gewichtheben, Bodybuilding",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52":"Ringen",
  "patient_foot_ankle_function_q52_q2_a4_o1":"schwere körperliche Arbeit",
  "patient_foot_ankle_function_q52_q2_a4_o2":"Körperliche Arbeit",
  "patient_foot_ankle_function_q52_q2_a4_o3":"Besondere Berufe: Balletttänzer, Berufssoldat, Spezialrettungsarbeiter, Stuntman",
  "patient_foot_ankle_function_q52_q2_a4_o4":"kann auf jedem unebenen Untergrund laufen",
  "patient_foot_ankle_function_q52_q2_a4_o5":"Alltagsaktivitäten sind nicht eingeschränkt",
  "patient_foot_ankle_function_q52_q2_a4_o6":"kann auf ebenem Boden laufen, alle Aktivitäten sind eingeschränkt",
  "patient_foot_ankle_quality_of_life":"Lebensqualität",
  "patient_foot_ankle_quality_of_life_instructions":"In den folgenden Fragen geht es um soziale, emotionale und Lebensstilprobleme, die Sie im Zusammenhang mit Ihrem Fuß-/Knöchelproblem haben könnten. Bitte denken Sie darüber nach, wie Sie sich die meiste Zeit in Bezug auf Ihren Fuß/Knöchel gefühlt haben.",
  "patient_foot_ankle_quality_of_life_q1":"Wie oft sind Sie sich Ihres Fuß-/Knöchelproblems bewusst?",
  "patient_foot_ankle_quality_of_life_q1_a1":"niemals",
  "patient_foot_ankle_quality_of_life_q1_a2":"monatlich",
  "patient_foot_ankle_quality_of_life_q1_a3":"wöchentlich",
  "patient_foot_ankle_quality_of_life_q1_a4":"täglich",
  "patient_foot_ankle_quality_of_life_q1_a5":"ständig",
  "patient_foot_ankle_quality_of_life_q2":"Haben Sie Ihren Lebensstil geändert, um potenziell schädliche Aktivitäten für Ihren Fuß/Knöchel zu vermeiden?",
  "patient_foot_ankle_quality_of_life_q2_a1":"gar nicht",
  "patient_foot_ankle_quality_of_life_q2_a2":"leicht",
  "patient_foot_ankle_quality_of_life_q2_a3":"in Maßen",
  "patient_foot_ankle_quality_of_life_q2_a4":"stark",
  "patient_foot_ankle_quality_of_life_q2_a5":"total",
  "patient_foot_ankle_quality_of_life_q3":"Wie sehr beunruhigt Sie das mangelnde Vertrauen in Ihren Fuß/Knöchel?",
  "patient_foot_ankle_quality_of_life_q3_a1":"gar nicht",
  "patient_foot_ankle_quality_of_life_q3_a2":"leicht",
  "patient_foot_ankle_quality_of_life_q3_a3":"in Maßen",
  "patient_foot_ankle_quality_of_life_q3_a4":"stark",
  "patient_foot_ankle_quality_of_life_q3_a5":"äußerst",
  "patient_foot_ankle_quality_of_life_q4":"Wie viel Schwierigkeiten haben Sie im Allgemeinen mit Ihrem Fuß/Knöchel?",
  "patient_foot_ankle_quality_of_life_q4_a1":"keine",
  "patient_foot_ankle_quality_of_life_q4_a2":"leichte",
  "patient_foot_ankle_quality_of_life_q4_a3":"mäßige",
  "patient_foot_ankle_quality_of_life_q4_a4":"schwere",
  "patient_foot_ankle_quality_of_life_q4_a5":"extreme",
  "patient_foot_ankle_quality_of_life_q5":"Wie ängstlich/deprimiert sind Sie heute?",
  "patient_foot_ankle_quality_of_life_q5_a1":"nicht ängstlich/deprimiert",
  "patient_foot_ankle_quality_of_life_q5_a2":"leicht",
  "patient_foot_ankle_quality_of_life_q5_a3":"in Maßen",
  "patient_foot_ankle_quality_of_life_q5_a4":"schwer",
  "patient_foot_ankle_quality_of_life_q5_a5":"extrem",
  "patient_foot_ankle_quality_of_life_q6":"Wie gut/schlecht ist Ihre Gesundheit heute? (100 bedeutet beste Gesundheit, 0 bedeutet schlechteste Gesundheit, die Sie sich vorstellen können)",
  "patient_foot_ankle_quality_of_life_q6_a1":"schlechteste Gesundheit",
  "patient_foot_ankle_quality_of_life_q6_a2":"beste Gesundheit",
  "patient_foot_ankle_satisfaction":"Zufriedenheit",
  "patient_foot_ankle_satisfaction_instruction":"Wie zufrieden sind Sie mit den Ergebnissen Ihrer Fuß-/Knöcheloperation oder -behandlung?",
  "patient_foot_ankle_satisfaction_instruction_a1":"sehr unzufrieden",
  "patient_foot_ankle_satisfaction_instruction_a2":"etwas unzufrieden",
  "patient_foot_ankle_satisfaction_instruction_a3":"etwas zufrieden",
  "patient_foot_ankle_satisfaction_instruction_a4":"sehr zufrieden",
  "patient_foot_ankle_satisfaction_q1":"Für Linderung der Symptome?",
  "patient_foot_ankle_satisfaction_q1_not_applicable":"unzutreffend",
  "patient_foot_ankle_satisfaction_q1_a1":"sehr unzufrieden",
  "patient_foot_ankle_satisfaction_q1_a2":"etwas unzufrieden",
  "patient_foot_ankle_satisfaction_q1_a3":"etwas zufrieden",
  "patient_foot_ankle_satisfaction_q1_a4":"sehr zufrieden",
  "patient_foot_ankle_satisfaction_q2":"Für Verbesserung Ihrer Fähigkeit, tägliche Aktivitäten auszuführen?",
  "patient_foot_ankle_satisfaction_q2_not_applicable":"unzutreffend",
  "patient_foot_ankle_satisfaction_q2_a1":"sehr unzufrieden",
  "patient_foot_ankle_satisfaction_q2_a2":"etwas unzufrieden",
  "patient_foot_ankle_satisfaction_q2_a3":"etwas zufrieden",
  "patient_foot_ankle_satisfaction_q2_a4":"sehr zufrieden",
  "patient_foot_ankle_satisfaction_q3":"Daß Sie Ihr Fuß-/Knöchelproblem „vergessen“ haben? (Bewusstsein über Fuß-/Knöchelprobleme)",
  "patient_foot_ankle_satisfaction_q3_not_applicable":"unzutreffend",
  "patient_foot_ankle_satisfaction_q3_a1":"sehr unzufrieden",
  "patient_foot_ankle_satisfaction_q3_a2":"etwas unzufrieden",
  "patient_foot_ankle_satisfaction_q3_a3":"etwas zufrieden",
  "patient_foot_ankle_satisfaction_q3_a4":"sehr zufrieden",
  "patient_foot_ankle_complete":"Ende des Fragebogens",
  "patient_foot_ankle_complete_text1":"Vielen Dank, dass Sie sich die Zeit genommen haben, dieses Formular auszufüllen.",
  "patient_foot_ankle_complete_text2":"Bitte klicken Sie auf „Senden“, um den Vorgang abzuschließen und Ihre Ergebnisse anzuzeigen.",
  "patient_foot_ankle_complete_text3":"Ihre Antworten werden vertraulich behandelt und in allen Berichten werden nur anonymisierte Informationen verwendet.",
  "patient_foot_ankle_results":"Ergebnisse",
  "patient_foot_ankle_results_text1":"Nachfolgend finden Sie Ihre Schmerz- und Fuß-/Knöchelfunktionswerte, die anhand Ihres Fragebogens berechnet wurden.",
  "patient_foot_ankle_results_pain":"Schmerz",
  "patient_foot_ankle_results_function":"Funktion",
  "patient_foot_ankle_results_overall_health_perception":"Allgemeine Gesundheitswahrnehmung",
  "patient_foot_ankle_results_feeling_of_instability":"Gefühl der Instabilität",
  "patient_foot_ankle_results_self_evaluation":"Fuß/Knöchelselbstbewertung",
  "patient_foot_ankle_results_text2":"Ihr Arzt wird in Kürze bei Ihnen sein.",
  "patient_elbow_pain":"Schmerz",
  "patient_elbow_pain_q1":"Haben Sie Schmerzen im Ellbogen?",
  "patient_elbow_pain_q1_a1":"nein",
  "patient_elbow_pain_q1_a2":"ja",
  "patient_elbow_pain_q2":"Was ist die beste Beschreibung für Ihre Ellbogenschmerzen?",
  "patient_elbow_pain_q2_a1":"keine oder ignoriert",
  "patient_elbow_pain_q2_a2":"leichte mit Aktivität, keine bis gelegentliche Einnahme von Medikamenten/Schmerzmitteln",
  "patient_elbow_pain_q2_a3":"mäßige mit oder ohne Aktivität, verwendet täglich Schmerzmittel",
  "patient_elbow_pain_q2_a4":"mäßige in Ruhe oder nachts",
  "patient_elbow_pain_q2_a5":"schwere in Ruhe, benötige ständig Medikamente, einschränkend",
  "patient_elbow_pain_instruction":"Bewerten Sie Ihre SCHMERZEN im Ellenbogen",
  "patient_elbow_pain_q3":"In diesem Moment",
  "patient_elbow_pain_q3_a1":"kein Schmerz",
  "patient_elbow_pain_q3_a2":"schlimmste Schmerzen, unerträglich",
  "patient_elbow_pain_q4":"übliche Schmerzen in den letzten 4 Wochen",
  "patient_elbow_pain_q4_a1":"kein Schmerz",
  "patient_elbow_pain_q4_a2":"schlimmste Schmerzen, unerträglich",
  "patient_elbow_pain_q5":"Wenn es in den letzten 4 Wochen am schlimmsten war",
  "patient_elbow_pain_q5_a1":"kein Schmerz",
  "patient_elbow_pain_q5_a2":"schlimmste Schmerzen, unerträglich",
  "patient_elbow_pain_q6":"im Ruhezustand",
  "patient_elbow_pain_q6_a1":"kein Schmerz",
  "patient_elbow_pain_q6_a2":"schlimmste Schmerzen, unerträglich",
  "patient_elbow_pain_q7":"einen schweren Gegenstand heben",
  "patient_elbow_pain_q7_a1":"kein Schmerz",
  "patient_elbow_pain_q7_a2":"schlimmste Schmerzen, unerträglich",
  "patient_elbow_pain_q8":"beim Ausführen einer  Aufgabe mit wiederholten Ellbogenbewegungen",
  "patient_elbow_pain_q8_a1":"kein Schmerz",
  "patient_elbow_pain_q8_a2":"schlimmste Schmerzen, unerträglich",
  "patient_elbow_pain_q9":"Nachts",
  "patient_elbow_pain_q9_a1":"kein Schmerz",
  "patient_elbow_pain_q9_a2":"schlimmste Schmerzen, unerträglich",
  "patient_elbow_pain_q10":"Wie oft wurden Sie nachts im Bett von Ellbogenschmerzen geplagt?",
  "patient_elbow_pain_q10_a1":"gar nicht",
  "patient_elbow_pain_q10_a2":"1-2 Nächte",
  "patient_elbow_pain_q10_a3":"manche Nächte",
  "patient_elbow_pain_q10_a4":"die meisten Nächte",
  "patient_elbow_pain_q10_a5":"jede Nacht",
  "patient_elbow_pain_q11":"Wie oft haben Ihre Ellbogenschmerzen Ihren Schlaf beeinträchtigt?",
  "patient_elbow_pain_q11_a1":"gar nicht",
  "patient_elbow_pain_q11_a2":"ab und zu",
  "patient_elbow_pain_q11_a3":"manchmal",
  "patient_elbow_pain_q11_a4":"meistens",
  "patient_elbow_pain_q11_a5":"die ganze Zeit",
  "patient_elbow_function":"Funktion",
  "patient_elbow_function_q1":"Wie lässt sich die FUNKTION Ihres Ellbogens am besten beschreiben?",
  "patient_elbow_function_q1_a1":"keine Einschränkungen",
  "patient_elbow_function_q1_a2":"leichte Einschränkungen, keine Einschränkungen bei Aktivitäten des täglichen Lebens",
  "patient_elbow_function_q1_a3":"kann keine Gegenstände >4,5 kg heben",
  "patient_elbow_function_q1_a4":"moderate Einschränkungen bei Aktivitäten des täglichen Lebens",
  "patient_elbow_function_q1_a5":"kann meine Haare nicht kämmen oder den Kopf berühren",
  "patient_elbow_function_q1_a6":"kann nicht alleine essen",
  "patient_elbow_function_q2":"Was ist die beste Beschreibung der AKTIVITÄT, die Sie tun können?",
  "patient_elbow_function_q2_a1":"unbegrenzte Nutzung des Ellbogens",
  "patient_elbow_function_q2_a2":"begrenzte Nutzung in der Freizeit",
  "patient_elbow_function_q2_a3":"beschränkt auf Haushalts- und Arbeitstätigkeiten",
  "patient_elbow_function_q2_a4":"kann für mich selbst sorgen",
  "patient_elbow_function_q2_a5":"behindert",
  "patient_elbow_function_q3":"Wie lange können Sie Ihren Ellbogen benutzen?",
  "patient_elbow_function_q3_a1":"kann Aktivitäten für 30 Minuten ausführen",
  "patient_elbow_function_q3_a2":"kann Aktivitäten für 15 Minuten ausführen",
  "patient_elbow_function_q3_a3":"kann Aktivitäten für 5 Minuten ausführen",
  "patient_elbow_function_q3_a4":"kann den Ellbogen nicht verwenden",
  "patient_elbow_function_instruction":"Geben Sie an, wie schwierig es aufgrund Ihres Ellenbogens war, die folgenden Aktivitäten in der letzten Woche auszuführen",
  "patient_elbow_function_instruction_0":"keine Schwierigkeit",
  "patient_elbow_function_instruction_1":"leicht",
  "patient_elbow_function_instruction_2":"mäßig",
  "patient_elbow_function_instruction_3":"schwer",
  "patient_elbow_function_instruction_4":"unfähig",
  "patient_elbow_function_q4":"Ein dichtes oder neues Glas öffnen ",
  "patient_elbow_function_q4_a1":"keine Schwierigkeit",
  "patient_elbow_function_q4_a2":"leicht",
  "patient_elbow_function_q4_a3":"mäßig",
  "patient_elbow_function_q4_a4":"schwer",
  "patient_elbow_function_q4_a5":"unfähig",
  "patient_elbow_function_q5":"Eine Einkaufstasche oder Aktentasche tragen",
  "patient_elbow_function_q5_a1":"keine Schwierigkeit",
  "patient_elbow_function_q5_a2":"leicht",
  "patient_elbow_function_q5_a3":"mäßig",
  "patient_elbow_function_q5_a4":"schwer",
  "patient_elbow_function_q5_a5":"unfähig",
  "patient_elbow_function_q6":"Heben von Dingen in Ihrem Haus, z.B. den Müll herausbringen",
  "patient_elbow_function_q6_a1":"keine Schwierigkeit",
  "patient_elbow_function_q6_a2":"leicht",
  "patient_elbow_function_q6_a3":"mäßig",
  "patient_elbow_function_q6_a4":"schwer",
  "patient_elbow_function_q6_a5":"unfähig",
  "patient_elbow_function_q7":"Waschen Sie Ihren Rücken",
  "patient_elbow_function_q7_a1":"keine Schwierigkeit",
  "patient_elbow_function_q7_a2":"leicht",
  "patient_elbow_function_q7_a3":"mäßig",
  "patient_elbow_function_q7_a4":"schwer",
  "patient_elbow_function_q7_a5":"unfähig",
  "patient_elbow_function_q8":"Toilettengang",
  "patient_elbow_function_q8_a1":"keine Schwierigkeit",
  "patient_elbow_function_q8_a2":"leicht",
  "patient_elbow_function_q8_a3":"mäßig",
  "patient_elbow_function_q8_a4":"schwer",
  "patient_elbow_function_q8_a5":"unfähig",
  "patient_elbow_function_q9":"Sich überall waschen",
  "patient_elbow_function_q9_a1":"keine Schwierigkeit",
  "patient_elbow_function_q9_a2":"leicht",
  "patient_elbow_function_q9_a3":"mäßig",
  "patient_elbow_function_q9_a4":"schwer",
  "patient_elbow_function_q9_a5":"unfähig",
  "patient_elbow_function_q10":"Persönliche Hygiene durchführen",
  "patient_elbow_function_q10_a1":"keine Schwierigkeit",
  "patient_elbow_function_q10_a2":"leicht",
  "patient_elbow_function_q10_a3":"mäßig",
  "patient_elbow_function_q10_a4":"schwer",
  "patient_elbow_function_q10_a5":"unfähig",
  "patient_elbow_function_q11":"Essen mit einem Messer schneiden",
  "patient_elbow_function_q11_a1":"keine Schwierigkeit",
  "patient_elbow_function_q11_a2":"leicht",
  "patient_elbow_function_q11_a3":"mäßig",
  "patient_elbow_function_q11_a4":"schwer",
  "patient_elbow_function_q11_a5":"unfähig",
  "patient_elbow_function_q12":"Mit Besteck essen",
  "patient_elbow_function_q12_a1":"keine Schwierigkeit",
  "patient_elbow_function_q12_a2":"leicht",
  "patient_elbow_function_q12_a3":"mäßig",
  "patient_elbow_function_q12_a4":"schwer",
  "patient_elbow_function_q12_a5":"unfähig",
  "patient_elbow_function_q13":"Freizeitaktivitäten, bei denen Ihr Arm, Schulter oder Hand belasted wird (z.B. Golf, Hämmern, Tennis usw.)",
  "patient_elbow_function_q13_a1":"keine Schwierigkeit",
  "patient_elbow_function_q13_a2":"leicht",
  "patient_elbow_function_q13_a3":"mäßig",
  "patient_elbow_function_q13_a4":"schwer",
  "patient_elbow_function_q13_a5":"unfähig",
  "patient_elbow_function_q14":"Schlafen aufgrund von Schmerzen in Ihrem Arm, Schulter oder Hand",
  "patient_elbow_function_q14_a1":"keine Schwierigkeit",
  "patient_elbow_function_q14_a2":"leicht",
  "patient_elbow_function_q14_a3":"mäßig",
  "patient_elbow_function_q14_a4":"schwer",
  "patient_elbow_function_q14_a5":"unfähig",
  "patient_elbow_function_q15":"Hemd anziehen",
  "patient_elbow_function_q15_a1":"keine Schwierigkeit",
  "patient_elbow_function_q15_a2":"leicht",
  "patient_elbow_function_q15_a3":"mäßig",
  "patient_elbow_function_q15_a4":"schwer",
  "patient_elbow_function_q15_a5":"unfähig",
  "patient_elbow_function_q16":"Oberen Knopf am Hemd schliessen",
  "patient_elbow_function_q16_a1":"keine Schwierigkeit",
  "patient_elbow_function_q16_a2":"leicht",
  "patient_elbow_function_q16_a3":"mäßig",
  "patient_elbow_function_q16_a4":"schwer",
  "patient_elbow_function_q16_a5":"unfähig",
  "patient_elbow_function_q17":"Sich selbst anziehen",
  "patient_elbow_function_q17_a1":"keine Schwierigkeit",
  "patient_elbow_function_q17_a2":"leicht",
  "patient_elbow_function_q17_a3":"mäßig",
  "patient_elbow_function_q17_a4":"schwer",
  "patient_elbow_function_q17_a5":"unfähig",
  "patient_elbow_function_q18":"Haare kämmen",
  "patient_elbow_function_q18_a1":"keine Schwierigkeit",
  "patient_elbow_function_q18_a2":"leicht",
  "patient_elbow_function_q18_a3":"mäßig",
  "patient_elbow_function_q18_a4":"schwer",
  "patient_elbow_function_q18_a5":"unfähig",
  "patient_elbow_function_q19":"Schuhe anziehen",
  "patient_elbow_function_q19_a1":"keine Schwierigkeit",
  "patient_elbow_function_q19_a2":"leicht",
  "patient_elbow_function_q19_a3":"mäßig",
  "patient_elbow_function_q19_a4":"schwer",
  "patient_elbow_function_q19_a5":"unfähig",
  "patient_elbow_function_q20":"Schuhe zubinden",
  "patient_elbow_function_q20_a1":"keine Schwierigkeit",
  "patient_elbow_function_q20_a2":"leicht",
  "patient_elbow_function_q20_a3":"mäßig",
  "patient_elbow_function_q20_a4":"schwer",
  "patient_elbow_function_q20_a5":"unfähig",
  "patient_elbow_function_q21":"Einen schweren Gegenstand tragen",
  "patient_elbow_function_q21_a1":"keine Schwierigkeit",
  "patient_elbow_function_q21_a2":"leicht",
  "patient_elbow_function_q21_a3":"mäßig",
  "patient_elbow_function_q21_a4":"schwer",
  "patient_elbow_function_q21_a5":"unfähig",
  "patient_elbow_function_q22":"Stehen Sie von einem Stuhl auf und drücken Sie mit dem Arm",
  "patient_elbow_function_q22_a1":"keine Schwierigkeit",
  "patient_elbow_function_q22_a2":"leicht",
  "patient_elbow_function_q22_a3":"mäßig",
  "patient_elbow_function_q22_a4":"schwer",
  "patient_elbow_function_q22_a5":"unfähig",
  "patient_elbow_function_q23":"Erledige schwere Hausarbeiten (z. B. Wände und Böden schrubben)",
  "patient_elbow_function_q23_a1":"keine Schwierigkeit",
  "patient_elbow_function_q23_a2":"leicht",
  "patient_elbow_function_q23_a3":"mäßig",
  "patient_elbow_function_q23_a4":"schwer",
  "patient_elbow_function_q23_a5":"unfähig",
  "patient_elbow_function_q24":"Drehen Sie einen Schlüssel",
  "patient_elbow_function_q24_a1":"keine Schwierigkeit",
  "patient_elbow_function_q24_a2":"leicht",
  "patient_elbow_function_q24_a3":"mäßig",
  "patient_elbow_function_q24_a4":"schwer",
  "patient_elbow_function_q24_a5":"unfähig",
  "patient_elbow_function_q25":"Einen Ball werfen",
  "patient_elbow_function_q25_a1":"keine Schwierigkeit",
  "patient_elbow_function_q25_a2":"leicht",
  "patient_elbow_function_q25_a3":"mäßig",
  "patient_elbow_function_q25_a4":"schwer",
  "patient_elbow_function_q25_a5":"unfähig",
  "patient_elbow_function_q26":"Aufgrund von Arm-, Schulter- oder Handschmerzen Ihrer gewohnten Arbeit nachgehen (zur Arbeit gehört auch die Hausarbeit, wenn das Ihre Hauptaufgabe ist)",
  "patient_elbow_function_q26_a1":"keine Schwierigkeit",
  "patient_elbow_function_q26_a2":"leicht",
  "patient_elbow_function_q26_a3":"mäßig",
  "patient_elbow_function_q26_a4":"schwer",
  "patient_elbow_function_q26_a5":"unfähig",
  "patient_elbow_function_q27":"Geben Sie Ihre übliche Arbeit an",
  "patient_elbow_function_q27_placeholder":"Arbeit spezifizieren",
  "patient_elbow_function_i_donot_work":"Ich arbeite nicht",
  "patient_elbow_function_q28":"Verwenden Sie Ihre übliche Technik für die Arbeit",
  "patient_elbow_function_q28_a1":"keine Schwierigkeit",
  "patient_elbow_function_q28_a2":"leicht",
  "patient_elbow_function_q28_a3":"mäßig",
  "patient_elbow_function_q28_a4":"schwer",
  "patient_elbow_function_q28_a5":"unfähig",
  "patient_elbow_function_q29":"Erledigen Sie Ihre Arbeit so, wie Sie möchten",
  "patient_elbow_function_q29_a1":"keine Schwierigkeit",
  "patient_elbow_function_q29_a2":"leicht",
  "patient_elbow_function_q29_a3":"mäßig",
  "patient_elbow_function_q29_a4":"schwer",
  "patient_elbow_function_q29_a5":"unfähig",
  "patient_elbow_function_q30":"Verbringen Sie Ihre übliche Zeit mit Ihrer Arbeit",
  "patient_elbow_function_q30_a1":"keine Schwierigkeit",
  "patient_elbow_function_q30_a2":"leicht",
  "patient_elbow_function_q30_a3":"mäßig",
  "patient_elbow_function_q30_a4":"schwer",
  "patient_elbow_function_q30_a5":"unfähig",
  "patient_elbow_function_q31":"Spielen Sie Ihr Musikinstrument oder treiben Sie Sport wegen Arm-, Schulter- oder Handschmerzen",
  "patient_elbow_function_q31_a1":"keine Schwierigkeit",
  "patient_elbow_function_q31_a2":"leicht",
  "patient_elbow_function_q31_a3":"mäßig",
  "patient_elbow_function_q31_a4":"schwer",
  "patient_elbow_function_q31_a5":"unfähig",
  "patient_elbow_function_q32":"Geben Sie den Sport oder das Musikinstrument an",
  "patient_elbow_function_q32_placeholder":"Sport-/Musikinstrument angeben",
  "patient_elbow_function_i_donot_play":"Ich treibe weder Sport noch spiele ein Instrument",
  "patient_elbow_function_q33":"Verwenden Sie Ihre übliche Technik zum Spielen Ihres Instruments oder Ihrer Sportart",
  "patient_elbow_function_q33_a1":"keine Schwierigkeit",
  "patient_elbow_function_q33_a2":"leicht",
  "patient_elbow_function_q33_a3":"mäßig",
  "patient_elbow_function_q33_a4":"schwer",
  "patient_elbow_function_q33_a5":"unfähig",
  "patient_elbow_function_q34":"Spielen Sie Ihr Musikinstrument oder Ihren Sport so gut, wie Sie es möchten",
  "patient_elbow_function_q34_a1":"keine Schwierigkeit",
  "patient_elbow_function_q34_a2":"leicht",
  "patient_elbow_function_q34_a3":"mäßig",
  "patient_elbow_function_q34_a4":"schwer",
  "patient_elbow_function_q34_a5":"unfähig",
  "patient_elbow_function_q35":"Verbringen Sie Ihre übliche Zeit damit, Ihr Instrument zu üben oder Ihren Sport zu spielen",
  "patient_elbow_function_q35_a1":"keine Schwierigkeit",
  "patient_elbow_function_q35_a2":"leicht",
  "patient_elbow_function_q35_a3":"mäßig",
  "patient_elbow_function_q35_a4":"schwer",
  "patient_elbow_function_q35_a5":"unfähig",
  "patient_elbow_function_q36":"Inwieweit hat Ihr Arm-Schulter- oder Handproblem in der vergangenen Woche Ihre NORMALEN GESELLSCHAFTS-/FREIZEITAKTIVITÄTEN mit Familie, Freunden, Nachbarn oder Gruppen beeinträchtigt?",
  "patient_elbow_function_q36_a1":"gar nicht",
  "patient_elbow_function_q36_a2":"leicht",
  "patient_elbow_function_q36_a3":"in Maßen",
  "patient_elbow_function_q36_a4":"ziemlich viel",
  "patient_elbow_function_q36_a5":"äußerst",
  "patient_elbow_function_q37":"Waren Sie in der vergangenen Woche aufgrund Ihres Arm-Schulter- oder Handproblems in Ihrer ARBEIT ODER ANDERE REGELMÄßIGE TÄGLICHE AKTIVITÄTEN eingeschränkt?",
  "patient_elbow_function_q37_a1":"gar nicht",
  "patient_elbow_function_q37_a2":"leicht",
  "patient_elbow_function_q37_a3":"in Maßen",
  "patient_elbow_function_q37_a4":"ziemlich viel",
  "patient_elbow_function_q37_a5":"äußerst",
  "patient_elbow_symptoms":"Weitere Symptome",
  "patient_elbow_symptoms_instruction":"Bitte bewerten Sie die Schwere der folgenden Symptome in der letzten Woche",
  "patient_elbow_symptoms_instruction_0":"keine",
  "patient_elbow_symptoms_instruction_1":"leicht",
  "patient_elbow_symptoms_instruction_2":"mäßig",
  "patient_elbow_symptoms_instruction_3":"schwer",
  "patient_elbow_symptoms_instruction_4":"extrem",
  "patient_elbow_symptoms_q1":"Arm-, Schulter- oder Handschmerzen",
  "patient_elbow_symptoms_q1_a1":"keine",
  "patient_elbow_symptoms_q1_a2":"leichte",
  "patient_elbow_symptoms_q1_a3":"mäßige",
  "patient_elbow_symptoms_q1_a4":"schwere",
  "patient_elbow_symptoms_q1_a5":"extreme",
  "patient_elbow_symptoms_q2":"Kribbeln (Ameisenlaufen) im Arm, in der Schulter oder in der Hand",
  "patient_elbow_symptoms_q2_a1":"kein",
  "patient_elbow_symptoms_q2_a2":"leichtes",
  "patient_elbow_symptoms_q2_a3":"mäßiges",
  "patient_elbow_symptoms_q2_a4":"schweres",
  "patient_elbow_symptoms_q2_a5":"extremes",
  "patient_elbow_symptoms_q3":"Wie würden Sie Ihren Ellbogen heute in Prozent des Normalwertes einschätzen? (0-100 %, wobei 100 % normal ist)",
  "patient_elbow_quality_of_life":"Lebensqualität",
  "patient_elbow_quality_of_life_instruction":"In den folgenden Fragen geht es um soziale, emotionale und Lebensstilprobleme, die Sie im Zusammenhang mit Ihrem Ellenbogenproblem haben könnten. Bitte denken Sie darüber nach, wie Sie sich die meiste Zeit in Bezug auf Ihren Ellenbogen gefühlt haben.",
  "patient_elbow_quality_of_life_q1":"Hatten Sie in den letzten 4 Wochen das Gefühl, dass Ihr Ellbogenproblem „Ihr Leben kontrolliert“?",
  "patient_elbow_quality_of_life_q1_a1":"gar nicht",
  "patient_elbow_quality_of_life_q1_a2":"ab und zu",
  "patient_elbow_quality_of_life_q1_a3":"manche Tage",
  "patient_elbow_quality_of_life_q1_a4":"an den meisten Tagen",
  "patient_elbow_quality_of_life_q1_a5":"täglich",
  "patient_elbow_quality_of_life_q2":"Wie sehr haben Sie in den letzten 4 Wochen Ihren Ellbogen „im Kopf“ gehabt?",
  "patient_elbow_quality_of_life_q2_a1":"gar nicht",
  "patient_elbow_quality_of_life_q2_a2":"ein bisschen",
  "patient_elbow_quality_of_life_q2_a3":"manchmal",
  "patient_elbow_quality_of_life_q2_a4":"meistens",
  "patient_elbow_quality_of_life_q2_a5":"immer",
  "patient_elbow_quality_of_life_instruction1":"Bitte bewerten Sie Folgendes, um Ihre GESUNDHEIT HEUTE am besten zu beschreiben",
  "patient_elbow_quality_of_life_instruction1_0":"kein Problem",
  "patient_elbow_quality_of_life_instruction1_1":"leichtes",
  "patient_elbow_quality_of_life_instruction1_2":"mäßiges",
  "patient_elbow_quality_of_life_instruction1_3":"schweres",
  "patient_elbow_quality_of_life_instruction1_4":"extrem/unfähig",
  "patient_elbow_quality_of_life_q3":"Problem beim Gehen",
  "patient_elbow_quality_of_life_q3_a1":"kein Problem",
  "patient_elbow_quality_of_life_q3_a2":"leichtes",
  "patient_elbow_quality_of_life_q3_a3":"mäßiges",
  "patient_elbow_quality_of_life_q3_a4":"schweres",
  "patient_elbow_quality_of_life_q3_a5":"unfähig",
  "patient_elbow_quality_of_life_q4":"Problem sich zu waschen oder anzuziehen",
  "patient_elbow_quality_of_life_q4_a1":"kein Problem",
  "patient_elbow_quality_of_life_q4_a2":"leichtes",
  "patient_elbow_quality_of_life_q4_a3":"mäßiges",
  "patient_elbow_quality_of_life_q4_a4":"schweres",
  "patient_elbow_quality_of_life_q4_a5":"unfähig",
  "patient_elbow_quality_of_life_q5":"Probleme bei der Ausführung üblicher Aktivitäten (z. B. Arbeit, Studium, Hausarbeit, Familie oder Freizeitaktivitäten)",
  "patient_elbow_quality_of_life_q5_a1":"keine Probleme",
  "patient_elbow_quality_of_life_q5_a2":"leichte",
  "patient_elbow_quality_of_life_q5_a3":"mäßige",
  "patient_elbow_quality_of_life_q5_a4":"schwere",
  "patient_elbow_quality_of_life_q5_a5":"unfähig",
  "patient_elbow_quality_of_life_q6":"Schmerzen oder Beschwerden",
  "patient_elbow_quality_of_life_q6_a1":"keine",
  "patient_elbow_quality_of_life_q6_a2":"leichte",
  "patient_elbow_quality_of_life_q6_a3":"mäßige",
  "patient_elbow_quality_of_life_q6_a4":"schwere",
  "patient_elbow_quality_of_life_q6_a5":"extreme",
  "patient_elbow_quality_of_life_q7":"Angstzustände/Depression",
  "patient_elbow_quality_of_life_q7_a1":"nicht ängstlich/deprimiert",
  "patient_elbow_quality_of_life_q7_a2":"leicht ängstlich/deprimiert",
  "patient_elbow_quality_of_life_q7_a3":"mäßig ängstlich/deprimiert",
  "patient_elbow_quality_of_life_q7_a4":"schwer ängstlich/deprimiert“",
  "patient_elbow_quality_of_life_q7_a5":"extrem ängstlich/deprimiert",
  "patient_elbow_quality_of_life_q8":"Wie gut oder schlecht ist Ihre Gesundheit HEUTE?",
  "patient_elbow_quality_of_life_q8_instruction":"100 bedeutet die beste Gesundheit, 0 bedeutet die schlechteste Gesundheit, die Sie sich vorstellen können",
  "patient_elbow_quality_of_life_q8_a1":"am schlimmsten",
  "patient_elbow_quality_of_life_q8_a2":"am besten",
  "patient_elbow_satisfaction":"Zufriedenheit",
  "patient_elbow_satisfaction_q1":"Sind Sie mit der Behandlung Ihres Ellenbogens zufrieden?",
  "patient_elbow_satisfaction_na":"unzutreffend",
  "patient_elbow_satisfaction_q1_a1":"ganz und gar nicht zufrieden",
  "patient_elbow_satisfaction_q1_a2":"sehr zufrieden",
  "patient_elbow_complete":"Ende des Fragebogens",
  "patient_elbow_complete_text1":"Vielen Dank, dass Sie sich die Zeit genommen haben, dieses Formular auszufüllen.",
  "patient_elbow_complete_text2":"Bitte klicken Sie auf „Senden“, um den Vorgang abzuschließen und Ihre Ergebnisse anzuzeigen.",
  "patient_elbow_complete_text3":"Ihre Antworten werden vertraulich behandelt und in allen Berichten werden nur anonymisierte Informationen verwendet",
  "patient_elbow_results":"Ergebnisse",
  "patient_elbow_results_text1":"Nachfolgend finden Sie Ihre Schmerz- und Ellenbogenfunktionswerte, die anhand Ihres Fragebogens berechnet wurden.",
  "patient_elbow_results_function":"Funktion",
  "patient_elbow_results_pain":"Schmerz",
  "patient_elbow_results_health_perception":"Allgemeine Gesundheitswahrnehmung",
  "patient_elbow_results_self_evaluation":"Ellenbogenselbstbewertung",
  "patient_elbow_results_text4":"Ihr Arzt wird in Kürze bei Ihnen sein.",
  "patient_hand_pain":"Schmerz",
  "patient_hand_pain_q1":"Beschreiben Sie die Schmerzen in Ihrer Hand/Ihrem Handgelenk in der LETZTEN WOCHE?",
  "patient_hand_pain_q1_a1":"keine bis sehr mild",
  "patient_hand_pain_q1_a2":"leichte",
  "patient_hand_pain_q1_a3":"mäßige",
  "patient_hand_pain_q1_a4":"schwere",
  "patient_hand_pain_q1_a5":"sehr schwerwiegende/extreme",
  "patient_hand_pain_q2":"Wie stark sind die Schmerzen in Ihrer Hand/Ihrem Handgelenk IN DIESEM MOMENT?",
  "patient_hand_pain_q2_a1":"kein Schmerz",
  "patient_hand_pain_q2_a2":"schlimmster Schmerz",
  "patient_hand_pain_q3":"Wie oft haben die Schmerzen in Ihrer Hand/Ihrem Handgelenk Ihre täglichen Aktivitäten (wie Essen oder Baden) in der letzten Woche beeinträchtigt?",
  "patient_hand_pain_q3_a1":"niemals",
  "patient_hand_pain_q3_a2":"selten",
  "patient_hand_pain_q3_a3":"manchmal",
  "patient_hand_pain_q3_a4":"oft",
  "patient_hand_pain_q3_a5":"immer",
  "patient_hand_symptoms":"Weitere Symptome",
  "patient_hand_symptoms_q1":"Wie stark war das Kribbeln (Ameisenlaufen) in Ihrem Arm, Schulter oder Hand in der letzten Woche?",
  "patient_hand_symptoms_q1_a1":"kein",
  "patient_hand_symptoms_q1_a2":"leicht",
  "patient_hand_symptoms_q1_a3":"mäßig",
  "patient_hand_symptoms_q1_a4":"schwer",
  "patient_hand_symptoms_q1_a5":"extrem",
  "patient_hand_symptoms_q2":"Wie war das Gefühl in Ihrer Hand während der letzten Woche?",
  "patient_hand_symptoms_q2_a1":"sehr gut",
  "patient_hand_symptoms_q2_a2":"gut",
  "patient_hand_symptoms_q2_a3":"in Ordnung",
  "patient_hand_symptoms_q2_a4":"schlecht",
  "patient_hand_symptoms_q2_a5":"sehr schlecht",
  "patient_hand_symptoms_q3":"Das Aussehen meiner Hand beeinträchtigt meine normalen täglichen Aktivitäten ?",
  "patient_hand_symptoms_q3_a1":"widerspreche entschiedends",
  "patient_hand_symptoms_q3_a2":"ich stimme nicht zu",
  "patient_hand_symptoms_q3_a3":"stimme weder zu noch widerspreche",
  "patient_hand_symptoms_q3_a4":"stimme zu",
  "patient_hand_symptoms_q3_a5":"stimme voll und ganz zu",
  "patient_hand_symptoms_q3_a6":"Wie würden Sie Ihre Hand/Ihr Handgelenk heute in Prozent des Normalwertes bewerten? (0-100 %, wobei 100 % normal ist)",
  "patient_hand_symptoms_q3_a6_normal":"normal",
  "patient_hand_function":"Funktion",
  "patient_hand_function_instruction1":"Wenn Sie eine bestimmte Aufgabe nicht erledigen, schätzen Sie bitte die Schwierigkeit ein, die Sie bei der Ausführung haben würden.",
  "patient_hand_function_instruction2":"Wenn Sie sich nicht sicher sind, wie Sie eine Frage beantworten sollen, geben Sie bitte die bestmögliche Antwort.",
  "patient_hand_function_q1":"Im Großen und Ganzen, wie gut hat Ihre Hand in der letzten Woche funktioniert?",
  "patient_hand_function_q1_a1":"sehr gut",
  "patient_hand_function_q1_a2":"gut",
  "patient_hand_function_q1_a3":"in Ordnung",
  "patient_hand_function_q1_a4":"schlecht",
  "patient_hand_function_q1_a5":"sehr schlecht",
  "patient_hand_function_q2":"Wie oft waren Sie aufgrund von Problemen mit Ihrer Hand/Ihrem Handgelenk NICHT in der Lage, Ihre Arbeit zu erledigen?",
  "patient_hand_function_q2_a1":"niemals",
  "patient_hand_function_q2_a2":"selten",
  "patient_hand_function_q2_a3":"manchmal",
  "patient_hand_function_q2_a4":"oft",
  "patient_hand_function_q2_a5":"immer",
  "patient_hand_function_q3":"Wie oft haben Sie bei Ihrer Arbeit aufgrund von Problemen mit Ihrer Hand/Ihrem Handgelenk LÄNGER GEBRAUCHT, UM AUFGABEN ZU ERFÜLLEN?",
  "patient_hand_function_q3_a1":"niemals",
  "patient_hand_function_q3_a2":"selten",
  "patient_hand_function_q3_a3":"manchmal",
  "patient_hand_function_q3_a4":"oft",
  "patient_hand_function_q3_a5":"immer",
  "patient_hand_function_instruction":"Geben Sie den SCHWIERIGKEITSGRAD an, in der letzten Woche aufgrund von Problemen mit Ihrer Hand/Ihrem Handgelenk die folgenden Aktivitäten auszuführen",
  "patient_hand_function_instruction_0":"keine Schwierigkeit",
  "patient_hand_function_instruction_1":"leichte Schwierigkeit",
  "patient_hand_function_instruction_2":"mäßige Schwierigkeit",
  "patient_hand_function_instruction_3":"schwere Schwierigkeit",
  "patient_hand_function_instruction_4":"extrem schwierig/unfähig",
  "patient_hand_function_q4":"Ein dichtes oder neues Glas öffnen ",
  "patient_hand_function_q4_a1":"keine Schwierigkeit",
  "patient_hand_function_q4_a2":"leichte Schwierigkeit",
  "patient_hand_function_q4_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q4_a4":"schwere Schwierigkeit",
  "patient_hand_function_q4_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q5":"Eine Bratpfanne halten",
  "patient_hand_function_q5_a1":"keine Schwierigkeit",
  "patient_hand_function_q5_a2":"leichte Schwierigkeit",
  "patient_hand_function_q5_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q5_a4":"schwere Schwierigkeit",
  "patient_hand_function_q5_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q6":"Hemd oder Bluse zuknöpfen",
  "patient_hand_function_q6_a1":"keine Schwierigkeit",
  "patient_hand_function_q6_a2":"leichte Schwierigkeit",
  "patient_hand_function_q6_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q6_a4":"schwere Schwierigkeit",
  "patient_hand_function_q6_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q7":"Erledige schwere Hausarbeiten (z. B. Wände und Böden schrubben)",
  "patient_hand_function_q7_a1":"keine Schwierigkeit",
  "patient_hand_function_q7_a2":"leichte Schwierigkeit",
  "patient_hand_function_q7_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q7_a4":"schwere Schwierigkeit",
  "patient_hand_function_q7_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q8":"Eine Einkaufstasche oder Aktentasche tragen",
  "patient_hand_function_q8_a1":"keine Schwierigkeit",
  "patient_hand_function_q8_a2":"leichte Schwierigkeit",
  "patient_hand_function_q8_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q8_a4":"schwere Schwierigkeit",
  "patient_hand_function_q8_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q9":"Waschen Sie Ihren Rücken",
  "patient_hand_function_q9_a1":"keine Schwierigkeit",
  "patient_hand_function_q9_a2":"leichte Schwierigkeit",
  "patient_hand_function_q9_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q9_a4":"schwere Schwierigkeit",
  "patient_hand_function_q9_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q10":"Essen mit einem Messer schneiden",
  "patient_hand_function_q10_a1":"keine Schwierigkeit",
  "patient_hand_function_q10_a2":"leichte Schwierigkeit",
  "patient_hand_function_q10_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q10_a4":"schwere Schwierigkeit",
  "patient_hand_function_q10_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q11":"Freizeitaktivitäten, bei denen Ihr Arm, Schulter oder Hand belasted wird (z.B. Golf, Hämmern, Tennis usw.)",
  "patient_hand_function_q11_a1":"keine Schwierigkeit",
  "patient_hand_function_q11_a2":"leichte Schwierigkeit",
  "patient_hand_function_q11_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q11_a4":"schwere Schwierigkeit",
  "patient_hand_function_q11_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q12":"Schlafen aufgrund von Schmerzen in Ihrem Arm, Schulter oder Hand",
  "patient_hand_function_q12_a1":"keine Schwierigkeit",
  "patient_hand_function_q12_a2":"leichte Schwierigkeit",
  "patient_hand_function_q12_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q12_a4":"schwere Schwierigkeit",
  "patient_hand_function_q12_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q13":"Aufgrund von Arm-, Schulter- oder Handschmerzen Ihrer gewohnten Arbeit nachgehen (zur Arbeit gehört auch die Hausarbeit, wenn das Ihre Hauptaufgabe ist)",
  "patient_hand_function_q13_a1":"keine Schwierigkeit",
  "patient_hand_function_q13_a2":"leichte Schwierigkeit",
  "patient_hand_function_q13_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q13_a4":"schwere Schwierigkeit",
  "patient_hand_function_q13_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q14":"Geben Sie Ihre übliche Arbeit an",
  "patient_hand_function_q14_placeholder":"Arbeit spezifizieren",
  "patient_hand_function_do_dot_work":"Ich arbeite nicht",
  "patient_hand_function_q15":"Verwenden Sie Ihre übliche Technik für die Arbeit",
  "patient_hand_function_q15_a1":"keine Schwierigkeit",
  "patient_hand_function_q15_a2":"leichte Schwierigkeit",
  "patient_hand_function_q15_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q15_a4":"schwere Schwierigkeit",
  "patient_hand_function_q15_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q16":"Erledigen Sie Ihre Arbeit so, wie Sie möchten",
  "patient_hand_function_q16_a1":"keine Schwierigkeit",
  "patient_hand_function_q16_a2":"leichte Schwierigkeit",
  "patient_hand_function_q16_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q16_a4":"schwere Schwierigkeit",
  "patient_hand_function_q16_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q17":"Verbringen Sie Ihre übliche Zeit mit Ihrer Arbeit",
  "patient_hand_function_q17_a1":"keine Schwierigkeit",
  "patient_hand_function_q17_a2":"leichte Schwierigkeit",
  "patient_hand_function_q17_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q17_a4":"schwere Schwierigkeit",
  "patient_hand_function_q17_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q18":"Spielen Sie Ihr Musikinstrument oder treiben Sie Sport wegen Arm-, Schulter- oder Handschmerzen",
  "patient_hand_function_q18_a1":"keine Schwierigkeit",
  "patient_hand_function_q18_a2":"leichte Schwierigkeit",
  "patient_hand_function_q18_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q18_a4":"schwere Schwierigkeit",
  "patient_hand_function_q18_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q19":"Geben Sie den Sport oder das Musikinstrument an",
  "patient_hand_function_q19_placeholder":"Sport-/Musikinstrument angeben",
  "patient_hand_function_donot_play":"Ich treibe weder Sport noch spiele ein Instrument",
  "patient_hand_function_20":"Verwenden Sie Ihre übliche Technik zum Spielen Ihres Instruments oder Ihrer Sportart",
  "patient_hand_function_q20_a1":"keine Schwierigkeit",
  "patient_hand_function_q20_a2":"leichte Schwierigkeit",
  "patient_hand_function_q20_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q20_a4":"schwere Schwierigkeit",
  "patient_hand_function_q20_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q21":"Spielen Sie Ihr Musikinstrument oder Ihren Sport so gut, wie Sie es möchten",
  "patient_hand_function_q21_a1":"keine Schwierigkeit",
  "patient_hand_function_q21_a2":"leichte Schwierigkeit",
  "patient_hand_function_q21_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q21_a4":"schwere Schwierigkeit",
  "patient_hand_function_q21_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q22":"Verbringen Sie Ihre übliche Zeit damit, Ihr Instrument zu üben oder Ihren Sport zu spielen",
  "patient_hand_function_q22_a1":"keine Schwierigkeit",
  "patient_hand_function_q22_a2":"leichte Schwierigkeit",
  "patient_hand_function_q22_a3":"mäßige Schwierigkeit",
  "patient_hand_function_q22_a4":"schwere Schwierigkeit",
  "patient_hand_function_q22_a5":"extrem schwierig/unfähig",
  "patient_hand_function_q23":"Inwieweit hat Ihr Arm-Schulter- oder Handproblem in der vergangenen Woche Ihre NORMALEN GESELLSCHAFTS-/FREIZEITAKTIVITÄTEN mit Familie, Freunden, Nachbarn oder Gruppen beeinträchtigt?",
  "patient_hand_function_q23_a1":"gar nicht",
  "patient_hand_function_q23_a2":"leicht",
  "patient_hand_function_q23_a3":"in Maßen",
  "patient_hand_function_q23_a4":"ziemlich viel",
  "patient_hand_function_q23_a5":"äußerst",
  "patient_hand_function_q24":"Waren Sie in der vergangenen Woche aufgrund Ihres Arm-Schulter- oder Handproblems in Ihrer ARBEIT ODER ANDERE REGELMÄßIGE TÄGLICHE AKTIVITÄTEN eingeschränkt?",
  "patient_hand_function_q24_a1":"gar nicht",
  "patient_hand_function_q24_a2":"leicht",
  "patient_hand_function_q24_a3":"in Maßen",
  "patient_hand_function_q24_a4":"ziemlich viel",
  "patient_hand_function_q24_a5":"äußerst",
  "patient_hand_questionnaire":"Fragebogen zum Boston-Karpaltunnelsyndrom",
  "patient_hand_questionnaire_symptom":"Symptome",
  "patient_hand_questionnaire_symptom_instruction":"Bitte wählen Sie die Antwort aus, die Ihren Symptomen am besten entspricht.",
  "patient_hand_questionnaire_symptom_instruction_0":"normal",
  "patient_hand_questionnaire_symptom_instruction_1":"leicht",
  "patient_hand_questionnaire_symptom_instruction_2":"medium",
  "patient_hand_questionnaire_symptom_instruction_3":"schwer",
  "patient_hand_questionnaire_symptom_instruction_4":"very serious",
  "patient_hand_questionnaire_symptom_q1":"Wie stark sind Ihre Hand- oder Handgelenkschmerzen in der Nacht?",
  "patient_hand_questionnaire_symptom_q1_a1":"normal",
  "patient_hand_questionnaire_symptom_q1_a2":"leicht",
  "patient_hand_questionnaire_symptom_q1_a3":"mittel",
  "patient_hand_questionnaire_symptom_q1_a4":"schwer",
  "patient_hand_questionnaire_symptom_q1_a5":"sehr ernst",
  "patient_hand_questionnaire_symptom_q2":"Wie oft sind Sie in den letzten 2 Wochen in einer typischen Nacht von Hand- oder Handgelenksschmerzen geweckt worden?",
  "patient_hand_questionnaire_symptom_q2_a1":"nicht",
  "patient_hand_questionnaire_symptom_q2_a2":"einmal",
  "patient_hand_questionnaire_symptom_q2_a3":"2-3 mal",
  "patient_hand_questionnaire_symptom_q2_a4":"4-5 mal",
  "patient_hand_questionnaire_symptom_q2_a5":"mehr als 5 mal",
  "patient_hand_questionnaire_symptom_q3":"Haben Sie tagsüber typischerweise Schmerzen in der Hand oder im Handgelenk?",
  "patient_hand_questionnaire_symptom_q3_a1":"kein Schmerz",
  "patient_hand_questionnaire_symptom_q3_a2":"leichte",
  "patient_hand_questionnaire_symptom_q3_a3":"mittlere",
  "patient_hand_questionnaire_symptom_q3_a4":"schwere",
  "patient_hand_questionnaire_symptom_q3_a5":"sehr schwere",
  "patient_hand_questionnaire_symptom_q4":"Wie oft haben Sie tagsüber Hand- oder Handgelenkschmerzen?",
  "patient_hand_questionnaire_symptom_q4_a1":"keine",
  "patient_hand_questionnaire_symptom_q4_a2":"1-2 mal/Tag",
  "patient_hand_questionnaire_symptom_q4_a3":"3-5 mal/Tag",
  "patient_hand_questionnaire_symptom_q4_a4":"mehr als 5 mal am Tag",
  "patient_hand_questionnaire_symptom_q4_a5":"ständig",
  "patient_hand_questionnaire_symptom_q5":"Wie lange dauert eine Schmerzepisode tagsüber durchschnittlich?",
  "patient_hand_questionnaire_symptom_q5_a1":"keine",
  "patient_hand_questionnaire_symptom_q5_a2":"<10 Minuten",
  "patient_hand_questionnaire_symptom_q5_a3":"10-60 Minuten kontinuierlich",
  "patient_hand_questionnaire_symptom_q5_a4":">60 Minuten",
  "patient_hand_questionnaire_symptom_q5_a5":"ständig",
  "patient_hand_questionnaire_symptom_q6":"Haben Sie Taubheit (Gefühlsverlust) in Ihrer Hand?",
  "patient_hand_questionnaire_symptom_q6_a1":"nicht",
  "patient_hand_questionnaire_symptom_q6_a2":"leichte",
  "patient_hand_questionnaire_symptom_q6_a3":"mittlere",
  "patient_hand_questionnaire_symptom_q6_a4":"schwere",
  "patient_hand_questionnaire_symptom_q6_a5":"sehr schwere",
  "patient_hand_questionnaire_symptom_q7":"Haben Sie eine Schwäche in Ihrer Hand oder Ihrem Handgelenk?",
  "patient_hand_questionnaire_symptom_q7_a1":"keine",
  "patient_hand_questionnaire_symptom_q7_a2":"leichte",
  "patient_hand_questionnaire_symptom_q7_a3":"mittlere",
  "patient_hand_questionnaire_symptom_q7_a4":"schwere",
  "patient_hand_questionnaire_symptom_q7_a5":"sehr schwere",
  "patient_hand_questionnaire_symptom_q8":"Haben Sie ein Kribbeln in der Hand?",
  "patient_hand_questionnaire_symptom_q8_a1":"kein",
  "patient_hand_questionnaire_symptom_q8_a2":"leichtes",
  "patient_hand_questionnaire_symptom_q8_a3":"mittleres",
  "patient_hand_questionnaire_symptom_q8_a4":"schweres",
  "patient_hand_questionnaire_symptom_q8_a5":"sehr schweres",
  "patient_hand_questionnaire_symptom_q9":"Wie stark ist die Taubheit (Gefühlsverlust) oder das Kribbeln in der Nacht?",
  "patient_hand_questionnaire_symptom_q9_a1":"kein/e",
  "patient_hand_questionnaire_symptom_q9_a2":"leicht",
  "patient_hand_questionnaire_symptom_q9_a3":"mittel",
  "patient_hand_questionnaire_symptom_q9_a4":"schwer",
  "patient_hand_questionnaire_symptom_q9_a5":"sehr schwer",
  "patient_hand_questionnaire_symptom_q10":"Wie oft wurden Sie in den letzten zwei Wochen in einer typischen Nacht durch Taubheitsgefühle oder Kribbeln in den Händen geweckt?",
  "patient_hand_questionnaire_symptom_q10_a1":"nicht",
  "patient_hand_questionnaire_symptom_q10_a2":"einmal",
  "patient_hand_questionnaire_symptom_q10_a3":"2-3 mal",
  "patient_hand_questionnaire_symptom_q10_a4":"4-5 mal",
  "patient_hand_questionnaire_symptom_q10_a5":"mehr als 5 mal",
  "patient_hand_questionnaire_function":"Funktion",
  "patient_hand_questionnaire_function_instructions":"Bewerten Sie den Grad der Schwierigkeit bei der Ausführung der folgenden Aktivitäten aufgrund Ihrer Symptome:",
  "patient_hand_questionnaire_function_instructions_0":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_instructions_1":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_instructions_2":"mittlere Schwierigkeit",
  "patient_hand_questionnaire_function_instructions_3":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_instructions_4":"kann es überhaupt nicht tun",
  "patient_hand_questionnaire_function_q1":"Schwierigkeiten, kleine Gegenstände wie Schlüssel oder Stifte zu greifen und zu verwenden",
  "patient_hand_questionnaire_function_q1_a1":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_q1_a2":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_q1_a3":"mittlere Schwierigkeit",
  "patient_hand_questionnaire_function_q1_a4":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_q1_a5":"kann es überhaupt nicht tun",
  "patient_hand_questionnaire_function_q2":"Schreiben",
  "patient_hand_questionnaire_function_q2_a1":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_q2_a2":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_q2_a3":"mäßige Schwierigkeit",
  "patient_hand_questionnaire_function_q2_a4":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_q2_a5":"kann es überhaupt nicht tun",
  "patient_hand_questionnaire_function_q3":"Kleidung zuknöpfen",
  "patient_hand_questionnaire_function_q3_a1":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_q3_a2":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_q3_a3":"mäßige Schwierigkeit",
  "patient_hand_questionnaire_function_q3_a4":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_q3_a5":"kann es überhaupt nicht tun",
  "patient_hand_questionnaire_function_q4":"Halten eines Buches beim Lesen",
  "patient_hand_questionnaire_function_q4_a1":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_q4_a2":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_q4_a3":"mäßige Schwierigkeit",
  "patient_hand_questionnaire_function_q4_a4":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_q4_a5":"kann es überhaupt nicht tun",
  "patient_hand_questionnaire_function_q5":"Greifen eines Telefonhörers",
  "patient_hand_questionnaire_function_q5_a1":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_q5_a2":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_q5_a3":"mäßige Schwierigkeit",
  "patient_hand_questionnaire_function_q5_a4":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_q5_a5":"kann es überhaupt nicht tun",
  "patient_hand_questionnaire_function_q6":"Öffnen von Gläsern",
  "patient_hand_questionnaire_function_q6_a1":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_q6_a2":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_q6_a3":"mäßige Schwierigkeit",
  "patient_hand_questionnaire_function_q6_a4":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_q6_a5":"kann es überhaupt nicht tun",
  "patient_hand_questionnaire_function_q7":"Hausarbeit",
  "patient_hand_questionnaire_function_q7_a1":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_q7_a2":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_q7_a3":"mäßige Schwierigkeit",
  "patient_hand_questionnaire_function_q7_a4":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_q7_a5":"kann es überhaupt nicht tun",
  "patient_hand_questionnaire_function_q8":"Tragen eines Einkaufskorbs",
  "patient_hand_questionnaire_function_q8_a1":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_q8_a2":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_q8_a3":"mäßige Schwierigkeit",
  "patient_hand_questionnaire_function_q8_a4":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_q8_a5":"kann es überhaupt nicht tun",
  "patient_hand_questionnaire_function_q9":"Baden und Anziehen",
  "patient_hand_questionnaire_function_q9_a1":"keine Schwierigkeit",
  "patient_hand_questionnaire_function_q9_a2":"geringe Schwierigkeit",
  "patient_hand_questionnaire_function_q9_a3":"mäßige Schwierigkeit",
  "patient_hand_questionnaire_function_q9_a4":"intensive Schwierigkeit",
  "patient_hand_questionnaire_function_q9_a5":"kann es überhaupt nicht tun",
  "patient_hand_uram":"URAM-Skala für Hand und Handgelenk",
  "patient_hand_uram_canyou":"Können Sie...",
  "patient_hand_uram_q1":"Sich mit einem Waschlappen waschen und dabei Ihre Hand flach halten",
  "patient_hand_uram_q1_a1":"ohne Schwierigkeiten",
  "patient_hand_uram_q1_a2":"mit sehr geringen Schwierigkeiten",
  "patient_hand_uram_q1_a3":"mit einigen Schwierigkeiten",
  "patient_hand_uram_q1_a4":"mit großer Mühe",
  "patient_hand_uram_q1_a5":"nahezu unmöglich",
  "patient_hand_uram_q1_a6":"unmöglich",
  "patient_hand_uram_q2":"Sich Ihr Gesicht waschen",
  "patient_hand_uram_q2_a1":"ohne Schwierigkeiten",
  "patient_hand_uram_q2_a2":"mit sehr geringen Schwierigkeiten",
  "patient_hand_uram_q2_a3":"mit einigen Schwierigkeiten",
  "patient_hand_uram_q2_a4":"mit großer Mühe",
  "patient_hand_uram_q2_a5":"nahezu unmöglich",
  "patient_hand_uram_q2_a6":"unmöglich",
  "patient_hand_uram_q3":"Eine Flasche in einer Hand halten",
  "patient_hand_uram_q3_a1":"ohne Schwierigkeiten",
  "patient_hand_uram_q3_a2":"mit sehr geringen Schwierigkeiten",
  "patient_hand_uram_q3_a3":"mit einigen Schwierigkeiten",
  "patient_hand_uram_q3_a4":"mit großer Mühe",
  "patient_hand_uram_q3_a5":"nahezu unmöglich",
  "patient_hand_uram_q3_a6":"unmöglich",
  "patient_hand_uram_q4":"Jemandem die Hand schütteln",
  "patient_hand_uram_q4_a1":"ohne Schwierigkeiten",
  "patient_hand_uram_q4_a2":"mit sehr geringen Schwierigkeiten",
  "patient_hand_uram_q4_a3":"mit einigen Schwierigkeiten",
  "patient_hand_uram_q4_a4":"mit großer Mühe",
  "patient_hand_uram_q4_a5":"nahezu unmöglich",
  "patient_hand_uram_q4_a6":"unmöglich",
  "patient_hand_uram_q5":"Etwas oder jemanden streicheln",
  "patient_hand_uram_q5_a1":"ohne Schwierigkeiten",
  "patient_hand_uram_q5_a2":"mit sehr geringen Schwierigkeiten",
  "patient_hand_uram_q5_a3":"mit einigen Schwierigkeiten",
  "patient_hand_uram_q5_a4":"mit großer Mühe",
  "patient_hand_uram_q5_a5":"nahezu unmöglich",
  "patient_hand_uram_q5_a6":"unmöglich",
  "patient_hand_uram_q6":"In die Hände klatschen",
  "patient_hand_uram_q6_a1":"ohne Schwierigkeiten",
  "patient_hand_uram_q6_a2":"mit sehr geringen Schwierigkeiten",
  "patient_hand_uram_q6_a3":"mit einigen Schwierigkeiten",
  "patient_hand_uram_q6_a4":"mit großer Mühe",
  "patient_hand_uram_q6_a5":"nahezu unmöglich",
  "patient_hand_uram_q6_a6":"unmöglich",
  "patient_hand_uram_q7":"Ihre Finger spreitzen",
  "patient_hand_uram_q7_a1":"ohne Schwierigkeiten",
  "patient_hand_uram_q7_a2":"mit sehr geringen Schwierigkeiten",
  "patient_hand_uram_q7_a3":"mit einigen Schwierigkeiten",
  "patient_hand_uram_q7_a4":"mit großer Mühe",
  "patient_hand_uram_q7_a5":"nahezu unmöglich",
  "patient_hand_uram_q7_a6":"unmöglich",
  "patient_hand_uram_q8":"Auf Ihre Hand lehnen",
  "patient_hand_uram_q8_a1":"ohne Schwierigkeiten",
  "patient_hand_uram_q8_a2":"mit sehr geringen Schwierigkeiten",
  "patient_hand_uram_q8_a3":"mit einigen Schwierigkeiten",
  "patient_hand_uram_q8_a4":"mit großer Mühe",
  "patient_hand_uram_q8_a5":"nahezu unmöglich",
  "patient_hand_uram_q8_a6":"unmöglich",
  "patient_hand_uram_q9":"Kleine Gegenstände mit Daumen und Zeigefinger aufheben",
  "patient_hand_uram_q9_a1":"ohne Schwierigkeiten",
  "patient_hand_uram_q9_a2":"mit sehr geringen Schwierigkeiten",
  "patient_hand_uram_q9_a3":"mit einigen Schwierigkeiten",
  "patient_hand_uram_q9_a4":"mit großer Mühe",
  "patient_hand_uram_q9_a5":"nahezu unmöglich",
  "patient_hand_uram_q9_a6":"unmöglich",
  "patient_hand_quality_of_life":"Lebensqualität",
  "patient_hand_quality_of_life_instruction":"Bitte bewerten Sie Folgendes, um Ihre GESUNDHEIT HEUTE am besten zu beschreiben",
  "patient_hand_quality_of_life_instruction_0":"kein Problem",
  "patient_hand_quality_of_life_instruction_1":"leichtes",
  "patient_hand_quality_of_life_instruction_2":"mäßiges",
  "patient_hand_quality_of_life_instruction_3":"schweres",
  "patient_hand_quality_of_life_instruction_4":"extremes/unmöglich",
  "patient_hand_quality_of_life_q1":"Problem beim Gehen",
  "patient_hand_quality_of_life_q1_a1":"kein Problem",
  "patient_hand_quality_of_life_q1_a2":"leichtes",
  "patient_hand_quality_of_life_q1_a3":"mäßiges",
  "patient_hand_quality_of_life_q1_a4":"schweres",
  "patient_hand_quality_of_life_q1_a5":"extremes/unmöglich",
  "patient_hand_quality_of_life_q2":"Problem sich zu waschen oder anzuziehen",
  "patient_hand_quality_of_life_q2_a1":"kein Problem",
  "patient_hand_quality_of_life_q2_a2":"leichtes",
  "patient_hand_quality_of_life_q2_a3":"mäßiges",
  "patient_hand_quality_of_life_q2_a4":"schweres",
  "patient_hand_quality_of_life_q2_a5":"extremes/unmöglich",
  "patient_hand_quality_of_life_q3":"Probleme mit üblichen Aktivitäten (z. B. Arbeit, Studium, Hausarbeit, Familie oder Freizeitaktivitäten)",
  "patient_hand_quality_of_life_q3_a1":"kein Problem",
  "patient_hand_quality_of_life_q3_a2":"leichte",
  "patient_hand_quality_of_life_q3_a3":"mäßige",
  "patient_hand_quality_of_life_q3_a4":"schwere",
  "patient_hand_quality_of_life_q3_a5":"extremes/unmöglich",
  "patient_hand_quality_of_life_q4":"Schmerzen oder Beschwerden",
  "patient_hand_quality_of_life_q4_a1":"keine",
  "patient_hand_quality_of_life_q4_a2":"leichte",
  "patient_hand_quality_of_life_q4_a3":"mäßige",
  "patient_hand_quality_of_life_q4_a4":"schwere",
  "patient_hand_quality_of_life_q4_a5":"extreme",
  "patient_hand_quality_of_life_q5":"Angstzustände/Depression",
  "patient_hand_quality_of_life_q5_a1":"nicht ängstlich/deprimiert",
  "patient_hand_quality_of_life_q5_a2":"leichte",
  "patient_hand_quality_of_life_q5_a3":"mäßige",
  "patient_hand_quality_of_life_q5_a4":"schwer ängstlich/deprimiert“",
  "patient_hand_quality_of_life_q5_a5":"extrem ängstlich/deprimiert",
  "patient_hand_quality_of_life_q6":"Wie gut oder schlecht ist Ihre Gesundheit HEUTE? (100 bedeutet beste Gesundheit 0 bedeutet schlechteste Gesundheit, die Sie sich vorstellen können)",
  "patient_hand_quality_of_life_q6_a1":"am schlimmsten",
  "patient_hand_quality_of_life_q6_a2":"am besten",
  "patient_hand_satisfaction":"Zufriedenheit",
  "patient_hand_satisfaction_instrucion":"Bitte geben Sie an, wie zufrieden Sie mit dem folgenden sind:",
  "patient_hand_satisfaction_instrucion_0":"sehr unzufrieden",
  "patient_hand_satisfaction_instrucion_1":"unzufrieden",
  "patient_hand_satisfaction_instrucion_2":"weder zufrieden noch unzufrieden",
  "patient_hand_satisfaction_instrucion_3":"etwas zufrieden",
  "patient_hand_satisfaction_instrucion_4":"sehr zufrieden",
  "patient_hand_satisfaction_q1":"Bewegung Ihres FINGERS",
  "patient_hand_satisfaction_q1_na":"unzutreffend",
  "patient_hand_satisfaction_q1_a1":"sehr unzufrieden",
  "patient_hand_satisfaction_q1_a2":"unzufrieden",
  "patient_hand_satisfaction_q1_a3":"weder zufrieden noch unzufrieden",
  "patient_hand_satisfaction_q1_a4":"etwas zufrieden",
  "patient_hand_satisfaction_q1_a5":"sehr zufrieden",
  "patient_hand_satisfaction_q2":"Bewegung Ihres HANDGELENKS",
  "patient_hand_satisfaction_q2_na":"unzutreffend",
  "patient_hand_satisfaction_q2_a1":"sehr unzufrieden",
  "patient_hand_satisfaction_q2_a2":"unzufrieden",
  "patient_hand_satisfaction_q2_a3":"weder zufrieden noch unzufrieden",
  "patient_hand_satisfaction_q2_a4":"etwas zufrieden",
  "patient_hand_satisfaction_q2_a5":"sehr zufrieden",
  "patient_hand_satisfaction_q3":"Aussehen Ihrer HAND",
  "patient_hand_satisfaction_q3_na":"unzutreffend",
  "patient_hand_satisfaction_q3_a1":"sehr unzufrieden",
  "patient_hand_satisfaction_q3_a2":"unzufrieden",
  "patient_hand_satisfaction_q3_a3":"weder zufrieden noch unzufrieden",
  "patient_hand_satisfaction_q3_a4":"etwas zufrieden",
  "patient_hand_satisfaction_q3_a5":"sehr zufrieden",
  "patient_hand_satisfaction_q4":"Ihre Hand-/Handgelenkbehandlung",
  "patient_hand_satisfaction_q4_na":"unzutreffend",
  "patient_hand_satisfaction_q4_a1":"sehr unzufrieden",
  "patient_hand_satisfaction_q4_a2":"unzufrieden",
  "patient_hand_satisfaction_q4_a3":"weder zufrieden noch unzufrieden",
  "patient_hand_satisfaction_q4_a4":"etwas zufrieden",
  "patient_hand_satisfaction_q4_a5":"sehr zufrieden",
  "patient_hand_complete":"Ende des Fragebogens",
  "patient_hand_complete_text1":"Vielen Dank, dass Sie sich die Zeit genommen haben, dieses Formular auszufüllen.",
  "patient_hand_complete_text2":"Bitte klicken Sie auf „Senden“, um den Vorgang abzuschließen und Ihre Ergebnisse anzuzeigen.",
  "patient_hand_complete_text3":"Ihre Antworten werden vertraulich behandelt und in allen Berichten werden nur anonymisierte Informationen verwendet",
  "patient_hand_result":"Ergebnisse",
  "patient_hand_result_text1":"Nachfolgend finden Sie Ihre Schmerz- und Hand- und Handgelenksfunktionswerte, die anhand Ihres Fragebogens berechnet wurden.",
  "patient_hand_result_function":"Funktion",
  "patient_hand_result_pain":"Schmerz",
  "patient_hand_result_health_perception":"Allgemeine Gesundheitswahrnehmung",
  "patient_hand_result_self_evaluation":"Hand- und Handgelenkselbstbewertung",
  "patient_hand_result_text2":"Ihr Arzt wird in Kürze bei Ihnen sein.",
  "patient_general_history":"GESCHICHTE DER AKTUELLEN BESCHWERDEN/VERLETZUNG/KRANKHEIT",
  "patient_general_history_q1":"Hauptbeschwerde (erklären Sie Ihre Beschwerde mit eigenen Worten)",
  "patient_general_history_q2":"Datum wenn das Problem began",
  "patient_general_history_q3":"Hatten Sie irgendeine Bildaufnahme?",
  "patient_general_history_q3_a1":"keine",
  "patient_general_history_q3_a2":"Röntgen",
  "patient_general_history_q3_a3":"Ultraschall",
  "patient_general_history_q3_a4":"MRT",
  "patient_general_history_q3_a5":"MRT-Arthrogramm (MRT mit Farbstoff im Gelenk)",
  "patient_general_history_q3_a6":"CT",
  "patient_general_history_q3_a7":"CT-Arthrogramm (CT mit Farbstoff im Gelenk)",
  "patient_general_history_q4":"Hatten Sie weitere Untersuchungen zu Ihrem Zustand, die oben nicht aufgeführt sind?",
  "patient_general_history_q4_placeholder":"Andere Untersuchungen",
  "patient_general_past_history":"VERGANGENE MEDIZINISCHE ANAMNESE / AKTUELLE MEDIZINISCHE PROBLEME",
  "patient_general_past_history_q1":"Haben Sie Allergien? (Arzneimittel oder Nahrung)",
  "patient_general_past_history_q1_a1":"nein",
  "patient_general_past_history_q1_a2":"ja",
  "patient_general_past_history_placeholder":"Geben Sie Allergie und Art der Reaktion an",
  "patient_general_past_history_q2":"Sind Sie allergisch gegen Latex?",
  "patient_general_past_history_q2_a1":"nein",
  "patient_general_past_history_q2_a2":"ja",
  "patient_general_past_history_q3":"Haben Sie Bluthochdruck oder werden Sie wegen Bluthochdruck behandelt?",
  "patient_general_past_history_q3_a1":"nein",
  "patient_general_past_history_q3_a2":"ja",
  "patient_general_past_history_q3_a2_placeholder":"Geben Sie Medikamente gegen Bluthochdruck an",
  "patient_general_past_history_q4":"Leiden Sie derzeit oder in der Vergangenheit an Herzbeschwerden/-erkrankungen?",
  "patient_general_past_history_q4_a1":"nein",
  "patient_general_past_history_q4_a2":"ja",
  "patient_general_past_history_q4_a2_o1":"Kondition auswählen",
  "patient_general_past_history_q4_a2_o2":"Angina",
  "patient_general_past_history_q4_a2_o3":"Herzinfarkt",
  "patient_general_past_history_q4_a2_o4":"Herzinsuffizienz (Flüssigkeit in Ihrer Lunge)",
  "patient_general_past_history_q4_a2_o5":"Herzgeräusch oder Herzklappenproblem",
  "patient_general_past_history_q4_a2_o6":"Herzklopfen/unregelmäßiger Herzschlag",
  "patient_general_past_history_q4_a2_o7":"Implantiertes Gerät (ICD, Herzschrittmacher)",
  "patient_general_past_history_q4_a2_o8":"Herzchirugie",
  "patient_general_past_history_q4_a2_o9":"Chirurgie der Halsschlagader",
  "patient_general_past_history_q4_a2_o10":"andere Herzerkrankung",
  "patient_general_past_history_q4_a2_o8_q1_placeholder":"specifiziere Herzchirurgie",
  "patient_general_past_history_q4_a2_o10_q1_placeholder":"spezifiziere andere Herzerkrankung",
  "patient_general_past_history_q5":"Haben Sie einen Kardiologen?",
  "patient_general_past_history_q5_a1":"nein",
  "patient_general_past_history_q5_a2":"ja",
  "patient_general_past_history_q5_a2_placeholder":"Name und Praxis",
  "patient_general_past_history_q6":"Haben Sie Durchblutungsstörungen in den Beinen (periphere Gefäßerkrankung)?",
  "patient_general_past_history_q6_a1":"nein",
  "patient_general_past_history_q6_a2":"ja",
  "patient_general_past_history_q6_a2_placeholder":"beschreibe",
  "patient_general_past_history_q7":"Haben Sie im letzten Monat einen Blutverdünner eingenommen?",
  "patient_general_past_history_q7_a1":"nein",
  "patient_general_past_history_q7_a2":"ja",
  "patient_general_past_history_q7_a2_q1":"Welchen?",
  "patient_general_past_history_q7_a2_q1_a1":"Aspirin",
  "patient_general_past_history_q7_a2_q1_a2":"Clopidogrel/Plavix",
  "patient_general_past_history_q7_a2_q1_a3":"Rivaroxaban",
  "patient_general_past_history_q7_a2_q1_a4":"Eliquis",
  "patient_general_past_history_q7_a2_q1_a5":"andere",
  "patient_general_past_history_q7_a2_q1_a5_placeholder":"spezifiziere Blutverdünner",
  "patient_general_past_history_q8":"Hatten Sie Probleme mit Ihrer Lunge oder Ihrem Brustkorb?",
  "patient_general_past_history_q8_a1":"nein",
  "patient_general_past_history_q8_a2":"ja",
  "patient_general_past_history_q8_a2_o1":"Husten mit Auswurf?",
  "patient_general_past_history_q8_a2_o2":"Haben Sie Probleme mit der Atmung?",
  "patient_general_past_history_q8_a2_o3":"andere",
  "patient_general_past_history_q8_a2_o2_o1":"Asthma",
  "patient_general_past_history_q8_a2_o2_o2":"hoher Blutdruck in der Lunge (pulmonale Hypertonie",
  "patient_general_past_history_q8_a2_o2_o3":"Lungenfibrose",
  "patient_general_past_history_q8_a2_o2_o4":"Sarkoidose",
  "patient_general_past_history_q8_a2_o2_o5":"COPD oder Emphysem",
  "patient_general_past_history_q8_a2_o2_o6":"Mukoviszidose",
  "patient_general_past_history_q8_a2_o2_o7":"TB (Tuberkulose)",
  "patient_general_past_history_q8_a2_o2_o8":"andere",
  "patient_general_past_history_q8_a2_o3_placehold":"spezifiziere",
  "patient_general_past_history_q8_a2_o2_o8_placeholder":"spezifiziere",
  "patient_general_past_history_q9":"Haben Sie im letzten Jahr Steroide (Prednison oder Kortison) eingenommen?",
  "patient_general_past_history_q9_a1":"nein",
  "patient_general_past_history_q9_a2":"ja",
  "patient_general_past_history_q10":"Benutzen Sie Sauerstoff zu Hause?",
  "patient_general_past_history_q10_a1":"nein",
  "patient_general_past_history_q10_a2":"ja",
  "patient_general_past_history_q11":"Haben Sie einen Atemwegsspezialisten?",
  "patient_general_past_history_q11_a1":"nein",
  "patient_general_past_history_q11_a2":"ja",
  "patient_general_past_history_q11_a2_placeholder":"Name und Praxis",
  "patient_general_past_history_q12":"Leiden Sie unter übermäßigem Schnarchen oder Schlafapnoe?",
  "patient_general_past_history_q12_a1":"nein",
  "patient_general_past_history_q12_a2":"ja",
  "patient_general_past_history_q12_a2_q1":"Wird Ihre Schlafapnoe mit CPAP oder BiPAP behandelt?",
  "patient_general_past_history_q12_a2_q1_a1":"nein",
  "patient_general_past_history_q12_a2_q1_a2":"ja",
  "patient_general_past_history_q13":"Hatten Sie jemals Leberprobleme?",
  "patient_general_past_history_q13_a1":"nein",
  "patient_general_past_history_q13_a2":"ja",
  "patient_general_past_history_q13_a2_o1":"ausgewählt",
  "patient_general_past_history_q13_a2_o2":"Zirrhose",
  "patient_general_past_history_q13_a2_o3":"Hepatitis A, B oder C",
  "patient_general_past_history_q13_a2_o4":"Gelbsucht",
  "patient_general_past_history_q13_a2_o5":"andere",
  "patient_general_past_history_q13_a2_o5_placeholder":"spezifiziere",
  "patient_general_past_history_q14":"Leiden Sie häufig unter Sodbrennen, Geschwüren oder Hiatushernien?",
  "patient_general_past_history_q14_a1":"nein",
  "patient_general_past_history_q14_a2":"ja",
  "patient_general_past_history_q15":"Hatten Sie jemals eine Nierenerkrankung?",
  "patient_general_past_history_q15_a1":"nein",
  "patient_general_past_history_q15_a2":"ja",
  "patient_general_past_history_q15_a2_q1":"Sind Sie Dialysepatient? Peritoneal? Hämodialyse",
  "patient_general_past_history_q15_a2_q1_a1":"nein",
  "patient_general_past_history_q15_a2_q1_a2":"ja",
  "patient_general_past_history_q15_a2_placeholder":"Geben Sie eine Nierenerkrankung an",
  "patient_general_past_history_q15_a2_q1_a2_placeholder":"Geben Sie Art und Zeitplan der Dialyse an",
  "patient_general_past_history_q16":"Haben Sie Diabetes?",
  "patient_general_past_history_q16_a1":"nein",
  "patient_general_past_history_q16_a2":"ja",
  "patient_general_past_history_q16_a2_q1":"Wie wird Ihr Diabetes behandelt? Diät/orale Medikamente oder Insulin?",
  "patient_general_past_history_q16_a2_q1_placeholder":"spezifiziere",
  "patient_general_past_history_q17":"Haben Sie Schilddrüsenprobleme?",
  "patient_general_past_history_q17_a1":"nein",
  "patient_general_past_history_q17_a2":"ja",
  "patient_general_past_history_q18":"Hatten Sie jemals Epilepsie, Krampfanfälle oder Krämpfe?",
  "patient_general_past_history_q18_a1":"nein",
  "patient_general_past_history_q18_a2":"ja",
  "patient_general_past_history_q18_a2_q1":"Wann war Ihr letzter Anfall?",
  "patient_general_past_history_q18_a2_q1_placeholder":"Wann?",
  "patient_general_past_history_q19":"Hatten Sie Probleme mit Ihren Nerven oder Muskeln?",
  "patient_general_past_history_q19_a1":"nein",
  "patient_general_past_history_q19_a2":"ja",
  "patient_general_past_history_q19_a2_o1":"Anfälle",
  "patient_general_past_history_q19_a2_o1_placeholder":"Wann?",
  "patient_general_past_history_q19_a2_o2":"TIA (transiente ischämische Attacke/Minischlaganfall) oder Schlaganfall",
  "patient_general_past_history_q19_a2_o2_placeholder":"Wann?",
  "patient_general_past_history_q19_a2_o3":"Gesichts-, Bein- oder Armschwäche",
  "patient_general_past_history_q19_a2_o4":"Neurologische Störung (Beispiele: Multiple Sklerose, ALS, Alzheimer)",
  "patient_general_past_history_q19_a2_o4_placeholder":"spezifiziere",
  "patient_general_past_history_q19_a2_o5":"Muskelerkrankung (Beispiele: Myasthenia gravis, Muskeldystrophie)",
  "patient_general_past_history_q19_a2_o5_placeholder":"spezifiziere",
  "patient_general_past_history_q19_a2_o6":"Probleme mit dem Hören, Sehen oder Gedächtnis",
  "patient_general_past_history_q19_a2_o7":"Chronischer Schmerz",
  "patient_general_past_history_q19_a2_o8":"Andere",
  "patient_general_past_history_q19_a2_o8_placeholder":"spezifiziere",
  "patient_general_past_history_q20":"Haben Sie Arthritis? Arthrose? Rheumatoide Arthritis oder anderes?",
  "patient_general_past_history_q20_a1":"nein",
  "patient_general_past_history_q20_a2":"ja",
  "patient_general_past_history_q20_a2_o1":"Haben Sie Arthritis? Arthrose? Rheumatoide Arthritis oder anderes?",
  "patient_general_past_history_q20_a2_o2":"Rheumatoide Arthritis",
  "patient_general_past_history_q20_a2_o3":"Andere",
  "patient_general_past_history_q20_a2_o3_placeholder":"spezifiziere",
  "patient_general_past_history_q21":"Waren Sie schon einmal wegen einer psychiatrischen Erkrankung in Behandlung? Typ?",
  "patient_general_past_history_q21_a1":"nein",
  "patient_general_past_history_q21_a2":"ja",
  "patient_general_past_history_q21_a2_o1":"schwere Angstzustände",
  "patient_general_past_history_q21_a2_o2":"Depression",
  "patient_general_past_history_q21_a2_o3":"Andere",
  "patient_general_past_history_q21_a2_o3_placeholder":"spezifiziere",
  "patient_general_past_history_q22":"Wurde bei Ihnen jemals eine Blutgerinnungsstörung diagnostiziert?",
  "patient_general_past_history_q22_a1":"nein",
  "patient_general_past_history_q22_a2":"ja",
  "patient_general_past_history_q22_a2_o1":"Leukämie oder Lymphom",
  "patient_general_past_history_q22_a2_o2":"Hämophilie",
  "patient_general_past_history_q22_a2_o3":"Blutgerinnsel",
  "patient_general_past_history_q22_a2_o4":"Anämie",
  "patient_general_past_history_q22_a2_o5":"Sichelzellenanämie",
  "patient_general_past_history_q22_a2_o6":"Andere",
  "patient_general_past_history_q22_a2_o6_placeholder":"spezifiziere",
  "patient_general_past_history_q23":"Sind Sie anämisch?",
  "patient_general_past_history_q23_a1":"nein",
  "patient_general_past_history_q23_a2":"ja",
  "patient_general_past_history_q24":"Hatten Sie jemals ein Blutgerinnsel in Ihren Beinen oder Ihrer Lunge?",
  "patient_general_past_history_q24_a1":"nein",
  "patient_general_past_history_q24_a2":"ja",
  "patient_general_past_history_q25":"Hatten Sie oder jemand in Ihrer Familie ernsthafte Probleme mit:",
  "patient_general_past_history_q25_a1":"Nasenbluten",
  "patient_general_past_history_q25_a1_o1":"nein",
  "patient_general_past_history_q25_a1_o2":"ja",
  "patient_general_past_history_q25_a2":"Blutungen bei Zahnextraktionen",
  "patient_general_past_history_q25_a2_o1":"nein",
  "patient_general_past_history_q25_a2_o2":"ja",
  "patient_general_past_history_q25_a3":"Blutungen nach der Operation",
  "patient_general_past_history_q25_a3_o1":"nein",
  "patient_general_past_history_q25_a3_o2":"ja",
  "patient_general_past_history_q26":"Wurde bei Ihnen Krebs diagnostiziert?",
  "patient_general_past_history_q26_a1":"nein",
  "patient_general_past_history_q26_a2":"ja",
  "patient_general_past_history_q26_a2_placeholder1":"Geben Sie die Krebsart an",
  "patient_general_past_history_q26_a2_placeholder2":"Wann?",
  "patient_general_past_history_q27":"Wurden Sie jemals mit einer Chemotherapie oder Strahlentherapie behandelt?",
  "patient_general_past_history_q27_a1":"nein",
  "patient_general_past_history_q27_a2":"ja",
  "patient_general_past_history_q27_a2_placeholder1":"Name der Behandlung",
  "patient_general_past_history_q27_a2_placeholder2":"Letztes Behandlungsdatum",
  "patient_general_past_history_q28":"Hatten Sie oder Ihr Angehöriger Probleme mit der Anästhesie?",
  "patient_general_past_history_q28_a1":"nein",
  "patient_general_past_history_q28_a2":"ja",
  "patient_general_past_history_q28_a2_q1":"Welche Probleme?",
  "patient_general_past_history_q28_a2_q1_placeholder":"Wähle aus",
  "patient_general_past_history_q28_a2_q1_o1":"Starke Übelkeit oder Erbrechen nach der Operation",
  "patient_general_past_history_q28_a2_q1_o2":"Maligne Hyperthermie (bei blutsverwandten Familienangehörigen oder bei Ihnen selbst)",
  "patient_general_past_history_q28_a2_q1_o3":"Ernährungsproblem oder Schwierigkeiten beim Einführen des Anästhesie-Atemschlauchs?",
  "patient_general_past_history_q28_a2_q1_o4":"Probleme bei der Platzierung eines Beatmungsschlauchs",
  "patient_general_past_history_q28_a2_q1_o5":"Andere",
  "patient_general_past_history_q28_a2_q1_o5_placeholder":"spezifiziere",
  "patient_general_past_history_q29":"Haben Sie Folgendes?",
  "patient_general_past_history_q29_q1":"Abgebrochene oder lockere Zähne, Zahnprothesen, Teilprothesen",
  "patient_general_past_history_q29_q1_a1":"nein",
  "patient_general_past_history_q29_q1_a2":"ja",
  "patient_general_past_history_q29_q2":"Probleme beim Bewegen des Halses",
  "patient_general_past_history_q29_q2_a1":"nein",
  "patient_general_past_history_q29_q2_a2":"ja",
  "patient_general_past_history_q29_q3":"Probleme beim Öffnen des Mundes",
  "patient_general_past_history_q29_q3_a1":"nein",
  "patient_general_past_history_q29_q3_a2":"ja",
  "patient_general_past_history_q30":"Könnten Sie schwanger sein?",
  "patient_general_past_history_q30_a1":"nein",
  "patient_general_past_history_q30_a2":"ja",
  "patient_general_past_history_q30_not_applicable":"unzutreffend",
  "patient_general_past_history_q30_a2_q1":"Letzte Menstruation",
  "patient_general_past_history_q30_a2_q1_placeholder":"Letzte Menstruation",
  "patient_general_past_history_q31":"Krankengeschichte: Bitte geben Sie alle weiteren medizinischen Erkrankungen an, die Sie haben und die oben nicht aufgeführt sind",
  "patient_general_past_history_q31_q1":"Listen Sie alle Medikamente auf (einschließlich rezeptfreier Medikamente, Inhalatoren, Pflaster, Tropfen, Vitamine, Mineralien, Nahrungsergänzungsmittel und Kräuter).",
  "patient_general_past_history_q31_q2":"Geben Sie den Namen des Medikaments, die Dosis, die Häufigkeit und den Grund für die Einnahme an",
  "patient_general_past_history_q31_q2_placeholder":"Name des Medikaments, Dosis, Häufigkeit, Grund der Einnahme",
  "patient_general_past_history_q31_q3":"Dose",
  "patient_general_past_history_q31_q4":"oft",
  "patient_general_past_history_q31_q5":"Grund der Einnahme",
  "patient_general_past_history_q31_q6":"Chirurgische Geschichte",
  "patient_general_past_history_q31_q6_placeholder":"Geben Sie den Eingriff und das Eingriffsdatum an",
  "patient_general_social_history":"SOZIALGESCHICHTE",
  "patient_general_social_history_q1":"Leben Sie allein?",
  "patient_general_social_history_q1_a1":"nein",
  "patient_general_social_history_q1_a2":"ja",
  "patient_general_social_history_q1_a1_q1":"Wer lebt mit Ihnen?",
  "patient_general_social_history_q1_a1_q1_placeholder":"Wer lebt mit Ihnen?",
  "patient_general_social_history_q2":"Rauchen Sie?",
  "patient_general_social_history_q2_a1":"nein",
  "patient_general_social_history_q2_a2":"ja",
  "patient_general_social_history_q2_a2_q1":"Wie viele Zigaretten am Tag?",
  "patient_general_social_history_q2_a2_q1_placeholder":"Anzahl der Zigaretten",
  "patient_general_social_history_q2_a2_q2":"Anzahl von Jahren?",
  "patient_general_social_history_q2_a2_q2_placeholder":"Jahre",
  "patient_general_social_history_q3":"Haben Sie in der Vergangenheit geraucht?",
  "patient_general_social_history_q3_a1":"nein",
  "patient_general_social_history_q3_a2":"ja",
  "patient_general_social_history_q3_a2_q1":"Wann haben Sie mit dem Rauchen aufgehört?",
  "patient_general_social_history_q3_a2_q1_placeholder":"Wann haben Sie mit dem Rauchen aufgehört?",
  "patient_general_social_history_q3_a2_q2_placeholder":"Jahre",
  "patient_general_social_history_q4":"Trinken sie Alkohol?",
  "patient_general_social_history_q4_a1":"nein",
  "patient_general_social_history_q4_a2":"ja",
  "patient_general_social_history_q4_a2_q1_placeholder":"Anzahl der Standardgetränke pro Woche",
  "patient_general_social_history_q5":"Nehmen Sie Freizeit- oder Straßendrogen (illegale Drogen), Marihuana oder Opioide, die Ihnen nicht verschrieben wurden, oder haben Sie dies jemals getan?",
  "patient_general_social_history_q5_a1":"nein",
  "patient_general_social_history_q5_a2":"ja",
  "patient_general_social_history_q5_a2_placeholder":"Geben Sie Medikamente an",
  "patient_general_complete":"Ende des Fragebogens",
  "patient_general_complete_text1":"Vielen Dank, dass Sie sich die Zeit genommen haben, dieses Formular auszufüllen.",
  "patient_general_complete_text2":"Bitte klicken Sie auf „Senden“, um den Vorgang abzuschließen und Ihre Ergebnisse anzuzeigen.",
  "patient_general_complete_text3":"Ihre Antworten werden vertraulich behandelt und in allen Berichten werden nur anonymisierte Informationen verwendet",
  "AppVersion":"App Version",
  "Complete_personal_info":"Vollständige Persönliche Daten",
  "To_complete_the_sign_up":"Um die Anmeldung abzuschließen, geben SIE bitte unten Ihre Daten ein.",
  "Select_Existing_Profile":"Wählen Sie ein existierendes Profil",
  "Get_In":"Anmelden",
  "Create_new_patient":"Einen neuen Patienten erstellen.",
  "myProfile":"Mein Profil",
  "First_Name":"Geburtsname",
  "Last_Name":"Nachname",
  "DOB":"Geburtsdatum",
  "Sex":"Geschlecht",
  "Address":"Adresse",
  "Postcode":"Postleitzahl",
  "Suburb":"Stadt",
  "Country_Code":"Landesvorwahl",
  "Mobile_Phone":"Mobiltelefon",
  "Home_Phone":"Festnetz",
  "Email":"Email",
  "Emergency_Contact":"Kontakt für Notfälle",
  "Phone":"Telefon",
  "Relationship":"Beziehung",
  "Regular_GP_Name":"Name des Hausarztes",
  "Practice_Name":"Hausarzt Praxisname",
  "Medicare_Card_Number":"Gesetzliche Krankenversicherungsnummer",
  "Ref":"Bezug",
  "Message":"Nachricht",
  "Expiry":"Ablauf",
  "Private_Health_Fund":"Privater Krankenversicherer",
  "Membership_Number":"Private Krankenversicherungsnummer",
  "Claim_Type":" ",
  "Work_Cover":" ",
  "Employer":"Arbeitgeber",
  "Third_Party_Claim_Number":" ",
  "Third_Party_Name":" ",
  "DVA_Concession_Card":" ",
  "Number":"Nummer",
  "Defence_Personnel":"Personal der Streitkräfte",
  "I_agree":"Ich stimme der Verarbeitung meiner personenbezogenen Daten zu und habe die gelesen",
  "Privacy_Policy":"Datenschutzbestimmungen",
  "visitClinic":"Vereinbare Termin",
  "selectdocOrg":"Wählen Sie Arzt & Organisation",
  "doctorOrganization":"Arztorganisation",
  "Select_Treatment_Category":"Behandlungskategorie wählen",
  "Do_you_want_to_submit_patient_form_or_not":"Wollen Sie das Patientenformular einreichen oder nicht?",
  "areyousurewanttocontinueyourform":"Ihr vorheriges Formular steht noch aus. Möchten Sie fortfahren?",
  "myVisit":"Frühere Besuche",
  "View_score":"Zeige Score an",
  "upcoming_visits":"Vereinbarte Termine",
  "No_data_found":"Keine Daten gefunden",
  "Doctor_Name":"Name des Arztes",
  "Appointment_Date_Time":"Datum und Uhrzeit des Termins",
  "Clinic_Name":"Klinikname",
  "Clinic_Address":"Klinikadresse",
  "akmed":"Akuna Med",
  "Type":"Typ",
  "Date_Time":"Datum & Zeit",
  "Action":"Aktion",
  "View":"Ansicht",
  "No_Messages":"Keine Nachrichten",
  "settings":"Einstellungen",
  "Language":"Sprache",
  "language":"Sprache & Region",
  "timezone":"Zeitzone",
  "Timeformat":"Zeitformat",
  "List_of_my_profile":"Meine Profile",
  "next_button":"weiter",
  "back_button":"zurück",
  "Continue":"weiter",
  "Close":"schliesse",
  "submit":"einreichen",
  "Sign_out":"zurück zum Profil",
  "Results":"Ergebnisse",
  "update":"Formularaktualisierung erfolgreich",
  "Personal_Data":"Persönliche Daten",
  "Date_of_Birth":"Geburtsdatum",
  "Hip_arthritis":"Hüftarthrose ?*",
  "Area_of_foot_ankle":"Betroffener Bereich des Fußes/Knöchels?*",
  "Diagnosis":"Diagnose ?*",
  "Evaluated_Shoulder":"Bewertete Schulter",
  "Dominant_Hand":"Bevorzugte Hand*",
  "Age":"Alter",
  "No":"nein",
  "Yes":"ja",
  "visitNo":"BesuchNein",
  "visitYes":"BesuchJA",
  "shoulderPainR":"SchulterschmerzenR",
  "Is_it_initial_visit":"Ist es Ihr erster Besuch bei",
  "for_the_problem":"für das Problem ?*",
  "Diagnosis_Procedure":"Diagnose / Prozedur",
  "Procedure":"Prozedur",
  "Evaluated_sdfs":"Betroffene Extremität*",
  "Please_Select_previous_visits":"Bitte wählen Sie frühere Besuche aus*",
  "Follow_Up":"Nachverfolgung",
  "Assessment_Date":"Bewertungsdatum",
  "Do_you_have_Dupuytren_disease":"Haben Sie Morbus Dupuytren?",
  "Do_you_have_carpal_tunnel_syndrome":"Haben Sie ein Karpaltunnelsyndrom?",
  "Is_your_unaffected_shoulder_pain":"Ist Ihre nicht betroffene Schulter schmerzfrei und erlaubt eine normale Funktion?",
  "Is_your_unaffected_hip_pain":"Ist Ihre nicht betroffene Hüfte schmerzfrei und erlaubt normale Funktion?",
  "Is_your_unaffected_knee_pain":"Ist Ihr nicht betroffenes Knie schmerzfrei und erlaubt normale Funktion?",
  "Is_your_unaffected_elbow_pain":"Ist Ihr nicht betroffener Ellenbogen schmerzfrei und erlaubt normale Funktion?",
  "Is_your_unaffected_foot_and_ankle_pain":"Ist Ihr nicht betroffener Fuß und Knöchel schmerzfrei und erlaubt normale Funktion?",
  "Is_your_unaffected_hand_pain":"Ist Ihre nicht betroffene Hand und Ihr Handgelenk schmerzfrei und erlauben eine normale Funktion?",
  "Patient_Consent":"Akunah Patienteninformation- und Einwilligungserklärung",
  "Patient_Last_Name":"Nachname des Patienten",
  "Patient_First_Name":"Vorname des Patienten",
  "Patient_Date_of_Birth":"Geburtsdatum des Patienten",
  "information":"Information",
  "I_am_informedDDD":"Für Zwecke der Auswertung, Planung, und Ergebnisbewertung Ihrer orthopädischen Behandlung, Ihr Arzt, ",
  "Clinician":"(im Folgenden \"Arzt\") verwendet von Akunah Medical Technology Pty Ltd und seinen Tochtergesellschaften („Akunah“) bereitgestellte Auswertung-, Erfassung- und Analyse von Ergebnisdaten- und/oder präoperative Planungsdienste und Software.",
  "For_that":"Für diesen Zweck wird Ihr Arzt Ihre persönlichen Daten zu Akunah übermitteln, einschließlich Computertomographie (CT)-Scans Ihres Körpers und anderer medizinische Daten.",
  "Akunah_softawre":"Akunah‘s Software und Dienstleistungen können die Auswertung von medizinischen Bildern, Bewertung von medizinischen Daten, Erstellung personalisierter 3D-Modelle Ihrer Anatomie zusammen mit wichtigen anatomischen Messungen, Operationsplanung und Unterstützung bei der Operation umfassen und diese können mit dem Arzt geteilt werden.",
  "the_clinic":"Ihr Arzt wird die Auswertung und präoperativ Planung für Ihre orthopädische Behandlung nutzen.",
  "oportunnity":"Sie haben außerdem die Möglichkeit, über das Patientenportal von Akunah personenbezogene Daten einzugeben und auf die Modelle und Pläne von Akunah zuzugreifen.",
  "akunah_approch":"Akunah‘s Ansatz zu Privatsphäre Und Sicherheit",
  "akunah_approch1":"Ihre Privatsphäre und die Sicherheit der Daten, welche Akunah von Ihnen erfassen werden, um Ihren Arzt zu unterstützen, steht für uns an erster Stelle.",
  "akunah_approch2":"Akunah hält sich freiwillig an die geltenden europäischen Datenschutzgesetze, zu denen auch die EU-Datenschutz-Grundverordnung EU/2016/679 gehört, da diese Gesetze die strengsten weltweit erlassenen Datenschutzgesetze sind. Diese Gesetze gehen über die Datenschutzgesetze Australiens, der Vereinigten Staaten und anderer Länder hinaus.",
  "akunah_approch3":"Akunah’s „Privacy Code for Processing Personal Information of Customer Individuals” („Datenschutzkodex für die Verarbeitung personenbezogener Daten von Kunden“) beschreibt wie Akunah die Europäischem Datenschutzgesetze erfüllt (\"",
  "akunah_approch4":"„Privacy Code“/ „Datenschutzkodex“).",
  "akunah_approch5":"Akunah‘s „Data Processing Agreement“ („Datenverarbeitungsvereinbarung“) beschreibt die Vereinbarungen, die Akunah mit seinen Ärzten zum Schutz und zur Sicherung Ihrer persönlichen Daten trifft (\"",
  "akunah_approch6":"„Data Processing Agreement“).",
  "akunah_approch7":"Diese Richtlinien sind in englischer Sprache auf der Akunah Webseite verfügbar:",
  "terms_":"https://akunah.com/termsofservices.",
  "akunah_approch8":"Im Falle von Widersprüchen zwischen dem Datenschutzkodex, der Datenverarbeitungsvereinbarung und diesem Dokument gelten die Bedingungen des erstgenannten Dokuments, für jegliche Inkonsistenz mit diesem Dokument.",
  "Data_Collection_and_Processing":"Datenerhebung und -verarbeitung",
  "I_am_informed":"Akunah wird möglicherweise Ihre Daten erfassen, einschließlich Informationen zu Ihren Kontaktinformationen, Authentifizierung, Identifikation, Beruf und Demografie. Akunah wird möglicherweise auch medizinische Daten über Ihrer Gesundheit sammeln, wie zum Beispiel",
  "Assessment_and_evaluation":"• Bewertungs- und Bewertungsdaten, körperliche Merkmale und andere Informationen, die für die vorgeschlagenen Behandlungen und Ergebnisse relevant sind;",
  "Previous_surgery":"• Frühere Operationen, Diagnosen, Röntgenbilder, 3D-Modelle, Anatomie, anatomische Messungen; und",
  "Health_and_medical_history":"• Gesundheits- und Krankengeschichte, einschließlich Ihrer Symptome, Medikamente und früherer Diagnosen und Behandlungen, die Ihnen verabreicht wurden.",
  "I_have_been_assured":"Akunah erhebt die Informationen stets auf rechtmäßige und faire Weise, sei es direkt von einem Patienten, von einem Arzt weitergegeben oder über die Akunah-Produkte. Akunah wird diese Daten in Übereinstimmung mit Ihrer nachstehend zum Ausdruck gebrachten Einwilligung verarbeiten, um bei Bedarf die Erbringung der Auswertung und der präoperativen Planungsdienstleistung zu ermöglichen.",
  "My_data":"Darüber hinaus kann Akunah Ihre personenbezogenen Daten verwenden, um alle geltenden Gesetze und Vorschriften einzuhalten.",
  "your_data":"Ihre Daten können zu dritten Parteien offengelegt werden, falls erforderlich um die vorgeschlagen Dienstleistungen zu erbringen oder wo gesetzlich vorgeschrieben.",
  "Data_Access_and_Storage":"Datenzugang, Speicherung und Sicherheit",
  "Personal_data_provided":"Die von Ihnen und Ihrem Arzt bereitgestellten Daten sind nur zugänglich für diejenigen Mitarbeiter von Akunah und Dritter Parteien, die zu Ihrer Behandlung oder Genesung beitragen (z. B. Anästhesist, Physiotherapeut) und Zugriff auf Ihre Daten benötigen.",
  "data1":"Die über die Software von Akunah eingegebenen Daten werden im Commonwealth of Australia bei einem autorisierten Data-Hosting Anbieter gespeichert. Dies stellt eine angemessene und angebrachte Stufe für Vertraulichkeit und Sicherheit sicher, widerspiegelnd die Art Ihrer Daten, die mit der Akunah-Software verarbeitet werden",
  "data2":"Falls Sie außerhalb des Commonwealths von Australien leben, und/oder Daten, die in die Akunah-Software eingegeben wurden, können von Akunah-Tochtergesellschaften mit Sitz außerhalb des Commonwealth of Australia, einschließlich der Europäischen Union oder den Vereinigten Staaten, zugegriffen werden. In diesen Ländern gelten möglicherweise unterschiedliche Datenschutzstandards für die Art und Weise, wie Ihre Daten verwendet und geschützt werden. In diesem Fall werden Ihre personenbezogenen Daten in diesem Land gespeichert.",
  "data3":"Akunah verfügt über angemessene Sicherheitsvorkehrungen gemäß allen geltenden gesetzlichen Anforderungen, um sicherzustellen, dass die Daten vertraulich behandelt und mit einem angemessenen Datenschutzniveau gegenüber allen Dritten geschützt werden. Wir verlangen von allen Zugangsberechtigten, dass sie Standards übernehmen, die ein zu dem Akunah Standard gleichwertiges Datenschutzniveau gewährleisten.",
  "data4":"Ihr Arzt teilt die Verantwortung für die Erfassung, den Zugriff, die Analyse und die Weitergabe Ihrer Daten gemäß den gesetzlichen Anforderungen.",
  "p_data1":"Ihre personenbezogenen Daten werden von Akunah für einen variablen Zeitraum gespeichert. Die Aufbewahrungsfrist wird anhand der folgenden Kriterien bestimmt: (i) dem Zweck, für den Akunah oder Ihr Arzt Ihre personenbezogenen Daten verwenden (beide müssen die Daten nur so lange aufbewahren, wie es für diesen Zweck erforderlich ist); und (ii) geltende Gesetze oder Vorschriften, die einen Mindestzeitraum festlegen können, für den Akunah Ihre personenbezogenen Daten aufbewahren muss.",
  "Deidentified_Data":"Anonymisiert Daten",
  "d_txt1":"Akunah kann Ihre Daten anonymisieren und diese anonymisierten Daten dann – normalerweise aggregiert mit anonymisierten Daten anderer Patienten – für Aufzeichnungszwecke, Forschung und Entwicklung, Datenanalyse, Software-/Geräteentwicklung und Verbesserung der Produkte und Dienstleistungen von Akunah verwenden. Dies ermöglicht Akunah, unsere Produkte und Dienstleistungen zum Nutzen von Patienten wie Sie kontinuierlich zu verbessern.",
  "Patient_Rights":"Ihre Rechte",
  "I_understand_that":"Es steht Ihnen frei, die Einwilligung zur Weitergabe Ihrer personenbezogenen Daten an Akunah zu verweigern. Bitte sprechen Sie in diesem Fall mit Ihrem Arzt, da Ihr Arzt Ihnen möglicherweise keine medizinischen Dienstleistungen oder Behandlungen anbieten kann, ohne die Software und Dienste von Akunah zu nutzen.",
  "I_understand_that_1txt":"Sie haben das Recht, Ihre Einwilligung jederzeit und mit allen Mitteln zu widerrufen, und gegebenenfalls haben Sie das Recht: (i) Informationen über die Verarbeitung Ihrer personenbezogenen Daten zu erhalten und auf diese personenbezogenen Daten zuzugreifen; (ii) ihre Korrektur zu beantragen, wo diese fehlerhaft sind; (iii) aufzufordern, dass die Verarbeitung Ihrer persönlich Daten eingeschränkt wird, einschließlich des Einspruchs gegen eine bestimmte Verarbeitung Ihrer personenbezogenen Daten und der Aufforderung zum Widerruf der Einwilligung die sie gegeben haben; (iv) aufzufordern, dass Ihre persönlich Daten gelöscht werden; (v) Richtlinien zur Verarbeitung Ihrer Daten nach Ihrem Tod festzulegen; und (vi) eine Kopie Ihrer unter den oben genannten Bedingungen verarbeiteten personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie können diese Rechte ausüben, indem Sie Ihre Anfrage direkt an Ihren Arzt richten.",
  "I_understand_that_2txt":"Ihre Rechte sind vorbehaltlich zutreffender gesetzlicher Ausnahmen und Einschränkungen.",
  "I_understand_that_3txt":"Nach dem Widerruf ist Ihr Arzt möglicherweise nicht in der Lage, Ihnen medizinische Leistungen oder Behandlungen anzubieten, wenn Sie diese Rechte vor dem vorgeschlagenen Eingriff ausüben.",
  "I_understand_that_4txt":"Sie können jederzeit eine Beschwerde über die Verarbeitung Ihrer persönlichen Daten bei der Aufsichtsbehörde in Ihrem Wohnsitzland einreichen.",
  "I_understand_that_5txt":"Im Hinblick auf anonymisierte Daten, anonymisierte Daten unterliegen nicht den gleichen rechtlichen Beschränkungen wie persönlich Daten. Beachten Sie bitte, dass Sie Ihre Zustimmung für Zugang und Verwendung Ihrer anonymisierten Daten nicht zurückziehen können, oder die Löschung dieser Daten einfordern können, weil es für Akunah nicht möglich ist, Ihre Daten zu finden. Nach Anonymisierung kann Akunah die Daten nicht mehr zu Ihnen zurückverfolgen.",
  "Contact":"Einwilligunserklärung",
  "I_am_able_to_contact":"Ich habe den obigen Informationsabschnitt gelesen und verstanden, oder jemand hat es mir in einer Sprache, die ich verstehe, vorgelesen.",
  "or_by_post":"Ich hatte die Gelegenheit Fragen zu stellen und ich bin zufrieden mit den Antworten, die ich erhalten habe.",
  "or_by_post2":"Ich erteile meinem Arzt,",
  "or_by_post2_1":"(dem „Arzt“) die Erlaubnis, meine personenbezogenen Daten an Akunah weiterzugeben.",
  "or_by_post3":"Ich erlaube, dass Akunah meine persönlichen und anonymisierte Daten sammelt, speichert, verwendet und teilt, wie im obigen Abschnitt „Informationen“ beschrieben.",
  "or_by_post4":"Ich verstehe, dass ich meine Zustimmung jederzeit widerrufen kann.",
  "or_by_post5":"Ich verstehe, dass ich eine unterzeichnete Kopie dieses Dokuments erhalten werde, die ich behalten kann.",
  "or_by_post6":"Ich kann Akunah bezüglich der Verarbeitung meiner Daten per E-Mail an",
  "info_akunah":"info@akunah.com",
  "or_by_post7":"oder per Post an Suite 307, Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD 4120, Australien, kontaktieren.",
  "Clear":"Löschen",
  "Date":"Datum",
  "Signed_by_Mouse":"Mit Maus unterschreiben *",
  "Name_if_consent_on_behalf_of_patient":"Name (bei Zustimmung im Namen des Patienten)",
  "Relationship_to_patient_if_applicable":"Beziehung zum Patienten, falls zutreffend",
  "Choose_Sign_Type":"Unterschriftstyp wählen*",
  "Draw":"Ziehe",
  "Signed_by_Keyboard":"MIt Tastatur unterzeichnet *",
  "Agreement":"Vereinbarung",
  "agree_that_I_have_read":"Ich stimme zu, dass ich diese Patienteneinwilligungserklärung gelesen und verstanden habe und dass ich der Verarbeitung meiner personenbezogenen Daten für die oben beschriebenen Zwecke ausdrücklich zustimme.",
  "NOTE":"NOTIZ",
  "If_the_patient":"Wenn der Patient jünger als 18 Jahre ist, muss dies von den Eltern oder Erziehungsberechtigten unterschrieben werden.",
  "If_the_patient_is_deceased":"Wenn der Patient verstorben ist, muss dies von seinen nächsten Angehörigen unterschrieben werden.",
  "patient_signature":"Unterschrift des Patienten",
  "profile_updated":"Profil aktualisiert",
  "profile_not_updated":"Profil nicht aktualisiert",
  "video":"Video",
  "radiology_form":"Radiologieformular",
  "doc_document":"Dokumentieren",
  "pathology_form":"Pathologieformular",
  "text":"Text",
  "Document_Name":"Dokumentname",
  "email_sent_successfully":"Löschanfrage erfolgreich gesendet.",
  "messages":"Mitteilungen",
  "adult_knee":"Knie für Erwachsene",
  "paediatric_knee":"Knie für Kinder",
  "document":"Meine Dokumente",
  "Check_in":"Anmelden",
  "info_icon":"Füllen Sie den Patientenfragebogen aus",
  "reminder":"Notiz",
  "Search_with_minimum_three_characters":"Suche mit mindestens drei Zeichen",
  "Select":"Wähle aus",
  "delete_account":"Account löschen",
  "DD_MM_YYYY":"DD / MM / JJJJ",
  "Female":"Weiblich",
  "Male":"Männlich",
  "want_to_disclose":"Möchte es nicht offenbaren",
  "enter_address":"Adresse eingeben",
  "logout":"Ausloggen",
  "full_name":"Vollständiger Name",
  "mmyy":"MM / JJ",
  "health_found":"Privater Krankenversicherer",
  "Not_Applicable":"Unzutreffend",
  "not_available":"Nicht verfügbar",
  "available":"Verfügbar",
  "shared":"Geteilt",
  "private":"Privat",
  "add_document":"Dokument hinzufügen",
  "drap_and_drop":"Ziehen Sie Dateien hierher und legen Sie sie dort ab",
  "or_browser_files":"oder finde Dateien auf Ihrem Computer",
  "browse_files":"Dateien durchsuchen",
  "download":"herunterladen",
  "Oops_error":"Hoppla! Die von Ihnen angeforderten Daten wurden nicht gefunden",
  "go_to_home":"Zurück zum Startbildschirm",
  "This_field_is_required":"Dieses Feld is obligatorisch.",
  "PendingForm":"Ausstehendes Formular",
  "NoFollowUpRecords":"Keine Folgeaufzeichnungen",
  "Right":"rechts",
  "Both":"Beide",
  "Left":"links",
  "shoulder":"Schulter",
  "hip_form":"Hüfte",
  "Foot_And_Ankle":"Fuß und Knöchel",
  "Hand_And_Wrist":"Hand und Handgelenk",
  "General":"Allgemein",
  "adolescent_knee":"Jugendliches Knie",
  "Elbow":"Ellbogen",
  "title":"Titel",
  "description":"Beschreibung",
  "enter_your_description_here":"Geben Sie hier Ihre Beschreibung ein",
  "enter_your_title_here":"Geben Sie hier Ihren Titel ein",
  "progress":"Ausstehend",
  "done":"Erledigt",
  "reset":"Zurücksetzen",
  "send":"Versenden",
  "Edit":"Bearbeiten",
  "delete":"Löschen",
  "Are_you_sure_want_to_done_the_remainder":"Sind Sie sicher, dass Sie diese Notiz vervollständigen möchten?",
  "Confirm":"Bestätigen",
  "Update":"Aktualisieren",
  "success":"Erfolg",
  "error":"Fehler",
  "Are_you_sure_you_want_to_delete":"Sind Sie sicher, dass Sie löschen möchten",
  "cancel":"Schliessen",
  "Hour_Time":"24-Stunden-Zeit",
  "Knee":"Knie",
  "no":"Nein",
  "yes":"Ja",
  "Year":"0 Jahr, 0 Monat, 0 Tage",
  "Bilateral":"Beidhändig",
  "Left_Arm":"Linke Schulter",
  "Right_Arm":"Rechte Schulter",
  "out_of":"von",
  "Right_Elbow":"Rechter Ellenbogen",
  "Left_Elbow":"Linker Ellenbogen",
  "right_foot_ankle":"Rechter Fuß/Knöchel",
  "left_foot_ankle":"Linker Fuß/Knöchel",
  "Right_hand_and_wrist":"Rechte Hand und Handgelenk",
  "Left_hand_and_wrist":"Linke Hand und Handgelenk",
  "Hip_Function_R":"Rechte Hüfte",
  "Hip_Function_L":"Linke Hüfte",
  "Right_Knee":"Rechtes Knie",
  "Left_Knee":"Linkes Knie",
  "Right_Pedi_Knee":"Rechtes Knie",
  "Left_Pedi_Knee":"Linkes Knie",
  "Scoring_Self_Evaluation":"Selbstbewertung Punktzahl",
  "presciption_form":"Rezept",
  "insurance_form":"Versicherung",
  "letter_form":"Brief",
  "refferal_form":"Überweisung",
  "medical_form":"medizinisch",
  "are_you_sure_delete_account":"Sind Sie sicher, dass Sie Ihr Konto löschen möchten?",
  "Adult_Knee_Pain":"Knieschmerzen bei Erwachsenen",
  "Knee_pain_13_18_year":"Knieschmerzen",
  "day_pdata":"Tag",
  "days_pdata":"Tage",
  "month_pdata":"Monat",
  "months_pdata":"Monate",
  "year_pdata":"Jahr",
  "years_pdata":"Jahre",
  "Name":"Name",
  "akunah_id":"Akunah ID",
  "mobile":"Telefon",
  "email":"Email",
  "upload_a_profile":"Lade ein Foto hoch",
  "No_special_characters_are_allowed":"Es sind keine Sonderzeichen erlaubt",
  "numbers_are_not_allowed":"Zahlen sind nicht erlaubt",
  "maximum_50_character_are_allowed":"Es sind maximal 50 Zeichen zulässig",
  "invalid_character":"Ungültiges Zeichen",
  "fields_only_contain_10_character":"Felder enthalten nur 10 Zeichen",
  "maximum_10_Numbers_are_allowed":"Es sind maximal 10 Nummern erlaubt",
  "Only_I":"Es sind nur Zahlen erlaubt",
  "invalid_home_phone_number":"Ungültige private Telefonnummer",
  "special_characters_are_not_allowed":"Sonderzeichen sind nicht erlaubt",
  "maximum_digit_are_allowed":"Maximal 1 Ziffer ist zulässig",
  "invalid_date":"Ungültiges Datum",
  "maximum_10_digits_are_allowed":"Es sind maximal 10 Ziffern zulässig",
  "switch_profile":"Profil wechseln",
  "loading":"Wird geladen",
  "error_max_profile_limit":"Sie haben die maximal zulässige Anzahl von Konten mit einer E-Mail-Adresse erreicht",
  "error_upcoming_appointments":"Keine bevorstehenden Termine gefunden",
  "error_past_visits":"Keine früheren Besuche gefunden",
  "error_documents":"Keine Dokumente gefunden",
  "country_hawaii":"(UTC-10:00) Hawaii Time",
  "country_tahiti":"(UTC-10:00) Tahiti",
  "country_pitcairn":"(UTC-08:00) Pitcairn",
  "country_niue":"(UTC-11:00) Niue",
  "country_mountain":"(UTC-07:00) Mountain Time",
  "country_arizona":"(UTC-07:00) Mountain Time - Arizona",
  "country_whitehorse":"(UTC-08:00) Pacific Time - Whitehorse",
  "country_chihuahua":"(UTC-07:00) Mountain Time - Chihuahua, Mazatlan",
  "country_edmonton":"(UTC-07:00) Mountain Time - Edmonton",
  "country_gambier":"(UTC-09:00) Gambier",
  "country_yellowknife":"(UTC-07:00) Mountain Time - Yellowknife",
  "country_dawson":"(UTC-07:00) Mountain Time - Dawson Creek",
  "country_belize":"(UTC-06:00) Belize",
  "country_hermosillo":"(UTC-07:00) Mountain Time - Hermosillo",
  "country_mexico":"(UTC-06:00) Central Time - Mexico City",
  "country_regina":"(UTC-06:00) Central Time - Regina",
  "country_tegucigalpa":"(UTC-06:00) Central Time - Tegucigalpa",
  "country_centraltime":"(UTC-06:00) Central Time",
  "country_rarotonga":"(UTC-10:00) Rarotonga",
  "country_pago":"(UTC-11:00) Pago Pago",
  "country_pacific":"(UTC-08:00) Pacific Time",
  "country_alaska":"(UTC-09:00) Alaska Time",
  "country_vancouver":"(UTC-08:00) Pacific Time - Vancouver",
  "country_tijuana":"(UTC-08:00) Pacific Time - Tijuana",
  "country_salvador":"(UTC-06:00) El Salvador",
  "country_costa":"(UTC-06:00) Costa Rica",
  "country_winnipeg":"(UTC-06:00) Central Time - Winnipeg",
  "country_galapagos":"(UTC-06:00) Galapagos",
  "country_managua":"(UTC-06:00) Managua",
  "country_america_cancun":"(UTC-05:00) America Cancun",
  "country_guatemala":"(UTC-06:00) Guatemala",
  "country_bogota":"(UTC-05:00) Bogota",
  "country_easter_island":"(UTC-05:00) Easter Island",
  "country_iqaluit":"(UTC-05:00) Eastern Time - Iqaluit",
  "country_toronto":"(UTC-05:00) Eastern Time - Toronto",
  "country_eastern_time":"(UTC-05:00) Eastern Time",
  "country_havana":"(UTC-05:00) Havana",
  "country_lima":"(UTC-05:00) Lima",
  "country_jamaica":"(UTC-05:00) Jamaica",
  "country_panama":"(UTC-05:00) Panama",
  "country_port_au_prince":"(UTC-05:00) Port-au-Prince",
  "country_nassau":"(UTC-05:00) Nassau",
  "country_rio_branco":"(UTC-05:00) Rio Branco",
  "country_barbados":"(UTC-04:00) Barbados",
  "country_halifax":"(UTC-04:00) Atlantic Time - Halifax",
  "country_bermuda":"(UTC-04:00) Bermuda",
  "country_boa_vista":"(UTC-04:00) Boa Vista",
  "country_curacao":"(UTC-04:00) Curacao",
  "country_marquesas":"(UTC-09:30) Marquesas",
  "country_caracas":"(UTC-04:00) Caracas",
  "country_grand_turk":"(UTC-04:00) Grand Turk",
  "country_la_paz":"(UTC-04:00) La Paz",
  "timezone_guyana":"(UTC-04:00) Guyana",
  "timezone_port_of_spain":"(UTC-04:00) Port of Spain",
  "timezone_manaus":"(UTC-04:00) Manaus",
  "timezone_martinique":"(UTC-04:00) Martinique",
  "timezone_porto_velho":"(UTC-04:00) Porto Velho",
  "timezone_santo_domingo":"(UTC-04:00) Santo Domingo",
  "timezone_puerto_rico":"(UTC-04:00) Puerto Rico",
  "timezone_araguaina":"(UTC-03:00) Araguaina",
  "timezone_belem":"(UTC-03:00) Belem",
  "timezone_buenos_aires":"(UTC-03:00) Buenos Aires",
  "timezone_newfound_time":"(UTC-03:30) Newfoundland Time - St. Johns",
  "timezone_asuncion":"(UTC-03:00) Asuncion",
  "timezone_campo":"(UTC-03:00) Campo Grande",
  "timezone_cayenne":"(UTC-03:00) Cayenne",
  "timezone_cuiaba":"(UTC-03:00) Cuiaba",
  "timezone_maceio":"(UTC-03:00) Maceio",
  "timezone_godthab":"(UTC-03:00) Godthab",
  "timezone_fortaleza":"(UTC-03:00) Fortaleza",
  "timezone_miquelon":"(UTC-03:00) Miquelon",
  "timezone_palmer":"(UTC-03:00) Palmer",
  "timezone_montevideo":"(UTC-03:00) Montevideo",
  "timezone_recife":"(UTC-03:00) Recife",
  "timezone_rothera":"(UTC-03:00) Rothera",
  "timezone_paramaribo":"(UTC-03:00) Paramaribo",
  "timezone_punta_arenas":"(UTC-03:00) Punta Arenas",
  "timezone_santig":"(UTC-03:00) Santiag",
  "timezone_salvador":"(UTC-03:00) Salvador",
  "timezone_stanley":"(UTC-03:00) Stanley",
  "timezone_south_georgia":"(UTC-02:00) South Georgia",
  "timezone_noronha":"(UTC-02:00) Noronha",
  "timezone_sao_pulo":"(UTC-02:00) Sao Paulo",
  "timezone_azores":"(UTC-01:00) Azores",
  "timezone_abidjan":"(UTC+00:00) Abidjan",
  "timezone_cape_verde":"(UTC-01:00) Cape Verde",
  "timezone_scoresbysund":"(UTC-01:00) Scoresbysund",
  "timezone_bissau":"(UTC+00:00) Bissau",
  "timezone_accra":"(UTC+00:00) Accra",
  "timezone_danmarkshavn":"(UTC+00:00) Danmarkshavn",
  "timezone_casablanca":"(UTC+00:00) Casablanca",
  "timezone_dublin":"(UTC+00:00) Dublin",
  "timezone_canary_islands":"(UTC+00:00) Canary Islands",
  "timezone_el_aaiun":"(UTC+00:00) El Aaiun",
  "timezone_thule":"(UTC-04:00) Thule",
  "timezone_gmt":"(UTC+00:00) GMT (keine Sommerzeit)",
  "timezone_lisbon":"(UTC+00:00) Lisbon",
  "timezone_faeroe":"(UTC+00:00) Faeroe",
  "timezone_reykjavik":"(UTC+00:00) Reykjavik",
  "timezone_monrovia":"(UTC+00:00) Monrovia",
  "timezone_algiers":"(UTC+01:00) Algiers",
  "timezone_london":"(UTC+00:00) London",
  "timezone_amsterdam":"(UTC+01:00) Amsterdam",
  "timezone_andorra":"(UTC+01:00) Andorra",
  "timezone_berlin":"(UTC+01:00) Berlin",
  "timezone_budapest":"(UTC+01:00) Budapest",
  "timezone_ceuta":"(UTC+01:00) Ceuta",
  "timezone_brussels":"(UTC+01:00) Brussels",
  "timezone_copenhagen":"(UTC+01:00) Copenhagen",
  "timezone_gibrltar":"(UTC+01:00) Gibraltar",
  "timezone_prague":"(UTC+01:00) Mitteleuropäische Zeit – Prag",
  "timezone_lagos":"(UTC+01:00) Lagos",
  "timezone_luxembourg":"(UTC+01:00) Luxembourg",
  "timezone_madrid":"(UTC+01:00) Madrid",
  "timezone_malta":"(UTC+01:00) Malta",
  "timezone_ndjamena":"(UTC+01:00) Ndjamena",
  "timezone_paris":"(UTC+01:00) Paris",
  "timezone_oslo":"(UTC+01:00) Oslo",
  "timezone_monaco":"(UTC+01:00) Monaco",
  "timezone_tirane":"(UTC+01:00) Tirane",
  "timezone_rome":"(UTC+01:00) Rome",
  "timezone_vienna":"(UTC+01:00) Vienna",
  "timezone_tunis":"(UTC+01:00) Tunis",
  "timezone_warsaw":"(UTC+01:00) Warsaw",
  "timezone_amman":"(UTC+02:00) Amman",
  "timezone_zurich":"(UTC+01:00) Zurich",
  "timezone_athens":"(UTC+02:00) Athens",
  "timezone_burcharest":"(UTC+02:00) Bucharest",
  "timezone_chisinau":"(UTC+02:00) Chisinau",
  "timezone_stockholm":"(UTC+01:00) Stockholm",
  "timezone_cairo":"(UTC+02:00) Cairo",
  "timezone_gaza":"(UTC+02:00) Gaza",
  "timezone_jerusalem":"(UTC+02:00) Jerusalem",
  "timezone_johannesburg":"(UTC+02:00) Johannesburg",
  "timezone_helsinki":"(UTC+02:00) Helsinki",
  "timezone_khartoum":"(UTC+02:00) Khartoum",
  "timezone_belgrade":"(UTC+01:00) Mitteleuropäische Zeit – Belgrad",
  "timezone_damascus":"(UTC+02:00) Damascus",
  "timezone_maputo":"(UTC+02:00) Maputo",
  "timezone_kaliningrad":"(UTC+02:00) Moscow-01 - Kaliningrad",
  "timezone_riga":"(UTC+02:00) Riga",
  "timezone_kiev":"(UTC+02:00) Kiev",
  "timezone_nicosia":"(UTC+02:00) Nicosia",
  "timezone_tallinn":"(UTC+02:00) Tallinn",
  "timezone_tripoli":"(UTC+02:00) Tripoli",
  "timezone_sofia":"(UTC+02:00) Sofia",
  "timezone_vilnius":"(UTC+02:00) Vilnius",
  "timezone_istanbul":"(UTC+03:00) Istanbul",
  "timezone_baghdad":"(UTC+03:00) Baghdad",
  "timezone_minsk":"(UTC+03:00) Minsk",
  "timezone_windhoek":"(UTC+02:00) Windhoek",
  "timezone_nairobi":"(UTC+03:00) Nairobi",
  "timezone_moscow":"(UTC+03:00) Moscow+00 - Moscow",
  "timezone_qatar":"(UTC+03:00) Qatar",
  "timezone_beirut":"(UTC+02:00) Beirut",
  "timezone_riyadh":"(UTC+03:00) Riyadh",
  "timezone_syowa":"(UTC+03:00) Syowa",
  "timezone_tehran":"(UTC+03:30) Tehran",
  "timezone_baku":"(UTC+04:00) Baku",
  "timezone_dubai":"(UTC+04:00) Dubai",
  "timezone_mahe":"(UTC+04:00) Mahe",
  "timezone_mauritius":"(UTC+04:00) Mauritius",
  "timezone_tbilisi":"(UTC+04:00) Tbilisi",
  "timezone_samara":"(UTC+04:00) Moscow+01 - Samara",
  "timezone_reunion":"(UTC+04:00) Reunion",
  "timezone_yerevan":"(UTC+04:00) Yerevan",
  "timezone_kabul":"(UTC+04:30) Kabul",
  "timezone_aqtau":"(UTC+05:00) Aqtau",
  "timezone_aqtobe":"(UTC+05:00) Aqtobe",
  "timezone_ashgabat":"(UTC+05:00) Ashgabat",
  "timezone_dushanbe":"(UTC+05:00) Dushanbe",
  "timezone_maldives":"(UTC+05:00) Maldives",
  "timezone_yekaterinburg":"(UTC+05:00) Moscow+02 - Yekaterinburg",
  "timezone_kerguelen":"(UTC+05:00) Kerguelen",
  "timezone_mawson":"(UTC+05:00) Mawson",
  "timezone_tashkent":"(UTC+05:00) Tashkent",
  "timezone_colombo":"(UTC+05:30) Colombo",
  "timezone_kathmandu":"(UTC+05:45) Kathmandu",
  "timezone_omsk":"(UTC+06:00) Moscow+03 - Omsk",
  "timezone_india_standard_time":"(UTC+05:30) India Standard Time",
  "timezone_chagos":"(UTC+06:00) Chagos",
  "timezone_dhaka":"(UTC+06:00) Dhaka",
  "timezone_bishkek":"(UTC+06:00) Bishkek",
  "timezone_cocos":"(UTC+06:30) Cocos",
  "timezone_thimphu":"(UTC+06:00) Thimphu",
  "timezone_almaty":"(UTC+06:00) Almaty",
  "timezone_vostok":"(UTC+06:00) Vostok",
  "timezone_rangoon":"(UTC+06:30) Rangoon",
  "timezone_christmas":"(UTC+07:00) Christmas",
  "timezone_davis":"(UTC+07:00) Davis",
  "timezone_karachi":"(UTC+05:00) Karachi",
  "timezone_hanoi":"(UTC+07:00) Hanoi",
  "timezone_beijing":"(UTC+08:00) China Time - Beijing",
  "timezone_hongkong":"(UTC+08:00) Hong Kong",
  "timezone_hovd":"(UTC+07:00) Hovd",
  "timezone_jakarta":"(UTC+07:00) Jakarta",
  "timezone_kuala_lumpur":"(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok":"(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk":"(UTC+07:00) Moscow+04 - Krasnoyarsk",
  "timezone_brunei":"(UTC+08:00) Brunei",
  "timezone_macau":"(UTC+08:00) Macau",
  "timezone_makassar":"(UTC+08:00) Makassar",
  "timezone_choibalsan":"(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar":"(UTC+08:00) Ulaanbaatar",
  "timezone_manila":"(UTC+08:00) Manila",
  "timezone_pyongyang":"(UTC+08:30) Pyongyang",
  "timezone_singapore":"(UTC+08:00) Singapore",
  "timezone_irkutsk":"(UTC+08:00) Moscow+05 - Irkutsk",
  "timezone_taipei":"(UTC+08:00) Taipei",
  "timezone_jayapura":"(UTC+09:00) Jayapura",
  "timezone_dili":"(UTC+09:00) Dili",
  "timezone_perth":"(UTC+08:00) Western Time - Perth",
  "timezone_yakutsk":"(UTC+09:00) Moscow+06 - Yakutsk",
  "timezone_palau":"(UTC+09:00) Palau",
  "timezone_seoul":"(UTC+09:00) Seoul",
  "timezone_tokyo":"(UTC+09:00) Tokyo",
  "timezone_darwin":"(UTC+09:30) Central Time - Darwin",
  "timezone_dumont":"(UTC+10:00) Dumont D'Urville",
  "timezone_brisbane":"(UTC+10:00) Eastern Time - Brisbane",
  "timezone_guam":"(UTC+10:00) Guam",
  "timezone_vladivostok":"(UTC+10:00) Moscow+07 - Vladivostok",
  "timezone_port_moresby":"(UTC+10:00) Port Moresby",
  "timezone_truk":"(UTC+10:00) Truk",
  "timezone_adelaide":"(UTC+10:30) Central Time - Adelaide",
  "timezone_casey":"(UTC+11:00) Eastern Time - Hobart",
  "timezone_melbourne_sydney":"(UTC+11:00) Eastern Time - Melbourne, Sydney",
  "timezone_kosrae":"(UTC+11:00) Kosrae",
  "timezone_efate":"(UTC+11:00) Efate",
  "timezone_guadalcanal":"(UTC+11:00) Guadalcanal",
  "timezone_ponape":"(UTC+11:00) Ponape",
  "timezone_norfolk":"(UTC+11:00) Norfolk",
  "timezone_magadan":"(UTC+11:00) Moscow+08 - Magadan",
  "timezone_noumea":"(UTC+11:00) Noumea",
  "timezone_majuro":"(UTC+12:00) Majuro",
  "timezone_funafuti":"(UTC+12:00) Funafuti",
  "timezone_nauru":"(UTC+12:00) Nauru",
  "timezone_tarawa":"(UTC+12:00) Tarawa",
  "timezone_kwajalein":"(UTC+12:00) Kwajalein",
  "timezone_enderbury":"(UTC+13:00) Enderbury",
  "timezone_wake":"(UTC+12:00) Wake",
  "timezone_fakaofo":"(UTC+13:00) Fakaofo",
  "timezone_wallis":"(UTC+12:00) Wallis",
  "timezone_fiji":"(UTC+13:00) Fiji",
  "timezone_apia":"(UTC+14:00) Apia",
  "timezone_kiritimati":"(UTC+14:00) Kiritimati",
  "timezone_tongatapu":"(UTC+13:00) Tongatapu",
  "timezone_auckland":"(UTC+13:00) Auckland",
  "dateFormet":"Datumsformat",
  "Org_success":"Die Anfrage wurde erfolgreich gesendet",
  "Org_err":"Sie sind der Organisation bereits beigetreten!",
  "Request_Organization":"Angabe der Institution",
  "organization_requested":"Institution erforderlich",
  "join":"Beitreten",
  "your_email":"email@address.com",
  "medical_summary":"Sie haben Ihre Anamnese/Krankengeschichte noch nicht ausgefüllt",
  "medical_summary_msg":"Meine Krankengeschichte /Anamnese",
  "medical_success":"Krankengeschichte/Anamnese erfolgreich eingereicht",
  "patient_form_popup":"Möchten Sie mit der Bearbeitung des begonnenenFragebogens fortsetzen?",
  "Please_confirm_that_your_details_are_correct":"Bitte bestätigen Sie, dass die eingegebenen Daten korrekt sind. ",
  "Welcome_to_Akunah_your_Health_Passport":"Willkommen bei Akunah, Ihrem Gesundheitspass.",
  "Your_helth_is_in_your_hands":"Ihre Gesundheit liegt in Ihren Händen",
  "Lets_begin_your_journey":"Beginnen Sie heute noch! ",
  "Please_complete_your_profile":"Bitte vervollständigen Sie Ihr Profil.",
  "Profile_complete":"Profil vollständig!",
  "Would_you_like_to_add_another_profile_for_a_family_member":"Möchten Sie ein weiteres Profil hinzufügen (Familienmitglied)? ",
  "Alert_detected":"Achtung: mehrfach Anmeldungen erkannt",
  "To_safeguard_your_data":"Die Verbindung wurde aufgrund von Zeitüberschreitung beendet, um den Datenschutz und die Sicherheit zu gewährleisten ",
  "Kindly_close_all_other_Akunah":"Bitte schließen Sie alle Register und drücken Sie \"Zurück zum Start\" , um fortzusetzen",
  "Akunah_Team_is_dedicated":"Der Schutz Ihrer Daten ist für das Team von Akunah von enormer Wichtigkeit!",
  "Last_Created":"Kürzlich erstellt",
  "First_Created":"Erstmalig erstellt",
  "Alphabetically":"Alphabetisch (A-Z)",
  "Alphabetically1":"Alphabetisch (Z-A)",
  "Your_form_submission_was_successful_Clinician":"Registrierung erfolgreich abgeschlossen. Sie werden nun direkt zur Clinician app weitergeleitet. ",
  "Your_form_submission_was_successful_Patient":"Registrierung erfolgreich abgeschlossen. Sie werden nun direkt zur Patienten app weitergeleitet. ",
  "If_this_doesnt_happen_within":"Sollten Sie in den nächsten 5 Sekunden nicht weitergeleitet werden",
  "click_here":"Bitte hier klicken",
  "Save":"Speichern",
  "Proceed_to_my_dashboard":"Zurück zur Übersicht",
  "myJourney":"Mein Verlauf",
  "Home":"Zurück zum Start ",
  "No_journey":"Kein Verlauf gefunden",
  "Share_results_with_your_doctor":"Teilen Sie die Ergebnisse mit Ihrem Arzt",
  "If_you_would_like_to_share_your_results_with":"Wenn Sie die Ergebnisse teilen möchten klicken Sie bitte auf \"Jetzt teilen\" unterhalb",
  "Link_PROMs":"Patienten Fragebögen verlinken",
  "Welcome_to_akunah":"Willkommen bei Akunah",
  "Share_to_your_Doctor_using_one_of_the_3_options_below":"Teilen Sie es Ihrem Arzt mit, indem Sie eine der 3 folgenden Optionen nutzen",
  "Doctor_Code":"Arztnummer",
  "Enter_the_code_that_has_been_provided_to_you_by_your":"Bitte geben Sie den Code ein, den Sie von Ihrem Arzt per Mail oder im Krankenhaus bekommen haben. Wenn der Code nicht verfügbar ist, suchen Sie nach den letzten Besuchen oder nach Ihrem Arzt.",
  "Recent_Visits":"Kürzlich besucht",
  "Select_your_recent_doctor_or":"Wählen Sie Ihren Arzt oder Ihre Behandlungsstätte hier aus",
  "organisation_here":"Organisation hier",
  "Search":"Suchen",
  "Search_for_your_doctor_or":"Suche nach Arzt oder ",
  "Please_confirm_that_the_following_details":"Bitte bestätigen Sie die Richtigkeit der folgenden Daten",
  "Organisation":"Organisation/ Behandlungsstätte",
  "PROMs_Specialty_Date":"Fragebögen Fachgebiet&Datum ",
  "Get_started_by_completing_your":"Beginnen Sie mit der Vervollständigung Ihrer Fragebögen (PROMS)",
  "and_exploring_your_progress":"und verfolgen Sie Ihre Fortschritte",
  "Fill_up_your_PROMs_questionnaire":"Füllen Sie Ihren Fragebogen (PROM) aus",
  "Hip":"Hüfte",
  "My_Progress":"Mein Fortschritt",
  "Completed":"Fertig",
  "Expired":"Abgelaufen",
  "Incomplete":"Unvollständig",
  "Specialty":"Fachgebiet",
  "Doctor":"Arzt",
  "Status":"Zustand",
  "View_form":"Formular ansehen",
  "Share_to_another_doctor":"Mit einem anderen Arzt teilen",
  "Edit_form":"Formular bearbeiten",
  "Answer_the_form":"Formular beantworten",
  "Complete_the_form":"Formular vervollständigen",
  "Delete_the_form":"Formular löschen",
  "Share_to_doctor":"Mit meinem Arzt teilen ",
  "Your_account_has_been_created":"Ihr Konto wurde erstellt",
  "Please_click_below_to_proceed_to_your_dashboard":"Bitte klicken Sie unterhalb um zurück zur Übersicht zu gelangen",
  "If_you_would_like_to_add_another_profile_for_a_family":"Wenn Sie ein weiteres Profil für ein Familienmitglied hinzufügen möchten bitte….",
  "Share":"Teilen",
  "Hip_Scoring_Self_Evaluation_Questionnaire":"Hüfte Fragebogen zur Selbstbewertung",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire":"Hand und Handgelenk Fragebogen zur Selbstbewertung",
  "Right_Hand_nd_Wrist":"Rechte Hand & Handgelenk ",
  "Left_Hand_nd_Wrist":"Linke Hand & Handgelenk",
  "DOB_1":"Geburtsdatum",
  "Sex_1":"Geschlecht",
  "Address_1":"Adresse",
  "Please_Choose_PROMs_to_link":"Bitte wählen Sie die PROMs die sie versenden möchten",
  "Please_select_atleast_one_form_or_close":"Bitte mindestens einen PROM wählen oder beenden",
  "cant_find_appropriate_PROMs_to_link":"Es konnten keine passenden PROMs zum Versenden gefunden werden ",
  "Request_New_PROMs":"Neue PROMS beantragen",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit":"Achtung! Es gibt noch einen unvollständigen Fragebogen aus einer letzten Sitzung ",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted":"Wenn Sie \"NEIN\" auswählen, wird ein neuer Fragebogen erstellt und der unvollständige Fragebogen gelöscht.",
  "Would_you_like_to_continue_this_form":"Möchten Sie fortfahren?",
  "Note":"Notiz:",
  "Expiring_in":"Ungültig in",
  "Date_Created":"Erstellungsdatum",
  "Days":"Tagen",
  "Shared":"Geteilt",
  "Pending":"Ausstehend",
  "Initial":"Ausgangs Punkte",
  "Recent":"Neue Punkte",
  "Pain":"Schmerz",
  "Function":"Funktion",
  "Share_Now":"Jetzt teilen",
  "Share_Later":"Später teilen",
  "Updated":"aktualisiert",
  "Display_More":"mehr anzeigen",
  "Address_Now":"Jetzt zuordnen/senden",
  "Address_Later":"Später zuordnen/senden",
  "You_have_pending_questionnaires":"Sie haben noch ausstehende Fragebögen",
  "Notifications":"Benachrichtigungen",
  "Form_shared_successfully":"Erfolgreich geteilt!",
  "You_have_already_shared_with_same_doctor_and_organization":"Wurde bereits geteilt!",
  "No_recent_notifications":"Keine Benachrichtigungen",
  "Have_a_good_day":"Wir wünschen Ihnen einen schönen Tag!",
  "Patient_scores":"Patienten Fragebögen",
  "Display less":"Weniger anzeigen",
  "myJourney_Shoulder":"Schulter",
  "myJourney_Shoulder_txt":"Wählen Sie Schulter um Ihren Verlauf \"Schulter\" zu sehen",
  "myJourney_Elbow":"Ellbogen",
  "myJourney_Elbow_txt":"Wählen Sie Ellbogen um Ihren Verlauf \"Ellbogen\" zu sehen ",
  "myJourney_Hand_Wrist":"Hand und Handgelenk",
  "myJourney_Hand_Wrist_txt":"Wählen Sie Hand und Handgelenk um Ihren Verlauf \"Hand u. Handgelenk\" zu sehen",
  "myJourney_Hip":"Hüfte",
  "myJourney_Hip_txt":"Wählen Sie Hüfte um Ihren Verlauf \"Hüfte\" zu sehen",
  "myJourney_knee":"Knie",
  "myJourney_knee_txt":"Wählen Sie Knie um Ihren Verlauf \"Knie\" zu sehen",
  "myJourney_Foot_Ankle":"Fuß und Knöchel ",
  "myJourney_Foot_Ankle_txt":"Wählen Sie Fuß und Knöchel um Ihren Verlauf \"Fuß und Knöchel\" zu sehen",
  "data_sort":"Sortieren",
  "Visit_Clinic":"Klinik/ Krankenhaus Besuch",
  "My_Visits":"Meine Besuche",
  "Patient_Scoring_Forms":"Patienten Fragebögen",
  "Other_findings":"Andere Erkenntnisse",
  "Organization":"Organisation",
  "more":"Mehr",
  "mediacal_summary":"Medizinischer Verlauf",
  "my_consent":"Meine Einverständniserklärungen",
  "Are_you_sure_you_want_to_reject":"Sind Sie sicher, dass Sie diese Überweisung ablehnen möchten? ",
  "reject":"ablehnen",
  "i_accept_consent":"Ich stimme zu",
  "grant":"Zustimmen",
  "no_conscent_found":"Keine Einverständniserklärung gefunden",
  "rejected":"Ablehnen",
  "No_Time_Lines":"Kein Verlauf ",
  "Hide_Score":"Punkte (Scores) ausblenden",
  "Show_Score":"Punkte (Scores) anzeigen",
  "patient_sex":"Geschlecht",
  "Dominant_hand_1":"Bevorzugte Hand",
  "patient_Initial_Assessment":"Erstbeurteilung",
  "patient_Treatment_Category":"Behandlungskategorie",
  "View_all":"Alles anzeigen",
  "Display_Less":"Weniger anzeigen",
  "patient_general_past_Medical_history":"Krankengeschichte",
  "patient_general_past_Medication_name":"Medikamentenliste",
  "Evaluated_Knee":"Knie ausgewertet",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function":"Ist Ihre nicht betroffene Hand und Ihr Handgelenk schmerzfrei und erlauben eine normale Funktion?",
  "Evaluated_Hip":"Hüfte ausgewertet",
  "Evaluated_Foot_Ankle":"Fuß und Knöchel ausgewertet",
  "patient_Evaluated_Elbow":"Ellbogen ausgewertet",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function":"Ist Ihr nicht betroffener Fuß und Knöchel schmerzfrei und erlaubt normale Funktion?",
  "Evaluated_Hand_Wrist":"Hand und Handgelenk ausgewertet",
  "patient_shoulder_feeling_instability_q2":"Bitte geben Sie an, wie instabil Ihre Schulter ist",
  "patient_shoulder_feeling_instability_q3":"Bitte geben Sie an, wie instabil Ihre Schulter ist",
  "patient_Hand_BCTQ":"Hand und Handgelenk BCTQ",
  "Paediatric_Knee_Satisfaction":"Kinderknie Patientenzufriedenheit",
  "patient_foot_ankle_symptoms":"Fuß&Knöchel Andere Symptome",
  "Right_Foot_and_Ankle":"Linker Fuß&Knöchel",
  "Left_Foot_and_Ankle":"Rechter Fuß&Knöchel",
  "summary":"Zusammenfassung",
  "Shoulder_Treatment_Form":"Schulter Behandlungsfragebogen",
  "Adolescent_knee_Treatment_Form":"Jugendliches Knie Behandlungsfragebogen",
  "Adult_Knee_Treatment_Form":"Knie Behandlungsfragebogen",
  "Paediatric_Knee_Treatment_Form":"Kinderknie Behandlungsfragebogen",
  "Hip_Treatment_Form":"Hüft Behandlungsfragebogen",
  "Foot_Ankle_Treatment_Form":"Fuß&Knöchel Behandlungsfragebogen",
  "Elbow_Treatment_Form":"Ellbogen Behandlungsfragebogen",
  "Hand_Wrist_Treatment_Form":"Hand und Handgelenk Behandlungsfragebogen",
  "Upload_complete":"Hochladen abgeschlossen",
  "Select_Date_Format":"Datumsformat auswählen ",
  "timezone_casey1":"(UTC+11:00) Casey",
  "timezone_Kamchatskiy":"(UTC+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
  "timezone_Guayaquil":"(UTC-05:00) Guayaquil",
  "requested":"requested a",
  "Display_name":"Name anzeigen",
  "Are_you_sure_you_want_to_delete_Consent":"Sind Sie sicher, dass Sie ablehnen möchten?",
  "form":"Fragebogen",
  "Requested_by":"Angefordert von",
  "requested_hip_form":"Hüft Fragebogen angefragt",
  "requested_shoulder_form":"Schulter Fragebogen angefragt",
  "requested_knee_form":"Knie Fragebogen angefragt ",
  "requested_foot_form":"Fuß und Knöchel Fragebogen angefragt ",
  "requested_hand_form":"Hand und Handgelenk Fragebogen angefragt ",
  "requested_elbow_form":"Ellenbogen Fragebogen angefragt ",
  "requested_General_form":"allgemeiner Fragebogen angefragt ",
  "requested_Paediatric_form":"Knie für Kinder Fragebogen angefragt ",
  "requested_Adolescent_form":"Jugendliches Knie Fragebogen angefragt ",
  "Expiring_in_Tomorrow":"Expiring tomorrow",
  "PROMs_questionnaire":"Dr. [vollständiger Name des Arztes, der angefragt hat] hat Sie darum gebeten, den PROMs-Fragebogen auszufüllen",
  "new_message":"Sie haben eine neue Nachricht von [vollständiger Name des Absenders der Nachricht]",
  "new_appointment":"Sie haben einen neuen Termin bei Dr. [vollständiger Name des Arztes]",
  "upcoming_appointment":"Zur Erinnerung: bevorstehender Termin bei Dr. [vollständiger Name] am [Datum]",
  "pending_message":"Zur Erinnerung: Sie haben einen ausstehenden PROMs-Fragebogen [Fachgebiet]",
  "document_message":"Dr. [vollständiger Name des Teilers] hat ein Dokument mit Ihnen geteilt",
  "requested_message":"Dr. [vollständiger Name des Antragstellers] hat um eine Einwilligung gebeten",
  "patient_added_message":"Sie wurden als Patient von Dr. [vollständiger Name] in [Organisation] aufgenommen.",
  "cancelled_appointment_message":"Ihr Termin bei Dr. [vollständiger Name] am [Datum des Termins] wurde abgesagt."
}