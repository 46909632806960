import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import formTimelineStyles from "./FormTimeline.module.css";
// import Button from "../../../custom/button/Button";
import toggleIcon from "../../images/dots-blue.svg";
import arrowRightIcon from "../../images/arrow-bright.svg";
import viewIcon from "../../images/eye-icon.svg";
import editIcon from "../../images/edit-icon.svg";
import deleteIcon from "../../images/delete-icon.svg";
import DeleteConfirmation from "../custom/delete-confirmation/DeleteConfirmation";
import useTranslation from "../customHooks/translations";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import CompletePopup from "../custom/confirmation-popup/ConfirmPopup";
import {
  DateFormatWithTimeZone,
  UserDateFormatTimeZone,
} from "../../utilities/DateFormate";

// import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
function FormTimeline({ resID }) {
  const { akid } = useParams();
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const queryParams = new URLSearchParams(window.location.search);

  const formType = queryParams.get("formType");
  // const [profile, setProfile] = useState("");
  let akToken = localStorage.getItem("AkTOKENI");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState([]);
  const [timezoneD, setTimeZone] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const translation = useTranslation();
  const [rId, setRId] = useState("");
  useEffect(() => {
    if (login) {
      // setProfile(login.patient);
      setTimeZone(login.patient.timezone);
      setTimeFormat(login.patient.dateFormat);
    }
  }, [login]);

  useEffect(() => {
    // setformData(data)
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/patients/patient_management_timeline?ak_id=${akToken}&limit=100&offset=0&type=${window.atob(formType)}`
        );
        const filteredData = response.data.data.filter(item => item.status !== "1" || item.org_id !== "");
        setformData(filteredData);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/unauthorized");
        }
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const hideConfirmationModal1 = () => {
    // setFromConfirm(false);
  };
  const handleRId = (e) => {
    resID(e);
    setRId(e);
  };
  // console.log(updatedData);
  return (
    <div className={`formTimelineSec ${formTimelineStyles.form_timeline_sec}`}>
      <div className="whitebox">
        <div className="row">
          <div className="col-12">
            <h4 className={`${formTimelineStyles.top_left_pf_txt}`}>
              {translation.myJourney} 
            </h4>
          </div>
          <div className="col-md-7 col-sm-7 text-end">
            <p className="Updated_dt">
              {/* {timeLineDate ? "Updated : " + timeLineDate : ""} */}
            </p>
          </div>
        </div>
        <div className="row">
          {isLoading ? (
            <>
              <div className="no_data">{translation.loading}....</div>
            </>
          ) : (
            <div>
              <div className="col-md-12 mxh_520 scrollbar_vert"></div>
              <div className="col-md-12 mxh_520 scrollbar_vert">
                {/* Patient Management plan screen screen form code */}

                <>
                  {formData.length === 0 ? (
                    <div className="no_data">{translation.No_Time_Lines} </div>
                  ) : (
                    <div>
                      <ul
                        className={`mp_items ${formTimelineStyles.stepProgress}`}
                      >
                        <>
                          {formData.map((row) => (
                            <li
                              className={`${formTimelineStyles.stepProgress_item} ${formTimelineStyles.is_submit}`}
                            >
                              <span
                                className=""
                                style={{
                                  display: "inline-block",
                                  position: "absolute",
                                  top: "0",
                                  left: "-37px",
                                  width: "12px",
                                  height: "12px",
                                  borderRadius: "50%",
                                  border: `2px solid #3f2783`,
                                  backgroundColor: `#3f2783`,
                                }}
                              ></span>
                              <span className="">
                                <div className="float-end me-2 ">
                                  {rId === row.resource_id ? (
                                    <span
                                      className="f_600 timeline_border c_pointer"
                                      style={{
                                        color: "#3f2783",
                                        minWidth: "111px",
                                        justifyContent: "center",
                                        backgroundColor:"#673ab730"
                                      }}
                                      onClick={() => handleRId()}
                                    >
                                      {translation.Hide_Score}
                                    </span>
                                  ) : (
                                    <span
                                      className="f_600 timeline_border c_pointer"
                                      style={{
                                        color: "#3f2783",
                                        width: "111px",
                                        justifyContent: "center",
                                     
                                      }}
                                      onClick={() => handleRId(row.resource_id)}
                                    >
                                      {translation.Show_Score}
                                    </span>
                                  )}
                                </div>
                                <label>
                
                                  {UserDateFormatTimeZone(
                                    row.assessment_date,
                                    timeFormat,
                                    timezoneD
                                  )}{" "}
                                </label>
                                <p className="fw-bolder">
                                  {row.type==="Shoulder" ? translation.shoulder
                                  :row.type ==="Elbow" ?translation.myJourney_Elbow 
                                  :row.type ==="Hand And Wrist" ?translation.Hand_And_Wrist
                                  :row.type ==="Hip" ?translation.hip_form
                                  :row.type ==="Adolescent Knee" ?translation.adolescent_knee
                                  :row.type ==="Foot And Ankle" ?translation.Foot_And_Ankle
                                  :row.type ==="Adult Knee" ? translation.adult_knee
                                  :row.type} -{" "}
                                  {row.evaluted_value === "1"
                                    ? translation.Right
                                    : row.evaluted_value === "2"
                                    ? translation.Left
                                    : translation.Both}
                                </p>
                              </span>
                            </li>
                          ))}
                        </>
                      </ul>
                    </div>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

FormTimeline.defaultProps = {
  type: "",
};

export default FormTimeline;
