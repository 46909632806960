import React, { useState, useEffect } from "react";
import dashboardStyles from "./Dashboard.module.css";
import Journey1 from "../../images/journey1.svg";
import Journey2 from "../../images/journey2.svg";
import Journey3 from "../../images/journey3.svg";
import Journey4 from "../../images/journey4.svg";
import Journey5 from "../../images/journey5.svg";
import Journey6 from "../../images/journey66.svg";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import useTranslation from "../customHooks/translations";
import { yearDates } from "../../utilities/DateFormate";
const Journey = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [store, setStore] = useState({});
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const [areAllFalse, setAreAllFalse] = useState(false);
  const [profile, setProfile] = useState({});
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  let akToken = localStorage.getItem("AkTOKENI");
  const translation = useTranslation()
  // useEffect(() => {
  //   if (data) {
  //     setStore();
  //   }
  // }, [data]);
  const navigate = useNavigate();
  useEffect(() => {
    if (login) {
      setProfile(login.patient);
      localStorage.setItem("patientId",login.patient.ak_id)
    }
  }, [login]);
  let axiosInstance = useAxios();
  const handleRedirectScore = (e) => {
    if (e === "Shoulder") {
      navigate(`/patient-scores?formType=${window.btoa(4)}`);
    } else if (e === "Elbow") {
      navigate(`/patient-scores?formType=${window.btoa(22)}`);
    } else if (e === "Knee") {
      const ageThreshold = yearDates(profile.dob) >= 13 ? 5 : 20;
      navigate(`/patient-scores?formType=${window.btoa(ageThreshold)}`);
      // navigate(`/patient-scores?formType=${window.btoa(5)}`);
    } else if (e == "Hand") {
      navigate(`/patient-scores?formType=${window.btoa(23)}`);
    } else if (e == "Foot") {
      navigate(`/patient-scores?formType=${window.btoa(21)}`);
    } else if (e == "Hip") {
      navigate(`/patient-scores?formType=${window.btoa(6)}`);
    } else if (e === "General") {
      navigate(`/patient-scores?formType=${window.btoa(33)}`);
    }
  };

  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/patient_avalaible_form?ak_id=${akToken}`
      );
      if (result.status === 200) {
        setStore(result.data.data);
      }
    } catch (err) {
      console.log(err.Messages);
    } finally {
      console.log("api response finally");
    }
  };
  useEffect(() => {
    handleConnectMe();
  }, []);

  useEffect(() => {
    // Check if all values in data are false
    const allFalse = Object.values(store).every(value => value === false);
    setAreAllFalse(allFalse);
  }, [store]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="my_visit_screen mb-3">
            <div className="row page_header">
              <div className="col-sm-6 col-md-6">
                <h4 className="ph_title">{translation.myJourney}</h4>
              </div>
            </div>
          </div>
          {areAllFalse ? <div className="panel">
              <div className="panel-body p_25">
                <b><div className="no_data">{translation.You_have_not}</div></b>
              </div>
            </div> :
          <div className={dashboardStyles.dashboard_page}>
            <div className={`row ${dashboardStyles.card_individual_sec}`}>
              {store.shoulder ? (
                <div className={`col-sm-4`}>
                  <div
                    className={`whitebox text-center ${dashboardStyles.whitebox} journey-box`}
                  >
                    <div className="journey-content">
                      <div
                        className="journey-baground c_pointer"
                        onClick={() => handleRedirectScore("Shoulder")}
                      >
                        <img src={Journey1} alt="user-logo" />
                      </div>
                      <h4
                        className="c_pointer"
                        onClick={() => handleRedirectScore("Shoulder")}
                      >
                        {translation.myJourney_Shoulder}
                      </h4>
                      <p className="">
                        {translation.myJourney_Shoulder_txt}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {store.elbow ? (
                <div className={`col-sm-4`}>
                  <div
                    className={`whitebox text-center ${dashboardStyles.whitebox} journey-box`}
                  >
                    <div className="journey-content">
                      <div
                        className="journey-baground c_pointer"
                        onClick={() => handleRedirectScore("Elbow")}
                      >
                        <img src={Journey2} alt="user-logo" />
                      </div>
                      <h4
                        className="c_pointer"
                        onClick={() => handleRedirectScore("Elbow")}
                      >
                        {translation.myJourney_Elbow}
                      </h4>
                      <p className="">
                        {translation.myJourney_Elbow_txt}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {store.handwrist ? (
                <div className={`col-sm-4`}>
                  <div
                    className={`whitebox text-center ${dashboardStyles.whitebox} journey-box`}
                  >
                    <div className="journey-content">
                      <div
                        className="journey-baground c_pointer"
                        onClick={() => handleRedirectScore("Hand")}
                      >
                        <img src={Journey3} alt="user-logo" />
                      </div>
                      <h4
                        className="c_pointer"
                        onClick={() => handleRedirectScore("Hand")}
                      >
                        {translation.myJourney_Hand_Wrist}
                      </h4>
                      <p className="">
                        {translation.myJourney_Hand_Wrist_txt}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {store.hip && (
                <div className={`col-sm-4`}>
                  <div
                    className={`whitebox text-center ${dashboardStyles.whitebox} journey-box`}
                  >
                    <div className="journey-content">
                      <div
                        className="journey-baground c_pointer"
                        onClick={() => handleRedirectScore("Hip")}
                      >
                        <img src={Journey4} alt="user-logo" />
                      </div>
                      <h4
                        className=" c_pointer"
                        onClick={() => handleRedirectScore("Hip")}
                      >
                        {translation.myJourney_Hip}
                      </h4>
                      <p className="">{translation.myJourney_Hip_txt}</p>
                    </div>
                  </div>
                </div>
              )}

              {store.adolescentKnee || store.paediatricKnee ? (
                <div className={`col-sm-4`}>
                  <div
                    className={`whitebox text-center ${dashboardStyles.whitebox} journey-box`}
                  >
                    <div className="journey-content">
                      <div
                        className="journey-baground c_pointer"
                        onClick={() => handleRedirectScore("Knee")}
                      >
                        <img src={Journey5} alt="user-logo" />
                      </div>
                      <h4
                        className="c_pointer"
                        onClick={() => handleRedirectScore("Knee")}
                      >
                        {translation.myJourney_knee}
                      </h4>
                      <p className="">{translation.myJourney_knee_txt}</p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {store.footankle ? (
                <div className={`col-sm-4`}>
                  <div
                    className={`whitebox text-center ${dashboardStyles.whitebox} journey-box`}
                  >
                    <div className="journey-content">
                      <div
                        className="journey-baground c_pointer"
                        onClick={() => handleRedirectScore("Foot")}
                      >
                        <img src={Journey6} alt="user-logo" />
                      </div>
                      <h4
                        className="c_pointer"
                        onClick={() => handleRedirectScore("Foot")}
                      >
                        {translation.myJourney_Foot_Ankle}
                      </h4>
                      <p className="">{translation.myJourney_Foot_Ankle_txt}</p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* <div className={`col-sm-4`}>
                <div
                  className={`whitebox text-center ${dashboardStyles.whitebox} journey-box`}
                >
                  <div className="journey-content">
                    <div
                      className="journey-baground c_pointer"
                      onClick={() => handleRedirectScore("General")}
                    >
                      <img src={Journey1} alt="user-logo" />
                    </div>
                    <h4
                      className="c_pointer"
                      onClick={() => handleRedirectScore("General")}
                    >
                      General
                    </h4>
                    <p className="">
                      Select Genaral to view your Genaral journey
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          }
        </>
      )}
    </>
  );
};

export default Journey;
