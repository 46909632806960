import React, { useEffect , useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import AppRouter from "./AppRouter";
import useTranslation from "./components/customHooks/translations.js";
                
import Welcome from "./Welcome";
import LoadingSpinner from "./components/custom/loader/LoadingSpinner";

// import { useHistory } from 'react-router-dom';
function App() {
  const { keycloak, initialized } = useKeycloak();
  // const [isLoading, setIsLoading] = useState(true);
  const translation = useTranslation();
  const [currentBrowser, setCurrentBrowser] = useState('');
  const [currentOS, setCurrentOS] = useState('');
  // const history = useHistory();
  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Android')) {
      setCurrentOS('Android');
    } else if (/(iPhone|iPad|iPod)/.test(userAgent)) {
      setCurrentOS('iOS');
    } else {
      setCurrentOS('Unknown OS');
    }

    if (userAgent.includes('Chrome')) {
      setCurrentBrowser('Google Chrome');
    } else if (userAgent.includes('Firefox')) {
      setCurrentBrowser('Mozilla Firefox');
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      setCurrentBrowser('Apple Safari');
    } else if (userAgent.includes('Edge')) {
      setCurrentBrowser('Microsoft Edge');
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR/')) {
      setCurrentBrowser('Opera');
    } else {
      setCurrentBrowser('Unknown Browser');
    }
  }, []);
  
  const isiOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };
  const isAndroid = () => {
    return /Android/.test(navigator.userAgent);
  };

  const redirectToApp = () => {
    const deepLink1 = 'com.akunahme'; 
    const deepLink2= 'com.akunah.me';
    const appStoreLink = 'https://apps.apple.com/us/app/akunah-me/id6467181621'; 
    const playStoreLink = 'https://play.google.com/store/apps/details?id=com.akunahme'; 

    if (isiOS()) {
      // User is on iOS, redirect to the app or App Store
      window.location.href = deepLink2;

      
    } else if (isAndroid()) {
      // User is on Android, redirect to the app or Play Store
      window.location.href = deepLink1;
    
    } else {
      // User is on neither iOS nor Android, handle appropriately (optional)
      // You can choose to redirect to a fallback page or display a message
      // history.push('/fallback'); // Use React Router to navigate to a fallback page
    }
  };


  useEffect(() => {
    // redirectToApp();
    sessionStorage.setItem("isConsent", "");
  }, []);

  
  useEffect(() => {
    setTimeout(() => {
      // setIsLoading(false);
    }, 2500);

  }, []);
  
  if (!initialized) {
    return <LoadingSpinner/>
  }

  return (
    <>
{/* <p>{currentBrowser} / {currentOS}</p> */}
      {!keycloak.authenticated && <Welcome />}

      {!!keycloak.authenticated && <AppRouter />}
    </>
  );
}

export default App;
