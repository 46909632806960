import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import scoreWidgetStyles from "./ScoreWidget.module.css";
import viewIcon from "../../images/view-right-arrow.svg";
import Move from "../../images/Move.svg";
import HideIcon from "../../images/Hide.svg";
import GradientSVG from "./GradientSVG";
import {
  FiveColor,
  ScoreColor,
  vasColor,
  CircleColor,
} from "../../utilities/DocumentType";
const handleForm = (rowId, formID, doctorId, patientId) => {
  if (formID === 4) {
    window.open(
      process.env.REACT_APP_FORM_URL +
        "shoulder-view-treatment-form" +
        `/${rowId}?doctor=${doctorId}&patient=${patientId}&management=true&is_doctor_form=true`,
      "_blank"
    );
  }
  if (formID === 6) {
    window.open(
      process.env.REACT_APP_FORM_URL +
        "hip-view-treatment-form" +
        `/${rowId}?doctor=${doctorId}&patient=${patientId}&management=true&is_doctor_form=true`,
      "_blank"
    );
  }
  if (formID === 21) {
    window.open(
      process.env.REACT_APP_FORM_URL +
        "foot-ankle-view-treatment-form" +
        `/${rowId}?doctor=${doctorId}&patient=${patientId}&management=true&is_doctor_form=true`,
      "_blank"
    );
  }
  if (formID === 22) {
    window.open(
      process.env.REACT_APP_FORM_URL +
        "elbow-view-treatment-form" +
        `/${rowId}?doctor=${doctorId}&patient=${patientId}&management=true&is_doctor_form=true`,
      "_blank"
    );
  }
  if (formID === 23) {
    window.open(
      process.env.REACT_APP_FORM_URL +
        "hand-wrist-view-treatment-form" +
        `/${rowId}?doctor=${doctorId}&patient=${patientId}&management=true&is_doctor_form=true`,
      "_blank"
    );
  }
  if (formID === 20) {
    window.open(
      process.env.REACT_APP_FORM_URL +
        "pediaknee-view-treatment-form" +
        `/${rowId}?doctor=${doctorId}&patient=${patientId}&management=true&is_doctor_form=true`,
      "_blank"
    );
  }
  if (formID === 5) {
    window.open(
      process.env.REACT_APP_FORM_URL +
        "adultknee-view-treatment-form" +
        `/${rowId}?doctor=${doctorId}&patient=${patientId}&management=true&is_doctor_form=true`,
      "_blank"
    );
  }
};

const ScoreWidget = ({
  title,
  path_color,
  score,
  shoulder,
  date,
  form_url,
  resID,
  formType,
  doctorId,
  patientId,
  total,
  scoreColor,
  score_name,
  hideData,
  reverse,
  min_score,
  eq5d5l,
}) => {
  // const value = 0.66;
  // console.log(total , typeof total)
  let res = reverse;

  return (
    <div className={`score_widget_sec ${scoreWidgetStyles.scoreWidgetSec}`}>
      <div className={`whitebox ${scoreWidgetStyles.whitebox}`}>
        <div className="row mb-2">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-6 text-start">
                <img
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Drag & Drop"
                  className="icon_style"
                  src={Move}
                  alt="user-logo"
                  width={"7px"}
                />
                {/* <img
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Hide a item"
                  className="icon_style ms-4 c_pointer"
                  src={HideIcon}
                  alt="user-logo"
                  width={"15px"}
                  onClick={() => hideData(title)}
                /> */}
              </div>
              <div className="col-6 text-end">
                <span
                  onClick={() =>
                    handleForm(resID, formType, doctorId, patientId)
                  }
                  className="c_pointer "
                >
                  View Form <img src={viewIcon} className="ml_4" alt="icon" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className={`col-md-6 ${scoreWidgetStyles.scorebox_title}`}>
            <h3>{title}</h3>
            <label className={`col-md-6 ${scoreWidgetStyles.is_target_text}`}>
              {shoulder}
            </label>
            <h4>
              <strong>
               {score_name === 'TignerRight' || score_name ===  "TignerLeft" ? <span className="f_45"> {"Level " + score} </span> :   
                eq5d5l ? <span className="f_45"> {"Level " + score} </span> : score}
              
              </strong>
            </h4>
            <label>
              {score} out of {total}
            </label>
          </div>
          <div className={`col-md-6 ${scoreWidgetStyles.skill_box}`}>
            <div style={{ width: 140, height: 140, margin: "0 auto" }}>
              <GradientSVG
                c1={
                  total.toString() === "5"
                    ? FiveColor(Number(score), res)
                    : total.toString() === "10"
                    ? vasColor(Number(score), res)
                    : ScoreColor(Number(score), res)
                }
                title={score}
              /> 
              {title ==='VAS' ?<CircularProgressbar
                value={CircleColor(total, score, res, min_score)=== 0 ? 10 : CircleColor(total, score, res, min_score)}
                maxValue={total}
                minValue={min_score}
                text={score.toString()}
                strokeWidth="13"
                // styles={buildStyles({
                //   textColor: "#000",
                //   pathColor: path_color,
                // })}
                styles={{
                  path: {
                    stroke: `url(#${score})`,
                    height: "100%",
                  },
                  trail: {
                    stroke: "#fffff",
                  },
                }}
              /> : 
              <CircularProgressbar
                value={CircleColor(total, score, res, min_score)}
                maxValue={total}
                minValue={min_score}
                text={score.toString()}
                strokeWidth="13"
                // styles={buildStyles({
                //   textColor: "#000",
                //   pathColor: path_color,
                // })}
                styles={{
                  path: {
                    stroke: `url(#${score})`,
                    height: "100%",
                  },
                  trail: {
                    stroke: "#fffff",
                  },
                }}
              /> }
            </div>
          </div>
        </div>

        <div className="row">
          <p className={`mb_0 ${scoreWidgetStyles.skillbox_btm_title}`}>
            <strong>{title} • </strong> <small>{date}</small>
          </p>
        </div>
      </div>
    </div>
  );
};

ScoreWidget.defaultProps = {
  title: "",
  path_color: "",
  score: "",
  shoulder: "",
  date: "",
  form_url: "",
  resID: "",
  formType: "",
  doctorId: "",
  patientId: "",
};

export default ScoreWidget;
