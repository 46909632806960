import React, { useState, useEffect } from "react";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useTranslation from "../customHooks/translations";
import ConsentTable from "./ConsentTable";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";

const MyConsent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const translation = useTranslation();
    const axiosInstance = useAxios();
    let akToken = localStorage.getItem('AkTOKENI')
    const [timezoneD, setTimeZone] = useState("");
    const [timeFormat, setTimeFormat] = useState("");
    const login = useSelector((state) => state.loginPatient.arr[0]);
    const [profile, setProfile] = useState("");

    const [reff, setReff] = useState("")

    const [data, setData] = useState([])
    const getConscentdata = async () => {
        try {
            const result = await axiosInstance.current.get(
                `extapp/patients/getUserConsent?ak_id=${akToken}`

            );

            if (result) {
                setData(result.data.result);
                console.log(result.data, "result");
            }

        } catch (err) {
            console.log(err.message || "Unexpected Error!");
        } finally {
            setIsLoading(false);
            console.log("api response finally");
        }


    }


    // useEffect(() => {
    //     setTimeout(() => {
    //     }, 1000);
    // }, []);

    useEffect(() => {
     
        getConscentdata()

    }, [reff])

    useEffect(() => {
        if (login) {
            setProfile(login.patient);
            setTimeZone(login.patient.timezone);
            setTimeFormat(login.patient.timeformat);
        }
    }, [login]);


    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="">
                        <div className="my_visit_screen">
                            <div className="row page_header">
                                <div className="col-sm-6 col-md-6">
                                    <h4 className="ph_title">{translation.my_consent}</h4>

                                </div>
                            </div>
                        </div>
                        <div className="whitebox ps-0">
                            {data.length !== 0 ? (

                                <>
                                    <div className="my_visit_screen mb_100">
                                        <ConsentTable data={data}
                                            timezoneD={timezoneD}
                                            timeFormat={timeFormat}
                                            setReff={setReff}
                                            reff={reff} />
                                    </div>
                                </>

                            ) : (
                                // <UpcomingVisitTable data={datas} />
                                <div className="panel-body p_25">
                                    <div className="no_data">{translation.no_conscent_found}</div>
                                </div>
                            )}
                        </div>

                    </div>

                </>
            )
            }
        </>
    );

}

export default MyConsent


