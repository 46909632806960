import React, { useEffect, useState } from "react";
import { useLanguageContext } from "./contexts/LanguageContext";
import { loginData } from "../redux/actions";
// import Dropdown from "./custom/dropdown/Dropdown";
import { useDispatch } from "react-redux";
import Dropdownno from "./custom/dropdown/Dropdownno";
import useAxios from "../axiosinstance";
import useTranslation from "./customHooks/translations";
import Message from "./custom/toster/Message";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
export default function LanguageHandler({ langData }) {
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const dispatch = useDispatch();
  const { language, changeLanguage } = useLanguageContext();
  const axiosInstance = useAxios();
  // const login = useSelector((state) => state.loginPatient.arr[0]);
  let akToken = localStorage.getItem("AkTOKENI");
  let akLang = sessionStorage.getItem("lang_");
  const translation = useTranslation();

  // useEffect(() => {
  //   sessionStorage.setItem("lang_", login.patient.user_language);
  // }, [login]);
 
  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getDetailsOfPatient?patient_id=${akToken}&use_for=patient`
      );
      if (result.status === 200) {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          setMessage(translation.profile_updated);
          setMessageType("success");
        } else {

        }
      }
    } catch (err) {

 

    } finally {
      
    }
  };
  // useEffect(()=>{
  //   handleConnectMe()
  // },[respondata])
  const [data, setData] = useState("")
  const handleChangeLanguage = (e) => {
    changeLanguage(e);
  
    sessionStorage.setItem("lang_", e);
    let lan = langData.filter((val) => val.value === e);
    setData(lan[0].id)
  };

useEffect(()=>{
  if(language){
    let lan=langData.filter((val) => val.value === language)[0]?.id
    sessionStorage.setItem("lang_id",lan?lan:'' )
  }
},[language,langData?.length])


//keycloak language change
// const { keycloak } = useKeycloak();
// function decodeJWT(token) {
//   const base64Url = token.split('.')[1];
//   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
//     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));

//   return JSON.parse(jsonPayload);
// }
// const decodedToken = decodeJWT(keycloak.token);

//   let key_cloak_lan=language=='ger'?'de':language=='ara'?'ar':language=='ita'?'it':language=='spa'?'es':language=='tha'?'th':language=='kor'?'ko':language=='ch'?'zh':language=='japan'?'ja':language=='dth'?'nl':language=='fr'?'fr':language=='en'?'en':'en'
// const Change_Keycloak_Language = async () => {
//   try {
//     const userId = decodedToken.sub; 
    
//     const response = await axios.put(
//       `https://accounts.akunah.com/auth/admin/realms/akunah-dev/users/${userId}`,
//       {
//         attributes: {
//           locale: [key_cloak_lan], 
//         },
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${keycloak.token}`, 
//           'Content-Type': 'application/json',
//         },
//       }
//     );
//     console.log('Language changed successfully:', response.data);
//   } catch (err) {
//     console.error('Error changing language:', err);
//   }
// };


  useEffect(() => {
    const Save = async () => {
      if (data) {
        await axiosInstance.current
          .get(`extapp/patients/saveUserLanguage/${akToken}?lang_id=${data}&lan_check=1`)
          .then((res) => {
            if (res) {
              // Change_Keycloak_Language()
              handleConnectMe()
              // setRespondata(new Date().getMilliseconds())
            }



          })
          .catch((err) => {
            setMessage(translation.profile_not_updated);
            setMessageType("error");
          });
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      }
    }
    Save()
  }, [data])


  useEffect(() => {
    if (akLang !== null)
      changeLanguage(akLang)
  }, [akLang])
  console.log(language,langData,"lahnguages")
  return (
    <>
      <div className="lang_ ">
        {message && <Message message={message} type={messageType} />}
        <Dropdownno
          data={langData}
          label={""}
          styleClass="language_drp_dwn"
          value={language}
          onChange={handleChangeLanguage}
        />
        {/* <select value={language} onChange={(e) => changeLanguage(e.target.value)} className="form-control form-select">
           <option value="en">En- English </option>
           <option value="fr">Fr- France </option>
         </select> */}
      </div>
      {/* 
      <div className="lang_ d-block d-lg-none">
        <select value={language} onChange={(e) => changeLanguage(e.target.value)}>
          <option value="en">En </option>
          <option value="fr">Fr </option>
        </select>
      </div> */}
    </>
  );
}
