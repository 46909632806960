
import moment from "moment-timezone";
export const UserDateFormat = (date, dateformate) => {
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  if (dateformate === "1") {
    // "DD/MM/YYYY"
    return new Date(date).toLocaleDateString("en-GB", { timeZone: "UTC" });
  }
  if (dateformate === "2") {
    let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone: "UTC" });
    // "MM/DD/YYYY"
    let y = mmdd.slice(6, Infinity);
    let m = mmdd.slice(3, 6);
    let d = mmdd.slice(0, 3);
    return `${m}${d}${y}`;
  } else {
    let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone: "UTC" });
    // "YYYY/MM/DD"
    let y = mmdd.slice(6, Infinity);
    let m = mmdd.slice(3, 6);
    let d = mmdd.slice(0, 2);
    return `${y}/${m}${d}`;
  }
};

export const DateFormate = (item) => {
  return `${new Date(item).getDate() + 1 >= 10
    ? `${new Date(item).getDate()}`
    : `0${new Date(item).getDate()}`
    }/${new Date(item).getMonth() + 1 >= 10
      ? `${new Date(item).getMonth() + 1}`
      : `0${new Date(item).getMonth() + 1}`
    }/${new Date(item).getFullYear()}`;
};



export const yearDate = (item) => {
  const birthDate = new Date(item);
  const currentDate = new Date();
  const ageDiff = currentDate - birthDate;
  const ageDate = new Date(ageDiff);
  const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
  return calculatedAge;
};

export const DobFormeat = (dobformeat) => {
  let y = dobformeat.slice(0, 4);
  let m = dobformeat.slice(5, 7);
  let d = dobformeat.slice(8, 10);
  return `${y}-${m}-${d}T10:00:00.000Z`;
};

const dateChange = (i, timeZone) => {
  return moment(i)
    .tz(timeZone)
    .format("YYYY-MM-DDTHH:mm:ss");
};

export const dateChangeByTimezone = (i, timeZone) => {
  return moment(i)
    .tz(timeZone)
    .format("YYYY-MM-DDTHH:mm:ss");
};
export const HoursMinutesFormat = (d, s, timeZone) => {
  const timeString = dateChange(d, timeZone);
  const date = new Date(timeString);
  const time = date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: s,
  });
  return time;
};

export const dateChangeUtc = (da) => {
  let strStartdate = String(da);
  let startdateConv = `${strStartdate.slice(0, 3)}, ${strStartdate.slice(
    4,
    7
  )} ${strStartdate.slice(8, 10)}, ${strStartdate.slice(11, 15)}`;
  return startdateConv;
};
export const validateDOB = (dob) => {
  // Regular expression for "DD/MM/YYYY" format
  const dobRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  // Check if the input matches the expected format
  if (!dobRegex.test(dob)) {
    return false; // Invalid format
  }
  // Extract day, month, and year components
  const [day, month, year] = dob.split('/').map(Number);
  // Get the current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
  const currentDay = currentDate.getDate();

  // Check if the year is within a reasonable range (e.g., not in the future)
  if (year < 1900 || year > currentYear) {
    return false; // Year out of range
  }

  // Check if the month is valid (between 1 and 12)
  if (month < 1 || month > 12) {
    return false; // Invalid month
  }

  // Check if the day is valid for the given month and year
  const maxDaysInMonth = new Date(year, month, 0).getDate();
  if (day < 1 || day > maxDaysInMonth) {
    return false; // Invalid day
  }

  // Check if the date is in the future
  if (year > currentYear || (year === currentYear && (month > currentMonth || (month === currentMonth && day >= currentDay)))) {
    return false; // Date is in the future
  }

  // If all checks pass, the DOB is valid
  return true;
};



export const Hours_Minutes_Format = (d, timeFormat) => {
  if (d) {
    if (!timeFormat) {
      const date = new Date(d);
      let hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      return `${formattedHours}:${formattedMinutes}`;
    } else {
      const date = new Date(d);
      let hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const seconds = date.getUTCSeconds();
      let ampm = "AM";

      if (hours >= 12) {
        ampm = "PM";
        if (hours > 12) {
          hours -= 12;
        }
      }

      if (hours === 0) {
        hours = 12;
      }

      // Format minutes with leading zero if less than 10
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedHours = hours < 10 ? `0${hours}` : hours;

      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

  }
};
function convertDateFormat(inputDate, dateformate) {
  // if(inputDate ){
  const dateParts = inputDate.match(/\w+/g); // Extract individual parts of the input date


  const day = dateParts[2].padStart(2, '0'); // Pad day with leading zero if needed
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const month = (monthNames.indexOf(dateParts[1]) + 1).toString().padStart(2, '0'); // Convert month name to a number and pad with a leading zero
  const year = new Date().getFullYear(); // Use the current year or provide the year as needed
  if (dateformate === "1") {
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }
  if (dateformate === "2") {
    // "MM/DD/YYYY"
    return `${month}/${day}/${year}`;

  } if (dateformate === "3") {
    // "YYYY/MM/DD"
    return `${year}/${month}/${day}`;
    // }
  }

}
export const DateFormatWithTimeZone = (dateformate, timeZone) => {
  const event = new Date();
  const options = {
    day: 'numeric',
    month: 'long',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: timeZone ? timeZone : "australia/brisbane",
  };
  let date1 = new Intl.DateTimeFormat('en-US', options).format(event)
  return convertDateFormat(date1, dateformate)

};

export const yearDates = (item) => {
  const birthDate = new Date(item);
  const currentDate = new Date();
  const ageDiff = currentDate - birthDate;
  const ageDate = new Date(ageDiff);
  const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
  return calculatedAge;
};

export const getTimeDifference = (date) => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - inputDate;

  // Convert milliseconds to seconds
  const seconds = Math.floor(timeDifference / 1000);

  // Convert seconds to minutes
  const minutes = Math.floor(seconds / 60);

  // Convert minutes to hours
  const hours = Math.floor(minutes / 60);

  // Convert hours to days
  const days = Math.floor(hours / 24);

  // Convert days to months
  const months = Math.floor(days / 30); // Assuming 30 days per month

  const years = Math.floor(days / 365); // Assuming 365 days per year

  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    return "Just now";
  }
}

export const UserDateFormatTimeZone = (date, dateformate, timezone) => {
  // const userDetails = useUserDetails()
  // console.log(userDetails, "userDetailsuserDetailsuserDetailsuserDetailsHHHH");
  if (dateformate === "1") {
    // "DD/MM/YYYY"
    // return new Date(date).toLocaleDateString("en-GB", { timeZone:userDetails.ing_timezone });
    let dates = moment(date).tz(timezone)?.format("YYYY-MM-DDTHH:mm:ss");
    const inputDate = new Date(dates);
    const year = inputDate.getFullYear();
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const convertedDateString = `${day}/${month}/${year}`;
    return convertedDateString;
  }
  if (dateformate === "2") {
    // let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone:timezone });
    let dates = moment(date).tz(timezone)?.format("YYYY-MM-DDTHH:mm:ss");
    // "MM/DD/YYYY"
    const inputDate = new Date(dates);
    const year = inputDate.getFullYear();
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const convertedDateString = `${month}/${day}/${year}`;
    return convertedDateString;
  } else {
    // let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone:timezone });
    let dates = moment(date).tz(timezone)?.format("YYYY-MM-DDTHH:mm:ss");
    // "YYYY/MM/DD"
    const inputDate = new Date(dates);
    const year = inputDate.getFullYear();
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const convertedDateString = `${year}/${month}/${day}`;
    return convertedDateString;
  }
};
// // Example usage:
// const inputDate = "2024-01-17T12:00:00"; // Replace this with your actual date string
// const result = getTimeDifference(inputDate);
// console.log(result);
