function calculateDaysDifference(startDateString, endDateString) {
    let startDate = new Date(startDateString);
    let endDate = new Date(endDateString);

    let timeDifference = endDate - startDate;

    let differenceInDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

    return differenceInDays;
}

export function checkExpiration(dateString,translation) {
    let differenceInDays = calculateDaysDifference(dateString, new Date());

    if(differenceInDays>14){
        return translation.Expired
    }else if(14-differenceInDays===5 || 14-differenceInDays===4 || 14-differenceInDays===3 || 14-differenceInDays===2 || 14-differenceInDays===1){
        return `${translation.Expiring_in} ${14-differenceInDays} ${14-differenceInDays===1?translation.day_pdata :translation.days_pdata}`
        // console.log(`${14-differenceInDays}ghg`)
    }
    else if(14-differenceInDays===0){
 return `${translation.Expiring_in_Tomorrow}`
    }
    else{
        return translation.progress
    }
  }

  export function TooltipExpiration(dateString,translation){
    let differenceInDays = calculateDaysDifference(dateString, new Date());
    if(14-differenceInDays===0){
 return `${translation.Expiring_in_Tomorrow}`
    }else{
    return `${translation.Expiring_in} ${14-differenceInDays} ${14-differenceInDays===1?translation.day_pdata :translation.days_pdata}`
    }
  }


  export const calculateTimeDifference = (uploadTime) => {
    const currentTime = new Date();
    const uploadedDate = new Date(uploadTime);
  
    const timeDifference = currentTime - uploadedDate;
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  
   if (currentTime.getDate() === uploadedDate.getDate() && currentTime.getMonth() === uploadedDate.getMonth() && currentTime.getFullYear() === uploadedDate.getFullYear()) {
      const hoursDifference = Math.floor(minutesDifference / 60);
      return `${24-hoursDifference} hour${hoursDifference === 1 ? '' : 's'} left`;
    } 
  };
  
 