import React, { useState } from "react";
import ReactPlayer from "react-player";
import useTranslation from "../../customHooks/translations.js";
const VideoPlayer = ({ url }) => {
  const translation = useTranslation();
  const [state, setPlayed] = useState();
  
  return (
    <div>
      {url !== "" ? (
        <>

        {/* <ReactPlayer
          playing={true}
          url={url}
          pip={false}
          muted={false}
          onProgress={(progress) => {
            setPlayed(progress.playedSeconds);
          }}
          controls
          width="100%"
          height="100%"
          style={{ backgroundColor: "black" }}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
          autoPlay
          disablepictureInPicture
          controlslist="nodownload noplaybackrate"
          data-setup="{controllBar: {pictureInPictureTopostle: false}}"
          duration="false"
        /> */}

        <div>
          <video width="100%" controls autoplay controlsList="nodownload">
            <source src={url} type="video/mp4" />
            <source src="path/to/video.webm" type="video/webm" />
            {translation.your_browser_does_not_support_the_video}
          </video>
        </div>
        </>
      ) : (
        translation.loading
      )}
    </div>
  );
};

export default VideoPlayer;
