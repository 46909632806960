import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import rcpStyles from "./ResultCircleProgress.module.css";
import PropTypes from "prop-types";
import GradientSVG from "./GradientSVG";
import useTranslation from "../../customHooks/translations.js";
import file from "../../../images/file.svg"
import {
  vasColor,
  ScoreColor,
  FiveColor,
  CircleColor,
} from "../../../utilities/DocumentType";
import { useSelector } from "react-redux";

const ResultCircleProgress = ({
  title,
  value,
  color,
  type,
  date,
  arm,
  total,
  text,
  reverse,
  min_score,
  resource_id,
}) => {
  let res = reverse;
  const translation = useTranslation();

  const login = useSelector((state) => state.loginPatient.arr[0]);

  const handleForm = ( doctor_id) => {

    if (type === 4) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "shoulder-view-treatment-form" +
          `/${resource_id}?patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 6) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "hip-view-treatment-form" +
          `/${resource_id}?patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 21) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "foot-ankle-view-treatment-form" +
          `/${resource_id}?patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 22) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "elbow-view-treatment-form" +
          `/${resource_id}?patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 23) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "hand-wrist-view-treatment-form" +
          `/${resource_id}?patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 20) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "pediaknee-view-treatment-form" +
          `/${resource_id}?patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 5) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "adultknee-view-treatment-form" +
          `/${resource_id}?patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 33) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "general-treatment-view" +
          `/${resource_id}?patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
  };
  return (
    <>
      <div className={`${rcpStyles.result_scorebox_col}`}>
        <div className={`${rcpStyles.top_content}`}>
          <div className={`${rcpStyles.scorebox_title}`}>
            <h4 className="mb-0">
              {title === "Function" ? translation.patient_shoulder_results_function : title === "Pain" ? translation.patient_shoulder_results_pain : title === "Instability" ? translation.patient_foot_ankle_results_feeling_of_instability : title === "Feeling of instability" ? translation.patient_foot_ankle_results_feeling_of_instability : translation.patient_hip_results_health_perception}

              <img src={file} onClick={()=>handleForm()}  alt="view" data-bs-toggle="tooltip" data-bs-placement="top" title="View Form" style={{position:"absolute",right:"0",top:"0",width:"18px"}} />

              <p className="circle_para mb-0">
                {arm === "Right"
                  ? translation.Right
                  : arm === "Both"
                    ? translation.Both : arm === "Left" ?
                      translation.Left : ""}
              </p>
            </h4>



            <strong>{value}</strong> <br /> {value} {translation.out_of} {total}

          </div>
          <div className={`${rcpStyles.result_skill_box}`}>
            <GradientSVG
              c1={
                total.toString() === "5"
                  ? FiveColor(Number(value), res)
                  : total.toString() === "10"
                    ? vasColor(Number(value), res)
                    : ScoreColor(Number(value), res)
              }
              title={value}
            />
            {title ==="Pain" || title === "Instability" ? 
            <CircularProgressbar
              className={`${rcpStyles.pr_circile}`}
              // value={CircleColor2(total, value, res, min_score)}
              value={total-value+min_score}
              // maxValue={100}
              text={`${text.toString()}`}
              strokeWidth="11"
              maxValue={total}
              min_score={min_score}
              styles={{
                path: {
                  stroke: `url(#${value})`,
                  height: "100%",
                },
                trail: {
                  stroke: "#fffff",
                },
              }}
            />:
             <CircularProgressbar
              className={`${rcpStyles.pr_circile}`}
              value={CircleColor(total, value, res, min_score)}
              // maxValue={100}
              text={`${text.toString()}`}
              strokeWidth="11"
              maxValue={total}
              min_score={min_score}
              styles={{
                path: {
                  stroke: `url(#${value})`,
                  height: "100%",
                },
                trail: {
                  stroke: "#fffff",
                },
              }}
            />
            }
           
          </div>
        </div>
        <span className={`${rcpStyles.btm_content}`}>
          {translation.Scoring_Self_Evaluation}{" "}
          <small>
            <b>•</b> {date}
          </small>
        </span>
      </div>
    </>
  );
};

ResultCircleProgress.propTypes = {
  title: PropTypes.string,
};
ResultCircleProgress.defaultProps = {
  title: "",
  value: "",
  color: "",
  type: "",
  date: "",
  arm: "",
  // type: "",
  total: "100",
};

export default ResultCircleProgress;
