import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../custom/inputfield/InputField";
import { Validators } from "../../utilities/Validator";
import Button from "../custom/button/Button";
import useAxios from "../../axiosinstance";
import Dropdown from "../custom/dropdown/Dropdown";
import { Form } from "react-bootstrap";
import { loginData, loginVisitsData } from "../../redux/actions/index";
import { useDispatch } from "react-redux";
import useTranslation from "../customHooks/translations";
import { fetchUserIdSuccess } from "../../redux/actions/index";
import { dateChangeUtc, validateDOB } from "../../utilities/DateFormate";
import Message from "../custom/toster/Message";
import { spacialCharReplace } from "../../utilities/Validator";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US"; // Import the desired locale
import fr from "date-fns/locale/fr";
import ar from "date-fns/locale/ar";
import de from "date-fns/locale/de";
import it from "date-fns/locale/it";
import es from "date-fns/locale/es";
import th from "date-fns/locale/th";
import YourComponent from "../YourComponent";
import { Modal } from "react-bootstrap";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import Select from "react-select";

function DoctorPersonalInfo() {
  registerLocale("en-US", enUS); // Register the locale with date-fns
  registerLocale("fr", fr);
  registerLocale("ar", ar);
  registerLocale("de", de);
  registerLocale("it", it);
  registerLocale("es", es);
  registerLocale("th", th);
  //  userData = "";
  const [button1Disabled, setButton1Disabled] = useState(false);
  const [button2Disabled, setButton2Disabled] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [value, setvalue] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [Refress, setRefress] = useState(false);
  const [selectedLang, setSelectedLang] = useState("");
  const [mobileErr, setmobileErr] = useState(false);
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [count, setCount] = useState("");
  const [count1, setCount1] = useState("");
  const [userData, setUserData] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState(false);
  const [doctor, setDoctor] = useState("");
  const [formVaild, setFormVaild] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [sex, setSex] = useState("");
  const [countryData, setCountrydata] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [countryCode_data, setCountryCode_data] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [dobError, setDobError] = useState(true);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const maxDate = new Date();
  const sexRef = useRef();
  const countryCodeRef = useRef();
  const mobileNumRef = useRef();
  const navigate = useNavigate();

  const handleFirstName = (e, err) => {
    // const value = e.target.value;
    // setFirstName(value);
    setFirstName(e);
    setFirstNameErr(err);
    setFormVaild(e.error ? e.error : false);
    setMessage("");
  };
  const handleLastName = (e, err) => {
    setLastName(e);
    // const value = e.target.value;
    // setLastName(value);
    setLastNameErr(err);
    setFormVaild(e.error ? e.error : false);
  };

  const handleSex = (e) => {
    setSex(e);
  };

  const DobFormate = (value, slice) => {
    // Check if the value contains slashes
    const hasSlashes = value.includes("/");
    const hasSlashes2 = value.lastIndexOf("/");

    // Clean the value by removing all non-digits
    const cleanedValue = value.replace(/\D/g, "");
    let formattedValue = cleanedValue.slice(0, 2);
    // If the slice parameter is provided, use that slice
    if (slice) {
      formattedValue = cleanedValue.slice(slice[0], slice[1]);
    } else {
      // Otherwise, use the default slice
      formattedValue = cleanedValue.slice(0, 2);
    }

    // If the value has slashes, return the original value
    if (hasSlashes) {
      return value;
    } else {
      return formattedValue;
    }
  };

  const handleDob = (e) => {
    // let value = e;
    let value = e;
    let value2 = value;
    value = value.replace(/\D/g, "/");

    const day = parseInt(value.slice(0, 2), 10);
    const month = parseInt(value.slice(2, 4), 10);

    if (day > 31 || month > 12) {
      setDobError(false); // Set validation state to false if day or month is invalid
      // value = "";
    } else {
      setDobError(true); // Reset validation state if day and month are valid
    }

    if (value) {
      const hasSlashes = value.includes("/");

      if (hasSlashes) {
        // Check if the length is 2 and add a leading "0" if needed
        if (value.length === 2) {
          value = "0" + value;
        }

        // Split the value into parts based on slashes
        const parts = value.split("/");

        // Ensure the month part has two digits
        if (parts.length === 3) {
          parts[1] = parts[1].padStart(2, "0");
        }

        // Reconstruct the value with formatted month
        value = parts.join("/");
      }

      value =
        value.replace(/\//g, "").substring(0, 2) +
        (value.length > 2 ? "/" : "") +
        value.replace(/\//g, "").substring(2, 4) +
        (value.length > 5 ? "/" : "") +
        value.replace(/\//g, "").substring(4, 8);
    }

    setStartDate(value);

    if (validateDOB(value)) {
      setDobError(true);
    } else {
      setDobError(false);
    }
  };

  const handleCountryCode = (e) => {
    setCountryCode(e);
    setCountryCode_data(e.value);
  };


  const handleMobileNum = (e, err) => {
    const value = e;
    setMobileNum(value);
    setmobileErr(err);
  };
  const handleCloseShow = () => {
    setModelShow(false);
  };
  const handleCloseShow1 = () => {
    // alert(1)
    // return;
    getData2();
    setModelShow1(false);
    navigate("/patient-registration");
    localStorage.removeItem("AkTOKENI");
    setFirstName("");
    setLastName("");
    setStartDate("");
    setSex("");
  };

  const redirectToHome = () => {
    navigate("/visit-clinic");
    // window.location.reload(false);
  };
  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getDetailsOfPatient?patient_id=${doctor}&use_for=patient`
      );
      if (result.status === 200) {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          dispatch(loginVisitsData(result.data));
          localStorage.setItem("AkTOKENI", doctor);
          sessionStorage.setItem(
            "lang_",
            result.data.data[0].patient.user_language
          );
          sessionStorage.setItem(
            "isConsent",
            result.data.data[0].patientInfo.isConsent
          );
          redirectToHome();
        }
      }
    } catch (err) {
      // setMessage(err.message || "Unexpected Error!");
      console.log(err.Messages);
      // setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };
  const getData = async () => {
    try {
      const response = await axiosInstance.current.post(
        "extapp/patients/pat_signin"
      );
      if (response) {
        navigate(`/`);
      }
      // sessionStorage.setItem("isConsent","true");
      dispatch(fetchUserIdSuccess(response.data));
      setCount1(response.data.count);
    } catch (error) {}
  };

  const getData2 = async () => {
    try {
      const response = await axiosInstance.current.post(
        "extapp/patients/pat_signin"
      );
      // sessionStorage.setItem("isConsent","true");
      if (response) {
        if (response.data.count === 0) {
          setFirstName(response.data.fn);
          setLastName(response.data.ln);
          setCount(response.data.count);
        }
      }
      setUserData(response.data.count);
      // You can dispatch or perform other actions with these values
      // dispatch(fetchUserIdSuccess({ firstName, lastName }));
    } catch (error) {
      // Handle error if needed
      // setMessage(error.message);
      // setMessageType("error");
      // console.log(error.message);
    }
  };
  useEffect(() => {
    getData2();
  }, []);

  const dobCheck = (dateString) => {
    let dateParts = dateString.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    // return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    if (dateParts) {
      let day = dateString.slice(0, 2);
      let month = dateString.slice(3, 5);
      let year = dateString.slice(6, Infinity);
      return `${month}/${day}/${year}`;
    }
  };
  let ddddd = dobCheck(startDate);

  const handleContinue1 = (e) => {
    // alert(2)
    // return;

    getData();
    setTimeout(() => {
      handleConnectMe();
      setModelShow1(false);
      setButton1Disabled(true);
      setButton2Disabled(true);
    }, 1000);
    // // redirectToHome();
    // // handleConnectMe();
    // navigate("/visit-clinic");
    // // localStorage.setItem("AkTOKENI", res.data.ak_id);
  };
  setTimeout(() => {
    // Enable both buttons after some asynchronous action
    setButton1Disabled(false);
    setButton2Disabled(false);
  }, 1000);

  var date = new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  var formattedDate = `${getTimezoneOffset().slice(
    0,
    3
  )}:${getTimezoneOffset().slice(3, 5)}`;

  function formatTime(time) {
    return time < 10 ? `0${time}` : time;
  }

  function getTimezoneOffset() {
    var offset = -date.getTimezoneOffset();
    var sign = offset >= 0 ? "+" : "-";
    offset = Math.abs(offset);
    var hours = Math.floor(offset / 60);
    var minutes = offset % 60;
    return `${sign}${formatTime(hours)}${formatTime(minutes)}`;
  }

  //Language Update

  let lang_id = sessionStorage.getItem("lang_id");

  const Language_Save = async (akptId) => {
    if (lang_id !== "undefined") {
      await axiosInstance.current
        .get(`extapp/patients/saveUserLanguage/${akptId}?lang_id=${lang_id}`)
        .then((res) => {
          if (res) {
            handleConnectMe();
            // setRespondata(new Date().getMilliseconds())
          }
        })
        .catch((err) => {});
    }
  };

  const handleContinue = (e) => {
    setMessage(false);
    setModelShow(false);

    e.preventDefault();
    if (!firstName) {
      firstNameRef.current.errUpdate();
    }
    if (!lastName) {
      lastNameRef.current.errUpdate();
    }
    if (!sex) {
      sexRef.current.errUpdate();
    }
    if (!startDate) {
      setDobError(false);
    }
    // if (!countryCode) {
    //   countryCodeRef.current.errUpdate();
    // }
    // if (!mobileNum) {
    //   mobileNumRef.current.errUpdate();
    // }

    if (
      !firstName ||
      !lastName ||
      !sex ||
      !startDate ||
      firstNameErr ||
      lastNameErr ||
      !dobError
      // !countryCode ||
      // !mobileNum
    ) {
      setFormVaild(true);
    } else {
      // alert(1);
      const json = JSON.stringify({
        firstname: firstName,
        lastname: lastName,
        dob: ddddd,
        gender: sex,
        offset: formattedDate,
        country_code: countryCode_data,
        mobile: mobileNum,
      });

      axiosInstance.current
        .post(`extapp/patients/newPatient_Registration`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // setvalue(localStorage.setItem("AkTOKENI", res.data.ak_id));

          // if (modelShow1) {
          setDoctor(res.data.ak_id);
          // setModelShow1(true);
          Language_Save(res.data.ak_id);
          if (userData === 0) {
            navigate(`/patient-complete/${res.data.ak_id}`);
          } else {
            getData();
            // navigate(`/`)
          }
          // navigate(`/patient-complete/${res.data.ak_id}`)
          // }
        })
        .catch((err) => {
          setMessage(err.response.data.message);
          setMessageType("error");
        });
    }
  };

  // useEffect(() => {
  //   if (doctor) {
  //     // getData();
  //     // handleConnectMe();
  //     // handleContinue1();
  //   }
  // }, [doctor]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  let akLang = sessionStorage.getItem("lang_");
  const handeleConfirm = () => {
    if (!firstName) {
      firstNameRef.current.errUpdate();
    }
    if (!lastName) {
      lastNameRef.current.errUpdate();
    }
    if (!sex) {
      sexRef.current.errUpdate();
    }
    if (!startDate) {
      setDobError(false);
    }
    // if (!countryCode) {
    //   countryCodeRef.current.errUpdate();
    // }
    // if (!mobileNum) {
    //   mobileNumRef.current.errUpdate();
    // }

    if (
      !firstName ||
      !lastName ||
      !sex ||
      !startDate ||
      firstNameErr ||
      mobileErr ||
      lastNameErr ||
      !dobError
      // !countryCode ||
      // !mobileNum
    ) {
      setFormVaild(true);
    } else {
      setModelShow(true);
    }
  };
  // useEffect(() => {
  //   handleCloseShow1();
  // })

  const getCountryCode = async () => {
    try {
      const response = await axiosInstance.current.get(`extapp/countrylist`);
      if (response.status === 200) {
        setCountrydata(response.data.data);
      }
    } catch (err) {
      console.log(err.Messages);
    } finally {
      console.log("api response finally");
    }
  };

  useEffect(() => {
    getCountryCode();
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fff" : "#fff", // Background color when focused
      border: `2px solid ${
        state.isFocused ? "rgba(63, 39, 131, 0.7)" : "rgba(63, 39, 131, 0.7)"
      }`, // Border color
      boxShadow: null, // Focus shadow
      borderRadius: "10px",
      "&:hover": {
        border: "2px solid rgba(63, 39, 131, 0.7)", // Hover state border color
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "rgba(63, 39, 131, 0.7)" : "#fff", // Selected option background color
      color: state.isSelected ? "#fff" : "#333", // Selected option text color
      "&:hover": {
        backgroundColor: "#f0f0f0", // Hover state background color
        color: "black", // Hover state text color
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#3f2783", // Placeholder text color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333", // Single selected value text color
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: "0 4px 8px gray", // Menu box shadow
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0, // Menu list padding
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the separator line between dropdown and clear indicators
    }),
  };
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="doctor_org_info_screen mb_100">
          <div className="form_panel">
            {message && <Message message={message} type={messageType} />}

            {/* <form> */}
            <h3 className="text_heading text-center ">
              {/* {translation.Complete_personal_info} */}
              {userData === 0 && (
                <>
                  Hi,<span className="text-capitalize">{firstName}!</span>
                  <br></br>
                  {translation.Welcome_to_Akunah_your_Health_Passport}
                </>
              )}
            </h3>
            <div className="form_panel_body p_2 ">
              <div className="">
                {/* <img src={""} alt="" className="reg_logo" /> */}
                {userData === 0 && (
                  <>
                    <div className="reg_sub_title">
                      <div className="text-bold black">
                        {translation.Your_helth_is_in_your_hands}
                      </div>
                      <div className="text-bold black">
                        {" "}
                        {translation.Lets_begin_your_journey}
                      </div>
                      <p className="mt-4 text-bold black">
                        {translation.Please_complete_your_profile}
                      </p>
                    </div>
                  </>
                )}
                {userData > 0 && (
                  <>
                    <div className="reg_sub_title">
                      <div className="text-bold-com black">
                        {translation.Complete_personal_info}
                      </div>
                      <div className="text-bold-sign black">
                        {" "}
                        {translation.To_complete_the_sign_up}
                      </div>
                    </div>
                  </>
                )}

                {/* <p className="reg_sub_title"><b>
                Your helth is in your hands.
                <br></br>
                Let's begin your journey.</b>
              </p> */}

                <div className="w_350">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 mb_10">
                      <InputField
                        label={translation.First_Name}
                        value={firstName}
                        name="firstName"
                        type="text"
                        placeholder={translation.First_Name}
                        onChange={handleFirstName}
                        ref={firstNameRef}
                        validators={[
                          {
                            check: Validators.required,
                            message: translation.This_field_is_required,
                          },
                          {
                            check: Validators.spacialName,
                            message:
                              translation.No_special_characters_are_allowed,
                          },
                        ]}
                        styleClass="br_10 mxw_100"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 mb_10">
                      <InputField
                        label={translation.Last_Name}
                        value={lastName}
                        name="lastName"
                        type="text"
                        placeholder={translation.Last_Name}
                        onChange={handleLastName}
                        ref={lastNameRef}
                        validators={[
                          {
                            check: Validators.required,
                            message: translation.This_field_is_required,
                          },
                          {
                            check: Validators.spacialName,
                            message:
                              translation.No_special_characters_are_allowed,
                          },
                        ]}
                        styleClass="br_10 mxw_100"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 mb_20">
                      <Dropdown
                        data={[
                          { value: 1, label: translation.Male },
                          { value: 2, label: translation.Female },
                          { value: 3, label: translation.want_to_disclose },
                        ]}
                        label={translation.Sex}
                        styleClass="mxw_100 br_10"
                        value={sex}
                        ref={sexRef}
                        placeholder={translation.Select}
                        onChange={handleSex}
                        validators={[
                          {
                            check: Validators.required,
                            message: translation.This_field_is_required,
                          },
                        ]}
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 mb_10">
                      {/* <Form.Group className="mb-3" controlId="fromDate">
                        <Form.Label>
                          {translation.DOB}{" "}
                          <i style={{ "font-style": "italic" }}>(DD/MM/YYYY)</i>
                        </Form.Label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          maxDate={maxDate}
                          // placeholderText="Please select a date"
                          className="form-control mb_0 br_10"
                          placeholderText="DD/MM/YYYY"
                          selected={startDate}
                          onChange={(date) => handleDob(date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          locale={
                            akLang === "en"
                              ? "en-US"
                              : akLang === "ara"
                              ? "ar"
                              : akLang === "ger"
                              ? "de"
                              : akLang === "ita"
                              ? "it"
                              : akLang === "spa"
                              ? "es"
                              : akLang === "tha"
                              ? "th"
                              : akLang === "fr"
                              ? "fr"
                              : "en-US"
                          } // Set the desired locale for date-fns
                        />
                      </Form.Group> */}
                      <div className="row">
                        <div className="col-sm-12 col-md-12 mb_10">
                          <InputField
                            label={translation.DOB + " (DD/MM/YYYY)"}
                            value={startDate}
                            name="startDate"
                            type="text"
                            placeholder={"DD/MM/YYYY"}
                            onChange={(e) => handleDob(e)} // Ensure you pass the event object correctly
                            styleClass="br_10 mxw_100"
                          />

                          {/* <input
  type="text"
  value={startDate}
  name="startDate"
  placeholder="DD/MM/YYYY"
  onChange={(e) => handleDob(e.target.value)}
  className="br_10 mxw_100"
/> */}

                          {!dobError && (
                            <p className="text-danger">
                              {translation.patient_elbow_function_q2_a5 +
                                " " +
                                translation.Date_of_Birth}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"18px" }}>
                        <div className="col-sm-12 col-md-12 mb_10">
                          <label>{translation.Country_Code}</label>
                          <Select
                            // onInputChange={handleInputChange}
                            value={countryCode}
                            name="provides"
                            options={countryData}
                            onChange={(e) => handleCountryCode(e)}
                            // className="basic-multi-select add_patient"
                            classNamePrefix="select"
                            placeholder={translation.Select}
                            styles={customStyles}
                          />
                          {/* <Dropdown
                            label={translation.Country_Code}
                            data={countryData}
                            value={countryCode}
                            name="CountryCode"
                            placeholder={translation.Select}
                            onChange={handleCountryCode}
                            ref={countryCodeRef}
                            // validators={[
                            //   {
                            //     check: Validators.required,
                            //     message: translation.This_field_is_required,
                            //   },
                            // ]}
                            styleClass="br_10 mxw_100"
                          /> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 mb_10">
                          <InputField
                            label={translation.Mobile_Phone}
                            value={mobileNum}
                            name="PhoneNumber"
                            type="text"
                            placeholder={translation.Mobile_Phone}
                            onChange={handleMobileNum}
                            ref={mobileNumRef}
                            // validators={[
                            //   {
                            //     check: Validators.required,
                            //     message: translation.This_field_is_required,
                            //   },

                            //   {
                            //     check: Validators.maxmobile,
                            //     message: translation.maximum_10_Numbers_are_allowed,
                            //   },
                            //   {
                            //     check: Validators.number,
                            //     message: translation.Only_I,
                            //   },
                            // {
                            //   check: Validators.minmobile,
                            //   message: "Give 10 digit Phone number",
                            // },
                            // ]}

                            styleClass="br_10 mxw_100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <YourComponent/> */}
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <Button
                        // onClick={handleContinue}
                        onClick={() => handeleConfirm()}
                        value={translation.Continue}
                        buttonStyle="btn_fill w_100 br_10 f_600 mt_40"
                      />
                      {/* {message !== "" && (
                        <p className="text-danger">{message} </p>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </form> */}
            {/*  */}
            <Modal
              show={modelShow}
              onHide={handleCloseShow}
              dialogClassName="modal-md patient_notes_popup"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-center">
                  {translation.Please_confirm_that_your_details_are_correct}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="">
                <div className="row">
                  <div className="col-sm-4">
                    <label className="d-block text-dark">
                      {translation.First_Name}{" "}
                    </label>
                  </div>
                  <div className="col-sm-6">
                    <span className="d-block">{firstName}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <label className="d-block text-dark">
                      {translation.Last_Name}
                    </label>
                  </div>
                  <div className="col-sm-6">
                    <span className="d-block">{lastName}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <label className="d-block text-dark">
                      {translation.Sex}{" "}
                    </label>
                  </div>
                  <div className="col-sm-6">
                    <span className="d-block">
                      {sex === "1"
                        ? translation.Male
                        : sex === "2"
                        ? translation.Female 
                        : translation.want_to_disclose}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <label className="d-block text-dark">
                      {translation.DOB}{" "}
                    </label>
                  </div>
                  <div className="col-sm-6">
                    <span className="d-block">{startDate}</span>
                  </div>
                </div>
                {countryCode.length !== 0 && (
                  <>
                    <div className="row">
                      <div className="col-sm-4">
                        <label className="d-block text-dark">
                          {translation.Country_Code}{" "}
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <span className="d-block">{countryCode_data}</span>
                      </div>
                    </div>
                  </>
                )}

                {mobileNum.length !== 0 && (
                  <div className="row">
                    <div className="col-sm-4">
                      <label className="d-block text-dark">
                        {translation.Mobile_Phone}{" "}
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <span className="d-block">{mobileNum}</span>
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer style={{ justifyContent: "flex-end" }}>
                <Button
                  // show={modelShow}
                  onClick={handleCloseShow}
                  value={"Cancel"}
                  buttonStyle="btn_fill"
                />
                <Button
                  show={modelShow}
                  onClick={handleContinue}
                  value={translation.Continue}
                  buttonStyle="btn_success"
                />
              </Modal.Footer>
            </Modal>

            {/* <Modal
              show={modelShow1}
              onHide={handleCloseShow1}
              dialogClassName="modal-md patient_notes_popup"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-center"></Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                <p className="text-bold">{translation.Profile_complete}</p>
                <p className="text-bold ps-4 pe-4">
                  {translation.Would_you_like_to_add_another_profile_for_a_family_member}
                </p>
              </Modal.Body>
              <Modal.Footer style={{ justifyContent: "flex-end" }}>
                <Button
                  show={modelShow1}
                  onClick={handleContinue1}
                  disabled={button1Disabled}
                  value={"No" // {translation.Continue}
                  }
                  buttonStyle="btn_success"
                />
                <Button
                  // show={modelShow}
                  onClick={handleCloseShow1}
                  disabled={button2Disabled}
                  value={"Yes"}
                  buttonStyle="btn_fill"
                />
              </Modal.Footer>
            </Modal> */}
          </div>
        </div>
      )}
    </>
  );
}

export default DoctorPersonalInfo;
