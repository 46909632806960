import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import profileStyle from "./Profile.module.css";
import arrowIcon from "../../images/arrowbluesm.svg";
import editIcon from "../../images/edit-icon.svg";
import useAxios from "../../axiosinstance";
import { yearDate } from "../../utilities/DateFormate";
import useTranslation from "../customHooks/translations";
import { UserDateFormat } from "../../utilities/DateFormate";
import { useSelector } from "react-redux";
// import Button from "../../../custom/button/Button";
import heart_icon_fill from "../../images/heart1_blue.png";
import heart_icon from "../../images/heart_out_blue.png";

function PatientProfile({ type, name, age }) {
  const axiosInstance = useAxios();
  const login = useSelector((state) => state.loginPatient.arr[0]);

  const { akid } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [profile, setProfile] = useState("");
  // const [Favo, setFavo] = useState();

  const translation = useTranslation();

  const [showMore, setShowMore] = useState(false);

  const [Status, setStatus] = useState("0");
  // const [Status, setStatus] = useState("0");

  const save = () => {
    axiosInstance.current
      .get(
        `extapp/doctors/getMedicalSummaryList?ak_id=${window.atob(
          akid
        )}&doctor_id=${login.ing_ak_id}`
      )
      .then((res) => {
        setStatus(res.data.data.status);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/unauthorized");
        }
      });
  };
  // useEffect(() => {
  //   // save();
  // }, [akid]);

  const handleFavo = (e) => {
    window.open(
      process.env.REACT_APP_FORM_URL +
        `medical-treatment/${akid}?doctor=null&doctor_id=${login.ing_ak_id}&is_doctor_form=true`,
      "_blank"
    );
    // navigate(`/medical-treatment/${akid}`);
  };
  const handleRedirect = () => {
    window.open(
      process.env.REACT_APP_FORM_URL +
        `/medical-history/${akid}?doctor=${login.ing_ak_id}&is_doctor_form=true`,
      "_blank"
    );
    // navigate("/medical-history")
  };
  useEffect(() => {
   
    if (login) {
      setProfile(login.patient);
    }
  }, [login]);
  const handelEdit = () => {
    navigate("/my-profile");
  };
  // console.log(profile,"login.ing_date_formate");
  return (
    <div className={`profile_expand_sec ${profileStyle.profile_sec}`}>
      <div className="whitebox">
        <div className="row">
          <div className="col-md-7 col-sm-7">
            {/* <h4 className={`${profileStyle.top_left_pf_txt}`}>
              {translation.patient_profile}
            </h4> */}
            {/* <div>
              {Status === "0" ? (
                <>
                  <img className="heart_ico" src={heart_icon_fill} alt="Medical summary" onClick={() => handleRedirect()} style={{ cursor: "pointer" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Patient did not fillup yet your medical summary" />
                  <span onClick={() => navigate("/medical-history")} className="c_pointer text-danger mt-1 ms-2"></span>


                </>
              ) : (

                <img className="heart_ico" src={heart_icon_fill} alt="Medical summary" onClick={() => handleFavo()} style={{ cursor: "pointer" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Patient medical summary" />
              )}
            </div> */}
          </div>
          <div className="col-md-5 col-sm-5 ">
            <div className="text-end">
              <span onClick={() => handelEdit()}>
                <img
                  src={editIcon}
                  className={`mr_15 c_pointer`}
                  alt="icon"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit Notes"
                />
              </span>
            </div>
            {/* <img src={checkIcon} className={`mt_4`} alt="icon" /> */}
          </div>
        </div>

        <div className={`profile ${profileStyle.profile}`}>
          <div
            className={`profile_pic_letter ${profileStyle.profile_pic_letter}`}
          >
            {/* <img
              src={profileTick}
              className={` ${profileStyle.profile_tic_icon}`}
              alt="icon"
            /> */}

            <span className="text-capitalize">
              {profile.firstname === undefined
                ? " "
                : profile.firstname.replace(" ", "").charAt(0)}
            </span>
          </div>
          <h4>
            {profile.firstname} {profile.lastname}
          </h4>
          <h6>{profile.ak_id} &nbsp;</h6>

          <p className="mb-1">
            <strong>{translation.Age}: {yearDate(profile.dob)} {translation.year_pdata} </strong>{" "}
          </p>
        </div>
        {showMore ? (
          <div className={`${profileStyle.profile_show_more} mt-3`}>
            {/* Management plan screen profile code */}

            <>
              {/* Patient Details screen profile code */}
              <div className="row">  
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.full_name}: </strong>
                    <span className="ms-1">{profile.firstname} {profile.lastname}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.DOB_1}: </strong>
                    <span className="ms-1">
                      {UserDateFormat(
                        profile.dob,
                        login.patient.dateFormat,
                        login.ing_timezone
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.Sex_1}: </strong>
                    <span className="ms-1">
                      {profile.gender === "1"
                        ? "Male"
                        : profile.gender === "2"
                        ? "Female"
                        : "Do not want to disclose"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.email}: </strong>
                    <span className="ms-1">{profile.email}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.Address_1}: </strong>
                    <span className="ms-1">{profile.address}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.mobile}: </strong>
                    <span className="ms-1">{profile.mobile} </span>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <strong>Treatment Category: </strong>
                    <span>Shoulder Assessment</span>
                  </div>
                </div> */}

              {/* <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.lastVisit}: </strong>
                    <span className="ms-1">{data.last_visit}</span>
                  </div>
                </div>
              </div> */}
              {/* {data.card_number && (
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.medicareCardNumber}: </strong>
                      <span className="ms-1 text-wrap">{data.card_number}</span>
                    </div>
                  </div>
                </div>
              )} */}

              {/* {data.dva_card && (
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.dVAConcessionCard}: </strong>
                      <span className="ms-1">
                        {data.dva_card === "0" ? "Not Available" : "Available"}
                      </span>
                    </div>
                  </div>
                </div>
              )} */}
            </>
          </div>
        ) : null}

        <div className="text-end">
          <span
            className="default_c c_pointer"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? translation.Display_Less : translation.Display_More}
            <img
              className={` ${showMore ? `${profileStyle.show_more}` : null} ${
                profileStyle.arrow_icon
              }`}
              src={arrowIcon}
              alt="icon"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

PatientProfile.defaultProps = {
  type: "",
};

export default PatientProfile;
