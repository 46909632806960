import React from "react";
import rangeTableStyles from "./RangeTable.module.css";
import useTranslation from "../customHooks/translations";

const RangeMotionTable = ({ type, rangeOfMotion, rangeOfMotionRecent }) => {
  // console.log("type", type);
  const translation = useTranslation();
  // const [initial, setInitial] = useState({});
  // console.log(rangeOfMotion)
  return (
    <div className={`rangeTableSec`}>
      <div className="row">
        <div className="col-md-12 col-sm-12 range_sec_new">
          <div className="whitebox">
            <div className={`box-header ${rangeTableStyles.box_header}`}>
              <div className="row range_table_header">
                <div className="col-md-7 col-sm-6 col-xs-6">
                  <h4 className={`${rangeTableStyles.box_title}`}>
                    {translation.rangeofMotion}
                  </h4>
                </div>
                <div className="col-md-5 col-sm-6 col-xs-6 text-end">
                  {type === "initial" ? (
                    <span className={`${rangeTableStyles.initial_title}`}>
                      <span></span>
                      {translation.initial}
                    </span>
                  ) : (
                    <>
                      <span className={`${rangeTableStyles.initial_title}`}>
                        <span></span>
                        {translation.initial}
                      </span>
                      <span className={`${rangeTableStyles.recent_title}`}>
                        <span></span>
                        {translation.recent}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={`scrollbar_hori ${rangeTableStyles.range_table}`}>
              <table className="">
                <tbody>
                  <tr>
                    <td width={'20%'}>{translation.activeForwardFlexion}</td>
                    {type === "initial" ? (
                      <>
                        <td width={'30%'} >
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.active_forward_right === null  || rangeOfMotion.active_forward_right === '' ? "None" : rangeOfMotion.active_forward_right + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td  >
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.active_forward_left === null || rangeOfMotion.active_forward_left === '' ? "None" : rangeOfMotion.active_forward_left + ' degrees'}

                            </span>
                          </p>
                        </td>
                      </>
                    ) : (
                      <>
                        <td width={'20%'}>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.active_forward_right === null || rangeOfMotion.active_forward_right === '' ? "None" : rangeOfMotion.active_forward_right + ' degrees'}

                            </span>
                          </p>
                        </td>
                        <td width={'20%'}>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.active_forward_left === null || rangeOfMotion.active_forward_left === '' ? "None" : rangeOfMotion.active_forward_left + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td width={'20%'}>
                          <p className="recent_value">
                            <span>R : </span>
                            <span className=" scores39">
                              {rangeOfMotionRecent.active_forward_right === null || rangeOfMotionRecent.active_forward_right === '' ? "None" : rangeOfMotionRecent.active_forward_right + ' degrees'}

                            </span>
                          </p>
                        </td>
                        <td width={'20%'}>
                          <p className="recent_value">
                            <span>L : </span>
                            <span className=" scores40">
                              {rangeOfMotionRecent.active_forward_left === null || rangeOfMotionRecent.active_forward_left === '' ? "None" : rangeOfMotionRecent.active_forward_left + ' degrees'}

                            </span>
                          </p>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{translation.lateralElevation}</td>
                    {type === "initial" ? (
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.leteral_Elevation_right === null || rangeOfMotion.leteral_Elevation_right === '' ? "None" : rangeOfMotion.leteral_Elevation_right + ' degrees'}

                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.leteral_Elevation_left === null || rangeOfMotion.leteral_Elevation_right === '' ? "None" : rangeOfMotion.leteral_Elevation_left + ' degrees'}

                            </span>
                          </p>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">

                              {rangeOfMotion.leteral_Elevation_right === null || rangeOfMotion.leteral_Elevation_right === '' ? "None" : rangeOfMotion.leteral_Elevation_right + ' degrees'}

                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.leteral_Elevation_left === null || rangeOfMotion.leteral_Elevation_left === '' ? "None" : rangeOfMotion.leteral_Elevation_left + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>R : </span>
                            <span className=" scores39">
                              {rangeOfMotionRecent.leteral_Elevation_right === null || rangeOfMotionRecent.leteral_Elevation_right === '' ? "None" : rangeOfMotionRecent.leteral_Elevation_right + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>L : </span>
                            <span className=" scores40">
                              {rangeOfMotionRecent.leteral_Elevation_left === null || rangeOfMotionRecent.leteral_Elevation_left === '' ? "None" : rangeOfMotionRecent.leteral_Elevation_left + ' degrees'}
                            </span>
                          </p>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{translation.eR1Scores}</td>
                    {type === "initial" ? (
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.er1_scores_right === null || rangeOfMotion.er1_scores_right === '' ? "None" : rangeOfMotion.er1_scores_right + ' degrees'}

                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.er1_scores_left === null || rangeOfMotion.er1_scores_left === '' ? "None" : rangeOfMotion.er1_scores_left + ' degrees'}

                            </span>
                          </p>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.er1_scores_right === null || rangeOfMotion.er1_scores_right === '' ? "None" : rangeOfMotion.er1_scores_right + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.er1_scores_left === null || rangeOfMotion.er1_scores_left === "" ? "None" : rangeOfMotion.er1_scores_left+ ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>R : </span>
                            <span className=" scores39">
                              {rangeOfMotionRecent.er1_scores_right === null || rangeOfMotionRecent.er1_scores_right === '' ? "None" : rangeOfMotionRecent.er1_scores_right + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>L : </span>
                            <span className=" scores40">
                              {rangeOfMotionRecent.er1_scores_left === null || rangeOfMotionRecent.er1_scores_left === "" ? "None" : rangeOfMotionRecent.er1_scores_left + ' degrees'}
                            </span>
                          </p>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{translation.eR2Scores}</td>
                    {console.log(typeof rangeOfMotionRecent.er2_scores_right , "rangeOfMotionRecent.er2_scores_right" , rangeOfMotionRecent.er2_scores_right)}
                    {type === "initial" ? (
                      
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.er2_scores_right === null || rangeOfMotion.er2_scores_right === '' ? "None" : rangeOfMotion.er2_scores_right === "1" ? "Hand behind head, elbow forward" : rangeOfMotion.er2_scores_right === "2" ? "Hand behind head, elbow back" : rangeOfMotion.er2_scores_right === "3" ? "Hand to top of head, elbow forward" : rangeOfMotion.er2_scores_right === "4" ? "Hand to top head, elbow back" : rangeOfMotion.er2_scores_right === "5" ? "Full elevation" : ""}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.er2_scores_left === null || rangeOfMotion.er2_scores_left === '' ? "None" : rangeOfMotion.er2_scores_left === "1" ? "Hand behind head, elbow forward" : rangeOfMotion.er2_scores_left === "2" ? "Hand behind head, elbow back" : rangeOfMotion.er2_scores_left === "3" ? "Hand to top of head, elbow forward" : rangeOfMotion.er2_scores_left === "4" ? "Hand to top head, elbow back" : rangeOfMotion.er2_scores_left === "5" ? "Full elevation" : ""}
                            </span>
                          </p>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.er2_scores_right === null || rangeOfMotion.er2_scores_right === '' ? "None" : rangeOfMotion.er2_scores_right === "1" ? "Hand behind head, elbow forward" : rangeOfMotion.er2_scores_right === "2" ? "Hand behind head, elbow back" : rangeOfMotion.er2_scores_right === "3" ? "Hand to top of head, elbow forward" : rangeOfMotion.er2_scores_right === "4" ? "Hand to top head, elbow back" : rangeOfMotion.er2_scores_right === "5" ? "Full elevation" : ""}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">

                              {rangeOfMotion.er2_scores_left === null || rangeOfMotion.er2_scores_left === '' ? "None" : rangeOfMotion.er2_scores_left === "1" ? "Hand behind head, elbow forward" : rangeOfMotion.er2_scores_left === "2" ? "Hand behind head, elbow back" : rangeOfMotion.er2_scores_left === "3" ? "Hand to top of head, elbow forward" : rangeOfMotion.er2_scores_left === "4" ? "Hand to top head, elbow back" : rangeOfMotion.er2_scores_left === "5" ? "Full elevation" : ""}

                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>R : </span>
                            <span className=" scores39">
                              {rangeOfMotionRecent.er2_scores_right === null || rangeOfMotionRecent.er2_scores_right === '' ? "None" : rangeOfMotionRecent.er2_scores_right === "1" ? "Hand behind head, elbow forward" : rangeOfMotionRecent.er2_scores_right === "2" ? "Hand behind head, elbow back" : rangeOfMotionRecent.er2_scores_right === "3" ? "Hand to top of head, elbow forward" : rangeOfMotionRecent.er2_scores_right === "4" ? "Hand to top head, elbow back" : rangeOfMotionRecent.er2_scores_right === "5" ? "Full elevation" : ""}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>L : </span>
                            <span className=" scores40">
                              {rangeOfMotionRecent.er2_scores_left === null || rangeOfMotionRecent.er2_scores_left === '' ? "None" : rangeOfMotionRecent.er2_scores_left === "1" ? "Hand behind head, elbow forward" : rangeOfMotionRecent.er2_scores_left === "2" ? "Hand behind head, elbow back" : rangeOfMotionRecent.er2_scores_left === "3" ? "Hand to top of head, elbow forward" : rangeOfMotionRecent.er2_scores_left === "4" ? "Hand to top head, elbow back" : rangeOfMotionRecent.er2_scores_left === "5" ? "Full elevation" : ""}
                            </span>
                          </p>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{translation.iR1Scores}</td>
                    {type === "initial" ? (
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.ir1_scores_right === null || rangeOfMotion.ir1_scores_right === '' ? "None" : rangeOfMotion.ir1_scores_right === "1" ? "Lateral Thigh" : rangeOfMotion.ir1_scores_right === "2" ? "Buttock" : rangeOfMotion.ir1_scores_right === "3" ? "Lumbosacral Junction" : rangeOfMotion.ir1_scores_right === "4" ? "Waist (L3)" : rangeOfMotion.ir1_scores_right === "5" ? "T12 Vertebra" :  rangeOfMotion.ir1_scores_right === "6" ? "Interscapular (T7)" : ""}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.ir1_scores_left === null  || rangeOfMotion.ir1_scores_left === '' ? "None" :  rangeOfMotion.ir1_scores_left === "1" ? "Lateral Thigh" : rangeOfMotion.ir1_scores_left === "2" ? "Buttock" : rangeOfMotion.ir1_scores_left === "3" ? "Lumbosacral Junction" : rangeOfMotion.ir1_scores_left === "4" ? "Waist (L3)" : rangeOfMotion.ir1_scores_left === "5" ? "T12 Vertebra" :  rangeOfMotion.ir1_scores_left === "6" ? "Interscapular (T7)" : ""}
                            </span>
                          </p>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.ir1_scores_right === null || rangeOfMotion.ir1_scores_right === '' ? "None" :  rangeOfMotion.ir1_scores_right === "1" ? "Lateral Thigh" : rangeOfMotion.ir1_scores_right === "2" ? "Buttock" : rangeOfMotion.ir1_scores_right === "3" ? "Lumbosacral Junction" : rangeOfMotion.ir1_scores_right === "4" ? "Waist (L3)" : rangeOfMotion.ir1_scores_right === "5" ? "T12 Vertebra" :  rangeOfMotion.ir1_scores_right === "6" ? "Interscapular (T7)" : ""}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.ir1_scores_left === null || rangeOfMotion.ir1_scores_left === '' ? "None" :  rangeOfMotion.ir1_scores_left === "1" ? "Lateral Thigh" : rangeOfMotion.ir1_scores_left === "2" ? "Buttock" : rangeOfMotion.ir1_scores_left === "3" ? "Lumbosacral Junction" : rangeOfMotion.ir1_scores_left === "4" ? "Waist (L3)" : rangeOfMotion.ir1_scores_left === "5" ? "T12 Vertebra" : rangeOfMotion.ir1_scores_left === "6" ? "Interscapular (T7)" : ""}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>R : </span>
                            <span className=" scores39">
                              {rangeOfMotionRecent.ir1_scores_right === null || rangeOfMotionRecent.ir1_scores_right === '' ? "None" : rangeOfMotionRecent.ir1_scores_right === "1" ? "Lateral Thigh" : rangeOfMotionRecent.ir1_scores_right === "2" ? "Buttock" : rangeOfMotionRecent.ir1_scores_right === "3" ? "Lumbosacral Junction" : rangeOfMotionRecent.ir1_scores_right === "4" ? "Waist (L3)" : rangeOfMotionRecent.ir1_scores_right === "5" ? "T12 Vertebra" :  rangeOfMotionRecent.ir1_scores_right === "6" ? "Interscapular (T7)" : ""}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>L : </span>
                            <span className=" scores40">
                              {rangeOfMotionRecent.ir1_scores_left === null || rangeOfMotionRecent.ir1_scores_left === '' ? "None" :  rangeOfMotionRecent.ir1_scores_left === "1" ? "Lateral Thigh" : rangeOfMotionRecent.ir1_scores_left === "2" ? "Buttock" : rangeOfMotionRecent.ir1_scores_left === "3" ? "Lumbosacral Junction" : rangeOfMotionRecent.ir1_scores_left === "4" ? "Waist (L3)" : rangeOfMotionRecent.ir1_scores_left === "5" ? "T12 Vertebra" : rangeOfMotionRecent.ir1_scores_left === "6" ? "Interscapular (T7)" : ""}

                            </span>
                          </p>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>{translation.iR2Scores}</td>
                    {type === "initial" ? (
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.ir2_scores_right === null || rangeOfMotion.ir2_scores_right === '' ? "None" : rangeOfMotion.ir2_scores_right + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.ir2_scores_left === null || rangeOfMotion.ir2_scores_left === "" ? "None" : rangeOfMotion.ir2_scores_left + ' degrees'}
                            </span>
                          </p>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <p className="initial_value">
                            <span>R : </span>
                            <span className=" scores29">
                              {rangeOfMotion.ir2_scores_right === null || rangeOfMotion.ir2_scores_right === "" ? "None" : rangeOfMotion.ir2_scores_right + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="initial_value">
                            <span>L : </span>
                            <span className=" scores30">
                              {rangeOfMotion.ir2_scores_left === null || rangeOfMotion.ir2_scores_left === '' ? "None" : rangeOfMotion.ir2_scores_left + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>R : </span>
                            <span className=" scores39">
                              {rangeOfMotionRecent.ir2_scores_right === null || rangeOfMotionRecent.ir2_scores_right === '' ? "None" : rangeOfMotionRecent.ir2_scores_right + ' degrees'}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="recent_value">
                            <span>L : </span>
                            <span className=" scores40">
                              {rangeOfMotionRecent.ir2_scores_left === null || rangeOfMotionRecent.ir2_scores_left === '' ? "None" : rangeOfMotionRecent.ir2_scores_left + ' degrees'}
                            </span>
                          </p>
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RangeMotionTable.defaultProps = {
  type: "",
};

export default RangeMotionTable;
