export const loginData = (e) =>{
    return {
      type: 'SET_LOGIN',
      data: e,
    };
  }
  export const loginVisitsData = (e) =>{
    return {
      type: 'SET_LOGIN_VISIT',
      data: e,
    };
  }