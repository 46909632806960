import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useTranslation from "../../customHooks/translations";

import rcpStyles from "./ResultCircleProgress.module.css";

const ScoreWidget = ({
  title,
  path_color,
  score,
  shoulder,
  value,
  date,
  form_url,
  resID,
  formType,
  doctorId,
  patientId,
  total,
  arm,
  scoreColor,
  type
}) => {
  const translation = useTranslation();
  return (
    <div className={`${rcpStyles.result_scorebox_col}`}>
      <div className={`${rcpStyles.top_content}`}>
        <div className={`${rcpStyles.scorebox_title}`}>
          <h4>
            <strong>{value}</strong> {title}
          </h4>
          <label>
            <strong>{arm}</strong> <br /> {value} {translation.out_of} {total}
          </label>
        </div>
        <div className={`${rcpStyles.result_skill_box}`}>
          <CircularProgressbar
            value={scoreColor}
            // maxValue={1}
            text={title === "VAS" ? `${score * 10}` : `${score * 1}`}
            strokeWidth="13"
            styles={buildStyles({
              textColor: "#000",
              pathColor: path_color,
            })}
          />
        </div>
      </div>
      <span className={`${rcpStyles.btm_content}`}>
        {type} {translation.Scoring_Self_Evaluation}{" "}
        <small>
          <b>•</b> {date}
        </small>
      </span>
    </div>
  );
};

ScoreWidget.defaultProps = {
  title: "",
  path_color: "",
  score: "",
  shoulder: "",
  date: "",
  form_url: "",
  resID: "",
  formType: "",
  doctorId: "",
  patientId: "",
};

export default ScoreWidget;
