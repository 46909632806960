import React from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import settingsStyle from './Settings.module.css';
import Language from './language/Language'
import regionGreyIcon from '../../images/region_grey.svg';
import regionBlueIcon from '../../images/region_blue.svg';
import useTranslation from "../customHooks/translations";

function Settings() {
  const translation = useTranslation()
  let lang= sessionStorage.getItem("lang_")
  return (
    <>
      <div className="" dir={lang === "ara" ? "rtl" : "ltr"} style={{ height: "100%" ,padding:"23px"}}>
      <Tab.Container id="settings_vert_tab" defaultActiveKey="language" >
        <Row className={`settings_main`}>
          <Col sm={3}>
            <Nav variant="pills" className={`flex-column ${settingsStyle.left_tab_menu}`}>
              <Nav.Item className={`${settingsStyle.nav_item}`}>
                <Nav.Link eventKey="language" href="#" className={`${settingsStyle.nav_link}`}>
                  <img src={regionGreyIcon} alt="icon" className='grey_icon' />
                  <img src={regionBlueIcon} alt="icon" className='blue_icon' />
                  {translation.language}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content className={`${settingsStyle.tab_content}`}>
              
              <Tab.Pane eventKey="language" className={`${settingsStyle.tab_pane}`}>
                <Language />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      </div>
    </>
  )
}

export default Settings