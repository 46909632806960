import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";

import { validateDropdown } from "../../../utilities/Validator";

const Dropdown = forwardRef(({
  value,
  label,
  data,
  placeholder,
  styleClass,
  validators,
  onChange,
  disabled
}, ref) => {
  const [error, setError] = useState(false);
const[errvalue,seterrvalue]=useState("")
  const handleChange = (event) => {
    const { value } = event.target;
    setError(validateDropdown(validators, value));
    onChange(value);
  };
// console.log(validators[0].message,"validatorsvalidators",errvalue);  
useImperativeHandle(ref, () => ({
  errUpdate(e) {
    setError(validateDropdown(validators, ""));
  }
}))
  
  let akLang = sessionStorage.getItem("lang_");

  return (
    <div className={`form-group ${styleClass}`}>
      {label && <label htmlFor="app-input-field">{label}</label>}
      <select
        value={value}
        className={ akLang === "ara" ? "form-control form-select drop_bacground home_box selectleft" : "form-control form-select drop_bacground home_box selectright"}
        onChange={handleChange}
        onBlur={handleChange}
        disabled={disabled}

      >
        <option value="">{placeholder}</option>
        {data.map((item, key) => (
          <option key={key} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {/* {error && <span className="text-danger">{error.message}</span>} */}
      {error && <span className="text-danger">{validators !==undefined?validators[0].message:error.message}</span>}
    </div>
  );
});

Dropdown.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array.isRequired,
  styleClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  value: "",
  label: "",
  styleClass: "",
  placeholder: ""
};

export default Dropdown;
