import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import "bootstrap/dist/css/bootstrap.min.css";
import 'es6-promise/auto';
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LanguageContextProvider from "./components/contexts/LanguageContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReactKeycloakProvider
    initOptions={{ onLoad: "login-required", checkLoginIframe: false }}
    authClient={keycloak}
  >
    <React.StrictMode>
      <LanguageContextProvider>
        <Router>
          <Provider store={configureStore}>
            <App />
          </Provider>
        </Router>
      </LanguageContextProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>
);

reportWebVitals();
