import React from "react";
import "./Spinner.css";
const LoadingSpinnerText = () => {
  return (
    <div className="loader-box text-center">
      <div className="loading"></div>
      <div style={{ top: "55%", position: "absolute", right: 0, left: 0 }}>
        <p style={{color:"#3f2783"}}>Securely Signing</p>
      </div>
    </div>
  );
};

export default LoadingSpinnerText;
