const initialState = {
  painScore: [
    {
      painshoulder_left: "0",
      painshoulder_right: "0",
      describe_pain_left: "0",
      describe_pain_right: "0",
      pain_today_rating_left: "0",
      pain_today_rating_right: "0",
      shoulder_pain_night_left: "0",
      shoulder_pain_night_right: "0",
      smoker: "1",
      cigrates_perday: "0",
      cigrates_month_left: "",
      smoke_years: "",
      pain_medication: "",
      narotic_medication: "0",
      pills_average: "0",
      best_statment_pain_left: "0",
      best_statment_pain_right: "0",
      Pin_tingling_left: "",
      pin_tingling_right: "",
      body_selection: [],
      // backbody:[]
    },
  ],

  functionScore: [
    {
      affected_shoulder_left: "0",
      affected_shoulder_right: "0",
      put_shirt_right: "3",
      put_shirt_left: "3",
      affected_side_right: "3",
      affected_side_left: "3",
      wash_back_right: "3",
      wash_back_left: "3",
      manage_toilet_right: "3",
      manage_toilet_left: "3",
      comb_hair_right: "3",
      comb_hair_left: "3",
      apply_makup_right: "3",
      apply_makup_left: "3",
      brush_teeth_right: "3",
      brush_teeth_left: "3",
      full_bottle_right: "3",
      full_bottle_left: "3",
      bring_mouth_right: "3",
      bring_mouth_left: "3",
      eat_soup_right: "3",
      eat_soup_left: "3",
      shake_hand_right: "3",
      shake_hand_left: "3",
      use_phone_right: "3",
      use_phone_left: "3",
      write_letter_right: "3",
      write_letter_left: "3",
      reach_shelf_right: "3",
      reach_shelf_left: "3",
      lift05_right: "3",
      lift05_left: "3",
      lift45_right: "3",
      lift45_left: "3",
      lift10_right: "3",
      lift10_left: "3",
      throwunder_hand_right: "3",
      throwunder_hand_left: "3",
      throwover_hand_right: "3",
      throwover_hand_left: "3",
      fullusual_work_right: "3",
      fullusual_work_left: "3",
      usual_hobbies: " ",
      usual_sport_right: "3",
      usual_sport_left: "3",
      hobbies: " ",
      feel_unstable: "",
      instability_acjoint: "",
      unstable_shoulder_range_right: "0",
      unstable_shoulder_range_left: "0",
    },
  ],
  wosiScore: [
    {
      wosi_q1_left: "0",
      wosi_q1_right: "0",
      wosi_q2_left: "0",
      wosi_q2_right: "0",
      wosi_q3_left: "0",
      wosi_q3_right: "0",
      wosi_q4_left: "0",
      wosi_q4_right: "0",
      wosi_q5_left: "0",
      wosi_q5_right: "0",
      wosi_q6_left: "0",
      wosi_q6_right: "0",
      wosi_q7_left: "0",
      wosi_q7_right: "0",
      wosi_q8_left: "0",
      wosi_q8_right: "0",
      wosi_q9_left: "0",
      wosi_q9_right: "0",
      wosi_q10_left: "0",
      wosi_q10_right: "0",
      wosi_q11_left: "0",
      wosi_q11_right: "0",
      wosi_q12_left: "0",
      wosi_q12_right: "0",
      wosi_q13_left: "0",
      wosi_q13_right: "0",
      wosi_q14_left: "0",
      wosi_q14_right: "0",
      wosi_q15_left: "0",
      wosi_q15_right: "0",
      wosi_q16_left: "0",
      wosi_q16_right: "0",
      wosi_q17_left: "0",
      wosi_q17_right: "0",
      wosi_q18_left: "0",
      wosi_q18_right: "0",
      wosi_q19_left: "0",
      wosi_q19_right: "0",
      wosi_q20_left: "0",
      wosi_q20_right: "0",
      wosi_q21_left: "0",
      wosi_q21_right: "0",
    },
  ],
  sacsScore: [
    {
      sacs_q1_left: "0",
      sacs_q1_right: "0",
      sacs_q2_left: "0",
      sacs_q2_right: "0",
      sacs_q3_left: "0",
      sacs_q3_right: "0",
      sacs_q4_left: "0",
      sacs_q4_right: "0",
      sacs_q5_left: "0",
      sacs_q5_right: "0",
      sacs_q6_left: "0",
      sacs_q6_right: "0",
      sacs_q7_left: "0",
      sacs_q7_right: "0",
      sacs_q8_left: "0",
      sacs_q8_right: "0",
      sacs_q9_left: "0",
      sacs_q9_right: "0",
      sacs_q10_left: "0",
      sacs_q10_right: "0",
      sacs_q11_left: "0",
      sacs_q11_right: "0",
      sacs_q12_left: "0",
      sacs_q12_right: "0",
      sacs_q13_left: "0",
      sacs_q13_right: "0",
      sacs_q14_left: "0",
      sacs_q14_right: "0",
      sacs_q15_left: "0",
      sacs_q15_right: "0",
      sacs_q16_left: "0",
      sacs_q16_right: "0",
      sacs_q17_left: "0",
      sacs_q17_right: "0",
      sacs_q18_left: "0",
      sacs_q18_right: "0",
      sacs_q19_left: "0",
      sacs_q19_right: "0",
      sacs_q20_left: "0",
      sacs_q20_right: "0",
      sacs_q21_left: "0",
      sacs_q21_right: "0",
    },
  ],
  safisScore: [
    {
      satisfaction_left: "1",
      satisfaction_right: "1",
    },
  ],

  TotalScores: [
    {
      constant_score_Left: 0,
      constant_score_right: 0,
      sst_score_left: 0,
      sst_score_right: 0,
      ucla_score_left: 0,
      ucla_score_right: 0,
      ases_score_left: 0,
      ases_score_right: 0,
      wosiScoreLeft: 0,
      wosi_score_right: 0,
      sacs_score_left: 0,
      sacs_score_right: 0,
      pain_today_rating_left: 0,
      pain_today_rating_right: 0,
    },
  ],
  doctorFeedback: [
    {
      active_forward_degree_right: "",//
      active_forward_degree_left: "",//
      prom_forward_right: "",
      prom_forward_left: "",
      active_forward_grade_right: '',//
      active_forward_grade_left: '',//
      lateralelevation_right: "",//
      lateralelevation_left: "",//
      prom_lateral_right: "",
      prom_lateral_left: "",
      strengh_lateralelevation_right: "", //
      strengh_lateralelevation_left: "",//
      er1_right: "",
      er1_left: "",
      prom_er1_right: "",
      prom_er1_left: "",
      er2_right: "",
      er2_left: "",
      armposition_right: "",
      armposition_left: "",
      ir1_right: "",
      ir1_left: "",
      ir2_right: "",
      ir2_left: "",
      treatment_notes: "",
      subscapularis_power_right: "",
      subscapularis_power_left: "",
      subscapularis_kg_right: "",
      subscapularis_kg_left: "",
      bearhug_right: "",
      bearhug_left: "",
      gerbers_lift_right: "",
      gerbers_lift_left: "",
      napoleon_right: "",
      napoleon_left: "",
      supraspinatus_power_right: "",
      supraspinatus_kg_right: "",
      supraspinatus_power_left: "",
      supraspinatus_kg_left: "",
      jobes_right: "",
      jobes_left: "",
      yocum_right: "",
      yocum_left: "",
      infraspinatus_power_right: "",
      infraspinatus_kg_right: "",
      infraspinatus_power_left: "",
      infraspinatus_kg_left: "",
      teresminor_right: "",
      teresminor_left: "",
      hornblower_right: "",
      hornblower_left: "",
      tenderness_right: "",
      tenderness_left: "",
      apinstability_right: "",
      apinstability_left: "",
      paxinos_right: "",
      paxinos_left: "",
      whipple_right: "",
      whipple_left: "",
      briens_right: "",
      briens_left: "",
      biceps_tenderness_right: "",
      biceps_tenderness_left: "",
      biceps_yergason_test_right: "",
      biceps_yergason_test_left: "",
      speeds_right: "",
      speeds_left: "",
      popeye_right: "",
      popeye_left: "",
      aers_right: "",
      aers_left: "",
      triceps_right: "",
      triceps_left: "",
      deltoidpower_right: "",
      deltoid_right: "",
      deltoidpower_left: "",
      deltoid_left: "",
      midpower_deltoid_right: "",
      middeltoid_right: "",
      midpower_deltoid_left: "",
      middeltoid_left: "",
      postpower_deltoid_right: "",
      postdeltoid_right: "",
      postpower_deltoid_left: "",
      postdeltoid_left: "",
      cspine: "",
      ssn_stretch_right: "",
      ssn_stretch_left: "",
      heri_right: "",
      heri_left: "",
      gagey_right: "",
      gagey_left: "",
      scapula_right: "",
      scapula_left: "",
      ligamentous: "0",
      thoracic_right: "",
      thoracic_left: "",
      grip_right: "",
      grip_left: "",
      treatment_notes2value: "",
      right_diagnosis: "",
      left_diagnosis: "",
      procedure: "",
      outcome_treatment: "",
    },
  ],

  shoulserScore: "1",
};

const ShoulderScoringReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SHOULDER_DATA":
      return { ...state, shoulserScore: action.data };

    case "UPDATE_SHOULDER_PAIN_FIELD_DATA":
      const painFieldVal = action.data.value;
      const painFieldKey = action.data.key;
      const painNewArr = state.painScore.map((object) => {
        return { ...object, [painFieldKey]: painFieldVal };
      });
      return { ...state, painScore: painNewArr };

    case "UPDATE_SHOULDER_PAIN_DATA":
      return { ...state, painScore: action.data };

    case "UPDATE_SHOULDER_FUNCTION_FIELD_DATA":
      const functionFieldVal = action.data.value;
      const functionFieldKey = action.data.key;
      const functionNewArr = state.functionScore.map((object) => {
        return { ...object, [functionFieldKey]: functionFieldVal };
      });
      return { ...state, functionScore: functionNewArr };

    case "UPDATE_SHOULDER_FUNCTION_DATA":
      return { ...state, functionScore: action.data };

    case "UPDATE_WOSI_FUNCTION_FIELD_DATA":
      const wosiFieldVal = action.data.value;
      const wosiFieldKey = action.data.key;
      const wosiNewArr = state.wosiScore.map((object) => {
        return { ...object, [wosiFieldKey]: wosiFieldVal };
      });
      return { ...state, wosiScore: wosiNewArr };

    case "UPDATE_WOSI_FUNCTION_DATA":
      return { ...state, wosiScore: action.data };

    case "UPDATE_SACS_FUNCTION_FIELD_DATA":
      const sacsFieldVal = action.data.value;
      const sacsFieldKey = action.data.key;
      const sacsNewArr = state.sacsScore.map((object) => {
        return { ...object, [sacsFieldKey]: sacsFieldVal };
      });
      return { ...state, sacsScore: sacsNewArr };

    case "UPDATE_SACS_FUNCTION_DATA":
      return { ...state, sacsScore: action.data };

    case "UPDATE_SATISFACTION_FUNCTION_FIELD_DATA":
      const safisFieldVal = action.data.value;
      const safisFieldKey = action.data.key;
      const safisNewArr = state.safisScore.map((object) => {
        return { ...object, [safisFieldKey]: safisFieldVal };
      });
      return { ...state, safisScore: safisNewArr };

    case "UPDATE_SATISFACTION_FUNCTION_DATA":
      return { ...state, safisScore: action.data };

    case "UPDATE_FEEDBACK_FUNCTION_FIELD_DATA":
      const feedFieldVal = action.data.value;
      const feedFieldKey = action.data.key;
      const feedNewArr = state.doctorFeedback.map((object) => {
        return { ...object, [feedFieldKey]: feedFieldVal };
      });
      return { ...state, doctorFeedback: feedNewArr };

    case "UPDATE_FEEDBACK_FUNCTION_DATA":
      return { ...state, doctorFeedback: action.data };

    case "UPDATE_SHOULDER_TOTAL_DATA":
      return { ...state, TotalScores: action.data };

    default:
      return state;
  }
};
export default ShoulderScoringReducer;
