import React, { useState } from "react";
import PropTypes from "prop-types";

import { validateCheckbox } from "../../../utilities/Validator";

const CheckboxNew = ({ fnClick,  checked ,styleClass,id,label,onChange,validators}) => {
    const [error, setError] = useState(false);
      const handleChange = (event) => {
        const { checked } = event.target;
        setError(validateCheckbox(validators, checked));
        onChange(checked);
        // console.log("checkbox", event.target);
      };
    return(

        <div className={`form-group ${styleClass}`}>

      <input
        onClick={e => {
          if (fnClick !== undefined) fnClick(e.target.checked);
        }}
        onChange={handleChange}
        // onChange={e => {
        //   if (fnChange !== undefined) fnChange(e.target.checked);
        // }}
        type="checkbox"
        checked={checked}
       id={id}
      />
    
      {/* {" Checkbox " + title} */}
        <label  className="ms-2" htmlFor={id} >
     {label}
   </label>
   {error && <span className="text-danger">{error.message}</span>}
   </div>
  );
    }

    CheckboxNew.propTypes = {
        styleClass: PropTypes.string,
        selected: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
      };
      
      CheckboxNew.defaultProps = {
        styleClass: "",
      };

      export default CheckboxNew