import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./DeleteConfirmation.css";
import DeleteIcon from "../../../images/delete-red.svg";
import useTranslation from "../../customHooks/translations";
// const handleCloseShow = () => {
//   setShow(false);
//   setShow1(false)
// };
// const [show, setShow] = useState(false);
//   const [show1, setShow1] = useState(false)

export default function DeleteConfirmation  ({
  // show1,
  showModal,
  hideModal,
  confirmModal,
  id,
  doctor_id,
  res_id,
  setResId,
  type,
  message,
  msg,
  handleCloseShow,
  setOrgId,
  setDoctorId,
  openmodel,
  reject,
  rejected
})  {
  const translation = useTranslation();

// const [hide, setHide] = useState(false)
// const [show, setShow] = useState(false);
// const [show1, setShow1] = useState(false)

// const handleshow= (e)=>{
//   setHide(false);

// }
// const handleCloseShow = () => {
//   setShow(false);
//   setShow1(false)
// };
console.log("idididid",id,doctor_id)

useEffect(() => {
  if(openmodel!=="filedelete"){
 if(id || doctor_id || res_id){
  setDoctorId(doctor_id)
  setOrgId(id)
  setResId(res_id)
 }
}
}, [id,doctor_id])


  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      className={`delete_modal_main`}
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <img src={DeleteIcon} className="delete_pp_icon" alt="icon" />
          <h4 className="center_title">
            {reject ? translation.Are_you_sure_you_want_to_reject :
              rejected ? translation.Are_you_sure_you_want_to_delete_Consent:
             translation.Are_you_sure_you_want_to_delete }
        
             <strong>{message}</strong>
          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button variant="default" className="btn_outline" onClick={hideModal}>
          {translation.cancel}
        </Button>
        <Button
          variant="danger"
          className="btn_danger"
          onClick={() => confirmModal(type, id)} 
        
          
        >
          {reject ? translation.reject :rejected ? translation.reject : translation.delete}
         
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


