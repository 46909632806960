const initialState = {
  arr: "",
  login:""
};


const loginData = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGIN":
      return { ...state, arr: action.data };
    case "SET_LOGIN_VISIT":
      return { ...state, login: action.data };
    default:
      return state;
  }
};

export default loginData;
