import React, { useMemo } from 'react';
import Modal from "react-bootstrap/Modal";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function MyDocView({ url }) {
  const memoizedModal = useMemo(() => {
    return (
     
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            prefetchMethod="GET"
            documents={url}
            config={{
              header: {
                disableHeader: false,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          />
      
    );
  }, [ ]);

  return (
    <div>
      {memoizedModal}
    </div>
  );
}
