import React, { useState, useRef} from "react";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import useTranslation from "../../customHooks/translations";

function GoogleAddress({ onAddressChange, label, valid,value }) {
  const [location, setLocation] = useState("");
  const translation = useTranslation();
  // const [addressError, setAddressError] = useState(valid);
  const inputRef = useRef(null);
  let YOUR_API_KEY = "AIzaSyAb4s2rhkg_dDms-SKZx16Ka5zDcU6aEIs";

  function handlePlaceChanged() {
    const place = inputRef.current.getPlaces()[0];
    if (place) {
      setLocation(place.formatted_address);
      onAddressChange(place.formatted_address);
    }
  }

  function handleLocation(value) {
    // setAddressError(false);
    onAddressChange(value);
    setLocation(value);
  }



  return (
    <>
      {label && <label htmlFor="app-input-field">{label} </label>}
      <LoadScript
        googleMapsApiKey={YOUR_API_KEY}
        libraries={["places"]}
        onLoad={() => console.log("LoadScript loaded")}
      >
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <input
            type="text"
            className="form-control br_10"
            placeholder={translation.enter_address}
            value={value?value:location}
            onChange={(e) => handleLocation(e.target.value)}
          />
        </StandaloneSearchBox>
      </LoadScript>

      {valid && <p className="text-danger">{translation.This_field_is_required}</p>}
    </>
  );
}
export default GoogleAddress;
