import React, { useRef } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line, getElementAtEvent } from "react-chartjs-2";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ScoreChartStyles from "./ScoreChart.module.css";
import PropTypes from "prop-types";
import useTranslation from "../customHooks/translations";
ChartJS.register(...registerables);

// const labels = ["12/12/2020", date2];

const ScoreChart = ({
  title,
  options,
  // chartData,
  initialRight,
  initialLeft,
  recentRight,
  recentLeft,
  type,
  // date1,
  // date2,
  dates,
  inital,
  recent,
}) => {
  const translation = useTranslation();

  const interpolateData = (data) => {
    const interpolatedData = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i] === -1) {
        const previousValue =
          interpolatedData[interpolatedData.length - 1] || 0;
        const nextValue = data[i + 1] || 0;
        interpolatedData.push((previousValue + nextValue) / 2);
      } else {
        interpolatedData.push(data[i]);
      }
    }
    return interpolatedData;
  };
  const data = {
    labels: dates,
    // datasets: [
    //         {
    //           yAxisID: "left-y-axis",
    //           label: "Left",
    //           data: recent,
    //           borderColor: "#3f2783",
    //           // backgroundColor: "#ECE9F3",
    //           backgroundColor: "rgba(236, 233, 243, 1)",
    //           fill: {
    //             target: "origin", // 3. Set the fill options
    //             above: "rgba(206, 206, 206, 0.3)",
    //           },
    //           borderDash: [5, 5],
    //           tension: 0.6,
    //         },
    //         {
    //           yAxisID: "right-y-axis",
    //           label: "Right",
    //           data: inital,
    //           borderColor: "#3f2783",
    //           // backgroundColor: "#cecece",
    //           backgroundColor: "rgba(141, 135, 135, 0.3)",
    //           fill: "origin",
    //           tension: 0.6,
    //         },
    //       ]
    datasets: [
      {
        label: "Left",
        data: inital.map((value) => (value === -1 ? null : value)), // dataset1Data.map((value) => value === -1 ? null : valu//e
        //  data :interpolateData(inital),
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#3f2783",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(206, 206, 206, 0.3)",
        },

        // backgroundColor: "rgba(236, 233, 243, 1)",
      },
      {
        label: "Right",
        data: recent.map((value) => (value === -1 ? null : value)),
        // data :interpolateData( recent),
        fill: false,
        borderColor: "#3f2783",
        fill: "origin",
        borderDash: [5, 5],
      },
    ],
  };
  const chartRef = useRef();
  const onPointClick = (event) => {
    if (getElementAtEvent(chartRef.current, event).length > 0) {
      // console.log("point click", getElementAtEvent(chartRef.current, event));
      // window.open('https://www.chartjs3.com/', '_blank');
    }
  };

  return (
    <div className={`score_widget_sec ${ScoreChartStyles.ScoreChartSec}`}>
      <div className={`whitebox ${ScoreChartStyles.whitebox}`}>
        <div className="row">
          <div className={`col-md-12 ${ScoreChartStyles.scorebox_main}`}>
            <h4 className={`${ScoreChartStyles.scorebox_title}`}>{title==="Pain" ? translation.Pain 
                          :title ==="Function" ?translation.Function 
                          : title ==="Instability"?translation.patient_foot_ankle_instability
                          :title ==="Feeling of instability" ?translation.patient_shoulder_feeling_instability
                          :title}</h4>
            <div className={`${ScoreChartStyles.score_content_box}`}>
              <div className={` ${ScoreChartStyles.score_content_row}`}>
                {type === "initial" ? (
                  <div
                    className={`row ${
                      (ScoreChartStyles.score_row,
                      ScoreChartStyles.initial_score_row)
                    }`}
                  >
                    <div
                      className={` col-md-6 col-sm-6 pr_0 ${ScoreChartStyles.left_sc_row}`}
                    >
                      <div className={` ${ScoreChartStyles.score_title}`}>
                        <p className={` ${ScoreChartStyles.initial_title}`}>
                          <span></span>{translation.Initial}
                        </p>
                      </div>
                      <div className={`${ScoreChartStyles.score_values}`}>
                        <span>R&nbsp;:&nbsp;</span>
                        <span>{initialRight}</span>
                      </div>
                      <div className={` ${ScoreChartStyles.score_values}`}>
                        <span>L&nbsp;:&nbsp;</span> <span>{initialLeft}</span>
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-sm-6 pr_0 ${ScoreChartStyles.pr_circles}`}
                    >
                      <div style={{ width: 70, height: 70, margin: "0 auto" }}>
                        <CircularProgressbar
                          value={initialRight}
                          maxValue={100}
                          text={`${initialRight}`}
                          strokeWidth="11"
                          styles={buildStyles({
                            textColor: "#000",
                            pathColor: "#ff0000",
                          })}
                        />
                      </div>
                      <div style={{ width: 70, height: 70, margin: "0 auto" }}>
                        <CircularProgressbar
                          value={initialLeft}
                          maxValue={100}
                          text={`${initialLeft}`}
                          strokeWidth="11"
                          styles={buildStyles({
                            textColor: "#000",
                            pathColor: "#ff0000",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={`row ${
                        (ScoreChartStyles.score_row,
                        ScoreChartStyles.initial_score_row)
                      }`}
                    >
                      <div
                        className={`col-md-5 col-sm-6 ${ScoreChartStyles.score_title}`}
                      >
                        <p className={` ${ScoreChartStyles.initial_title}`}>
                          <span></span>{translation.Initial}
                        </p>
                      </div>
                      <div
                        className={`col-md-3 col-sm-3 ${ScoreChartStyles.score_values}`}
                      >
                        <span>R&nbsp;:&nbsp;</span>
                        <span>{initialRight}</span>
                      </div>
                      <div
                        className={`col-md-4 col-sm-3 ${ScoreChartStyles.score_values}`}
                      >
                        <span>L&nbsp;:&nbsp;</span> <span>{initialLeft}</span>
                      </div>
                    </div>

                    <div
                      className={`row ${
                        (ScoreChartStyles.score_row,
                        ScoreChartStyles.recent_score_row)
                      }`}
                    >
                      <div
                        className={`col-md-5 col-sm-6 ${ScoreChartStyles.score_title}`}
                      >
                        <p className={`${ScoreChartStyles.recent_title}`}>
                          <span></span>{translation.Recent}
                        </p>
                      </div>
                      <div
                        className={`col-md-3 col-sm-3 ${ScoreChartStyles.score_values}`}
                      >
                        <span>R&nbsp;:&nbsp;</span>
                        <span>{recentRight}</span>
                      </div>
                      <div
                        className={`col-md-4 col-sm-3 ${ScoreChartStyles.score_values}`}
                      >
                        <span>L&nbsp;:&nbsp;</span>
                        <span>{recentLeft}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {type === "initial" ? (
              ""
            ) : (
              <div className={`col-md-12 ${ScoreChartStyles.chart_area}`}>
                {Object.keys({
                  labels: dates,
                  datasets: [
                    {
                      label: "Left",
                      data: recent,
                      borderColor: "#3f2783",
                      backgroundColor: "#ECE9F3",
                      opacity: 0.2,
                      fill: true,
                      borderDash: [5, 5],
                      tension: 0.6,
                      yAxisID: "y1",
                    },
                    {
                      label: "Right",
                      data: inital,
                      borderColor: "#3f2783",
                      backgroundColor: "#cecece",
                      opacity: 0.2,
                      fill: true,
                      tension: 0.6,
                      yAxisID: "y",
                    },
                  ],
                }).length === 0 &&
                {
                  labels: dates,
                  datasets: [
                    {
                      label: "Left",
                      data: recent,
                      borderColor: "#3f2783",
                      backgroundColor: "#ECE9F3",
                      opacity: 0.2,
                      fill: true,
                      borderDash: [5, 5],
                      tension: 0.6,
                      yAxisID: "y1",
                    },
                    {
                      label: "Right",
                      data: inital,
                      borderColor: "#3f2783",
                      backgroundColor: "#cecece",
                      opacity: 0.2,
                      fill: true,
                      tension: 0.6,
                      yAxisID: "y",
                    },
                  ],
                }.constructor === Object ? (
                  ""
                ) : (
                  <>
                    <Line
                      data={data}
                      options={options}
                      ref={chartRef}
                      onClick={onPointClick}
                    />
                  </>
                  // <Line
                  //   options={options}
                  //   onClick = {onPointClick}
                  //   ref = {chartRef}
                  //   data={{
                  //     labels: dates,
                  //     datasets: [
                  //       {
                  //         yAxisID: "left-y-axis",
                  //         label: "Left",
                  //         data: recent,
                  //         borderColor: "#3f2783",
                  //         // backgroundColor: "#ECE9F3",
                  //         backgroundColor: "rgba(236, 233, 243, 1)",
                  //         fill: {
                  //           target: "origin", // 3. Set the fill options
                  //           above: "rgba(206, 206, 206, 0.3)",
                  //         },
                  //         borderDash: [5, 5],
                  //         tension: 0.6,
                  //       },
                  //       {
                  //         yAxisID: "right-y-axis",
                  //         label: "Right",
                  //         data: inital,
                  //         borderColor: "#3f2783",
                  //         // backgroundColor: "#cecece",
                  //         backgroundColor: "rgba(141, 135, 135, 0.3)",
                  //         fill: "origin",
                  //         tension: 0.6,
                  //       },
                  //     ]
                  //   }}

                  // />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ScoreChart.propTypes = {
  // valueLeft: PropTypes.string,
  // valueRight: PropTypes.string,
  title: PropTypes.string,
  chartData: PropTypes.object,
  options: PropTypes.object,
};
ScoreChart.defaultProps = {
  title: "",
  chartData: {},
  options: {},
  initialRight: "",
  initialLeft: "",
  recentRight: "",
  recentLeft: "",
  type: "",
};

export default ScoreChart;
