import React, { useState, useEffect } from "react";
import useAxios from "../../axiosinstance";
import visitsStyles from "./MyVisits.module.css";
import useTranslation from "../customHooks/translations";
import Modal from "react-bootstrap/Modal";
import ResultCircleProgress from "../custom/result-circle-progress/ResultCircleProgress";
import ScoreWidget from "./score-widget/ScoreWidget";
import { DateFormate, UserDateFormat } from "../../utilities/DateFormate";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import Button from "../custom/button/Button";
import { useSelector } from "react-redux";
export default function Followup({ id,selectedItem }) {
    const axiosInstance = useAxios();
    const [Followup, setFollowup] = useState([]);
    const [show, setShow] = useState(false); 
    const translation = useTranslation();
    let lang = sessionStorage.getItem("lang_")
    const [profile, setProfile] = useState("");
    const login = useSelector((state) => state.loginPatient.arr[0]);
    // console.log(ChangeLocalization("Feeling of instability"))
    useEffect(() => {
      if (login) {
        setProfile(login.patient);
      }
    }, [login]);
    const getFollowupData = async () => {
        try {
            const result = await axiosInstance.current.get(
                `extapp/patients/followups/${profile.ak_id}?resource_id=${id}`
            );
            if (result.status === 200) {
                setFollowup(result.data.data);
                setShow(true);
            }
        } catch (err) {
            console.log(err.Messages);
            // setMessageType("error");
        } finally {
            console.log("api response finally");
            setShow(true);
        }
    };

    useEffect(() => {
      if(profile.ak_id){
        getFollowupData();
      }
    }, [id, profile]);

    const [showModal, setShowModal] = useState(false);
    const [data, setDatas] = useState([]);
    const handleClose = () => setShowModal(false);
    const [load, setLoad] = useState(false);
    const getData = async (id) => {
      const response = await axiosInstance.current.get(
        `/extapp/patients/scoringLists/${profile.ak_id}/${id}`
      );
      setDatas(response.data.data);
      setLoad(true);
    };
  
    const handleShow = (id) => {
      setLoad(false);
      setShowModal(true);
      getData(id);
    };
    const handleChk = (doctor, org, scoringForm, pending, resource_ids) => {
        // alert(doctor , org ,scoringForm)
    
        // alert( scoringForm)
        if (!pending) {
          window.open(
            process.env.REACT_APP_FORM_URL +
            "personal-data" +
            `/${window.btoa(profile.ak_id)}` +
            `/${window.btoa(doctor)}` +
            `/${window.btoa(org)}` +
            `/${window.btoa(scoringForm)}`,
            "_blank"
          );
        } else {
          if (scoringForm === 5) {
            window.open(
              process.env.REACT_APP_FORM_URL +
              "adult-knee-pain" +
              `/${resource_ids}`,
              "_blank"
            );
          }
          if (scoringForm === 4) {
            window.open(
              process.env.REACT_APP_FORM_URL + "pain" + `/${resource_ids}`,
              "_blank"
            );
          }
          if (scoringForm === 20) {
            window.open(
              process.env.REACT_APP_FORM_URL +
              "pedia-knee-pain" +
              `/${resource_ids}`,
              "_blank"
            );
          }
          if (scoringForm === 6) {
            window.open(
              process.env.REACT_APP_FORM_URL + "hip-pain" + `/${resource_ids}`,
              "_blank"
            );
          }
          if (scoringForm === 21) {
            window.open(
              process.env.REACT_APP_FORM_URL +
              "foot-ankle-pain" +
              `/${resource_ids}`,
              "_blank"
            );
          }
          if (scoringForm === 22) {
            window.open(
              process.env.REACT_APP_FORM_URL + "elbow-pain" + `/${resource_ids}`,
              "_blank"
            );
          }
          if (scoringForm === 23) {
            window.open(
              process.env.REACT_APP_FORM_URL +
              "hand-and-wrist-pain" +
              `/${resource_ids}`,
              "_blank"
            );
          }
          if (scoringForm === 33) {
            // alert(1)
            window.open( 
              process.env.REACT_APP_FORM_URL +
              "general-history" +
              `/${resource_ids}`,
              "_blank"
            );
          }
        }
      };
    return (
        <div>
            <div
                className={`collapse ${id === selectedItem ? "" : "show"
                    }`}
                id={id}
                data-bs-parent="#accordion"
                key={id}
            >
                {/* {!show && <span>Loading..</span>} */}

                {show && ( 
                    <>
                        {Followup.length === 0 && (
                            <span
                                className={`${visitsStyles.visits_fallowup} ms-3`}
                            >
                                {" "}
                                {translation.NoFollowUpRecords}
                            </span>
                        )}

                        {Followup.map((item) => (
                            <div
                                className={`${visitsStyles.visits_fallowup} `}
                            >
                                <div
                                    className={`${visitsStyles.visits_fallow_connect}`}
                                >
                                    <span
                                        className={`${lang === "ara" ? visitsStyles.visits_trianglearb : visitsStyles.visits_triangle}`}
                                    ></span>
                                </div>
                                <div
                                    className={`${visitsStyles.visits_inner}`}
                                >
                                    <div
                                        className={`${visitsStyles.visits_left_content}`}
                                    >
                                        <p
                                            className={`${visitsStyles.visits_assdet}`}
                                        >
                                            <span>
                                                {item.created_at}
                                            </span>
                                            <span>
                                                {" "}
                                                {item.evaluted === "Both"
                                                    ? translation.Both
                                                    : item.evaluted === "Right"
                                                        ? translation.Right
                                                        : translation.Left}{" "}
                                                -{" "}
                                            </span>
                                            {/* <span> {item.case_type} </span> */}
                                            <span>
                                                {" "}
                                                {item.case_type === "Shoulder"
                                                    ? translation.shoulder
                                                    : item.case_type === "Adult Knee"
                                                        ? translation.adult_knee
                                                        : item.case_type === "Hip"
                                                            ? translation.hip_form
                                                            : item.case_type === "Foot And Ankle"
                                                                ? translation.Foot_And_Ankle
                                                                : item.case_type === "Elbow"
                                                                    ? translation.Elbow
                                                                    : item.case_type === "Hand And Wrist"
                                                                        ? translation.Hand_And_Wrist
                                                                        : item.case_type === "Paediatric Knee"
                                                                            ? translation.paediatric_knee
                                                                            :
                                                                            item.case_type === "Adolescent Knee"
                                                                                ? translation.adolescent_knee
                                                                                : translation.General}{" "}
                                            </span>
                                            <span>{item.resource_type}</span>
                                        </p>
                                        <p
                                            className={`${visitsStyles.visits_dctrdet}`}
                                        >
                                            <span>{item.doctor_name}, </span>
                                            <span>{item.org_name}</span>
                                        </p>
                                    </div>
                                    {/* <div
                                        className="btn_view"
                                        onClick={() =>
                                          handleShow(item.resource_id)
                                        }
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title={item.scoreDetails}
                                      >
                                        {translation.View_score}
                                      </div> */}

                                    {item.status === true ? (
                                        <>
                                            {item.case_type !== "General" && (
                                                <div
                                                    className="btn_view"
                                                    onClick={() =>
                                                        handleShow(item.resource_id)
                                                    }
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={item.scoreDetails}
                                                >
                                                    {translation.View_score}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div
                                            className="btn_view"
                                            onClick={() =>
                                                handleChk(
                                                    item.ak_dc,
                                                    item.ak_ou,
                                                    item.type,
                                                    item.personal_info,
                                                    item.resource_id
                                                )
                                            }
                                        // data-bs-toggle="tooltip"
                                        // data-bs-placement="top"
                                        // title={item.scoreDetails}
                                        >
                                            {translation.PendingForm}
                                            {/* Pending Form */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
            <Modal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            dialogClassName="modal-md patient_notes_popup model_width modal-dialog-centered"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                {translation.View_score}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <div className="result_screen mb-4">
                <div className={lang === "ara" ? "panelarb" : "panel"}>
                  <div className="panel-body p_25">
                    <div className="row justify-content-center"> 
                      {!load ? (
                        <LoadingSpinner />
                      ) : (
                        <>
                          {data.length === 0 ? (
                            <div className="panel">
                              <div className="panel-body p_25">
                                <div className="no_data">
                                  {translation.No_data_found}
                                  {/* No Score Found */}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {data.map((row) => (
                                <>
                                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div className="box_d">
                                      <ResultCircleProgress
                                        total={row.total_score}
                                        title={row.title}
                                        type=""
                                        arm={row.evaluted_value}
                                        // text={
                                        //   row.title === "VAS"
                                        //     ? `${row.score * 10}`
                                        //     : `${row.score * 1}`
                                        // }
                                        // value={
                                        //   row.title === "VAS"
                                        //     ? `${row.score * 10}`
                                        //     : `${row.score * 1}`
                                        // }
                                        value={Number(row.score)}
                                        text={Number(row.score)}
                                        // value={row.score}
                                        date={row.date}
                                        color={row.path_color}
                                        reverse={row.reversed}
                                        min_score={row.min_score}
                                      />
                                    </div>
                                  </div>
                                </>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>
              <Button
                onClick={handleClose}
                value={translation.cancel}
                buttonStyle="btn_nill mr_20 ms-auto"
              />
            </Modal.Footer>
          </Modal>
        </div>
    )
}
