import React, {  useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./DoctorRegistration.css"
import DoctorPersonalInfo from "./DoctorPersonalInfo"
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function DoctorRegistration() {
  const [isLoading, setIsLoading] = useState(true);
  const login = useSelector((state) => state.userDetails.user);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(()=>{
    if(login.count>=5){
      navigate(`/`)
    }
  },[login?.count])
  return (
    <>
     {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="doctor_reg_screen">
          <div className="tab-content pt-0">
          <DoctorPersonalInfo />
          </div>
        </div>
      )}
    </>
  );
}

export default DoctorRegistration;
