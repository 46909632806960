import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import nextWhiteIcon from "../../images/next-white.png";
import nextIcon from "../../images/next.png";
import Dropdown from "../custom/dropdown/Dropdown";
import { Validators } from "../../utilities/Validator";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import { useRef } from "react";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import useTranslation from "../customHooks/translations";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Message from "../custom/toster/Message";
import icon_info from '../../images/info-icon.svg';
import CreateOrganizationReq from "../joinorg/CreateOrganizationReq";
function VisitClinic() {
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const login = useSelector((state) => state.loginPatient.arr[0]);

  // const hip = useSelector((state) => state.hipScoreReducer);
  // const footAnkle = useSelector((state) => state.footAnkle);
  // const pediaKnee = useSelector((state) => state.pediaKnee);
  // const shoulderScore = useSelector((state) => state.shoulderScore);
  // const elbowScore = useSelector((state) => state.elbowScore);
  // const handWrist = useSelector((state) => state.handWrist);
  // const KneeFeedbackReduce = useSelector((state) => state.KneeFeedbackReduce);

  let jsonHip = useSelector((state) => state.hipScoreReducer);
  let adultKneeScore = useSelector((state) => state.adultKneeScore);
  let footAnkle = useSelector((state) => state.footAnkle);
  let pediaKnee = useSelector((state) => state.pediaKnee);
  let elbowScore = useSelector((state) => state.elbowScore);
  let handWrist = useSelector((state) => state.handWrist);
  let shoulderJson = useSelector((state) => state.shoulderScore);
  let genjson = useSelector((state) => state.GeneralFormReduces);

  const [doctor, setDoctor] = useState("");
  const [org, setorg] = useState("");
  const [dob, setDob] = useState("");
  const [akptId, setAkptId] = useState("");
  const [items, setItems] = useState([]);
  const [items1, setItems1] = useState([]);
  const [items2, setItems2] = useState([]);
  const [consentCheck, setConsentCheck] = useState(false);
  const [allready, setAllready] = useState(false);
  const [scoringForm, setScoringForm] = useState("");
  const scoringFormRef = useRef();
  const [showModal, setshowModal] = useState(false);
  const [showModalForm, setshowModalForm] = useState(false);
  const [formSelected, setFormSelected] = useState("");
  const [orgErr, setOrgErr] = useState(false);
  const [doctorErr, setDoctorErr] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [completeStatus, setcompleteStatus] = useState("no");
  const [completePersonal, setCompletePersonal] = useState("no");

  const [show1, setShow1] = useState(true);
  let akToken = localStorage.getItem("AkTOKENI");

  const continueModel = () => {
    setshowModal(true);
  };
  const hideModals = () => {
    setshowModal(false);
  };
  const hideModalsForm = () => {
    setshowModalForm(false);
  };

  const handleScoringForm = (e) => {
    setFormSelected(e);
    if (e === "5") {
      if (new Date().getFullYear() - new Date(dob).getFullYear() <= 13) {
        setScoringForm("20");
      } else {
        setScoringForm("5");
      }
    } else {
      setScoringForm(e);
    }
    // setIsLoading(true);
  };

  useEffect(() => {
    if (scoringForm !== "") checkConsent();
    checkAlready();
    setShow1(false);
  }, [scoringForm]);

  useEffect(() => {
    if (login) {
      setAkptId(login.patient.ak_id);
      setDob(login.patient.dob);
    }
  }, [login]);
  const continueRedirect = () => {
    if (!org) {
      setOrgErr(true);
    }
    if (!doctor) {
      setDoctorErr(true);
    }
    if (!scoringForm) {
      scoringFormRef.current.errUpdate();
    }

    if (!scoringForm || !org || !doctor) {
    } else {
      if (scoringForm) {
        if (!consentCheck) {
          window.open(
            process.env.REACT_APP_FORM_URL +
            "consent-form" +
            `/${window.btoa(akptId)}` +
            `/${window.btoa(doctor)}` +
            `/${window.btoa(org)}` +
            `/${window.btoa(scoringForm)}`,
            "_blank"
          );
          refreshPage()
        } else {
          setshowModalForm(true);
          // window.open(
          //   process.env.REACT_APP_FORM_URL +
          //     "personal-data" +
          //     `/${window.btoa(akptId)}` +
          //     `/${window.btoa(doctor)}` +
          //     `/${window.btoa(org)}` +
          //     `/${window.btoa(scoringForm)}`,
          //   "_blank"
          // );
        }
      }
    }
  };

  const getData = async () => {
    // const response = await axiosInstance.current.get(`extapp/doctors/list`);
    // console.log(response.data.data);
  };

  const deleteData = async () => {
    const response = await axiosInstance.current.delete(
      `extapp/forms/note?resource_id=${allready.data}`
    );
    if (response) {
      continueRedirect();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const getForm = async () => {
      const response = await axiosInstance.current.get(
        `extapp/resource_type?ak_id=${doctor}&org_id=${org}`
      );
      setItems2(response.data);
    };
    if (doctor) getForm();
  }, [doctor,org]);

  // let a = items2.filter((items) => items.type_id !== 5 && items.type_id !== 20);
  // const formList = a.map((item) => {
  //   return { value: item.type_id, label: item.alias };
  // });
  const formList = items2.map((item) => {
    return { value: item.type_id, label: item.alias === "Shoulder" ? translation.shoulder : item.alias === "Knee" ? translation.Knee : item.alias === "Hip" ? translation.hip_form : item.alias === "Foot And Ankle" ? translation.Foot_And_Ankle : item.alias === "Elbow" ? translation.Elbow : item.alias === "Hand And Wrist" ? translation.Hand_And_Wrist : translation.General };
  });


  const checkConsent = async () => {
    const response = await axiosInstance.current.get(
      `extapp/patients/constent_form?org_id=${org}&ak_id=${doctor}&type=14&akpt_id=${akToken}&formType=${scoringForm}`
    );
    setConsentCheck(response.data.status);
  };
  const checkAlready = async () => {
    const response = await axiosInstance.current.get(
      `extapp/forms/get_complete_forms?ak_pt=${akptId}&ak_dc=${doctor}&ak_ou=${org}&type=${scoringForm}`
    );
    if (response.status === 200) setAllready(response.data);
    setcompleteStatus(response.data.status);
    setCompletePersonal(response.data.check_personal_data);
    setShow1(true);
  };

  const checkForm = () => {
    if (completeStatus === true) {
      // alert(1)
      continueModel();
    }
    if (completeStatus === false) {
      continueRedirect();
      // alert(2)
    }
  };
  const continueForm = () => {
    if (completePersonal) {
      if (scoringForm === "5") {
        refreshPage()
        window.open(
          process.env.REACT_APP_FORM_URL +
          "adult-knee-pain" +
          `/${allready.data}`,
          "_blank"
        );
      }
      if (scoringForm === "4") {
        refreshPage()
        window.open(
          process.env.REACT_APP_FORM_URL + "pain" + `/${allready.data}`,
          "_blank"
        );
      }
      if (scoringForm === "20") {
        refreshPage()
        window.open(
          process.env.REACT_APP_FORM_URL +
          "pedia-knee-pain" +
          `/${allready.data}`,
          "_blank"
        );
      }
      if (scoringForm === "6") {
        refreshPage()
        window.open(
          process.env.REACT_APP_FORM_URL + "hip-pain" + `/${allready.data}`,
          "_blank"
        );
      }
      if (scoringForm === "21") {
        refreshPage()
        window.open(
          process.env.REACT_APP_FORM_URL +
          "foot-ankle-pain" +
          `/${allready.data}`,
          "_blank"
        );
      }
      if (scoringForm === "22") {
        refreshPage()
        window.open(
          process.env.REACT_APP_FORM_URL + "elbow-pain" + `/${allready.data}`,
          "_blank"
        );
      }
      if (scoringForm === "23") {
        refreshPage()
        window.open(
          process.env.REACT_APP_FORM_URL +
          "hand-and-wrist-pain" +
          `/${allready.data}`,
          "_blank"
        );
      }
      if (scoringForm === "33") {
        refreshPage()
        window.open(
          process.env.REACT_APP_FORM_URL +
          "general-history" +
          `/${allready.data}`,
          "_blank"
        );
      }
    } else {
      refreshPage()
      window.open(
        process.env.REACT_APP_FORM_URL +
        "personal-data" +
        `/${window.btoa(akptId)}` +
        `/${window.btoa(doctor)}` +
        `/${window.btoa(org)}` +
        `/${window.btoa(scoringForm)}`,
        "_blank"
      );
    }
  };
  const hideModal = (e) => {
    if (e) {
      deleteData();
    } else {
      continueForm();
    }
  };

  let hipOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      procedure: "",
      evlHipPain: "",
      followups: "",
      unaffected_hip: "",
    },
  ];
  let adultOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      age: "",
      evlAdultKnee: "",
      unaffected_adult: "",
      followups: "",
    },
  ];
  let footAnkleOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_footAnkle: "",
      anklePain: "",
      followups: "",
    },
  ];
  let pediaKneeOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_pedia: "",
      evlPediaPain: "",
      followups: "",
    },
  ];
  let elbowOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_elbow: "",
      elbowPain: "",
      followups: "",
    },
  ];
  let handWristOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_hand: "",
      evaluated: "",
      followups: "",
      isDisease: "0",
      isSyndrome: "0",
    },
  ];
  let ShoulderData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_shoulder: "",
      shoulserScore: "",
      followups: "",
    },
  ];
  let GeneralData = [
    {
      dominant: "",
      evaluted_value: "0",
      unaffected_shoulder: "0",
      followups: "",
    },
  ];
  const [isApiPending, setIsApiPending] = useState(false);
  const createResID = () => {
    let json;
    let otherData;

    if (scoringForm === "6") {
      json = jsonHip;
      otherData = JSON.stringify(hipOtherData);
    }
    if (scoringForm === "5") {
      json = adultKneeScore;
      otherData = JSON.stringify(adultOtherData);
    }
    if (scoringForm === "20") {
      json = pediaKnee;
      otherData = JSON.stringify(pediaKneeOtherData);
    }
    if (scoringForm === "21") {
      json = footAnkle;
      otherData = JSON.stringify(footAnkleOtherData);
    }
    if (scoringForm === "22") {
      json = elbowScore;
      otherData = JSON.stringify(elbowOtherData);
    }
    if (scoringForm === "23") {
      json = handWrist;
      otherData = JSON.stringify(handWristOtherData);
    }
    if (scoringForm === "4") {
      json = shoulderJson;
      // console.log(json)
      otherData = JSON.stringify(ShoulderData);
    }
    if (scoringForm === "33") {
      json = genjson;
      otherData = JSON.stringify(GeneralData);
    }
    setIsApiPending(true)
    axiosInstance.current
      .post(
        `extapp/forms/create_all_types_of_form?ak_pt=${akToken}&ak_dc=${doctor}&ak_ou=${org}&type=${scoringForm}&resource_id=${""}&otherData=${otherData}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMessage(translation.form_submitted);
        setMessageType("success");
        // reDir(res.data.resource_id);
        hideModalsForm();
        hideModals();
        setItems1([]);
        setItems2([]);
        // setDoctorname("");
        setOrgname("");
        setItems([]);
        setDoctor("");
        setDisable(!disable);
        // alert('saved')
        setTimeout(() => {
          setMessage(false);
        }, [2000]);
      }).finally(() => {
        setIsApiPending(false); // Set API request to not pending after it completes
      });;
  };
  function refreshPage(){ 
    setTimeout(() => {
      
      window.location.reload(); 
    }, 2000);
}
  const handleChk = (e) => {
    if (e) {
      refreshPage()
      window.open(
        process.env.REACT_APP_FORM_URL +
        "personal-data" +
        `/${window.btoa(akptId)}` +
        `/${window.btoa(doctor)}` +
        `/${window.btoa(org)}` +
        `/${window.btoa(scoringForm)}`,
        "_blank"
      );
    } else {
      // extapp/forms/new_form_not_fill_?type&ak_pt&ak_dc&ak_ou
      createResID();
    }
  };


  const [inputValue, setInputValue] = useState('');
  const [lastTypingTime, setLastTypingTime] = useState(null);

  const handleOnSearch = (value, results) => {
    setLastTypingTime(new Date().getTime());
    setInputValue(value);
  };

  const getAllOrg = async (value) => {
    try {
      const response = await axiosInstance.current.get(
        `/extapp/patients/searchByDocOrOrg/${akToken}?keyword=${value}`
      );
      const options = response.data.data.map((item) => {
        return {
          value: item.id,
          label: item.label,
          akDc_id: item.akdc_id,
          aKou: item.akou,
        };
      });
      setItems(options);
    } catch (error) {
      setItems([]);
    }
  };


  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        // Make your API call here using the inputValue
        if (inputValue.length > 2) {
          getAllOrg(inputValue);
        } else {
          setItems([]);
        }

      }, 1000); // Adjust the delay (in milliseconds) as needed

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when lastTypingTime changes
    }
  }, [lastTypingTime, inputValue]);
  const [orgname, setOrgname] = useState("");
  const handleChange = (event, newValue) => {
    setorg(newValue === null ? "" : newValue.aKou);
    setOrgname(newValue === null ? "" : newValue.label);
    setDoctor(newValue === null ? "" : newValue.akDc_id);

    setOrgErr(false);
    setScoringForm("");
  };

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (org === "") {
      setItems1([]);
      setItems2([]);
      // setDoctorname("");
      setOrgname("");
      setItems([]);
      setDoctor("");
      setDisable(!disable);
    }
  }, [org]);

  // useEffect(() => {
  //   if (doctorname === "") {
  //     setItems2([]);
  //     setItems1([]);
  //     setFormSelected("");
  //   }
  // }, [doctorname]);

  // console.log(items1);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="visit_clinic_screen mb_100">
          {message && <Message message={message} type={messageType} />}

          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h2 className="ph_title">{translation.Check_in}
                <img className="c_pointer ms-2" src={icon_info} data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={translation.info_icon} alt='icon' />
              </h2>
            </div>
                {/* <CreateOrganizationReq /> */}
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {/* <h2 className="f_600 mb_30 ">{translation.doctorOrganization}</h2> */}
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <label>{translation.selectdocOrg}</label>

                  <div className="dropdown_main_1">
                    <span className="white_shade"></span>
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      // disableClearable={true}
                      options={items}
                      value={orgname}
                      className=" w-100"
                      onChange={handleChange}
                      // disableClearable={disable}
                      renderInput={(params) => (
                        <TextField
                          placeholder={translation.Select}
                          className=""
                          style={
                            {
                              // height: "34px",
                              // // border: "2px solid rgba(63, 39, 131, 0.7)",
                              // borderRadius: "10px",
                              // color: "#3F2783",
                              // // padding: "0 15px",
                              // height: "40px",
                              // marginBottom: "12px",
                              // width: "100%",
                              // boxShadow: "none",
                              // zIndex: 1,
                            }
                          }
                          {...params}
                          // onChange={(e) => handleOnSearch(e.target.value)}
                          onKeyUp={(e) => handleOnSearch(e.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </div>
                  <span
                    className="fst-italic fw-light"
                    style={{ color: "#3F2783" }}
                  >
                    {" "}
                    {translation.Search_with_minimum_three_characters}
                  </span>
                  <br></br>
                  {orgErr && (
                    <span className="text-danger mt-3">
                      {translation.This_field_is_required}
                    </span>
                  )}
                  {/* <ReactSearchAutocomplete
                    items={items}
                    fuseOptions={{
                      shouldSort: true,
                      threshold: 0.6,
                      location: 0,
                      distance: 100,
                      maxPatternLength: 32,
                      minMatchCharLength: 3,
                      keys: ["label", "value"],
                    }} // Search on both fields
                    resultStringKeyName="label" // String to display in the results
                    onSearch={handleOnSearch}
                    // onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    // onFocus={handleOnFocus}
                    placeholder={{translation.Select}}
                    onClear={handleOnClear}
                    showIcon={false}
                    styling={{
                      height: "34px",
                      border: "2px solid rgba(63, 39, 131, 0.7)",
                      borderRadius: "10px",
                      color: "#3f2783",
                      padding: "0 15px",
                      height: "40px",
                      marginBottom: "12px",
                      width: "100%",
                      boxShadow: "none",
                      zIndex: 1,
                      marginTop:"20px",
                    }}
                  />
                  {orgErr && (
                    <span className="text-danger mt-3">
                      This field is required
                    </span>
                  )} */}
                </div>
              </div>
              {/* <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <label>{translation.Select_Doctor}</label>
                  <div className="dropdown_main_1">
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      options={items1}
                      value={doctorname}
                      className=" w-100"
                      onChange={handleOnSelectDoctor}
                      renderInput={(params) => (
                        <TextField
                          placeholder={translation.Select}
                          {...params}
                          onChange={(e) => handleOnDoctorSelect(e.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </div>

                  <span
                    className="fst-italic fw-light"
                    style={{ color: "#3F2783" }}
                  >
                    {" "}
                    Search with minimum three characters
                  </span>
                  {doctorErr && (
                    <span className="text-danger mt-3">
                      This field is required
                    </span>
                  )}
                </div>
              </div> */}

              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <Dropdown
                    data={formList}
                    label={translation.Select_Treatment_Category}
                    styleClass="mxw_100 br_10"
                    value={formSelected}
                    placeholder={translation.Select}
                    ref={scoringFormRef}
                    onChange={handleScoringForm}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6">
                {!show1 ? (
                  <button className="next_btn btn_disabled">
                    {translation.next_button}
                    <span>
                      {/* <img src={nextIcon} alt="" /> */}
                      <img src={nextWhiteIcon} alt="" />
                    </span>
                  </button>
                ) : (
                  <button
                    className="next_btn btn_fill"
                    onClick={() => checkForm()}
                  // onClick={continueModel}
                  >
                    {translation.next_button}
                    <span>
                      {/* <img src={nextIcon} alt="" /> */}
                      <img src={nextWhiteIcon} alt="" />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <Modal
            show={showModal}
            onHide={hideModals}
            className={`delete_modal_main`}
            centered
          >
            <Modal.Body>
              <div className="text-center">
                {/* <img src={DeleteIcon} className="delete_pp_icon" alt="icon" /> */}
                <h4 className="center_title">
                  {translation.areyousurewanttocontinueyourform}
                </h4>
              </div>
            </Modal.Body>
            <Modal.Footer className="text-center">
              <Button
                variant="default"
                className="btn_outline"
                onClick={() => hideModal(true)}
              >
                {translation.no}
              </Button>
              <Button
                variant="default"
                className="btn_green"
                onClick={() => hideModal(false)}
              >
                {translation.yes}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showModalForm}
            onHide={hideModalsForm}
            className={`delete_modal_main`}
            centered
          >
            <Modal.Body>
              <div className="text-center">
                {/* <img src={DeleteIcon} className="delete_pp_icon" alt="icon" /> */}
                <h4 className="center_title">
                  {/* {translation.areyousurewanttocontinueyourform} */}
                  {translation.Do_you_want_to_submit_patient_form_or_not}
                </h4>
              </div>
            </Modal.Body>
            <Modal.Footer className="text-center">
              <Button
                variant="default"
                className="btn_outline"
                onClick={() => handleChk(false)}
                disabled={isApiPending}
              >
                {translation.no}
              </Button>
              <Button
                variant="default"
                className="btn_green"
                onClick={() => handleChk(true)}
                disabled={isApiPending}
              >
                {translation.yes}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}

export default VisitClinic;
