import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import scpStyles from "./ScoreCircleProgress.module.css";
import PropTypes from "prop-types";
import { CircleColor, CircleColor2 } from "../../utilities/DocumentType";
import useTranslation from "../customHooks/translations";
const ScoreCircleProgress = ({
  title,
  initialRight,
  initialLeft,
  recentRight,
  recentLeft,
  type,
  min_score,
  total_score,
  reversed,
  single,
  side,
}) => {
  let res = reversed;
  const translation=useTranslation();

 
 
  // console.log(initialRight, initialLeft, recentRight, recentLeft , typeof initialRight, typeof initialLeft, typeof recentRight, typeof recentLeft, "data aa ");
  return (
    <div className={`score_widget_sec ${scpStyles.scpSec}`}>
      <div className={`whitebox ${scpStyles.whitebox}`}>
        <div className="row">
          <div className={`col-md-12 ${scpStyles.scorebox_main}`}>
            <h4 className={`${scpStyles.scorebox_title}`}>{title==="Pain" ? translation.Pain 
                          :title ==="Function" ?translation.Function 
                          : title ==="Instability"?translation.patient_foot_ankle_instability
                          :title ==="Feeling of instability" ?translation.patient_shoulder_feeling_instability
                          :title}</h4>
            <div className={`${scpStyles.score_content_box}`}>
              <div className={` ${scpStyles.score_content_row}`}>
                {type === "initial" ? (
                  <div
                    className={`row ${
                      (scpStyles.score_row, scpStyles.initial_score_row)
                    }`}
                  >
                    <div
                      className={` col-md-6 col-sm-6 pr_0 ${scpStyles.left_sc_row}`}
                    >
                      <div className={` ${scpStyles.score_title}`}>
                        <p className={` ${scpStyles.initial_title}`}>
                          <span></span>{translation.Initial}
                        </p>
                      </div>
                      {!side ? (
                        <div className={`${scpStyles.score_values} `}>
                          {/* <span>R&nbsp;:&nbsp;</span> */}
                          <span>{Math.round(single)}</span>
                        </div>
                      ) : (
                        <>
                          <div 
                          className={`${scpStyles.score_values} `}>
                            <span>R&nbsp;:&nbsp;</span>
                            {initialRight === -1 || initialRight=== "" ? (
                              <span> </span>
                            ) : (
                              <span>{Math.round(initialRight)}</span>
                            )}
                          </div>
                          <div className={` ${scpStyles.score_values} `}>
                            <span>L&nbsp;:&nbsp;</span>{" "}
                            {initialLeft === -1 ||initialLeft === "" ? (
                              <span> </span>
                            ) : (
                              <span>{Math.round(initialLeft)}</span>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={`col-md-6 col-sm-6 pr_0 ${scpStyles.pr_circles}`}
                    >
                      <div style={{ width: 70, height: 70, margin: "0 auto" }}>
                        {
                          initialRight !== "" 
                           && initialRight !== -1 && (
                            title ==="Pain" || title === "Instability"? 
                            <CircularProgressbar
                              // value={Math.round(initialRight)}
                              value={(total_score-Math.round(initialRight))+min_score}
                              maxValue={total_score}
                              text={`${Math.round(initialRight)}`}
                              strokeWidth="11"
                              styles={buildStyles({
                                textColor: "#000",
                                pathColor: "#ff0000",
                              
                              })}
                              
                            />:<CircularProgressbar
                              // value={Math.round(initialRight)}
                              value={CircleColor(
                                total_score,
                                Math.round(initialRight),
                                res,
                                min_score
                              )}
                              maxValue={total_score}
                              text={`${Math.round(initialRight)}`}
                              strokeWidth="11"
                              styles={buildStyles({
                                textColor: "#000",
                                pathColor: "#ff0000",
                              })}
                            />
                          )}
                      </div>
                      <div style={{ width: 70, height: 70, margin: "0 auto" }}>
                        {
                          initialLeft !== "" &&
                           initialLeft !== -1 && (
                            title ==="Pain" || title === "Instability"? 
                            <CircularProgressbar
                              // value={Math.round(initialLeft)}

                              value={(total_score-Math.round(initialLeft))+min_score}
                              maxValue={total_score}
                            
                              text={`${Math.round(initialLeft)}`}
                              strokeWidth="11"
                              styles={buildStyles({
                                textColor: "#000",
                                pathColor: "#ff0000",
                               
                               
                               
                              })}
                             
    
                            />:
                            <CircularProgressbar
                              // value={Math.round(initialLeft)}

                              value={CircleColor(
                                total_score,
                                Math.round(initialLeft),
                                res,
                                min_score
                              )}
                              maxValue={total_score}
                              text={`${Math.round(initialLeft)}`}
                              strokeWidth="11"
                              styles={buildStyles({
                                textColor: "#000",
                                pathColor: "#ff0000",
                              })}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={`row ${
                        (scpStyles.score_row, scpStyles.initial_score_row)
                      }`}
                    >
                      <div
                        className={` col-md-6 col-sm-6 pr_0 ${scpStyles.left_sc_row}`}
                      >
                        <div className={` ${scpStyles.score_title}`}>
                          <p className={` ${scpStyles.initial_title}`}>
                            <span></span>{translation.Initial}
                          </p>
                        </div>
                        <div className={`${scpStyles.score_values}`}>
                          <span>R&nbsp;:&nbsp;</span>
                          {initialRight === -1 ? (
                            <span> </span>
                          ) : (
                            <span>{Math.round(initialRight)} </span>
                          )}
                        </div>
                        <div className={` ${scpStyles.score_values}`}>
                          <span>L&nbsp;:&nbsp;</span>{" "}
                          {initialLeft === -1 ? (
                            <span> </span>
                          ) : (
                            <span>{Math.round(initialLeft)}</span>
                          )}
                        </div>
                      </div>
                      <div
                        className={`col-md-6 col-sm-6 pr_0 ${scpStyles.pr_circles}`}
                      >
                        <div
                          style={{ width: 70, height: 70, margin: "0 auto" }}
                        >
                          {
                            initialRight !== "" &&
                            initialRight !== -1 && (
                              <CircularProgressbar
                                // value={Math.round(initialRight)}
                                value={CircleColor(
                                  total_score,
                                  Math.round(initialRight),
                                  res,
                                  min_score
                                )}
                                maxValue={total_score}
                                text={`${Math.round(initialRight)}`}
                                strokeWidth="11"
                                styles={buildStyles({
                                  textColor: "#000",
                                  pathColor: "#ff0000",
                                })}
                              />
                            )}
                        </div>
                        <div
                          style={{ width: 70, height: 70, margin: "0 auto" }}
                        >
                          {
                            initialLeft !== "" &&
                            initialLeft !== -1 && (
                              <CircularProgressbar
                                // value={Math.round(initialLeft)}
                                value={CircleColor(
                                  total_score,
                                  Math.round(initialLeft),
                                  res,
                                  min_score
                                )}
                                maxValue={total_score}
                                text={`${Math.round(initialLeft)}`}
                                strokeWidth="11"
                                styles={buildStyles({
                                  textColor: "#000",
                                  pathColor: "#ff0000",
                                })}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                    {title !== "SANE Pre-injury" && (
                      <div
                        className={`row ${
                          (scpStyles.score_row, scpStyles.recent_score_row)
                        }`}
                      >
                        <div
                          className={` col-md-6 col-sm-6 pr_0 ${scpStyles.left_sc_row}`}
                        >
                          <div className={` ${scpStyles.score_title}`}>
                            <p className={` ${scpStyles.recent_title}`}>
                              <span></span>{translation.Recent}
                            </p>
                          </div>
                          <div className={`${scpStyles.score_values}`}>
                            <span>R&nbsp;:&nbsp;</span>
                            {recentRight === -1 ? (
                              <span> </span>
                            ) : (
                              <span>{Math.round(recentRight)}</span>
                            )}
                          </div>
                          <div className={` ${scpStyles.score_values}`}>
                            <span>L&nbsp;:&nbsp;</span>{" "}
                            {recentLeft === -1 ? (
                              <span> </span>
                            ) : (
                              <span>{Math.round(recentLeft)}</span>
                            )}
                          </div>
                        </div>
                        <div
                          className={`col-md-6 col-sm-6 pr_0 ${scpStyles.pr_circles}`}
                        >
                          <div
                            style={{ width: 70, height: 70, margin: "0 auto" }}
                          >
                            {recentRight !== 0 &&
                              recentRight !== "" &&
                              recentRight !== -1 && (
                                <CircularProgressbar
                                  // value={Math.round(recentRight)}

                                  value={CircleColor(
                                    total_score,
                                    Math.round(recentRight),
                                    res,
                                    min_score
                                  )}
                                  maxValue={total_score}
                                  text={`${Math.round(recentRight)}`}
                                  strokeWidth="11"
                                  styles={buildStyles({
                                    textColor: "#000",
                                    pathColor: "#006dff",
                                  })}
                                />
                              )}
                          </div>
                          <div
                            style={{ width: 70, height: 70, margin: "0 auto" }}
                          >
                            {recentRight !== 0 &&
                              recentRight !== "" &&
                              recentRight !== -1 && (
                                <CircularProgressbar
                                  // value={Math.round(recentLeft)}
                                  value={CircleColor(
                                    total_score,
                                    Math.round(recentLeft),
                                    res,
                                    min_score
                                  )}
                                  maxValue={total_score}
                                  text={`${Math.round(recentLeft)}`}
                                  strokeWidth="11"
                                  styles={buildStyles({
                                    textColor: "#000",
                                    pathColor: "#006dff",
                                  })}
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ScoreCircleProgress.propTypes = {
  // valueLeft: PropTypes.string,
  // valueRight: PropTypes.string,
  title: PropTypes.string,
};
ScoreCircleProgress.defaultProps = {
  title: "",
  initialRight: "",
  initialLeft: "",
  recentRight: "",
  recentLeft: "",
  type: "",
};

export default ScoreCircleProgress;
