export const updateHandWristScores = (scores) => {
    return {
        type: 'UPDATE_GENERAL_DATA',
        data: scores,
    };
};

export const updateHistoryComplaintFieldsScore = (score) => {
    return {
        type: "UPDATE_HISTORY_COMPLAINT_FIELDS",
        data: score,
    };
};
export const updateHistoryComplaintScore = (score) => {
    return {
        type: "UPDATE_HISTORY_COMPLAINT",
        data: score,
    };
};
export const updateSocialComplaintFieldsScore = (score) => {
    return {
        type: "UPDATE_SOCIAL_COMPLAINT_FIELDS",
        data: score,
    };
};
export const updateSocialComplaintScore = (score) => {
    return {
        type: "UPDATE_SOCIAL_COMPLAINT",
        data: score,
    };
};

export const updatePastComplaintFieldsScore = (score) => {
    return {
        type: "UPDATE_PAST_COMPLAINT_FIELDS",
        data: score,
    };
};
export const updatePastComplaintScore = (score) => {
    return {
        type: "UPDATE_PAST_COMPLAINT",
        data: score,
    };
};
export const updatefeedbackFieldsScore = (score) => {
    return {
        type: "UPDATE_FEEDBACK_COMPLAINT_FIELDS",
        data: score,
    };
};
export const updatefeedbackComplaintScore = (score) => {
    return {
        type: "UPDATE_FEEDBACK_COMPLAINT",
        data: score,
    };
};