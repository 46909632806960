import React from "react";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import scpStyles from "./ScoreProgressLine.module.css";
// import PropTypes from "prop-types";
import ProgressBar from "react-bootstrap/ProgressBar";
import useTranslation from "../customHooks/translations";

const ScoreProgressLine = ({
  title,
  initialRight,
  initialLeft,
  recentRight,
  recentLeft,
  type,
}) => {
  const translation=useTranslation();
  return (
    <div className={`score_widget_sec ${scpStyles.scpSec}`}>
      <div className={`whitebox ${scpStyles.whitebox}`}>
        <div className="row">
          <div className={`col-md-12 ${scpStyles.scorebox_main}`}>
            <h4 className={`${scpStyles.scorebox_title}`}>{title}</h4>
            <div className={`${scpStyles.score_content_box}`}>
              <div className={` ${scpStyles.score_content_row}`}>
                {type === "initial" ? (
                  <div
                    className={`row ${
                      (scpStyles.score_row, scpStyles.initial_score_row)
                    }`}
                  >
                    <div
                      className={` col-md-6 col-sm-6 pr_0 ${scpStyles.left_sc_row}`}
                    >
                      <div className={` ${scpStyles.score_title}`}>
                        <p className={` ${scpStyles.initial_title}`}>
                          <span></span>{translation.Initial}
                        </p>
                      </div>
                      <div className={`${scpStyles.score_values}`}>
                        <span>R&nbsp;:&nbsp;</span>
                        <span>{Math.round(initialRight)}</span>
                      </div>
                      <div className={`${scpStyles.pr_bar}`}>
                        <ProgressBar
                          now={Math.round(initialRight)}
                          max={10}
                          className="initial"
                        />
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-sm-6 pr_0 ${scpStyles.right_sc_row}`}
                    >
                      <div className={` ${scpStyles.score_values}`}>
                        <span>L&nbsp;:&nbsp;</span>{" "}
                        <span>{Math.round(initialLeft)}</span>
                      </div>
                      <div className={`${scpStyles.pr_bar}`}>
                        <ProgressBar
                          now={Math.round(initialLeft)}
                          max={10}
                          className="initial"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={`row ${
                        (scpStyles.score_row, scpStyles.initial_score_row)
                      }`}
                    >
                      <div
                        className={` col-md-6 col-sm-6 pr_0 ${scpStyles.left_sc_row}`}
                      >
                        <div className={` ${scpStyles.score_title}`}>
                          <p className={` ${scpStyles.initial_title}`}>
                            <span></span>{translation.Initial}
                          </p>
                        </div>
                        <div className={`${scpStyles.score_values}`}>
                          <span>R&nbsp;:&nbsp;</span>
                          <span>{Math.round(initialRight)}</span>
                        </div>
                        <div className={`${scpStyles.pr_bar}`}>
                          <ProgressBar
                            now={Math.round(initialRight)}
                            max={10}
                            className="initial"
                          />
                        </div>
                      </div>
                      <div
                        className={`col-md-6 col-sm-6 pr_0 ${scpStyles.right_sc_row}`}
                      >
                        <div className={` ${scpStyles.score_values}`}>
                          <span>L&nbsp;:&nbsp;</span> <span>{Math.round(initialLeft)}</span>
                        </div>
                        <div className={`${scpStyles.pr_bar}`}>
                          <ProgressBar
                            now={Math.round(initialLeft)}
                            max={10}
                            className="initial"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={`row ${
                        (scpStyles.score_row, scpStyles.recent_score_row)
                      }`}
                    >
                      <div
                        className={` col-md-6 col-sm-6 pr_0 ${scpStyles.left_sc_row}`}
                      >
                        <div className={` ${scpStyles.score_title}`}>
                          <p className={` ${scpStyles.recent_title}`}>
                            <span></span>{translation.Recent}
                          </p>
                        </div>
                        <div className={`${scpStyles.score_values}`}>
                          <span>R&nbsp;:&nbsp;</span>
                          <span>{Math.round(recentRight)}</span>
                        </div>
                        <div className={`${scpStyles.pr_bar}`}>
                          <ProgressBar
                            now={Math.round(recentRight)}
                            max={10}
                            className="recent"
                          />
                        </div>
                      </div>
                      <div
                        className={`col-md-6 col-sm-6 pr_0 ${scpStyles.right_sc_row}`}
                      >
                        <div className={` ${scpStyles.score_values}`}>
                          <span>L&nbsp;:&nbsp;</span> <span>{Math.round(recentLeft)}</span>
                        </div>
                        <div className={`${scpStyles.pr_bar}`}>
                          <ProgressBar
                            now={Math.round(recentLeft)}
                            max={10}
                            className="recent"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ScoreProgressLine.defaultProps = {
  title: "",
  initialRight: "",
  initialLeft: "",
  recentRight: "",
  recentLeft: "",
  type: "",
};

export default ScoreProgressLine;
