

export const Replace_PROMs_questionnaire = (value, doctor_name, specialty) => {
    let lang = sessionStorage.getItem("lang_");
  if (value) {
    const styledDoctorName = (
      <span style={{ fontWeight: "600", color: "#402783", fontSize: "15px" }}>
        {doctor_name}
      </span>
    );
    if (lang === "en") {
      const parts = value.split("[doctor’s full name who requested]");

      const output = (
        <span>
          {/* {parts[0]}  */}
          {styledDoctorName}
          {parts[1].replace("[specialty]", specialty)}
        </span>
      );

      return output;
    } else if (lang === "ara") {
      const parts = value.split("[الاسم الكامل للطبيب الذي طلب]");

      const output = (
        <span>
          {styledDoctorName}
          {parts[1].replace("[التخصص]", specialty)}
        </span>
      );

      return output;
    } else if (lang === "ger") {
      const parts = value.split(
        "[vollständiger Name des Arztes, der angefragt hat]"
      );

      const output = (
        <span>
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else if (lang === "ita") {
      const parts = value.split(
        "[nome completo del medico che ha fatto la richiesta]"
      );

      const output = (
        <span>
          {styledDoctorName}
          {parts[1].replace("[specialità]", specialty)}
        </span>
      );

      return output;
    } else if (lang === "spa") {
      const parts = value.split(
        "[nombre completo del doctor que lo solicitó]"
      );

      const output = (
        <span>
          {styledDoctorName}
          {parts[1].replace("[especialidad]", specialty)}
        </span>
      );

      return output;
    } else if (lang === "fr") {
      const parts = value.split("[nom du Docteur qui fait la demande]");

      const output = (
        <span>
          {styledDoctorName}
          {parts[1].replace("[spécialité]", specialty)}
        </span>
      );

      return output;
    } else {
      return value;
    }
  }
};
export const Replace_New_Message = (value, doctor_name) => {
    let lang = sessionStorage.getItem("lang_");
  if (value) {
    const styledDoctorName = (
      <span style={{ fontWeight: "600", color: "#402783", fontSize: "15px" }}>
        {doctor_name}
      </span>
    );
    if (lang === "en") {
      const parts = value.split("[full name of message sender]");

      const output = (
        <span>
          {parts[0]} 
          {styledDoctorName}
        </span>
      );

      return output;
    } else if (lang === "ara") {
      const parts = value.split("[الاسم الكامل لمرسل الرسالة]");

      const output = (
        <span>
          {parts[0]} 
          {styledDoctorName}
          
        </span>
      );

      return output;
    } else if (lang === "ger") {
      const parts = value.split(
        "[vollständiger Name des Absenders der Nachricht]"
      );

      const output = (
        <span>
          {parts[0]}
          {styledDoctorName}
    
        </span>
      );

      return output;
    } else if (lang === "ita") {
      const parts = value.split(
        "[nome completo del mittente del messaggio]"
      );

      const output = (
        <span>
           {parts[0]}
          {styledDoctorName}
     
        </span>
      );

      return output;
    } else if (lang === "spa") {
      const parts = value.split(
        "[nombre completo del remitente del mensaje]"
      );

      const output = (
        <span>
            {parts[0]}
          {styledDoctorName}
      
        </span>
      );

      return output;
    } else if (lang === "fr") {
      const parts = value.split("[nom de l'envoyeur]");

      const output = (
        <span>
           {parts[0]}
          {styledDoctorName}
        </span>
      );

      return output;
    } else {
      return value;
    }
  }
};
export const Replace_New_Appointment = (value, doctor_name) => {
    let lang = sessionStorage.getItem("lang_");
  if (value) {
    const styledDoctorName = (
      <span style={{ fontWeight: "600", color: "#402783", fontSize: "15px" }}>
        {doctor_name}
      </span>
    );
    if (lang === "en") {
      const parts = value.split("[doctor’s full name]");

      const output = (
        <span>
          {parts[0].replace("Dr.",'')} 
          {styledDoctorName}
        </span>
      );

      return output;
    } else if (lang === "ara") {
      const parts = value.split("[الاسم الكامل للطبيب]");

      const output = (
        <span>
          {parts[0]} 
          {styledDoctorName}
          
        </span>
      );

      return output;
    } else if (lang === "ger") {
      const parts = value.split(
        "[vollständiger Name des Arztes]"
      );

      const output = (
        <span>
          {parts[0].replace("Dr.",'')}
          {styledDoctorName}
    
        </span>
      );

      return output;
    } else if (lang === "ita") {
      const parts = value.split(
        "[nome completo del medico]"
      );
      
      const output = (
        <span>
           {parts[0].replace("Dr.",'')}
          {styledDoctorName}
     
        </span>
      );

      return output;
    } else if (lang === "spa") {
      const parts = value.split(
        "[nombre completo del doctor]"
      );

      const output = (
        <span>
            {parts[0].replace("Dr.",'')}
          {styledDoctorName}
      
        </span>
      );

      return output;
    } else if (lang === "fr") {
      const parts = value.split("[nom du Docteur]");

      const output = (
        <span>
           {parts[0].replace('Dr','')}
          {styledDoctorName}
        </span>
      );

      return output;
    } else {
      return value;
    }
  }
};
export const Replace_Patient_Added = (value, doctor_name, org) => {
    let lang = sessionStorage.getItem("lang_");
  if (value) {
    const styledDoctorName = (
      <span style={{ fontWeight: "600", color: "#402783", fontSize: "15px" }}>
        {lang === "ara"?doctor_name.replace("Dr. ",''):doctor_name}
      </span>
    );
    if (lang === "en") {
      const parts = value.split("[full name]");
    
      const output = (
        <span>
          {parts[0].replace('Dr','')} 
          {styledDoctorName}
          {parts[1].replace("[organisation]", org)}
        </span>
      );

      return output;
    } else if (lang === "ara") {
      const parts = value.split("[الاسم الكامل]");

      const output = (
        <span>
          {parts[0]} 
          {styledDoctorName}
          {parts[1].replace("[المؤسسة]", org)}
        </span>
      );

      return output;
    } else if (lang === "ger") {
      const parts = value.split(
        "[vollständiger Name]"
      );

      const output = (
        // <span>
        //   {parts[0].replace('Dr.','')} 
        //   {styledDoctorName}
        //   {parts[1].replace('[Organisation]',org)}
        // </span>
        <span>
        {parts[0] ? parts[0].replace('Dr.', '') : ''} 
        {styledDoctorName}
        {parts[1] ? parts[1].replace('[Organisation]', org) : ''}
      </span>
      );

      return output;
    } else if (lang === "ita") {
      const parts = value.split(
        "[nome completo]"
      );

      const output = (
        <span>
          {parts[0].replace('Dr.','')} 
          {styledDoctorName}
          {parts[1].replace("[organizzazione]", org)}
        </span>
      );

      return output;
    } else if (lang === "spa") {
      const parts = value.split(
        "[nombre completo]"
      );

      const output = (
        <span>
          {parts[0].replace('Dr.','')} 
          {styledDoctorName}
          {parts[1].replace("[organización]", org)}
        </span>
      );

      return output;
    } else if (lang === "fr") {
      const parts = value.split("[nom du Docteur]");

      const output = (
        <span>
          {parts[0].replace('Dr','')} 
          {styledDoctorName}
          {parts[1].replace("[établissement]", org)}
        </span>
      );

      return output;
    } else {
      return value;
    }
  }
};
export const Replace_Requested_Message = (value, doctor_name) => {
    let lang = sessionStorage.getItem("lang_");
  if (value) {
    const styledDoctorName = (
      <span style={{ fontWeight: "600", color: "#402783", fontSize: "15px" }}>
        {lang === "ara"?doctor_name.replace("Dr. ",''):doctor_name}
      </span>
    );
    if (lang === "en") {
      const parts = value.split("[full name of who requested]");
     
      const output = (
        <span>
     
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else if (lang === "ara") {
      const parts = value.split("[الاسم الكامل لمن طلب]");

      const output = (
        <span>
          {parts[0]} 
          {styledDoctorName}
          {parts[1]}
          
        </span>
      );

      return output;
    } else if (lang === "ger") {
      const parts = value.split(
        "[vollständiger Name des Antragstellers]"
      );

      const output = (
        <span>
     
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else if (lang === "ita") {
      const parts = value.split(
        "[nome completo di chi ha fatto la richiesta]"
      );
      
      const output = (
        <span>
           {parts[0].replace("Dr.",'')}
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else if (lang === "spa") {
      const parts = value.split(
        "[nombre completo de quien lo solicitó]"
      );

      const output = (
        <span>
            {parts[0].replace("Dr.",'')}
          {styledDoctorName}
          {parts[1]}
      
        </span>
      );

      return output;
    } else if (lang === "fr") {
      const parts = value.split("[nom du Docteur]");
    
      const output = (
        <span>
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else {
      return value;
    }
  }
};
export const Replace_Shared_Document = (value, doctor_name) => {
    let lang = sessionStorage.getItem("lang_");
  if (value) {
    const styledDoctorName = (
      <span style={{ fontWeight: "600", color: "#402783", fontSize: "15px" }}>
        {lang === "ara"?doctor_name.replace("Dr. ",''):doctor_name}
      </span>
    );
    if (lang === "en") {
      const parts = value.split("[full name of who shared]");
     
      const output = (
        <span>
     
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else if (lang === "ara") {
      const parts = value.split("[الاسم الكامل لمن شارك]");

      const output = (
        <span>
          {parts[0]} 
          {styledDoctorName}
          {parts[1]}
          
        </span>
      );

      return output;
    } else if (lang === "ger") {
      const parts = value.split(
        "[vollständiger Name des Teilers]"
        
      );

      const output = (
        <span>
     
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else if (lang === "ita") {
      const parts = value.split(
        "[nome completo di chi ha condiviso]"
      );
      
      const output = (
        <span>
           {parts[0].replace("Dr.",'')}
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else if (lang === "spa") {
      const parts = value.split(
        "[nombre completo de quien compartió]"
      );

      const output = (
        <span>
            {parts[0].replace("Dr.",'')}
          {styledDoctorName}
          {parts[1]}
      
        </span>
      );

      return output;
    } else if (lang === "fr") {
      const parts = value.split("[nom du Docteur]");
    
      const output = (
        <span>
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else {
      return value;
    }
  }
};

export const Replace_cancelled_appointment_message = (value, doctor_name, date) => {
  let lang = sessionStorage.getItem("lang_");
  if (value) {
    const styledDoctorName = (
      <span style={{ fontWeight: "600", color: "#402783", fontSize: "15px" }}>
        {doctor_name}
      </span>
    );

    if (lang === "en") {
      const messageParts = value
        .replace("[full name]", doctor_name)  
        .replace("[date of appointment]", date)
        .split(doctor_name);

      return (
        <span>
        {messageParts[0]} 
        {styledDoctorName} 
        {messageParts[1]} 
      </span>
      );

      
    }else if(lang === "ara"){
      const messageParts = value
        .replace("[الاسم الكامل]", doctor_name)  
        .replace("[تاريخ الموعد]", date)
        .split(doctor_name);

      return (
        <span>
        {messageParts[0]} 
        {styledDoctorName} 
        {messageParts[1]} 
      </span>
      );
    }else if(lang === "ger"){
      const messageParts = value
      .replace("[vollständiger Name]", doctor_name)  
      .replace("[Datum des Termins]", date)
      .split(doctor_name);

    return (
      <span>
      {messageParts[0]} 
      {styledDoctorName} 
      {messageParts[1]} 
    </span>
    );
    }else if(lang === "ita"){
      const messageParts = value
      .replace("[nome completo]", doctor_name)  
      .replace("[data dell'appuntamento]", date)
      .split(doctor_name);

    return (
      <span>
      {messageParts[0]} 
      {styledDoctorName} 
      {messageParts[1]} 
    </span>
    );
    }else if(lang === "spa"){
      const messageParts = value
      .replace("[nombre completo]", doctor_name)  
      .replace("[fecha de la cita]", date)
      .split(doctor_name);

    return (
      <span>
      {messageParts[0]} 
      {styledDoctorName} 
      {messageParts[1]} 
    </span>
    );
    }else if(lang === "fr"){
      const messageParts = value
      .replace("[nom]", doctor_name)  
      .replace("[date du rendez-vous]", date)
      .split(doctor_name);

    return (
      <span>
      {messageParts[0]} 
      {styledDoctorName} 
      {messageParts[1]} 
    </span>
    );
    }
  }
  return null;
}

export const Replace_upcoming_appointment = (value, doctor_name, date)=>{
  let lang = sessionStorage.getItem("lang_");
  console.log(doctor_name,"doctor_namedoctor_name")
  if (value) {
    const styledDoctorName = (
      <span style={{ fontWeight: "600", color: "#402783", fontSize: "15px" }}>
        {doctor_name}
      </span>
    );

    if (lang === "en") {
      const messageParts = value
        .replace("Dr. [full name]", doctor_name)  
        .replace("[date]", date)
        .split(doctor_name);

      return (
        <span>
        {messageParts[0]} 
        {styledDoctorName} 
        {messageParts[1]} 
      </span>
      );
    }else if(lang === "ara"){
      const messageParts = value
        .replace("[الاسم الكامل] في", doctor_name)  
        .replace("[تاريخ الموعد]", date)
        .split(doctor_name);

      return (
        <span>
        {messageParts[0]} 
        {styledDoctorName} 
        {messageParts[1]} 
      </span>
      );
    }else if(lang === "ger"){
      const messageParts = value
      .replace("Dr. [vollständiger Name]", doctor_name)  
      .replace("[Datum]", date)
      .split(doctor_name);

    return (
      <span>
      {messageParts[0]} 
      {styledDoctorName} 
      {messageParts[1]} 
    </span>
    );
    }else if(lang === "ita"){
      const messageParts = value
      .replace("Dr. [nome completo]", doctor_name)  
      .replace("[data]", date)
      .split(doctor_name);

    return (
      <span>
      {messageParts[0]} 
      {styledDoctorName} 
      {messageParts[1]} 
    </span>
    );
    }else if(lang === "spa"){
      const messageParts = value
      .replace("Dr. [nombre completo]", doctor_name)  
      .replace("[fecha]", date)
      .split(doctor_name);

    return (
      <span>
      {messageParts[0]} 
      {styledDoctorName} 
      {messageParts[1]} 
    </span>
    );
    }else if(lang === "fr"){
      const messageParts = value
      .replace("Dr [nom du Docteur]", doctor_name)  
      .replace("[date]", date)
      .split(doctor_name);

    return (
      <span>
      {messageParts[0]} 
      {styledDoctorName} 
      {messageParts[1]} 
    </span>
    );
    }
    
    return null;
}
}
