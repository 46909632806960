// import { useEffect, useRef } from "react";
// import axios from "axios";

// import { useKeycloak } from "@react-keycloak/web";

// const useAxiosUpload = (baseURL) => {

//   const axiosInstanceUpload = useRef();

//   const { keycloak, initialized } = useKeycloak();
//   const kcToken = keycloak.token;

//   useEffect(() => {
//     axiosInstanceUpload.current = axios.create({
//       baseURL: process.env.REACT_APP_APIURLUPLOAD,
//       headers: {
//         Authorization: initialized ? `Bearer ${kcToken}` : undefined,
//       },
//     });

//     return () => {
//       axiosInstanceUpload.current = undefined;
//     };
//   }, [baseURL, initialized, kcToken]);

//   return axiosInstanceUpload ;
// };
// export default useAxiosUpload;

import { useEffect, useRef } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
const useAxiosUpload = (baseURL) => {
  const axiosInstanceUpload = useRef();
  const { keycloak, initialized } = useKeycloak();
  const interval = setInterval(5, 60 * 1000);
  useEffect(() => {
    // const refresh = async () => {
    //   if (initialized && keycloak.authenticated) {
    //     const refreshed = await keycloak.updateToken(-1);
    //     if (refreshed) {
    //      
    //     } else {
    //       
    //     }
    //   }
    // };
    const createAxiosInstance = () => {
      axiosInstanceUpload.current = axios.create({
        baseURL: process.env.REACT_APP_APIURLUPLOAD,
        headers: {
          Authorization:
            initialized && keycloak.token
              ? `Bearer ${keycloak.token}`
              : undefined,
        },
      });
      // axiosInstanceUpload.current.interceptors.request.use(async (config) => {
      //   if (initialized && keycloak.authenticated) {
      //     const refreshed = await keycloak.updateToken(-1);
      //     if (refreshed) {
      //    
      //       config.headers.Authorization = `Bearer ${keycloak.token}`;
      //     } else {
      //      
      //     }
      //   }
      //   return config;
      // });
    };
    createAxiosInstance();
    // const interval = setInterval(refresh, 60 * 1000);
    // return () => {
    //   clearInterval(interval);
    //   axiosInstanceUpload.current = null;
    // };
  }, [interval]);
  return axiosInstanceUpload;
};
export default useAxiosUpload;
