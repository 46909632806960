const initialState = { 
    arr : new Date().getMilliseconds()
}
  
  const refRedux = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_REFRESH':
        return { ...state, arr: action.data };

      default:
        return state;
    }
  }

  export default refRedux;
